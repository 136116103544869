import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import DOMPurify from 'dompurify';

import { TTrustTransform } from './trust.interface';

/**
 * Trust content pipe
 * https://medium.com/@swarnakishore/
 * angular-safe-pipe-implementation-to-bypass-domsanitizer-stripping-out-content-c1bf0f1cc36b
 *
 * <div [innerHtml]="htmlSnippet | trust: 'html'"></div>
 *
 */
@Pipe({
  name: 'trust',
})
export class TrustPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  /**
   * PipeTransform method
   *
   * @param value - source value
   * @param type - 'html' | 'style' | 'url' | 'resourceUrl'
   */
  transform(value: any, type: string): TTrustTransform {
    const sanitizedContent = DOMPurify.sanitize(value);
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(sanitizedContent);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedContent);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

import { IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

/**
 * Get shadow hint for field
 *
 * @param code - shadow hint msg
 * @param doc - doc instance
 */
export function getShadowHint(code: string, doc: IDocument): string {
  return DocHelper.getMessage(`shadow_hint_${code}`, doc);
}

<ng-container *ngFor="let group of to.info">
  <div class="group-title">
    {{ group.titleMsg }}
  </div>
  <div *ngFor="let item of group.items">
    <div class="item-label">{{ item.label }}</div>
    <ispui-collapse class="item-value" [previewHeight]="15" #collapse>
      <ispui-collapse-button>
        <ispui-link class="collapse-button" type="default-hover">
          {{ collapse.isOpen ? to.collapseMsg : to.expandMsg }}
        </ispui-link>
      </ispui-collapse-button>
      <div>{{ item.value || '—' }}</div>
    </ispui-collapse>
  </div>
</ng-container>

import { Injectable } from '@angular/core';

import { TFormMode } from 'app/form/form.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { BehaviorSubject } from 'rxjs';

import { awaitStencilElementLazy } from 'utils/await-stencil-element-lazy';

import { HiddenService } from './hidden.service';
import { ModeService } from './mode.service';

import { DynamicFormService } from '../dynamic-form.service';
import {
  DynamicFormLayoutPlace,
  ISPFieldConfig,
  ISPFieldWrapper,
} from '../model';
import { filterConfigs, findConfig } from '../utils/formly-configs';

export const DYNAMIC_FORM_SCROLLABLE_CONTAINER_ID =
  'isp-dynamic-form-scrollable-container';

export const DYNAMIC_FORM_HEADER_ID = 'isp-dynamic-form-header';

export const DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR = `#${DYNAMIC_FORM_SCROLLABLE_CONTAINER_ID}`;

export const DYNAMIC_FORM_HEADER_SELECTOR = `#${DYNAMIC_FORM_HEADER_ID}`;

/**
 * Dynamic form layout service
 */
@Injectable()
export class LayoutService {
  /** df service. @TODO i.ablov remove this relation */
  private dynamicformService: DynamicFormService;

  /** form scrollable element subject */
  private readonly formContainerSubject: BehaviorSubject<
    HTMLElement | undefined
  > = new BehaviorSubject(undefined);

  /** form header element subject */
  private readonly formHeaderSubject: BehaviorSubject<HTMLElement | undefined> =
    new BehaviorSubject(undefined);

  /** form footer element subject */
  private readonly formFooterSubject: BehaviorSubject<HTMLElement | undefined> =
    new BehaviorSubject(undefined);

  /** form scrollabe element stream. Used to store form element, that can be manually scrolled */
  readonly formContainer$ = this.formContainerSubject.asObservable();

  /** form header element stream. Used to get header height for fields precise scrolling */
  readonly formHeader$ = this.formHeaderSubject.asObservable();

  /** form footer element stream */
  readonly formFooter$ = this.formHeaderSubject.asObservable();

  get formContainer(): HTMLElement | undefined {
    return this.formContainerSubject.value;
  }

  set formContainer(element: HTMLElement) {
    this.formContainerSubject.next(element);
  }

  get formHeader(): HTMLElement | undefined {
    return this.formHeaderSubject.value;
  }

  set formHeader(element: HTMLElement) {
    this.formHeaderSubject.next(element);
  }

  get formHeaderHeight(): number {
    return this.formHeaderSubject.value?.clientHeight || 0;
  }

  get formFooter(): HTMLElement | undefined {
    return this.formFooterSubject.value;
  }

  set formFooter(element: HTMLElement) {
    this.formFooterSubject.next(element);
  }

  get formFooterHeight(): number {
    return this.formFooterSubject.value?.clientHeight || 0;
  }

  constructor(
    private readonly modeService: ModeService,
    private readonly hiddenService: HiddenService,
    private readonly bus: MessageBusService,
  ) {}

  private getAllVisibleFields(
    layoutPlace: DynamicFormLayoutPlace = 'body',
  ): ISPFieldConfig[] {
    return filterConfigs(
      this.dynamicformService.fieldList,
      c =>
        !c.fieldGroup &&
        !c.templateOptions.isHidden &&
        ((!c.templateOptions.layoutPlace && layoutPlace === 'body') ||
          c.templateOptions.layoutPlace === layoutPlace),
    );
  }

  init(dynamicformService: DynamicFormService): void {
    this.dynamicformService = dynamicformService;
  }

  /**
   * Get config of page for provided field. Return undefined, if field is not in page
   *
   * @param config - field config
   */
  getFieldPage(config: ISPFieldConfig): ISPFieldConfig | undefined {
    // if field is inside a closed page, we should open it
    const page = findConfig(this.dynamicformService.fieldList, f => {
      return (
        f.wrappers.includes(ISPFieldWrapper.Page) &&
        findConfig(f.fieldGroup, ff => ff.key === config.key) !== undefined
      );
    });

    return page;
  }

  /**
   * Toggle page state
   *
   * @param page - page config
   * @param openState - page opening state
   */
  togglePage(page: ISPFieldConfig, openState?: boolean): Promise<void> {
    return new Promise(resolve => {
      this.bus
        .emit('form-collapse-toggle', {
          name: page.templateOptions.data.name,
          openState,
        })
        .then(resolve);
    });
  }

  /**
   * Toggle page state of provided field. If field is not in page then resolved instantly
   *
   * @param config - field config
   * @param openState - page opening state
   */
  toggleFieldPage(config: ISPFieldConfig, openState?: boolean): Promise<void> {
    const page = this.getFieldPage(config);

    if (page) {
      return this.togglePage(page, openState);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Check that field is on other displaying form mode
   *
   * @param config - field config
   */
  isFieldOnOtherMode(config: ISPFieldConfig): boolean {
    return this.hiddenService.isHiddenOnlyByMode(config.key);
  }

  /**
   * Toggle form displaying mode. Resolves after form rerender over
   *
   * @param mode - form displaying mode
   */
  togglePageMode(mode?: TFormMode): Promise<void> {
    return new Promise(resolve => {
      this.modeService.toggleMode(mode);

      // @WARN trigger formly cdr! May not work in different situations
      this.dynamicformService.formGroup.updateValueAndValidity();

      // wait for form rerender after mode changing
      setTimeout(resolve);
    });
  }

  /**
   * Check that field or some DOM element in form view (between header and footer)
   *
   * @param param - DOM element of field config
   */
  isInFormView(param: Element | ISPFieldConfig): boolean {
    let element: Element;
    if (!(param instanceof Element)) {
      element = document.getElementById(param.id);
    } else {
      element = param;
    }

    const header = this.formHeader?.getBoundingClientRect();
    const footer = this.formFooter?.getBoundingClientRect();
    const form = this.formContainer.getBoundingClientRect();
    const field = element.getBoundingClientRect();

    const isInForm = field.top >= form.top && field.bottom <= form.bottom;
    const isBelowHeader = header ? field.top >= header.bottom : true;
    const isAboveFooter = footer ? field.bottom <= footer.top : true;

    return isInForm && isBelowHeader && isAboveFooter;
  }

  isFirstVisibleConfig(
    config: ISPFieldConfig,
    layoutPlace: DynamicFormLayoutPlace = 'body',
  ): boolean {
    const configs = this.getAllVisibleFields(layoutPlace);
    return configs[0]?.id === config.id;
  }

  isLastVisibleConfig(
    config: ISPFieldConfig,
    layoutPlace: DynamicFormLayoutPlace = 'body',
  ): boolean {
    const configs = this.getAllVisibleFields(layoutPlace);
    return configs[configs.length - 1]?.id === config.id;
  }

  /**
   * Change form mode and open form pages of field, if needed
   *
   * @param config - field config
   */
  async navigateToField(config: ISPFieldConfig): Promise<void> {
    if (this.isFieldOnOtherMode(config)) {
      await this.togglePageMode();
    }

    await this.toggleFieldPage(config, true);
  }

  /**
   * Scroll to field. Automatically open field page and toggle form mode if needed
   *
   * @param config - field config
   * @param ifNeeded - check that element already in form view. If so, no scroll will be applied
   */
  async scrollToField(
    config: ISPFieldConfig,
    ifNeeded?: boolean,
  ): Promise<void> {
    await this.navigateToField(config);

    return this.scrollTo(document.getElementById(config.id), ifNeeded);
  }

  /**
   * Scroll to element on the form
   *
   * @param elementOrSelector - html element or selector string
   * @param ifNeeded - check that element already in form view. If so, no scroll will be applied
   */
  async scrollTo(
    elementOrSelector: HTMLElement | string,
    ifNeeded?: boolean,
  ): Promise<void> {
    let element: HTMLElement;
    if (typeof elementOrSelector === 'string') {
      element = document.querySelector(elementOrSelector);
    } else {
      element = elementOrSelector;
    }

    if (!element) {
      return Promise.reject(
        `LayoutService: No field with such query selector ${
          elementOrSelector as string
        }`,
      );
    }

    // @HACK after form initiallization, on first form render it will have no visual displaying
    // because of all form wrapped into 'ispui-tooltip-wrapper'. So no scroll will be triggered
    // so we need to wait until wrapper will be loaded
    await awaitStencilElementLazy('ispui-tooltip-wrapper');

    if (ifNeeded && this.isInFormView(element)) {
      return;
    }

    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { IconProviderModule } from 'common/icon-provider/icon-provider.module';

import { SiteSummaryFieldComponent } from './site-summary.component';

import { ISPFieldType } from '../../model';

/**
 * Site summary field module
 */
@NgModule({
  declarations: [SiteSummaryFieldComponent],
  imports: [
    CommonModule,
    IconProviderModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.SiteSummary,
          component: SiteSummaryFieldComponent,
        },
      ],
    }),
  ],
})
export class SiteSummaryFieldModule {}

import { IDocument, ISelect } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface SelectControlWithValue {
  readonly control: ISelect;
  readonly type: 'select';
  value: string[] | string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForSelect(
  control: ISelect,
  doc: IDocument,
): SelectControlWithValue {
  return {
    value: DocHelper.getSelectValue(control.$name, doc),
    control,
    type: 'select',
  };
}

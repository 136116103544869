import { IDocument, IInput } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface CheckboxControlWithValue {
  readonly control: IInput;
  readonly type: 'checkbox';
  value: string;
}

/**
 * Check that input control meta is checkbox meta
 *
 * @param control - control metadata
 */
export function isCheckboxControl(control: IInput): boolean {
  return control.$type === 'checkbox';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForCheckbox(
  control: IInput,
  doc: IDocument,
): CheckboxControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'checkbox',
  };
}

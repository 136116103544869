import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicFormModule } from 'common/dynamic-form/dynamic-form.module';

import { ExtformComponent } from './extform.component';
import { SocialLinksModule } from './social-links/social-links.module';

@NgModule({
  declarations: [ExtformComponent],
  imports: [CommonModule, DynamicFormModule, SocialLinksModule],
  exports: [ExtformComponent],
  bootstrap: [ExtformComponent],
})
export class ExtformModule {}

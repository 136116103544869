import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUIDropdownModule } from '@ispui/ng-dropdown';
import { ISPUIInputModule } from '@ispui/ng-input';
import { ISPUILinkModule } from '@ispui/ng-link';
import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { SessionTipModule } from 'common/session-tip/session-tip.module';

import { SavedMessagesComponent } from './saved-messages/saved-messages.component';
import { TextareaFieldComponent } from './textarea.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's input field component
 */
@NgModule({
  declarations: [TextareaFieldComponent, SavedMessagesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.TextArea,
          component: TextareaFieldComponent,
        },
      ],
    }),
    ISPUILinkModule,
    ISPUIDropdownModule,
    ISPUIPreloaderRoundModule,
    ISPUIInputModule,
    ISPUITooltipModule,
    SessionTipModule,
  ],
})
export class TextareaFieldModule {}

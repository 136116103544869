import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import {
  IFormCollapseUi,
  IFormCollapseEvent,
} from './model/form-collapse.interface';

const SCROLL_DELAY = 5;

/**
 * Collapsible component module. Designed specifically for forms.
 *
 * Usage:
 * ```html
 * <isp-form-collapse [data]="page">
 *   <div>put the collapsible contents here</div>
 * </isp-form-collapse>
 * ```
 */
@UntilDestroy()
@Component({
  selector: 'isp-form-collapse',
  templateUrl: './form-collapse.component.html',
  styleUrls: ['./scss/form-collapse.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCollapseComponent implements OnInit {
  private readonly isOpenedSubject = new BehaviorSubject(false);

  /** header tooltip */
  readonly headerTooltip$ = this.isOpenedSubject.pipe(
    map(isOpened =>
      isOpened ? this.collapseTooltipMsg : this.expandTooltipMsg,
    ),
  );

  /** collapse data */
  @Input() data: IFormCollapseUi;

  /** refresh observable */
  @Input() refresh$: Observable<any> = null;

  /** whether or not the component can show the refresh icon */
  @Input() canRefresh: boolean;

  /** Tooltip for refresh button */
  @Input() refreshTooltipMsg: string;

  /** custom modificator for header, for clients needs */
  @Input() classNameModificator = 'default';

  /** collapse msg */
  @Input() collapseTooltipMsg: string;

  /** expand msg */
  @Input() expandTooltipMsg: string;

  /** whether the collapsible is opened */
  isOpened: boolean;

  /** collapse elements name */
  get id(): string {
    return `collapse-${this.data.name}`;
  }

  /** header icon name */
  get icon(): string {
    return this.isOpened ? 'down' : 'up';
  }

  /** header icon custom styles */
  readonly iconStyles = {
    width: '10px',
    height: '10px',
  };

  /** collapsible's expand/collapse event */
  @Output() readonly toggle = new EventEmitter<IFormCollapseEvent>();

  /** refresh icon custom styles */
  readonly retryIconStyles = {
    width: '15px',
    height: '13px',
  };

  /** whether the collapsible's button is refreshing */
  isRefreshing: boolean;

  @ViewChild('header') header: ElementRef<HTMLElement>;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bus: MessageBusService,
  ) {}

  /**
   * Subscribe to event of page-collapse toggling from other components
   */
  private initPageToggle(): void {
    this.bus
      .on$('form-collapse-toggle')
      .pipe(
        filter(event => event.payload.name === this.data.name),
        untilDestroyed(this),
      )
      .subscribe(event => {
        this.emitCollapseEvent(event.payload.openState);

        // set timeout for waiting until page toggles it's state and rerenders
        setTimeout(() => {
          event.response();
        }, SCROLL_DELAY);
      });
  }

  /**
   * Subscribe to event of scrolling to page-collapse element
   */
  private initPageScroll(): void {
    this.bus
      .on$('form-collapse-scroll-to')
      .pipe(
        filter(event => event.payload.name === this.data.name),
        untilDestroyed(this),
      )
      .subscribe(event => {
        const headerElement = this.header.nativeElement as HTMLElement;
        switch (event.payload.scrollPosition) {
          case 'top':
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'end',
            });
          case 'bottom':
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'end',
            });
          default:
            return headerElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            });
        }
      });
  }

  ngOnInit(): void {
    this.isOpened = !this.data.isCollapsed || !this.data.title.length;
    this.isOpenedSubject.next(this.isOpened);
    this.initPageToggle();
    this.initPageScroll();
  }

  /**
   * Toggles the collapsible
   *
   * @param enterKeydownEvent - passed if method was called on KeyboardEvent with enter button
   */
  toggleCollapse(enterKeydownEvent?: KeyboardEvent): void {
    // for avoiding form submit
    if (enterKeydownEvent) {
      enterKeydownEvent.preventDefault();
      enterKeydownEvent.stopPropagation();
    }
    this.emitCollapseEvent();
  }

  /**
   * Emit collapse event
   *
   * @param isOpened
   */
  emitCollapseEvent(isOpened = !Boolean(this.isOpened)): void {
    if (isOpened === this.isOpened) {
      return;
    }
    this.isOpened = isOpened;
    this.isOpenedSubject.next(this.isOpened);

    const event = {
      isOpened: this.isOpened,
      name: this.data.name,
    };
    this.toggle.next(event);
    this.bus.emit('form-collapse-state', event);
  }

  /**
   * Refreshes the collapsible's content
   *
   * @param event - mouse event
   */
  refreshContent(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.refresh$) {
      return;
    }
    this.isRefreshing = true;
    this.refresh$.pipe(first()).subscribe(() => {
      this.isRefreshing = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

@Component({
  selector: 'isp-template-field',
  templateUrl: './template.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateFieldComponent extends ISPFieldTypeBase<ISPFieldType.Template> {}

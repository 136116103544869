import {
  Component,
  ChangeDetectionStrategy,
  Input,
  forwardRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'isp-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./scss/slider.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSliderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSliderComponent implements ControlValueAccessor {
  /** Minimum value for slider */
  @Input() min = 0;

  /** Maximum value for slider */
  @Input() max = 100;

  /** Step for range and keyboard input */
  @Input() step = 1;

  /** Input id */
  @Input() id: string;

  /** Label for input */
  @Input() label: string;

  /** Whether field is required for input */
  @Input() required: boolean;

  /** Whether field should show unlimited button */
  @Input() unlimited = false;

  /** Sprite icon's name. Used to display an ulimit value button */
  @Input() unlimitedIconName = 'ff-unlimit-a';

  /** String for input value */
  @Input() unlimitedValue: string;

  /** Field's description (at the end of input) */
  @Input() description: string;

  /** Slider value */
  @Input() value: number | string = 0;

  /** Whether field is disabled */
  @Input() disabled: boolean;

  /** Event on focus number input or range */
  @Output() readonly sliderFocus = new EventEmitter<Event>();

  /** Event on blur number input or range */
  @Output() readonly sliderBlur = new EventEmitter<Event>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  /**
   * Returns numeric value.
   * If value is unlimited returns middle point of the range.
   */
  getNumberValue(): number {
    if (typeof this.value === 'number') {
      return this.value;
    } else {
      return Number(this.value);
    }
  }

  /** Is current value equals unlimited */
  isUnlimited(): boolean {
    return this.value === this.unlimitedValue;
  }

  /**
   * Handler for input's change events
   *
   * @param event - change event
   */
  onChange(event: Event): void {
    const sliderValue = (event.target as HTMLInputElement).value;
    const numberValue = parseInt(sliderValue, 10);

    setTimeout(() => {
      if (numberValue > this.max) {
        this.updateValue(this.max);
      } else if (numberValue < this.min) {
        this.updateValue(this.min);
      } else {
        this.updateValue(Number.isNaN(numberValue) ? this.min : numberValue);
      }
    });
  }

  /**
   * Handler for focus event on number input or range
   *
   * @param event - focus event
   */
  onFocus(event: Event): void {
    this.sliderFocus.emit(event);
  }

  /**
   * Handler for blur event on number input or range
   *
   * @param event - blur event
   */
  onBlur(event: Event): void {
    this.sliderBlur.emit(event);
    this.onTouched();
  }

  /**
   * Handler for unlimited button click. Sets unlimited value
   */
  setUnlimitedValue(): void {
    this.updateValue(this.unlimitedValue);
  }

  updateValue(value: number | string): void {
    this.value = value;
    this.onChangeWrapper(this.value);

    this.cdr.markForCheck();
  }

  writeValue(value: number | string): void {
    this.updateValue(value);

    this.cdr.markForCheck();
  }

  onChangeWrapper = (_value: number | string) => undefined;

  registerOnChange(fn: any): void {
    this.onChangeWrapper = fn;
  }

  onTouched = () => undefined;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.cdr.markForCheck();
  }
}

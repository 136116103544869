import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUICollapseModule } from '@ispui/ng-collapse';
import { ISPUILinkModule } from '@ispui/ng-link';

import { ChatSummaryFieldComponent } from './chat-summary.component';

import { ISPFieldType } from '../../model';
/**
 * Chat summary field module
 */
@NgModule({
  declarations: [ChatSummaryFieldComponent],
  imports: [
    CommonModule,
    ISPUICollapseModule,
    ISPUILinkModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.ChatSummary,
          component: ChatSummaryFieldComponent,
        },
      ],
    }),
  ],
})
export class ChatSummaryFieldModule {}

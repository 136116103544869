import { IFormPage } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { PageWrapperTO } from './model/page-to.interface';

import { ISPFieldConfig, ISPFormState } from '../../model';

/**
 * Append template options, specific for page field wrapper
 *
 * @param config - config to append
 * @param state - dynamic form state
 * @param page - page metadata
 */
export function appendPageOptions(
  config: ISPFieldConfig,
  state: ISPFormState,
  page: IFormPage,
): void {
  const containsErrorFromServer =
    Boolean(state.errorFromServer) &&
    page.field?.some(f => f.$name === state.errorFromServer.$object);
  const templateOptions: PageWrapperTO = {
    data: {
      title: DocHelper.getMessage(page.$name, state.doc),
      name: page.$name,
      isCollapsed: !containsErrorFromServer && page.$collapsed === 'yes',
      isBaseMode: state.modeService.mode === 'base',
      isDrawerMode: state.drawerChildService.isActive(),
    },
    collapseTooltipMsg: state.context.collapseTooltipMsg,
    expandTooltipMsg: state.context.expandTooltipMsg,
  };

  config.templateOptions = {
    ...templateOptions,
    ...config.templateOptions,
  };

  config.expressionProperties = {
    ...config.expressionProperties,
    'templateOptions.data': (
      _,
      formState: ISPFormState,
      fieldConfig: ISPFieldConfig,
    ) => ({
      ...fieldConfig.templateOptions.data,
      isBaseMode: formState.modeService.mode === 'base',
    }),
  };
}

/**
 * Check element stencil hydration state
 *
 * @HACK everything related only this one considering - ALL lazy-loadable stencil elements on thair hydration over will get this class
 * @WARN stencil element could be builded with different hydration config, and may have another class, not 'hydrated'
 * @param element - html element
 */
function isHydrated(element: HTMLElement): boolean {
  return element.classList.contains('hydrated');
}

/**
 * Working with stencil can be a bit tricky if you don't understand how works its custom elements defining and loading
 *
 * Some elements are lazy-loadable. It means that they have NO logic and STYLES for a small time. Lazy means that it will start
 * loading this logic only on FIRST render of this stencil element
 *
 * So if you need to perform some action on first render, maybe in AfterViewInit, you first need to wait a little bit, or you will get some error
 *
 * This function allows you to wait this time, if needed. Optionaly it has confinient flags to wait first render or just custom element defining
 *
 * @param element - html element
 * @param options - additional options
 * @param options.firstRender - wait a small moment (macrotask) for element to first render. Usually need if element was getted from ViewChild. True by default
 * @param options.defined - wait for custom element to be defined. True by default
 * @param options.lazy - wait lazy loading element logic. Chunk loading state checks by 'hydrated' class. False by default.
 */
export async function awaitStencilElement<T extends HTMLElement>(
  element: T,
  options?: {
    defined?: boolean;
    firstRender?: boolean;
    lazy?: boolean;
  },
): Promise<T> {
  if (options?.defined !== false) {
    await customElements.whenDefined(element.tagName.toLowerCase());
  }

  if (options?.firstRender !== false) {
    await new Promise(r => setTimeout(r));
  }

  if (options?.lazy !== true) {
    return Promise.resolve(element);
  }

  if (isHydrated(element)) {
    return Promise.resolve(element);
  }

  return new Promise(resolve => {
    const observer = new MutationObserver(() => {
      if (isHydrated(element)) {
        observer.disconnect();
        resolve(element);
      }
    });
    observer.observe(element, { attributes: true });
  });
}

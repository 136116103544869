<!-- @TODO i.ablov add zebra to rows, depending on 'to.alternation' flag! -->
<table *ngIf="to.rows$ | async as rows" class="list" cellspacing="0">
  <tr *ngIf="displayHeader(rows)" class="list__head">
    <th *ngFor="let cell of to.header" class="list__head-row">
      {{ cell.title }}
    </th>
  </tr>
  <tr
    *ngFor="let row of rows; trackBy: trackByRowFn"
    class="list__row"
    [ngClass]="{
      list__row_underline: to.underline,
      list__row_hover: to.hover && !row.color,
      list__row_color: row.color,
      list__row_color_grey: row.color === 'grey',
      list__row_color_transparent: row.color === 'transparent'
    }"
    role="listitem"
  >
    <isp-formly-list-cell
      *ngFor="let cell of row.cells; trackBy: trackByCellFn"
      [ngClass]="getCellClasses(cell)"
      [style]="{
        width: cell.$width || (cell.$type === 'button' ? '0' : 'auto')
      }"
      [cell]="cell"
      [row]="row"
      [configs]="field.fieldGroup"
      (buttonClick)="emitButtonClick($event, row)"
    ></isp-formly-list-cell>
  </tr>
</table>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUIValidationErrorModule } from '@ispui/ng-validation-error';

import { ValidationErrorWrapperComponent } from './validation-error.component';

import { ISPFieldWrapper } from '../../model';

/**
 * Field base wrapper
 */
@NgModule({
  declarations: [ValidationErrorWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ISPUIValidationErrorModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: ISPFieldWrapper.ValidationError,
          component: ValidationErrorWrapperComponent,
        },
      ],
    }),
  ],
})
export class ValidationErrorModule {}

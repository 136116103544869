import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { ActionService } from 'app/services/action.service';
import { PreloadedActionService } from 'app/services/preloaded-action.service';
import { Observable } from 'rxjs';

import { convertParamsToStringWithEncode } from 'utils/convert-params-to-string';
import { DocHelper } from 'utils/dochelper';

import { docInjectionToken, IDocService } from './chat.interface';

@Injectable()
export class ChatService {
  constructor(
    @Inject(docInjectionToken)
    private readonly docService: IDocService,
    private readonly preloadedActionService: PreloadedActionService,
    private readonly actionService: ActionService,
  ) {}

  /**
   * Chat rate click handler
   *
   * @param elid - chat message id
   * @param func - rate action func
   */
  rateMessage(elid: string, func: string): Observable<any> {
    return this.preloadedActionService.getAction({
      elid,
      func,
      plid: DocHelper.elid(this.docService.doc),
    });
  }

  /**
   * Redirect to file loading after click on file in chat
   *
   * @param action - file action func
   * @param params - file params
   */
  redirectToFileLoading(action: string, params: Params): void {
    const url = this.docService.doc.$host + this.docService.doc.$binary;
    this.actionService.openNewWindow(
      `${url}?${convertParamsToStringWithEncode({
        func: action,
        ...params,
      })}`,
    );
  }
}

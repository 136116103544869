import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Chat formly component
 */
@Component({
  selector: 'isp-formly-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./scss/chat.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatFieldComponent extends ISPFieldTypeBase<ISPFieldType.Chat> {}

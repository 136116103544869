import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconSimularPipe } from './icon-simular';

/**
 * Icon Simular module
 */
@NgModule({
  imports: [CommonModule],
  declarations: [IconSimularPipe],
  providers: [],
  exports: [IconSimularPipe],
})
export class IconSimularModule {}

import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

import '@ispui/notification-group/dist/components';

import { INotification } from '@ispui/notification-group';

import { IMessageSet } from './api5-service/api.interface';

export { INotification };

export type NotificationStatus = 'danger' | 'info' | 'success' | 'warning';

export type NotificationEventType = 'click' | 'close' | 'hide' | 'link-click';

export interface INotificationEvent<T = any> {
  type: NotificationEventType;
  id: string;
  data?: T;
}

/**
 * Notification service to show popup notifications
 */
@Injectable({
  providedIn: 'root',
})
export class ISPNotificationService {
  private notificationGroupEl: HTMLIspuiNotificationGroupElement;

  private readonly notificationEvents$ = new Subject<INotificationEvent>();

  messages: IMessageSet;

  private getNotificationGroupElement(): HTMLIspuiNotificationGroupElement {
    if (!this.notificationGroupEl) {
      const existingNotificationGroup = document.querySelector(
        'ispui-notification-group.main-notification-group',
      );
      if (existingNotificationGroup) {
        this.notificationGroupEl =
          existingNotificationGroup as HTMLIspuiNotificationGroupElement;
      } else {
        const tpl = document.createElement('template');
        tpl.innerHTML = `
        <ispui-notification-group
          class="main-notification-group"
          buttons="hide"
          style="${
            this.messages?.msg_expand
              ? `
                --ispui-notification__expand-button-text:'${this.messages.msg_expand}';
                --ispui-notification__collapse-button-text:'${this.messages.msg_collapse}';
                `
              : ''
          };
          ">
        </ispui-notification-group>
        `;
        document.body.appendChild(tpl.content);
        this.notificationGroupEl = document.querySelector(
          '.main-notification-group',
        );
        if (this.messages?.msg_closeall) {
          this.notificationGroupEl.tooltips = {
            close: this.messages.msg_closeall,
          };
        }
      }
      this.notificationGroupEl.addEventListener('closeRequest', e => {
        this.notificationEvents$.next({
          type: 'close',
          id: (e.target as HTMLIspuiNotificationElement).getAttribute(
            'notification-id',
          ),
          data: (e.target as HTMLIspuiNotificationElement).data,
        });
      });
      this.notificationGroupEl.addEventListener('hideall', (e: CustomEvent) => {
        e.detail?.forEach(
          ({
            element,
            id,
          }: {
            element: HTMLIspuiNotificationElement;
            id: string;
          }) => {
            this.notificationEvents$.next({
              type: 'close',
              id,
              data: element.data,
            });
          },
        );
      });
      this.notificationGroupEl.addEventListener('click', e => {
        const notificationParent = (e.target as HTMLElement).closest(
          'ispui-notification',
        );
        if (notificationParent) {
          this.notificationEvents$.next({
            type: 'click',
            id: notificationParent.getAttribute('notification-id'),
            data: (notificationParent as any).data,
          });
        }
      });
    }
    return this.notificationGroupEl;
  }

  showNotification(
    notification: INotification,
  ): Observable<INotificationEvent> {
    const notificationId: string = notification.id || Math.random().toString();
    setTimeout(() => {
      this.getNotificationGroupElement().addNotification({
        ...notification,
        links: notification.links?.map(link => {
          return {
            ...link,
            callback: (ev: MouseEvent): void => {
              this.notificationEvents$.next({
                type: 'link-click',
                id: notification.id,
                data: notification.data,
              });
              if (link.callback) {
                return link.callback(ev);
              }
            },
          };
        }),
        id: notificationId,
      });
    });
    return this.notificationEvents$.pipe(
      filter(e => e.id === notificationId),
      takeWhile(e => e.type !== 'close', true),
    );
  }

  /**
   * Show notify with usefullinks
   *
   * @param msg
   * @param title
   * @param id
   */
  showUsefulLinks(
    msg: string,
    title: string,
    id: string,
  ): Observable<INotificationEvent> {
    const notification: INotification = {
      id,
      title,
      message: msg,
      status: 'info',
      isHtml: true,
    };

    return this.showNotification(notification);
  }

  close(id: string): void {
    // @HACK setTimeout is required for properly routing animation works
    setTimeout(() => {
      this.getNotificationGroupElement().closeNotificationById(id);
    });
  }

  isNotificationDisplayed(id: string): boolean {
    return Boolean(
      this.notificationGroupEl?.querySelector(
        `ispui-notification[notification-id="${id}"]`,
      ),
    );
  }

  /**
   * Show error banner
   *
   * @param errorMsg
   * @param title
   * @param link
   */
  showError(
    errorMsg: string,
    title: string,
    link: string = null,
  ): Observable<INotificationEvent> {
    const notificationId = Date.now().toString();
    const notification: INotification = {
      id: notificationId,
      title,
      message: errorMsg,
      status: 'danger',
      links: link ? [{ text: link }] : undefined,
    };

    return this.showNotification(notification);
  }
}

import { IInput, IField } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { FileInputTO } from './model/file-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
  ISPValidator,
} from '../../model';
import { getMaxSizeValidator } from '../../validators/validators';

/**
 * Get config for input-file field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getInputFileConfig(
  control: IInput,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.InputFile> {
  const templateOptions: FileInputTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    caption: DocHelper.getMessage('msg_choosefile', state.doc),
  };

  const config: ISPFieldConfig<ISPFieldType.InputFile> = {
    key: control.$name,
    type: ISPFieldType.InputFile,
    wrappers: [ISPFieldWrapper.FieldBase, ISPFieldWrapper.ValidationError],
    templateOptions,
  };

  const maxSize = control.$maxsize ? Number(control.$maxsize) : NaN;
  if (maxSize) {
    config.validators = {
      [ISPValidator.Maxsize]: getMaxSizeValidator(maxSize),
    };
  }

  return config;
}

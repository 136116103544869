import { IField, IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { IImgUi } from '../dynamic-form.interface';

/**
 * Get image ui
 *
 * @param field - field metadata
 * @param doc - doc instance
 */
export function getImage(field: IField, doc: IDocument): IImgUi | null {
  if (field?.img) {
    const img = field.img[0];
    return {
      icon: img.$img,
      url: DocHelper.getStringValue(img.$name, doc),
      width: img.$width,
      height: img.$height,
    };
  }
  return null;
}

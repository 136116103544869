import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgZone,
  OnInit,
} from '@angular/core';

import { IExtformPageInfo } from 'app/app.interface';
import { prepareModelToSubmit } from 'app/form/form.utils';
import {
  IDocument,
  IErrorResponse,
  IExtformMeta,
} from 'app/services/api5-service/api.interface';
import { HttpBaseService } from 'app/services/http-base-service/http-base.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { IFormButtonClickEvent } from 'common/dynamic-form/dynamic-form.interface';
import {
  DynamicFormContext,
  ISPFieldConfig,
  ISPFieldType,
} from 'common/dynamic-form/model';
import { TemplateConfig } from 'common/dynamic-form/types';
import { DocHelper } from 'utils/dochelper';
import { hasFile } from 'utils/has-file';

declare const ispPrefetchObject: {
  listeners: ((doc: IExtformMeta) => void)[];
  result?: IExtformMeta;
};

declare const pageInfo: IExtformPageInfo;

const PRELOAD_MESSAGE_ID = 'isp-page__preloader';

@Component({
  selector: 'isp-extform',
  templateUrl: './extform.component.html',
  styleUrls: ['./scss/extform.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtformComponent implements OnInit {
  private readonly docSubject: BehaviorSubject<IExtformMeta | null> =
    new BehaviorSubject(null);

  readonly doc$ = this.docSubject;

  readonly title$ = this.doc$.pipe(
    filter(doc => Boolean(doc)),
    map(doc => DocHelper.getMessage('title', doc)),
  );

  readonly context$ = this.doc$.pipe(
    filter(doc => Boolean(doc)),
    map(doc => this.getDynamicFormContextFromExtform(doc)),
  );

  get doc(): IExtformMeta | null {
    return this.docSubject.value;
  }

  /** model is required to reset form state, after submit, to synchronize model and doc state */
  model = {};

  readonly socialLinksConfig: TemplateConfig = {
    type: ISPFieldType.Template,
    templateOptions: {
      replace: (config: ISPFieldConfig) =>
        config.type === ISPFieldType.TextData &&
        config.templateOptions.originalControl.$name === 'signup_link',
    },
  };

  constructor(
    private readonly zone: NgZone,
    private readonly httpBaseService: HttpBaseService,
    @Inject(WINDOW) private readonly window: WindowWrapper,
  ) {}

  private turnOffPreloader(): void {
    const preloadMessageElement = document.getElementById(PRELOAD_MESSAGE_ID);
    preloadMessageElement.style.display = 'none';
  }

  private getDynamicFormContextFromExtform(
    doc: IExtformMeta,
  ): DynamicFormContext {
    return {
      passwordGenChars: doc.pwgencharacters?.$ || '',
      passwordGenLength: Number(doc.pwgenlen?.$) || 14,
      passwordStrength: doc.pwstrength?.$ || '0',
      forceHint: false,
      // there is no such msgs in extform
      collapseTooltipMsg: '',
      expandTooltipMsg: '',
      validationDuplicateMsg: '',
      validationFileMaxSizeMsg: '',
      validationRequiredMsg: '',
      emptyListMsg: '',
      fileUploadMsg: '',
    };
  }

  private getExtformDocument(): Observable<IExtformMeta> {
    // use prefetch global object, that contain prefetched result of initial request

    let docStream: Observable<IExtformMeta>;
    if (ispPrefetchObject.result) {
      this.turnOffPreloader();
      docStream = of(ispPrefetchObject.result);
    } else {
      docStream = new Observable<IExtformMeta>(observer => {
        const fetchCallback = (doc: IExtformMeta) => {
          this.turnOffPreloader();
          observer.next(doc);
        };
        ispPrefetchObject.listeners.push(fetchCallback);
      }).pipe(take(1));
    }

    return docStream.pipe(
      tap(doc => {
        if (pageInfo.error) {
          doc.error = {
            $object: pageInfo.error.object,
            msg: { $: pageInfo.error.msg },
          } as IErrorResponse;
        }
      }),
    );
  }

  ngOnInit(): void {
    this.getExtformDocument().subscribe(doc => {
      this.zone.run(() => {
        this.docSubject.next(doc);
      });
    });
  }

  handleButtonClick(event: IFormButtonClickEvent): void {
    const button = event.button;
    button.preloaderSubject.next(true);

    if (button.$func) {
      this.window.location.href = `${this.doc.$host}${this.doc.$binary}?func=${button.$func}`;
    } else {
      const params = prepareModelToSubmit({
        form: {
          ...event.form,
          sfromextform: 'yes',
          newwindow: 'extform',
        },
        button: event.button,
        func: this.doc.$func,
        elid: DocHelper.elid(this.doc),
        plid: DocHelper.plid(this.doc),
        sok: true,
      });

      let request: Observable<IDocument>;
      if (hasFile(params)) {
        request = this.httpBaseService.uploadFile(params as any).pipe(
          filter(e => e.type === HttpEventType.Response),
          map(e => (e as HttpResponse<{ doc: IDocument }>).body.doc),
        );
      } else {
        request = this.httpBaseService.post(params as any);
      }
      request.subscribe(doc => {
        if (doc.$stylesheet === 'login' || doc.$stylesheet === 'desktop') {
          this.window.location.href = `${doc.$host}${doc.$binary}`;
          return;
        }

        if (doc.ok) {
          switch (true) {
            case doc.ok.$type === 'blank':
              this.window.location.href = doc.ok.$;
              return;
            case doc.ok.$type === 'top':
              this.window.location.href = `${doc.$host}${doc.$binary}?${
                doc.ok.$ || ''
              }`;
              return;
            case doc.ok.$type === 'url':
              this.window.location.href = doc.ok.$;
              return;
            case doc.ok.$type === 'form':
              this.window.location.href = `${doc.$host}${doc.$binary}?${
                doc.ok.$ || ''
              }`;
              return;
            case doc.ok.$type === 'list':
              this.window.location.href = `${doc.$host}${doc.$binary}?${
                doc.ok.$ || ''
              }`;
              return;
            case Boolean(doc.ok):
              this.window.location.href = `${doc.$host}${doc.$binary}`;
              return;
          }
        }

        // @WARN reset mode in order to synchronize it with new doc
        this.model = {};

        if (doc.error && !doc.metadata) {
          this.docSubject.next({
            ...this.docSubject.value,
            ...{
              error: doc.error,
            },
          });
        } else {
          this.docSubject.next(doc as IExtformMeta);
        }
      });
    }
  }
}

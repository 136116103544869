import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { ISPFieldWrapper } from 'common/dynamic-form/model/field-wrapper.enum';
import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { InputBaseComponent } from './input-base.component';

/**
 * Field base wrapper
 */
@NgModule({
  declarations: [InputBaseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SvgIconModule,
    ISPUITooltipModule,
    FormlyModule.forChild({
      wrappers: [
        { name: ISPFieldWrapper.InputBase, component: InputBaseComponent },
      ],
    }),
  ],
})
export class InputBaseModule {}

import { IDocument, IFormPage } from 'app/services/api5-service/api.interface';

import { getTypedField, TypedField } from './get-typed-field';

export interface TypedPage {
  readonly fields: TypedField[];
  /** original page metadata */
  readonly page: IFormPage;
}

/**
 * Map fields list to typed page with custom page metadata
 *
 * @param page - page metadata
 * @param doc - doc
 */
export function getTypedPage(page: IFormPage, doc: IDocument): TypedPage {
  const typedFields = page.field.map(field => getTypedField(field, doc));

  return {
    fields: typedFields,
    page,
  };
}

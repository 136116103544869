import { NgModule } from '@angular/core';

import { TrustPipe } from './trust.pipe';

/**
 * Pipe for bypass value for src, innerHtml...
 */
@NgModule({
  imports: [],
  exports: [TrustPipe],
  declarations: [TrustPipe],
  providers: [],
})
export class TrustPipeModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { IconSimularModule } from 'pipes/icon-simular/icon-simular.module';

import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';
import { NgISPUITreeModule } from '@ngispui/tree';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { TreeFieldComponent } from './tree.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's tree field component
 */
@NgModule({
  declarations: [TreeFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgISPUITreeModule,
    SvgIconModule,
    IconSimularModule,
    ISPUIPreloaderRoundModule,
    ISPUITooltipModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Tree,
          component: TreeFieldComponent,
        },
      ],
    }),
  ],
})
export class TreeFieldModule {}

import { TActionType } from 'app/services/api5-service/api.interface';

/** sidebar footer data */
export interface IFooterData {
  /** product info (e.g. `ISPmanager Lite 5.250.0`) */
  product: string;
  /** copyright line (e.g. `ISPsystem © 1997-2020`) */
  copyright: string;
  /** copyright url (e.g. `https://ispsystem.ru`) */
  copyrightUrl?: string;
  /** old theme link text (e.g. "Старый интерфейс") */
  oldThemeMessage: string;
  /** change tariff link text (e.g. "Сменить тариф") */
  tariffMessage?: string;
  /** change tariff link func (e.g. "pricelist.change") */
  tariffFunc?: string;
}

/** Event of clicking on the tariffs link in sidebar footer */
export interface IFooterTariffClickEvent {
  func: string;
  actionType: TActionType;
  nativeEvent: MouseEvent;
}

/** sidebar toolbar filters */
export enum MainMenuFilter {
  All,
  Favorite,
  Popular,
  New,
}

/** type of mainmenu */
export type TMenuLabelType = 'favorite' | 'five' | 'full' | 'new' | 'popular';

/** interface of mainmenu type */
export interface MenuLabel {
  hintKey: string;
  type: TMenuLabelType;
  icon: string;
}

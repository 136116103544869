/**
 * Possible routes
 */
export enum Route {
  Login = 'login',
  Dashboard = 'dashboard',
  List = 'list',
  Report = 'report',
  Form = 'form',
  Helpboard = 'helpboard',
}

/**
 * Page main info
 *
 * @WARN this is global object, that initiallized in xsl file
 */
export interface IDesktopPageInfo {
  /** product binary '/billmgr' */
  binary: string;
  isBranding: number;
  theme: string;
  /** host and binary together, like 'https://172.31.225.196/billmgr' */
  baseUrl: string;
  commonPath: string;
  level: string;
  product: string;
  version: string;
  version_ispmanager: string;
  version_billmanager: string;
  userName: string;
  licid: string;
  userid: string;
  startpage: string;
  startform: string;
}

export interface IExtformPageInfo {
  /** product binary '/billmgr' */
  binary: string;
  /** product name 'billmgr' */
  product: string;
  /** func that used by form */
  func: string;
  /** url host like 'https://172.31.225.144' */
  host: string;
  /** theme path like '/manimg/orion/' */
  theme: string;
  /** full url like 'https://172.31.225.144/billmgr' */
  url: string;
  /** chars sets, that should be used for password generation on the form. Like '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' */
  pwgencharacters: string;
  /** password length, that should be used in password generation */
  pwgenlen: string;
  /** min password strength, to pass validation */
  pwstrength: string;
  /** favicon name */
  favicon: string;
  /** directory that should be used for getting static files. By default 'default/' */
  localDir: string;
  /** entity id */
  elid: string;
  /** parent entity id */
  plid: string;
  /** product version like '5.340.0-2022.03.10_11:03' */
  version: string;
  /** messages key-value set */
  messages: Record<string, string>;
  /** contain query params for request, that was used to get this doc */
  tparams: Record<string, string>;
  /** some error to display. Usually form validation error */
  error?: {
    /** field name */
    object: string;
    /** validation msg */
    msg: string;
  };
}

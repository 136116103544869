<isp-svg-icon
  *ngIf="to.img?.icon"
  class="textdata__img"
  [name]="to.img.icon"
  [customStyle]="{ width: to.img.width, height: to.img.height }"
></isp-svg-icon>
<img
  *ngIf="to.img?.url"
  class="textdata__image"
  [alt]="to.content"
  [width]="to.img.width"
  [src]="to.img.url"
  [height]="to.img.height"
/>
<div
  *ngIf="to.isBanner; else plainText"
  class="ispui-banner textdata__banner"
  [class.ispui-banner_status_warning]="!to.isTextHighlight"
  [class.ispui-banner_status_danger]="to.isTextHighlight"
>
  <div class="ispui-banner__icon"></div>
  <div class="ispui-banner__content">
    <div class="ispui-banner__text" [innerHTML]="to.content"></div>
  </div>
</div>
<ng-template #plainText>
  <div
    class="textdata__content ispui-text"
    [ngClass]="{
      'ispui-text_warn': to.isTextHighlight,
      textdata__content_fullwidth: to.isFullWidth
    }"
    [innerHTML]="to.content"
  ></div>
</ng-template>

<div class="frame-wrapper">
  <iframe
    [ispFullheight]="isFullheight"
    frameborder="0"
    [name]="key"
    class="frame"
    [ngClass]="{ frame_invert: to.originalField.$name === 'secret' }"
    [src]="field.defaultValue | trust: 'resourceUrl'"
    width="100%"
    [height]="to.height"
  ></iframe>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { IFormButtonUi } from 'components/form-button';

import { ISPFieldConfig } from '../../../model';
import { IListCellUi, IListRowUi } from '../model/list.interface';

/**
 * List cell
 */
@Component({
  selector: 'isp-formly-list-cell',
  templateUrl: './list-cell.component.html',
  styleUrls: ['./scss/list-cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCellFieldComponent {
  @Input() cell: IListCellUi;

  @Input() row: IListRowUi;

  @Input() configs: ISPFieldConfig[];

  @Output() readonly buttonClick: EventEmitter<IFormButtonUi> =
    new EventEmitter();

  onButtonClick(button: IFormButtonUi): void {
    this.buttonClick.emit(button);
  }

  /**
   * Get formly field for insert to formly-field
   *
   * @WARN do not write fieldConfig to cell, because formly override configs in fieldGroup! You should use field config right from fieldGroup
   * @param configs - configs array
   * @param cell - list cell
   */
  getField(configs: ISPFieldConfig[], cell: IListCellUi): ISPFieldConfig {
    return configs.find(field => field.key === cell.control?.$name);
  }
}

import { appendConfigCommonOptions } from './append-config-common-options';

import { ISPFieldConfig, ISPFormState } from '../../model';
import {
  getSelectConfig,
  getAutocompleteConfig,
  getRadioConfig,
  getDateTimeConfig,
  getTextareaConfig,
  getCodeConfig,
  getLinkConfig,
  getInputTextConfig,
  getCheckboxConfig,
  getDatePickerConfig,
  getInputFileConfig,
  getPasswordConfig,
  getHiddenConfig,
  getListConfig,
  getListFilterConfig,
  getTextDataConfig,
  getFrameConfig,
  getSliderConfig,
  getTreeConfig,
  getChatConfig,
  getChatSummaryConfig,
  getCaptchaConfig,
  getTextEditorConfig,
} from '../../types';
import { TypedField } from '../form-preparing';

/**
 * Map control meta to Formly field config
 *
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getConfigsFromTypedField(
  field: TypedField,
  state: ISPFormState,
): ISPFieldConfig[] {
  return field.controls
    .map(control => {
      let config: ISPFieldConfig | ISPFieldConfig[] | undefined;
      switch (control.type) {
        case 'captcha':
          config = getCaptchaConfig(control.control, field.field, state);
          break;
        case 'select':
          config = getSelectConfig(control.control, field.field, state);
          break;
        case 'autocomplete':
          config = getAutocompleteConfig(control.control, field.field, state);
          break;
        case 'radio':
          config = getRadioConfig(control.control, field.field, state);
          break;
        case 'datetime':
          config = getDateTimeConfig(control.control, field.field, state);
          break;
        case 'textarea':
          config = getTextareaConfig(control.control, field.field, state);
          break;
        case 'code':
          config = getCodeConfig(control.control, field.field, state);
          break;
        case 'link':
          config = getLinkConfig(control.control, field.field, state);
          break;
        case 'input':
          config = getInputTextConfig(control.control, field.field, state);
          break;
        case 'checkbox':
          config = getCheckboxConfig(control.control, field.field, state);
          break;
        case 'datepicker':
          config = getDatePickerConfig(control.control, field.field, state);
          break;
        case 'file':
          config = getInputFileConfig(control.control, field.field, state);
          break;
        case 'password':
          config = getPasswordConfig(control.control, field.field, state);
          break;
        case 'hidden':
          config = getHiddenConfig(control.control, field.field);
          break;
        case 'list':
          config = getListConfig(control.control, field.field, state);
          break;
        case 'listfilter':
          // @WARN create filter only if there a filter meta.
          // It could be that the listfilter exists, but related list is hidden - there will be no meta in that case
          if (state.doc.flist) {
            config = getListFilterConfig(control.control, field.field, state);
          }
          break;
        case 'htmldata':
          config = getTextDataConfig(control.control, field.field, state);
          break;
        case 'textdata':
          config = getTextDataConfig(control.control, field.field, state);
          break;
        case 'frame':
          config = getFrameConfig(control.control, field.field, state);
          break;
        case 'slider':
          config = getSliderConfig(control.control, field.field, state);
          break;
        case 'tree':
          config = getTreeConfig(control.control, field.field, state);
          break;
        case 'ticket':
          config = getChatConfig(control.control, field.field, state);
          break;
        case 'ticketinfo':
          config = getChatSummaryConfig(control.control, state);
          break;
        case 'texteditor':
          config = getTextEditorConfig(control.control, field.field, state);
          break;
      }

      if (!config) {
        return;
      }

      const configs = Array.isArray(config) ? config : [config];

      configs.forEach(c => {
        if ('value' in control && c.key) {
          // @TODO i.ablov move ALL options appending to typed interfaces
          c.defaultValue = control.value;
        }

        appendConfigCommonOptions(c, control.control, field.field, state);
      });

      return configs;
    })
    .flat()
    .filter(Boolean);
}

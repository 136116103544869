import { Params } from '@angular/router';

import { ITab } from 'app/services/tab/tab.interface';

import { TDrawersMetadata } from 'common/dynamic-form/services/drawer-parent.service';

import {
  IControl,
  IDocument,
  IField,
  IFormPage,
  IInput,
  IListOptionList,
  IMessageSet,
  INormalBanner,
  INotificationBanner,
  IRecord,
  ISelect,
  IToolbar,
  IToolGroup,
  IValue,
  PasswordStrength,
  TRecordValue,
} from '../app/services/api5-service/api.interface';
import { IListMap } from '../common/dynamic-form/dynamic-form.interface';

/**
 * Helper class for get property form doc
 */
export class DocHelper {
  /**
   * Get error
   *
   * @param doc - doc object
   */
  static getError(doc: IDocument): string {
    let errMsg = '';
    const errObject = doc?.error?.msg;
    if (Array.isArray(errObject)) {
      errMsg = errObject[0]?.$;
    } else if (errObject?.detail?.$) {
      errMsg = errObject.detail.$;
    } else {
      errMsg = errObject?.$;
    }
    return errMsg;
  }

  /**
   * Get form page list
   *
   * @param doc - doc object
   */
  static getFormPageList(doc: IDocument): IFormPage[] {
    return doc?.metadata?.form?.page;
  }

  /**
   * Get set of field names to show in base form mode
   *
   * @param doc - document
   */
  static getBaseFieldNamesSet(doc: IDocument): Set<string> {
    const result = new Set<string>();
    const form = doc?.metadata?.form;

    const controlTypes: (keyof IField)[] = [
      'input',
      'link',
      'textarea',
      'tree',
      'slider',
      'frame',
      'htmldata',
      'textdata',
      'list',
      'datetime',
      'select',
    ];

    const selectFields = (fields: IField[], pageName?: string) => {
      const baseFields = fields?.filter(field => field.$base);
      if (!baseFields?.length) {
        return;
      }
      if (pageName) {
        result.add(pageName);
      }
      baseFields.forEach(field => {
        controlTypes.forEach(cType => {
          const controlList = field[cType] as IControl[];
          controlList?.forEach(control => result.add(control.$name));
        });
      });
    };

    if (form?.field) {
      selectFields(form.field);
    }
    form?.page?.forEach(page => {
      selectFields(page.field, page.$name);
    });
    return result;
  }

  /**
   * Has the form base and extended modes
   *
   * @param doc - form doc
   */
  static hasBaseMode(doc: IDocument): boolean {
    return DocHelper.getBaseFieldNamesSet(doc).size > 0;
  }

  /**
   * Get password strength messgae
   *
   * @param strength - password strength
   * @param doc - doc object
   */
  static getPasswordStrengthMessage(
    strength: PasswordStrength,
    doc: IDocument,
  ): string {
    switch (strength) {
      case '0':
        return DocHelper.getMessage('msg_pwcheck_short', doc);
      case '1':
        return DocHelper.getMessage('msg_pwcheck_weak', doc);
      case '2':
        return DocHelper.getMessage('msg_pwcheck_good', doc);
      case '3':
        return DocHelper.getMessage('msg_pwcheck_strong', doc);
    }
  }

  /**
   * Get messgae by name
   *
   * @param name - msg key
   * @param doc - doc object
   */
  static getMessage(name: string, doc: IDocument): string {
    const messageSet = this.getMessageSet(doc);
    return messageSet[name] || '';
  }

  /**
   * Get messages
   *
   * @param doc - doc object
   */
  static getMessageSet(doc: IDocument): IMessageSet {
    let messageSet = {};
    const messageSetRaw = doc?.messages;
    // hack for revisium list
    if (Array.isArray(messageSetRaw)) {
      messageSetRaw.forEach(msg => {
        messageSet = { ...messageSet, ...msg.msg };
      });
    } else {
      messageSet = messageSetRaw?.msg || {};
    }
    return messageSet;
  }

  static elid(doc: IDocument): string {
    if (!doc) {
      return '';
    }
    return Array.isArray(doc.elid) ? doc.elid[0].$ : doc.elid?.$ || '';
  }

  static plid(doc: IDocument): string {
    if (!doc) {
      return '';
    }
    return Array.isArray(doc.plid) ? doc.plid[0].$ : doc.plid?.$ || '';
  }

  static getStringValue(key: string, doc?: IDocument): string {
    if (!doc?.[key]) {
      return '';
    }

    const value = doc[key];
    if (Array.isArray(value)) {
      const uniqueValues = new Set(
        value.map(v => v.$).filter(v => v !== undefined),
      );
      return Array.from(uniqueValues).join(',');
    } else {
      return value.$ || '';
    }
  }

  /**
   * Get value by key from server (doc)
   *
   * @param key - value key from doc
   * @param doc - form data from server
   * @returns null if there is no such value in doc
   */
  static getValue(key: string, doc: IDocument): string[] | string | null {
    let value = doc[key];
    if (Array.isArray(value)) {
      value = value.map(v => v?.$).filter(v => Boolean(v));
    } else {
      value = value ? value.$ || '' : null;
    }
    return value;
  }

  static getSelectValue(name: string, doc: IDocument): string[] | string {
    if (name === 'lang') {
      return doc?.[name]?.[0]?.$ || '';
    }

    const value: IValue | IValue[] | undefined = doc?.[name];
    if (Array.isArray(value)) {
      return value.map(v => v.$);
    }
    return value?.$ || '';
  }

  static getElement(name: string, record: IRecord | IRecord[]): TRecordValue {
    let value = record?.[name];
    if (!value && Array.isArray(record)) {
      value = record[0]?.[name];
      if (Array.isArray(value)) {
        value = value[0];
      }
    }
    return value;
  }

  static getElementValue(name: string, record: IRecord | IRecord[]): string {
    const element = this.getElement(name, record);
    return element?.$ || '';
  }

  static getElementOrigValue(
    name: string,
    record: IRecord | IRecord[],
  ): string {
    const element = this.getElement(name, record);
    return element?.$orig || element?.$ || '';
  }

  static getElementColor(name: string, record: IRecord | IRecord[]): string {
    let element = this.getElement(name, record);
    if (Array.isArray(element)) {
      element = element[0];
    }
    return element?.$color || '';
  }

  static getTParams(doc: IDocument): Params {
    const params = {};
    for (const paramKey in doc.tparams) {
      if (Object.prototype.hasOwnProperty.call(doc.tparams, paramKey)) {
        params[paramKey] = doc.tparams[paramKey]?.$;
      }
    }
    return params;
  }

  /**
   * Get element list form doc
   *
   * @param doc - doc object
   */
  static getElemList(doc: IDocument): IRecord[] {
    const elem = doc?.elem;
    if (Array.isArray(elem)) {
      return elem as IRecord[];
    } else if (elem) {
      return [elem];
    } else {
      return [];
    }
  }

  /**
   * Get toolgrp
   *
   * @param doc - doc object
   */
  static getToolGrp(doc: IDocument): IToolGroup[] {
    return doc?.metadata?.toolbar?.toolgrp || [];
  }

  /**
   * Get elid column
   *
   * @param doc - doc object
   */
  static getElidColumn(doc: IDocument): string {
    return doc?.metadata?.$key;
  }

  /**
   * Get the key name of the column
   *
   * @param doc - doc object
   */
  static getKeyname(doc: IDocument): string {
    return doc?.metadata?.$keyname;
  }

  /**
   * Transform list array to list map
   *
   * @param list - list to transform
   */
  static reduceFormList(list: IListOptionList[]): IListMap {
    if (!list?.reduce) {
      return {};
    }
    return list.reduce((acc, cur) => {
      acc[cur.$name] = cur.elem;
      return acc;
    }, {});
  }

  /**
   * Get toolbar meta from tab
   *
   * @param tab
   */
  static getToolbar(tab: ITab): IToolbar {
    return tab?.doc?.metadata?.toolbar;
  }

  /**
   * Get toolbar meta from tab contextmenu
   *
   * @param tab
   */
  static getContextMenu(tab: ITab): IToolbar {
    return tab?.doc?.metadata?.contextmenu;
  }

  /**
   * Check is doc have active filter
   *
   * @param doc - doc instance
   */
  static isFilterActive(doc: IDocument): boolean {
    return Boolean(doc.p_filter);
  }

  /**
   * Get applyed filter msg
   *
   * @param doc - doc instance
   */
  static getApplyedFilterMsg(doc: IDocument): string {
    return doc.p_filter?.$ || '';
  }

  /**
   * Get doc without form summary
   *
   * @param doc - doc
   */
  static removeFormSummary(doc: IDocument): IDocument {
    return {
      ...doc,
      metadata: {
        ...doc.metadata,
        summary: undefined,
      },
    };
  }

  /**
   * Get drawers metadata from doc
   *
   * @param doc - doc
   */
  static getDrawersMetadata(doc: IDocument): TDrawersMetadata {
    type TFieldWithPageName = IField & { pageName?: string };
    const pageFields: TFieldWithPageName[] =
      doc.metadata?.form?.page
        ?.filter(p => Boolean(p.field))
        ?.map(p => p.field.map(f => ({ ...f, pageName: p.$name })))
        ?.flat() || [];
    const topFields = doc.metadata?.form?.field || [];
    const fields: TFieldWithPageName[] = [...pageFields, ...topFields];

    const drawerSelects = fields
      .filter(f => f.select)
      .map(f => f.select)
      .flat()
      .filter(s => s.if?.find(ifCond => '$drawer' in ifCond));

    return drawerSelects.reduce<TDrawersMetadata>((buffer, select) => {
      const drawerFieldNames = select.if
        .filter(ifCond => '$drawer' in ifCond)
        .map(ifCond => ifCond.$drawer);
      const drawerPageNamesSet = new Set<string>(
        fields
          .filter(f => f.pageName && drawerFieldNames.includes(f.$name))
          .map(f => f.pageName),
      );
      return {
        ...buffer,
        [select.$name]: {
          selectName: select.$name,
          selectDefaultValue: DocHelper.getSelectValue(select.$name, doc),
          selectDrawerValue: select.if.find(ifCond => '$drawer' in ifCond)
            .$value,
          drawerFieldNames,
          drawerPageNames: Array.from(drawerPageNamesSet),
        },
      };
    }, {});
  }

  // Get all notification-like banners from doc
  //
  // @param doc - doc
  static getNotificationBanners(doc: IDocument | null): INotificationBanner[] {
    return (
      (doc?.banner as INotificationBanner[])?.filter(
        b => b.$pinned === undefined,
      ) || []
    );
  }

  /**
   * Get all normal banners from doc
   *
   * @param doc - doc
   */
  static getNormalBanners(doc: IDocument | null): INormalBanner[] {
    return (
      (doc?.banner as INormalBanner[])?.filter(b => b.$pinned === 'yes') || []
    );
  }

  /**
   * Get all plainhints for select
   *
   * @param doc - doc
   * @param select - select
   */
  static getPlainhintsForSelect(
    doc: IDocument,
    select: ISelect,
  ): Record<string, string> | null {
    if (!select.$plainhint) return null;

    const plainhints: Record<string, string> = {};
    const optionsList = doc.slist?.find(item => item.$name === select.$name);
    if (optionsList) {
      for (const option of optionsList.val) {
        const key = `hint_${select.$name}__${option.$key}`;
        plainhints[key] = DocHelper.getMessage(key, doc);
      }
    }
    return plainhints;
  }

  /**
   * Get all plainhints for checkbox
   *
   * @param doc - doc
   * @param input - input with type checkbox
   */
  static getPlainhintsForCheckbox(
    doc: IDocument,
    input: IInput,
  ): Record<string, string> | null {
    if (!input.$plainhint) return null;

    const keyOn = `hint_${input.$name}__on`;
    const keyOff = `hint_${input.$name}__off`;
    return {
      [keyOn]: DocHelper.getMessage(keyOn, doc),
      [keyOff]: DocHelper.getMessage(keyOff, doc),
    };
  }
}

<isp-svg-icon
  *ngIf="inSprite; else img"
  [ngClass]="{ icon_animated: isAnimated }"
  [name]="name | iconSimular"
  [customStyle]="customStyle"
></isp-svg-icon>

<ng-template #img>
  <div
    [style.background]="getBgStyle() | trust: 'style'"
    [ngStyle]="customStyle"
  ></div>
</ng-template>

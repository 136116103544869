<header
  [class]="
    'form-collapse__header form-collapse__header_' + classNameModificator
  "
  [ngClass]="{ 'form-collapse__header_opened': isOpened }"
  [tabIndex]="0"
  (click)="toggleCollapse()"
  role="button"
  (keydown.space)="toggleCollapse()"
  (keydown.enter)="toggleCollapse($event)"
  [attr.aria-expanded]="isOpened"
  [attr.aria-controls]="id"
  [attr.aria-label]="data.title"
  *ngIf="!data.isBaseMode && !data.isDrawerMode && data.title.length"
  #header
>
  <ispui-tooltip
    placement="topRight"
    [disabled]="!(headerTooltip$ | async)"
    [title-text]="headerTooltip$ | async"
  >
    <isp-svg-icon
      [name]="icon"
      [customStyle]="iconStyles"
      class="form-collapse__icon"
    ></isp-svg-icon>
  </ispui-tooltip>

  <span class="form-collapse__title">
    {{ data.title }}
  </span>

  <ispui-tooltip
    *ngIf="canRefresh"
    class="form-collapse__icon_retry"
    placement="topRight"
    [title-text]="refreshTooltipMsg"
  >
    <isp-svg-icon
      name="t-retry_s"
      [customStyle]="retryIconStyles"
      class="form-collapse__icon"
      [class.form_collapse__icon_is_spinning]="isRefreshing"
      (click)="refreshContent($event)"
      (keyup.enter)="refreshContent($event)"
      (keyup.space)="refreshContent($event)"
      [tabIndex]="0"
      role="button"
    ></isp-svg-icon>
  </ispui-tooltip>
</header>
<div
  [class]="'form-collapse__wrap form-collapse__wrap_' + classNameModificator"
  [id]="id"
  [ngClass]="{
    'form-collapse-content_hidden':
      !isOpened && !this.data.isBaseMode && !this.data.isDrawerMode
  }"
>
  <ng-content></ng-content>
</div>

import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { ISPNotificationService } from 'app/services/notification.service';
import { BehaviorSubject, from } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  retryWhen,
  skip,
  take,
  tap,
} from 'rxjs/operators';

import { ConfirmOptions, ISPUIConfirmManager } from '@ispui/confirm';
import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { DocHelper } from 'utils/dochelper';

import { Route } from './app.interface';
import {
  Func,
  IDesktopMeta,
  IDocument,
  IMenuNode,
} from './services/api5-service/api.interface';
import { Api5Service } from './services/api5-service/api5.service';
import { HttpBaseService } from './services/http-base-service/http-base.service';

import { NarrowBannerService } from '../components/narrow-banner/narrow-banner.service';

const GET_DESKTOP_RETRY_COUNT = 5;

const RETRY_TIMEOUT = 1000;

/**
 * App service main service of application
 */
@Injectable({
  providedIn: 'root',
})
export class AppService {
  /** desktop metadata subject */
  private readonly desktopSubject = new BehaviorSubject<IDesktopMeta>(null);

  /** doc property $features stream */
  private readonly docFeatures$ = this.httpBaseService.docFeatures$;

  /* isLoading app flag subject */
  readonly isLoading$ = new BehaviorSubject<boolean>(true);

  /* desktop metadata observable */
  readonly desktop$ = this.desktopSubject.pipe(
    filter(desktop => desktop !== null),
  );

  /** returns the binary path (such as `/ispmgr` or `/billmgr` part in the URI) */
  get binary(): string {
    return this.desktopSubject.value.$binary;
  }

  /** return the host */
  get host(): string {
    return this.desktopSubject.value.$host;
  }

  /** return desktop instance */
  get desktop(): IDesktopMeta | null {
    return this.desktopSubject.value;
  }

  /** should balance be displayed. Some users (admin, european) don't have balance */
  get isUserHaveBalance(): boolean {
    // balance can be absent in ispmgr, or for admins
    const isBalanceExist = Boolean(this.desktop?.balance);
    // if credit feature is on, you should display balance anyway
    const isUserHaveCreditFeature = this.desktop?.user?.$credit === 'yes';
    return isUserHaveCreditFeature || isBalanceExist;
  }

  constructor(
    private readonly api5Service: Api5Service,
    private readonly router: Router,
    private readonly titleService: Title,
    @Inject(WINDOW) private readonly window: WindowWrapper,
    private readonly updates: SwUpdate,
    private readonly notificationService: ISPNotificationService,
    private readonly httpBaseService: HttpBaseService,
    private readonly narrowBannerService: NarrowBannerService,
  ) {
    this.subscribeToUpdateSwShowBanner();
  }

  /**
   * Sets the current's app title (`<title>`)
   */
  private setTitle(): void {
    const desktop = this.desktopSubject.value;
    const title = desktop?.messages?.msg?.title;
    if (title) {
      this.titleService.setTitle(title);
    }
  }

  /**
   * Subscribe to new doc features for page reloading
   */
  private subscribeToDocFeatures(): void {
    this.docFeatures$
      .pipe(
        filter(features => Boolean(features)),
        distinctUntilChanged(),
        skip(1),
      )
      .subscribe(() => {
        // setTimeout for time to handle all response, like close form
        setTimeout(() => {
          document.location.reload();
        });
      });
  }

  /**
   * Subscribe to update cache and show notify about
   */
  private subscribeToUpdateSwShowBanner(): void {
    this.updates.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        tap(() => {
          const doc = this.desktopSubject.value;

          this.narrowBannerService.openBanner({
            status: 'danger',
            text: DocHelper.getMessage('msg_available_update', doc),
            button: {
              label: DocHelper.getMessage('msg_update', doc),
              onClick: () => {
                this.updates.activateUpdate().then(() => {
                  this.window.location.reload();
                });
              },
            },
          });
        }),
      )
      .subscribe();
  }

  /**
   * Check auth for dev mode
   *
   * @param desktopData - desktop data
   */
  private checkAuth(desktopData: IDocument): void {
    if (desktopData?.$func === Func.Logon) {
      const confirmManager = new ISPUIConfirmManager();

      const confirmOptions: ConfirmOptions = {
        header: 'Ошибка авторизации',
        description: 'Перейти на форму авторизации?',
      };

      from(confirmManager.open(confirmOptions))
        .pipe(filter(confirmResult => confirmResult.resolve))
        .subscribe(() => {
          this.router.navigateByUrl(Route.Login);
        });
    }
  }

  /**
   * Init app method
   */
  init(): void {
    this.subscribeToDocFeatures();
    this.getDesktop();
  }

  getDesktop(): void {
    this.api5Service
      .desktop()
      .pipe(
        tap(data => this.checkAuth(data)),
        retryWhen(errors =>
          errors.pipe(delay(RETRY_TIMEOUT), take(GET_DESKTOP_RETRY_COUNT)),
        ),
      )
      .subscribe({
        next: data => {
          this.desktopSubject.next(data);
          this.notificationService.messages = DocHelper.getMessageSet(data);
          this.isLoading$.next(false);
          this.setTitle();
        },
        error: () => {
          this.window.location.reload();
        },
      });
  }

  getUserName(): string {
    return this.desktopSubject.value.user?.$name;
  }

  /**
   * Get message from desktop's doc
   *
   * @param name
   */
  getDesktopMessage(name: string): string {
    return DocHelper.getMessage(name, this.desktopSubject.value);
  }

  /**
   * Get mainmenu nodes
   */
  getMainMenu(): IMenuNode[] {
    return this.desktopSubject.value?.mainmenu?.node || [];
  }

  /**
   * Get modern mainmenu nodes
   */
  getModernMainMenu(): IMenuNode[] {
    return this.desktopSubject.value?.mainmenu?.modernmenu?.node || [];
  }
}

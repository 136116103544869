/**
 * Find specific elements in an array
 *
 * @param regExpForSearch - regexp to match in items
 * @param array - element array
 * @param getStringWhereToSearchFN - function to get string where to search
 * @returns array elements that were found by the passed regular expression
 */
export function searchForElementsInArray<T = unknown>(
  regExpForSearch: RegExp,
  array: T[],
  getStringWhereToSearchFN: (input: T) => string,
): T[] {
  return array.filter(item => {
    const str = getStringWhereToSearchFN(item).toLowerCase();

    return regExpForSearch.test(str);
  });
}

<isp-slider
  [min]="min"
  [max]="max"
  [step]="step"
  [label]="to.caption"
  [required]="to.required"
  [description]="to.desc"
  [formControl]="formControl"
  [formlyAttributes]="field"
  (slider-focus)="toggleFocus(true)"
  (slider-blur)="toggleFocus(false)"
>
</isp-slider>

import { IDocument, IField } from 'app/services/api5-service/api.interface';
import { ITicket } from 'app/services/api5-service/api.interface/ticket.interface';

import { DocHelper } from 'utils/dochelper';

export interface TicketControlWithValue {
  readonly control: ITicket;
  readonly type: 'ticket';
  value: string;
}

export interface TicketInfo {
  readonly control: ITicket;
  readonly type: 'ticketinfo';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForTicket(
  field: IField,
  doc: IDocument,
): (TicketControlWithValue | TicketInfo)[] {
  return (field.ticket ? [field.ticket[0]] : [])
    .map(control => {
      const chat: TicketControlWithValue = {
        value: DocHelper.getStringValue(control.$name, doc),
        control,
        type: 'ticket',
      };

      const ticketInfo: TicketInfo = {
        control,
        type: 'ticketinfo',
      };

      return [chat, ticketInfo];
    })
    .flat();
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { PhoneGroupComponent } from './phone-group.component';

import { ISPFieldType } from '../../model';

@NgModule({
  declarations: [PhoneGroupComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.PhoneGroup,
          component: PhoneGroupComponent,
        },
      ],
    }),
  ],
})
export class PhoneGroupModule {}

import {
  IDocument,
  IField,
  ITextData,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface TextDataControlWithValue {
  readonly control: ITextData;
  readonly type: 'textdata';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForTextData(
  field: IField,
  doc: IDocument,
): TextDataControlWithValue[] {
  return (field.textdata ? [field.textdata[0]] : []).map(control => {
    return {
      value: DocHelper.getStringValue(control.$name, doc),
      control,
      type: 'textdata',
    };
  });
}

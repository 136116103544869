import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { AutocompleteSelectOption } from 'components/autocomplete-select/model/autocomplete-select-option.interface';
import {
  debounceTime,
  filter,
  finalize,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { SetValuesService } from 'common/dynamic-form/services/set-values.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Autocomplete select field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-autocomplate-select-field',
  templateUrl: './autocomplete-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSelectFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.AutocompleteSelect>
  implements OnInit
{
  /** Select pending flag for preloader */
  pending = false;

  /** Cast api options to autocomplete select options */
  get autocompleteOptions(): AutocompleteSelectOption[] | null {
    return (
      this.to.options?.map(o => ({
        k: o.$key,
        v: o.$,
      })) || null
    );
  }

  constructor(
    private readonly setValuesService: SetValuesService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    const preloaderDebounce = 500;
    // change pending state depending on a set values requests
    this.setValuesService.setValuesRequest$
      .pipe(
        filter(({ field }) => field === this.key),
        switchMap(({ result }) => {
          // immediately turn on preloader, if no options
          if (!this.to.options?.length) {
            this.pending = true;
            this.cdr.markForCheck();
          }
          return result.pipe(
            startWith(true),
            debounceTime(preloaderDebounce),
            tap(() => {
              // turn on preloader after 500ms if searching is too long
              this.pending = true;
              this.cdr.markForCheck();
            }),
            finalize(() => {
              this.pending = false;
              this.cdr.markForCheck();
            }),
          );
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}

import { ISPFieldConfig, ISPFieldType } from '../../model';

/**
 * Merge two configs
 *
 * @param a - first config
 * @param b - second config. Will override options from first config
 */
export function mergeConfigs<
  T extends ISPFieldType,
  C extends ISPFieldConfig<T>,
>(a?: C, b?: C): C {
  return {
    ...a,
    ...b,
    modelOptions: {
      ...a?.modelOptions,
      ...b?.modelOptions,
    },
    templateOptions: {
      ...a?.templateOptions,
      ...b?.templateOptions,
    },
    expressionProperties: {
      ...a?.expressionProperties,
      ...b?.expressionProperties,
    },
  };
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { sprite } from 'assets/icons/svg-sprite';

/**
 * Component provides insert svg sprite into application
 * need to insert into app.component
 */
@Component({
  selector: 'isp-svg-sprite',
  styleUrls: ['./scss/svg-sprite.scss'],
  template: '<div [innerHTML]="svg"></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgSpriteComponent implements OnInit {
  svg: SafeHtml;

  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.svg = this.sanitizer.bypassSecurityTrustHtml(sprite);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Hidden field component for Formly
 */
@Component({
  selector: 'isp-formly-hidden',
  templateUrl: './hidden.component.html',
  styles: [
    `
      :host {
        display: contents;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HiddenFieldComponent extends ISPFieldTypeBase<ISPFieldType.Hidden> {}

import { ProgressType } from 'app/services/api5-service/api.interface';

/**
 * Get progress id for backend task
 *
 * @param progressType - progress type
 */
export function createProgressIdForFormSubmitting(
  progressType: ProgressType,
): string {
  return `${progressType}_form${Date.now()}`;
}

/**
 * Get progress id for backend task for toolbutton
 *
 * @param progressType - progress type
 */
export function createProgressIdForToolbutton(
  progressType: ProgressType,
): string {
  return `${progressType}_list${Date.now()}`;
}

/**
 * Get progress id for backend task for downloading list
 */
export function createProgressIdForDownloadList(): string {
  return `_downloadlist${Date.now()}`;
}

/**
 * Check that progress id was generated for 'wait' progress type
 *
 * @param id - generated progress id
 */
export function isWaitProgressTypeById(id: string): boolean {
  return String(id).startsWith('wait');
}

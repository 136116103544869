<isp-svg-icon
  *ngIf="textTO.img?.icon"
  class="textdata__img"
  [name]="textTO.img.icon"
  [customStyle]="{ width: textTO.img.width, height: textTO.img.height }"
></isp-svg-icon>
<img
  *ngIf="textTO.img?.url"
  class="textdata__image"
  [alt]="textTO.content"
  [width]="textTO.img.width"
  [src]="textTO.img.url"
  [height]="textTO.img.height"
/>
<div
  class="textdata__content ispui-text"
  [ngClass]="{
    'ispui-text_warn': textTO.isTextHighlight,
    textdata__content_fullwidth: textTO.isFullWidth
  }"
>
  <span [innerHTML]="textTO.content"></span>
  <isp-formly-link [field]="link"></isp-formly-link>
  <formly-field
    *ngFor="let field of hiddenFields"
    [field]="field"
  ></formly-field>
</div>

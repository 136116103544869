import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IFilterTag } from './model/filter-tag.interface';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * List filter component
 */
@Component({
  selector: 'isp-list-tags',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./scss/list-filter.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFilterComponent extends ISPFieldTypeBase<ISPFieldType.ListFilter> {
  selectedTags: Set<string> = new Set();

  private setTags(): void {
    this.formState.listService.setFilter(
      this.to.listName,
      this.to.targetProperty,
      Array.from(this.selectedTags),
    );
  }

  isTagSelected(tag: IFilterTag): boolean {
    return this.selectedTags.has(tag.key);
  }

  toggleTagSelection(checked: boolean, tag: IFilterTag): void {
    if (checked) {
      this.selectedTags.add(tag.key);
    } else {
      this.selectedTags.delete(tag.key);
    }

    this.setTags();
  }

  onResetTags(): void {
    this.selectedTags.clear();

    this.setTags();
  }
}

import { IField, IList } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { ListFilterTO } from './model/list-filter-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';

/**
 * Get config for list filter field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getListFilterConfig(
  control: IList,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.ListFilter> {
  const fieldAndListBinder = field.listfilter;
  // why BE send filter list object, not array? This means that there can be only one filter... okay
  const filterMeta = state.doc.flist;

  const filterValues = Array.isArray(filterMeta.val)
    ? filterMeta.val
    : [filterMeta.val];

  const templateOptions: ListFilterTO = {
    originalBinder: fieldAndListBinder,
    listName: fieldAndListBinder.$src_list,
    targetProperty: fieldAndListBinder.$src_field,
    tags: filterValues.map(tag => ({
      key: tag.$key,
      msg: tag.$,
    })),
    resetMsg: DocHelper.getMessage('filterclear', state.doc),
    label: DocHelper.getMessage(filterMeta.$name, state.doc),
    // @TODO i.ablov this offset distinct from default because radiogroup have strange padding, that visually looks like 15px between label and radiogroup
    // remove padding from radiogroup and remove this offset!
    labelOffset: '12px',
  } as ListFilterTO;

  return {
    type: ISPFieldType.ListFilter,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
  };
}

import { IField } from 'app/services/api5-service/api.interface';

/**
 * Check if field should be fullWidth
 *
 * @param field - field metadata
 */
export function isFullWidth(field: IField): boolean {
  return field?.$fullwidth === 'yes' || Boolean(field?.frame?.length);
}

import { Params } from '@angular/router';

/**
 * Convert params object to params string
 * func={ func: 'auth', out: 'devel'} => auth%26out%3Ddevel
 *
 * @param params - params object to convert
 */
export function convertParamsToStringWithEncode(params: Params): string {
  return Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
}

/**
 * Convert params object to params string
 * func={ func: 'auth', out: 'devel'} => auth&out=devel
 *
 * @param params - params object to convert
 */
export function convertParamsToString(params: Params): string {
  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&');
}

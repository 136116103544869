import { ISPFieldConfig } from '../../model';

/**
 * Get flat array of fields configs from fields tree structure
 *
 * @param fieldsConfigs - fields configs
 * @param condition - condition function
 */
export function filterConfigs(
  fieldsConfigs: ISPFieldConfig[],
  condition: (field: ISPFieldConfig) => boolean,
): ISPFieldConfig[] {
  const fieldsThatAppliedCondition: ISPFieldConfig[] = [];

  const filter = (fields: ISPFieldConfig[]) => {
    fields.forEach(field => {
      if (condition(field)) {
        fieldsThatAppliedCondition.push(field);
      }

      if (field.fieldGroup) {
        filter(field.fieldGroup);
      }
    });
  };

  filter(fieldsConfigs);

  return fieldsThatAppliedCondition;
}

import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConditionService } from './condition.service';

import { IControl } from '../../../app/services/api5-service/api.interface';

/**
 * Service provides if/else conditions handling for form, control fields disabling and hiding by condition
 */
@Injectable()
export class DisabledService {
  /** set of disabled by '$readonly' property control names. Can be changed from 'setValues' functionality */
  private readonly disabledNamesSubject: BehaviorSubject<Set<string>> =
    new BehaviorSubject(new Set());

  /** stream of page/field/control names that sould be disabled by 'readonly' or some 'shadow' condition */
  readonly disabledNames$ = combineLatest([
    this.disabledNamesSubject.pipe(map(set => Array.from(set))),
    this.conditionService.shadowNames$,
  ]).pipe(map(sources => Array.from(new Set(sources.flat()))));

  /** get all page/field/control names that sould be disabled by 'readonly' or some 'shadow' condition */
  get disabledNames(): string[] {
    return Array.from(
      new Set(
        Array.from(this.disabledNamesSubject.value).concat(
          this.conditionService.shadowNames,
        ),
      ),
    );
  }

  constructor(private readonly conditionService: ConditionService) {}

  /**
   * Update disabled controls set, by adding new control to disabled set
   *
   * @param name - control name. @WARN Not field name!
   */
  disableControl(name: string): void {
    const set = this.disabledNamesSubject.value;
    set.add(name);
    this.disabledNamesSubject.next(new Set(set.values()));
  }

  /**
   * Update disabled controls set, by removing control name from disabled set
   *
   * @param name - control name. @WARN Not field name!
   */
  enableControl(name: string): void {
    const set = this.disabledNamesSubject.value;
    set.delete(name);
    this.disabledNamesSubject.next(new Set(set.values()));
  }

  /**
   * Add control disabled state to internal state
   *
   * @param control - control metadata
   */
  handleControl(control: IControl): void {
    if (control.$readonly === 'yes') {
      this.disableControl(`${control.$name}`);
    }
  }

  /**
   * Check if field is disabled because of 'readonly' or some 'shadow' condition
   *
   * @param name - field/control name
   */
  isFieldDisabled(name: string): boolean {
    return (
      this.disabledNamesSubject.value.has(name) ||
      this.conditionService.isShadow(name)
    );
  }

  /**
   * Stream of field disabled state because of 'readonly' or some 'shadow' condition
   *
   * @param name - field/control name
   */
  isFieldDisabled$(name: string): Observable<boolean> {
    return combineLatest([
      this.conditionService.isShadow$(name),
      this.disabledNamesSubject.pipe(map(set => set.has(name))),
    ]).pipe(map(([shadow, disabled]) => shadow || disabled));
  }

  /**
   * Clear service state
   */
  clear(): void {
    this.disabledNamesSubject.next(new Set());
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { TextDataFieldComponent } from './textdata.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's textdata and htmldata field component
 */
@NgModule({
  declarations: [TextDataFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SvgIconModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.TextData,
          component: TextDataFieldComponent,
        },
      ],
    }),
  ],
})
export class TextDataFieldModule {}

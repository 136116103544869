import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { EMPTY, Observable, of, pipe, UnaryFunction } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { DocHelper } from 'utils/dochelper';

import { IDocument } from './api5-service/api.interface';
import { IHttpParam } from './http-base-service/http-base.interface';
import { HttpBaseService } from './http-base-service/http-base.service';
import { ISPNotificationService } from './notification.service';
import { ProgressBarService } from './progressbar-service/progressbar.service';

import { NarrowBannerService } from '../../components/narrow-banner/narrow-banner.service';
import { AppService } from '../app.service';

/**
 * Preloaded action options
 */
export interface PreloadedActionOptions {
  /** should progressbar be shown on request start */
  showProgressbar?: boolean;
  /** should progressbar be hidden on request over */
  hideProgressbar?: boolean;
  /** we use fake progressbar to show user request progress. This time before progress bar get max value (97%). Default 1000ms */
  timeForProgressbarMaxValue?: number;
  /** custom api handler path for request */
  action?: string;
  /**
   * If doc has error and doesn't have form metadata it will be filtered out.
   * This flag allow you forcly pass doc with error, for additional handling
   */
  emitOnError?: boolean;
  /** Don't show error notification if doc has error object */
  skipErrorNotification?: boolean;
}

/**
 * Preloaded action service
 * Init preloader on get/post requests
 */
@Injectable({
  providedIn: 'root',
})
export class PreloadedActionService {
  private readonly longRequestMap = [
    'dns.blacklist',
    'authlog',
    'perlext',
    'pythonext',
  ];

  constructor(
    private readonly httpBaseService: HttpBaseService,
    private readonly progressBarService: ProgressBarService,
    @Inject(WINDOW) private readonly window: WindowWrapper,
    private readonly notificationService: ISPNotificationService,
    private readonly narrowBannerService: NarrowBannerService,
    private readonly appService: AppService,
  ) {}

  private onRequestStart(
    params: IHttpParam,
    options: PreloadedActionOptions,
  ): void {
    if (options?.showProgressbar !== false) {
      const defaultTimeout = options?.timeForProgressbarMaxValue || 1000;
      const progressTimeout = this.longRequestMap.includes(params.func)
        ? 2 * defaultTimeout
        : defaultTimeout;
      this.progressBarService.showProgressBar(progressTimeout);
    }
  }

  private onRequestOver(options: PreloadedActionOptions): void {
    if (options?.hideProgressbar !== false) {
      this.progressBarService.hideProgressBar();
    }
  }

  /**
   * Show notification with error message
   *
   * @param msg - error message
   */
  private showErrorNotification(msg: string): void {
    this.notificationService.showError(
      msg,
      this.appService.getDesktopMessage('msg_error'),
    );
  }

  /**
   * Show network error narrow banner
   */
  private showNetworkErrorBanner(): void {
    // @TODO b.landik get network error msg from appService doc! F-1142
    const text = 'Server or Network error';

    this.narrowBannerService.openBanner({
      id: 'network-error',
      status: 'danger',
      text,
    });
  }

  /**
   * Check on auth error and reload page
   *
   * @TODO i.ablov remove this from that service
   * @param doc - doc instance
   */
  private hasLogonFunc(doc: IDocument): boolean {
    if (doc?.$func === 'logon') {
      const title = '';
      const errorMsg = this.appService.getDesktopMessage('msg_auth_error');
      const linkMsg = this.appService.getDesktopMessage('msg_login');
      this.notificationService
        .showError(title, errorMsg, linkMsg)
        .pipe(filter(e => e.type === 'link-click'))
        .subscribe(() => {
          this.window.location.reload();
        });
      return true;
    }
    return false;
  }

  /**
   * Operators for check on logon function
   *
   * @TODO i.ablov remove this from that service
   */
  private checkLogonFuncOp(): UnaryFunction<
    Observable<IDocument>,
    Observable<IDocument>
  > {
    return pipe(filter((doc: IDocument) => !this.hasLogonFunc(doc)));
  }

  /**
   * Check response for BE or network error
   *
   * @TODO i.ablov remove this from that service
   * @param options - options
   */
  private handleBackendError(
    options: PreloadedActionOptions,
  ): UnaryFunction<Observable<IDocument>, Observable<IDocument>> {
    return pipe(
      catchError(() => {
        this.showNetworkErrorBanner();
        this.progressBarService.hideProgressBar();
        return EMPTY;
      }),
      filter((doc: IDocument) => {
        const errorMsg = DocHelper.getError(doc);
        if (Boolean(errorMsg)) {
          if (!options?.skipErrorNotification) {
            this.showErrorNotification(errorMsg);
          }
          this.progressBarService.hideProgressBar();

          // if exist form, update tab
          return Boolean(doc.metadata?.form || options?.emitOnError);
        }

        return true;
      }),
    );
  }

  /**
   * Get action
   *
   * @param params - http params
   * @param options - preloaded request options
   */
  getAction(
    params: IHttpParam,
    options?: PreloadedActionOptions,
  ): Observable<any> {
    this.onRequestStart(params, options);
    return this.httpBaseService.get(params).pipe(
      tap(() => this.onRequestOver(options)),
      this.handleBackendError(options),
      this.checkLogonFuncOp(),
    );
  }

  /**
   * Upload action
   *
   * @param params - request params
   * @param options - preloaded request options
   */
  uploadAction(
    params: IHttpParam,
    options?: PreloadedActionOptions,
  ): Observable<HttpEvent<IDocument> | null> {
    this.onRequestStart(params, options);
    return this.httpBaseService.uploadFile(params, options?.action).pipe(
      tap(e => {
        if (e.type === HttpEventType.Response) {
          this.onRequestOver(options);
        }
      }),
      map(e => {
        if (e.type === HttpEventType.Response) {
          const errorMsg = DocHelper.getError(e.body?.doc);
          if (errorMsg?.length) {
            this.showErrorNotification(errorMsg);
          }
          return { ...e, body: e.body?.doc };
        }
        return e;
      }),
      catchError(() => {
        this.showNetworkErrorBanner();
        this.onRequestOver(options);
        return of(null);
      }),
    );
  }

  /**
   * Post action
   *
   * @param params - request params
   * @param options - preloaded request options
   */
  postAction(
    params: IHttpParam,
    options?: PreloadedActionOptions,
  ): Observable<IDocument> {
    this.onRequestStart(params, options);
    return this.httpBaseService.post(params, options?.action).pipe(
      tap(() => this.onRequestOver(options)),
      this.handleBackendError(options),
      this.checkLogonFuncOp(),
    );
  }
}

import { Params } from '@angular/router';

import { IDocument, IToolGroup } from 'app/services/api5-service/api.interface';
import { IHttpParam } from 'app/services/http-base-service/http-base.interface';
import { BehaviorSubject } from 'rxjs';

import { DocHelper } from 'utils/dochelper';
import { getObjectSortedString } from 'utils/object.hash';

import { ITab, ITabState, SerializedTab, TabType } from './tab.interface';

/**
 * Transform doc tparams and pager params from doc to IHttpParam
 *
 * @param doc - doc instance
 */
function getTabQueryParams(doc: IDocument): IHttpParam {
  const tparams = doc.tparams;
  const queryParams: IHttpParam = {};
  if (tparams) {
    Object.keys(tparams).forEach(key => {
      if (!['func', 'out'].includes(key)) {
        queryParams[key] = tparams[key].$ !== undefined ? tparams[key].$ : '';
      }
    });
  }

  if (doc.p_num?.$) {
    queryParams.p_num = doc.p_num.$;
  }

  // remove p_cnt, need only for set p_cnt, otherwise brake table.settings
  if (queryParams?.p_cnt) {
    delete queryParams.p_cnt;
  }

  // remove p_col to update column's sort from backend
  if (queryParams?.p_col) {
    delete queryParams.p_col;
  }

  return queryParams;
}

/**
 * Tab class
 */
export class Tab implements ITab {
  /** property for generate tab's id */
  static index = 0;

  /** tab doc subject */
  private readonly docSubject: BehaviorSubject<IDocument> =
    new BehaviorSubject<IDocument>(null);

  /** tab internal id */
  private readonly _id: number;

  get id(): number {
    return this._id;
  }

  /** title of tab */
  title: string;

  /** doc func */
  func: string;

  /** doc metadata type */
  type: TabType;

  groupName: string;

  /** was this tab updated from server (if it was, that means the parent tab should be updated too) */
  wasUpdated = false;

  get route(): string {
    return `${this.type}/${this.func}/${this.id}`;
  }

  get params(): IHttpParam {
    return { func: this.func, ...this.q };
  }

  /** get string with sorted by key params for compare tabs */
  get pHash(): string {
    return getObjectSortedString(this.params);
  }

  /** get pin status */
  get isPin(): boolean {
    return this.doc?.$pin === 'yes' || this.savedPinStatus;
  }

  isActive: boolean;

  isChild: boolean;

  /** flag for save pin status when restore tab from localstorage */
  savedPinStatus: boolean;

  set doc(doc: IDocument) {
    this.docSubject.next(doc);
  }

  get doc(): IDocument | null {
    return this.docSubject.value;
  }

  /** parent doc id */
  get plid(): string {
    return DocHelper.plid(this.doc);
  }

  /** tab doc id */
  get elid(): string {
    return DocHelper.elid(this.doc);
  }

  /** doc observable for load doc when restored tab */
  readonly doc$ = this.docSubject.asObservable();

  /** query params of action without 'func' and 'out' */
  q: IHttpParam;

  /** list of selected elements for tables */
  selectionList: string[];

  /** cache for dropdown menu in list  */
  listCache: Map<string, IToolGroup[]> = new Map();

  state: ITabState = {
    progressIdSubject: new BehaviorSubject(null),
    progressModalStateSubject: new BehaviorSubject(null),
    progressModalUplaodStateSubject: new BehaviorSubject(null),
    reportSubmitModel: null,
    reportList: undefined,
    reportParams: {} as any,
    listSearch: '',
    listScroll: 0,
    model: null,
    isFormBaseMode: true,
    succeededDrawerSelectsMetadata: {},
  };

  /** parent state for same tab logic */
  parentState = {
    state: null,
    func: null,
    q: null,
  };

  /** tab was open in parent tab */
  isSame?: boolean;

  constructor({ title, func, isActive, isChild, type }: Partial<ITab>) {
    this.title = title;
    this.func = func;
    this.isActive = isActive;
    this.isChild = isChild;
    this._id = Tab.index++;
    this.type = type;
  }

  /**
   * Create tab instance from doc instance
   *
   * @param doc - doc instance
   */
  static createFromDoc(doc: IDocument): Tab {
    const title: string = DocHelper.getMessage('title', doc);
    const type: TabType =
      doc.$func === 'dashboard' ? 'dashboard' : (doc.metadata.$type as TabType);
    const func = doc.$func;
    const tab = new Tab({
      title,
      type,
      func,
      isActive: true,
      isChild: false,
    });
    tab.doc = doc;
    tab.q = getTabQueryParams(doc);
    return tab;
  }

  /**
   * Create tab instance from url
   *
   * @param url - url string
   * @param queryParams - url query params
   */
  static createFromUrl(url: string, queryParams: Params): Tab {
    const urlParamList = url.split('/');
    const func = urlParamList[2] || null;
    const type = (urlParamList[1] || null) as TabType;
    const tabInstance = new Tab({
      title: '',
      isActive: true,
      isChild: false,
      type,
      func,
    });
    tabInstance.q = queryParams;
    return tabInstance;
  }

  /**
   * Create tab from serialized tab data
   *
   * @param tabData - tab data
   */
  static deserialize(tabData: SerializedTab): Tab {
    const tabInstance = new Tab({
      title: tabData.title,
      isActive: tabData.isActive,
      isChild: tabData.isChild,
      type: tabData.type,
      func: tabData.func,
    });
    tabInstance.q = tabData.q;
    tabInstance.groupName = tabData.groupName;
    tabInstance.savedPinStatus = tabData.savedPinStatus;
    return tabInstance;
  }

  /**
   * Get tab data for saving into local storage
   */
  serialize(): SerializedTab {
    return {
      func: this.func,
      isActive: this.isActive,
      isChild: this.isChild,
      title: this.title,
      type: this.type,
      q: this.q,
      groupName: this.groupName,
      savedPinStatus: this.savedPinStatus,
    };
  }

  /**
   * Update tab instance with new doc data
   *
   * @param doc - doc instance
   * @param clearSearch - should clear listSearch from state
   */
  update(doc: IDocument, clearSearch?: boolean): void {
    const title: string = DocHelper.getMessage('title', doc);
    const func = doc.$func;
    const type: TabType =
      String(func) === 'dashboard'
        ? 'dashboard'
        : (doc.metadata?.$type as TabType);
    this.title = title;
    this.type = type;
    this.func = func;
    this.doc = doc;
    this.q = getTabQueryParams(doc);
    this.state.model = {};
    this.listCache = new Map();
    if (clearSearch) {
      this.state.listSearch = '';
    }
  }

  /**
   * Toggle progress modal for tab
   *
   * @param percent - files upload percent. Null for turning modal off
   * @param commentMsg
   */
  setUploadFileProgressModal(
    percent: number | null,
    commentMsg?: string,
  ): void {
    // @TODO i.ablov use 'progressModalStateSubject' instead!
    this.state.progressModalUplaodStateSubject.next(
      percent
        ? {
            comment: commentMsg,
            percent,
            percentDisplay: true,
          }
        : null,
    );
  }
}

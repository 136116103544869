import { ISPFieldConfig, ISPFieldWrapper } from './model';
import { everyConfig } from './utils/formly-configs';
import { isFieldWithControl } from './utils/is-field-with-control';

/**
 * Has field attribute notprop=yes
 *
 * @param field - field
 */
export function isNotProp(field: ISPFieldConfig): boolean {
  // @TODO i.ablov what the $notprop !!!??? Add to api!
  return (
    isFieldWithControl(field) &&
    (field.templateOptions?.originalControl as any)?.$notprop === 'yes'
  );
}

/**
 * Is field valid and can be submitted
 *
 * @param field - field to check
 */
export function isFieldValid(field: ISPFieldConfig): boolean {
  const isHiddenOnlyByMeta =
    field.templateOptions.isHidden && !field.templateOptions.isHiddenByMode;
  if (isHiddenOnlyByMeta) {
    return true;
  }

  // pass fields without form control
  if (!field.formControl) {
    return true;
  }

  const isNotProperty = isNotProp(field);
  const hasNoErrors = !Boolean(field.formControl.errors);
  const isMixed =
    'originalControl' in field.templateOptions &&
    '$mixed' in field.templateOptions.originalControl &&
    field.templateOptions.originalControl.$mixed === 'yes';

  return isNotProperty || hasNoErrors || isMixed;
}

/**
 * Does form have errors besides hidden fields
 *
 * @param fields - list of form field configs
 */
export function isFormSubmittable(fields: ISPFieldConfig[]): boolean {
  return everyConfig(fields, field => {
    const isHiddenOnlyByMeta =
      field.templateOptions.isHidden && !field.templateOptions.isHiddenByMode;
    if (isHiddenOnlyByMeta) {
      // before "base/extended form" feature we could just skip hidden page, because it meant validity (even though it could contain invalid children).
      // but now hidden page doesn't mean validity, because it may contain fields hidden by mode, and if such a child field is invalid, the page is invalid as well
      if (field.wrappers?.includes(ISPFieldWrapper.Page)) {
        const childrenHiddenByMode = field.fieldGroup.filter(
          f => f.templateOptions.isHiddenByMode,
        );
        if (childrenHiddenByMode.length) {
          return everyConfig(childrenHiddenByMode, isFieldValid);
        }
      }
      return 'skip-node-and-childs';
    }
    return isFieldValid(field);
  });
}

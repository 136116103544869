<div
  *ngFor="let page of summaryPages$ | async; trackBy: trackByPage; index as i"
  class="page"
  [ngClass]="{ page_hidden: isPageHidden(page) }"
>
  <div
    class="page__header"
    [ngClass]="{ page__header_active: !page.isCollapsed }"
    (click)="handlePageClick(page.$name, i, !page.isCollapsed)"
  >
    <div class="page__icon-wrapper">
      <isp-icon-provider
        class="page__icon"
        [name]="page.$img"
        [inSprite]="page?.$spritesvg === 'yes'"
        [customStyle]="pageIconStyles"
      ></isp-icon-provider>
    </div>

    <h4 class="page__title">
      {{ getPageTitle(page.$name) }}
    </h4>
  </div>
  <div class="page__description">
    <span
      *ngFor="let label of page.label; trackBy: trackByLabel"
      class="page__label"
      [ngClass]="{ page__label_hidden: isLabelHidden(label.$name) }"
    >
      <span
        class="page__label-text"
        [ngClass]="{ 'page__label-text_hideable': label.$hidden === 'yes' }"
      >
        {{
          label.$hidden === 'yes'
            ? getLabelText(label.$name, label.isHidden)
            : getLabelText(label.$name, false, label.unlimitValue)
        }}
      </span>

      <button
        *ngIf="label.$hidden === 'yes'"
        type="button"
        class="page__label-button"
        (click)="toggleLabelContent(page.$name, label.$name)"
      >
        {{ getLabelButtonText(label.isHidden) }}
      </button>
    </span>
  </div>
</div>

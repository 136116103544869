import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Checkbox field component
 */
@Component({
  selector: 'isp-formly-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./scss/checkbox.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends ISPFieldTypeBase<ISPFieldType.Checkbox> {
  get caption(): string {
    return `${this.to.caption}${
      this.to.required ? '<span class="required-mark">*</span>' : ''
    }`;
  }
}

import { IFrame, IField } from 'app/services/api5-service/api.interface';

import { FrameTO } from './model/frame-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { isFullWidth } from '../../utils/is-full-width';

/**
 * Get config for frame field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param _state - dynamic form state
 */
export function getFrameConfig(
  control: IFrame,
  field: IField,
  _state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Frame> {
  const templateOptions: FrameTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    height: control.$height,
    isFullWidth: isFullWidth(field),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Frame,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
  };
}

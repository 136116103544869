<div class="formly-input__control-group">
  <ispui-input
    [name]="key"
    type="text"
    [formlyAttributes]="field"
    [label]="to.inputLabel"
    readonly
    [value]="dateTime$ | async"
    (customFocus)="toggleFocus(true)"
    (customBlur)="toggleFocus(false)"
  ></ispui-input>
</div>

import { FormScrollerTO } from './model/form-scroller-to.interface';

import { ISPFieldConfig, ISPFieldType } from '../../model';

/**
 * Get config for chat field
 *
 * @param bottomScrollToSelector - selector of element, that will be scrolled to, after click on bottom arrow button
 */
export function getFormScrollerConfig(
  bottomScrollToSelector: string,
): ISPFieldConfig<ISPFieldType.FormScroller> {
  const templateOptions: FormScrollerTO = {
    layoutPlace: 'body',
    layoutDisplayInGroup: false,
    bottomScrollToSelector,
  };

  return {
    type: ISPFieldType.FormScroller,
    templateOptions,
  };
}

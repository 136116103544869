const chars = {
  q: 'qй',
  w: 'wц',
  e: 'eу',
  r: 'rк',
  t: 'tе',
  y: 'yн',
  u: 'uг',
  i: 'iш',
  o: 'oщ',
  p: 'pз',
  '[': '[х{',
  '{': '[х{',
  ']': ']ъ}',
  '}': ']ъ}',
  a: 'aф',
  s: 'sы',
  d: 'dв',
  f: 'fа',
  g: 'gп',
  h: 'hр',
  j: 'jо',
  k: 'kл',
  l: 'lд',
  ';': ';ж:',
  ':': ';ж:',
  "'": '\'э"',
  '"': '\'э"',
  z: 'zя',
  x: 'xч',
  c: 'cс',
  v: 'vм',
  b: 'bи',
  n: 'nт',
  m: 'mь',
  ',': ',б<?',
  '<': ',б<',
  '.': '.ю>/',
  '>': '.ю>',
  '/': '/.?',
  '?': '/.?',
  '#': '#№',
  й: 'qй',
  ц: 'wц',
  у: 'eу',
  к: 'rк',
  е: 'tе',
  н: 'yн',
  г: 'uг',
  ш: 'iш',
  щ: 'oщ',
  з: 'pз',
  х: '[х{',
  ъ: ']ъ}',
  ф: 'aф',
  ы: 'sы',
  в: 'dв',
  а: 'fа',
  п: 'gп',
  р: 'hр',
  о: 'jо',
  л: 'kл',
  д: 'lд',
  ж: ';ж:',
  э: '\'э"',
  я: 'zя',
  ч: 'xч',
  с: 'cс',
  м: 'vм',
  и: 'bи',
  т: 'nт',
  ь: 'mь',
  б: ',б<',
  ю: '.ю>',
  '№': '#№',
};

/**
 * Switches the chars in the prompted text (e.g. `тест` -> `ntcn` and vice versa) and handles dote and comma case by returning two value
 *
 * @param prompt - word to switch the chars in
 * @returns object with fields 'switchedWordWithReplacedEnDoteComma' and 'switchedWordWithReplacedRuDoteComma'. The 'switchedWordWithReplacedRuDoteComma' contains result that switched '.' to '/' and ',' to '?'. The 'switchedWordWithReplacedEnDoteComma' contains result that switched '.' to 'ю' and ',' to 'б'
 */
export function keyLangSwitch(prompt: string): string[] {
  const resultChars = [];

  for (let index = 0; index < prompt.length; index++) {
    const char = prompt[index];
    const charsPair = chars[char];
    resultChars.push(charsPair || char);
  }

  return resultChars;
}

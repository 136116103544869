import { ISPFieldConfig, ISPFieldType, ISPTemplateOptions } from '../model';

/**
 * Check that field should be displayed in header
 *
 * @param config - field config
 */
export function getLayoutPlace(
  config: ISPFieldConfig,
): ISPTemplateOptions['layoutPlace'] {
  if (
    config.type === ISPFieldType.Chat ||
    config.type === ISPFieldType.Layout ||
    config.type === ISPFieldType.Group ||
    config.type === ISPFieldType.PhoneGroup ||
    config.type === ISPFieldType.SiteSummary ||
    config.type === ISPFieldType.ChatSummary ||
    config.type === ISPFieldType.FormScroller ||
    config.type === ISPFieldType.Template ||
    config.type === ISPFieldType.TextWithLink
  ) {
    return config.templateOptions.layoutPlace;
  }

  if (config.type === ISPFieldType.ListFilter) {
    return config.templateOptions.originalBinder.$fixed === 'yes'
      ? 'header'
      : 'body';
  }

  return config.templateOptions.originalField.$fixed === 'yes'
    ? 'header'
    : 'body';
}

/**
 * Get the string by which the saved response template will be searched
 *
 * @param textAreaElement - native textArea element
 */
export function getSourceString(textAreaElement: HTMLTextAreaElement): string {
  const caretPosition = textAreaElement.selectionStart;
  const from = textAreaElement.value.lastIndexOf('\n', caretPosition - 1) + 1;
  const subTextForTemplate = textAreaElement.value.substring(
    from,
    caretPosition,
  );

  const sentenceBeforeCursor = /[.?!] *([^.?!]*)$/.exec(subTextForTemplate);

  return sentenceBeforeCursor ? sentenceBeforeCursor[1] : subTextForTemplate;
}

<ispui-password
  [id]="id"
  [name]="key"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [label]="to.inputLabel"
  [required]="to.required"
  class="ispui-password__field"
  autocomplete="new-password"
  [invalid]="showError"
  eye
  [passwordGenLength]="to.passwordGenLength"
  [chars]="to.passwordGenChars"
  (passwordGenerated)="handlePasswordGenerate($event)"
  (customFocus)="toggleFocus(true)"
  (customBlur)="toggleFocus(false)"
  #passwordField
>
  <ispui-tooltip
    *ngIf="isShowGenerate"
    [title-text]="to.generateLabel"
    placement="top"
    class="ispui-password__hint-anchor"
    viewport-selector="#layout-main-page"
    slot="input-content"
  >
    <button
      type="button"
      class="ispui-password__generate-button"
      tabindex="0"
      (click)="generatePassword()"
    >
      <isp-svg-icon name="ff-genpwd-a"></isp-svg-icon>
    </button>
  </ispui-tooltip>
</ispui-password>
<div
  *ngIf="to.displayPasswordStrength"
  class="password-strength"
  [ngClass]="{
    'password-strength_too-weak': passwordStrength === '0',
    'password-strength_weak': passwordStrength === '1',
    'password-strength_good': passwordStrength === '2',
    'password-strength_hard': passwordStrength === '3',
    'password-strength_closed': !passwordStrength
  }"
>
  <div class="password-strength__indicator"></div>
  <div class="password-strength__label">{{ passwordStrengthMsg }}</div>
</div>

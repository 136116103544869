import {
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';

import { IDesktopPageInfo, IExtformPageInfo } from 'app/app.interface';
import { LocalStorageKey } from 'app/services/local-storage/local-storage.interface';
import { LocalStorageService } from 'app/services/local-storage/local-storage.service';

import { AceEditorComponent } from '@ispdevkit/code-editor';
import { Option, SelectBuilderParams } from '@ispui/select';
import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { AceEditorOptions, TWordWrap } from './code.interface';
import { fontSizeList, modeList, themeList, wrapList } from './code.metadata';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

declare const pageInfo: IDesktopPageInfo | IExtformPageInfo;

/**
 * Code formly field component
 */
@Component({
  selector: 'isp-formly-code',
  templateUrl: './code.component.html',
  styleUrls: ['./scss/code.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Code>
  implements OnInit
{
  /** default params for select */
  private readonly defaultSelectBuilderParams: SelectBuilderParams = {
    searchCount: 10,
    $dropdown: {
      parentSelector: '#layout-main-page',
      popupClass: 'isp-dynamic-form-select-dropdown-popup',
    },
    $button: {
      style: {
        /* eslint-disable @typescript-eslint/naming-convention */
        '--ispui-select-button-legend__pl': '15px',
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    },
    $list: {
      style: {
        marginRight: '0px',
        paddingRight: '10px',
        /* eslint-disable @typescript-eslint/naming-convention */
        '--ispui-select-list__item-height': '35px',
        '--ispui-select-list__max-height': '350px',
        '--ispui-select-list__item-br': 'var(--isp-border-radius-main)',
        /* eslint-enable @typescript-eslint/naming-convention */
      },
    },
    $search: {
      style: {
        padding: '10px 10px',
        margin: '0',
      },
    },
    $toggle: {
      style: {
        padding: '5px 0px 15px 20px',
      },
    },
  };

  /** ace editor instance */
  @ViewChild(AceEditorComponent, { static: true }) editor: AceEditorComponent;

  /** current fullscreen button icon */
  get fullscreenButtonIcon(): string {
    return this.isFullScreen ? 'ff-unzoom-a' : 'ff-zoom-a';
  }

  /** current fullscreen button hint */
  get fullscreenButtonTitle(): string {
    return this.isFullScreen
      ? this.to.msg.exitFullscreen
      : this.to.msg.fullscreen;
  }

  /** whether the editor can show lines numbers */
  get canShowLineNumbers(): boolean {
    return this.aceOptions.showLineNumbers;
  }

  set canShowLineNumbers(showLineNumbers: boolean) {
    this.updateAceOptions({ showLineNumbers });
  }

  /** current font size */
  get fontSize(): number {
    return this.aceOptions.fontSize;
  }

  set fontSize(fontSize: number) {
    this.updateAceOptions({ fontSize: +fontSize });
  }

  /** current editor color scheme */
  get theme(): string {
    return this.aceOptions.theme;
  }

  set theme(theme: string) {
    this.updateAceOptions({ theme });
  }

  /** current editor code language */
  get mode(): string {
    return this.aceOptions.mode;
  }

  set mode(mode: string) {
    this.updateAceOptions({ mode });
  }

  /** current editor word wrap mode */
  get wrap(): string {
    return String(this.aceOptions.wrap);
  }

  set wrap(value: string) {
    let wrap: TWordWrap;
    if (value === 'true') {
      wrap = true;
    } else if (value === 'false') {
      wrap = false;
    } else {
      wrap = Number(value);
    }
    this.updateAceOptions({ wrap });
  }

  /** font size variants list */
  readonly fontSizeList = fontSizeList;

  /** default font size */
  readonly defaultFontSize = 12;

  /** programming languages list */
  readonly modeList = modeList.map(([slug, name]) => [
    `ace/mode/${slug}`,
    name,
  ]);

  /** available themes list */
  readonly themeList = themeList.map(([slug, name]) => [
    `ace/theme/${slug}`,
    name,
  ]);

  /** word wrap variants list */
  readonly wrapList = wrapList;

  /** whether the editor is in fullscreen mode */
  isFullScreen = false;

  /** default options for Ace Editor */
  aceOptions: AceEditorOptions = {
    wrap: false,
    fontSize: 12,
    showLineNumbers: true,
    theme: 'ace/theme/chrome',
    showPrintMargin: false,
    mode: '',
  };

  /** font size option list */
  get fontSizeOptions(): Option[] {
    return fontSizeList.map(o => ({
      text: `${o}px`,
      value: `${o}`,
    }));
  }

  /** font size select build params */
  get fontSizeSelectBuilderParams(): SelectBuilderParams {
    return {
      ...this.defaultSelectBuilderParams,
      id: this.getInputName('fontsize'),
      width: 75,
    };
  }

  /** programming languages option list */
  get modeOptionList(): Option[] {
    return modeList.map(([slug, name]) => ({
      text: name,
      value: `ace/mode/${slug}`,
    }));
  }

  /** programming languages select build params */
  get modeSelectBuilderParams(): SelectBuilderParams {
    return {
      ...this.defaultSelectBuilderParams,
      id: this.getInputName('language'),
      width: 120,
    };
  }

  /** available themes option list */
  get themeOptionList(): Option[] {
    return themeList.map(([slug, name]) => ({
      text: name,
      value: `ace/theme/${slug}`,
    }));
  }

  /** themes select build params */
  get themeSelectBuilderParams(): SelectBuilderParams {
    return {
      ...this.defaultSelectBuilderParams,
      id: this.getInputName('theme'),
      width: 170,
    };
  }

  /** word wrap option list */
  get wrapOptionList(): Option[] {
    return wrapList.map(([value, text]) => ({
      text,
      value: value.toString(),
    }));
  }

  /** word wrap select build params */
  get wrapSelectBuilderParams(): SelectBuilderParams {
    return {
      ...this.defaultSelectBuilderParams,
      id: this.getInputName('wordwrap'),
      width: 100,
    };
  }

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: WindowWrapper,
  ) {
    super();
    (this.window as any).ace.config.set(
      'basePath',
      `${pageInfo.theme}/assets/ace`,
    );
  }

  @HostListener('keyup.esc')
  closeFullscreenMode(): void {
    if (this.isFullScreen) {
      this.toggleFullscreen();
    }
  }

  /**
   * Update Ace Editor Options
   *
   * @param ops - options to update
   */
  private updateAceOptions(ops: Partial<AceEditorOptions>): void {
    this.aceOptions = {
      ...this.aceOptions,
      ...ops,
    };
    this.cdr.markForCheck();
    this.localStorage.patch(LocalStorageKey.CodeEditorSettings, ops);
  }

  /**
   * Restores saved editor settings state
   */
  private restoreSettings(): void {
    const savedSettings = this.localStorage.get<AceEditorOptions>(
      LocalStorageKey.CodeEditorSettings,
    );
    if (savedSettings?.wrap) {
      this.wrap = String(savedSettings.wrap);
    }
    if (savedSettings?.fontSize) {
      this.fontSize = savedSettings.fontSize;
    }
    if (savedSettings?.theme) {
      this.theme = savedSettings.theme;
    }
    if (savedSettings?.showLineNumbers !== undefined) {
      this.canShowLineNumbers = savedSettings.showLineNumbers;
    }
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.restoreSettings();
    this.mode = `ace/mode/${this.to?.language || 'text'}`;
    // we trigger resize for Ace editor to adapt its scroll so long text can be seen fully
    setTimeout(() => this.window.dispatchEvent(new Event('resize')), 1000);
  }

  /**
   * Reset the editor
   */
  resetEditor(): void {
    this.editor.setOptions(this.aceOptions);
    /** @HACK update ace editor height after reset */
    this.window.dispatchEvent(new Event('resize'));
  }

  toggleFullscreen(): void {
    this.isFullScreen = !this.isFullScreen;
    setTimeout(() => this.resetEditor());
  }

  /**
   * Returns the unique ID for settings input
   *
   * @param name - name of field
   */
  getInputName(name: string): string {
    return `${this.id}-code-${name}`;
  }

  /**
   * Prevent enter click bubbling for being able to use text search in the editor
   *
   * @param event - keybouar event
   */
  preventEnterClickBubbling(event: KeyboardEvent): void {
    event.stopPropagation();
  }
}

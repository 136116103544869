import { IDocument, ITicket } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import {
  ChatSummaryGroup,
  ChatSummaryTO,
} from './model/chat-summary-to.interface';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';

/**
 * Get chat summary from backend ticket metadata
 *
 * @param doc - document instance
 * @param name - name of list with messages
 */
function getChatSummary(
  doc: IDocument,
  name: string,
): ChatSummaryGroup[] | undefined {
  const mlist = doc?.mlist?.find(list => list.$name === name);
  const infoMessage = mlist?.message?.find(message => message.$type === 'info');

  if (!infoMessage) {
    return;
  }

  return infoMessage.rowgroup.map(group => ({
    titleMsg: DocHelper.getMessage(group.$name, doc),
    items: group.row.map(item => ({
      label: DocHelper.getMessage(item.$name, doc),
      value: item.$,
    })),
  }));
}

/**
 * Get config for chat summary field
 *
 * @param control - control metadata
 * @param state - dynamic form state
 */
export function getChatSummaryConfig(
  control: ITicket,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.ChatSummary> | undefined {
  const info = getChatSummary(state.doc, control.$name);

  if (!info) {
    return;
  }

  const templateOptions: ChatSummaryTO = {
    info,
    collapseMsg: DocHelper.getMessage('collapse', state.doc),
    expandMsg: DocHelper.getMessage('expand', state.doc),
    layoutPlace: 'summary',
  };

  return {
    type: ISPFieldType.ChatSummary,
    templateOptions,
    expressionProperties: {
      'templateOptions.isHidden': (_, __, fieldConfig) =>
        !fieldConfig.templateOptions.info,
    },
  };
}

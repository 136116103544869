import { appendConfigWrapperOptions } from './append-config-wrapper-options';
import { getConfigsFromTypedField } from './get-configs-from-typed-field';
import { wrappConfigsIntoGroup } from './wrapp-configs-into-group';

import { ISPFieldConfig, ISPFormState } from '../../model';
import { forEachConfig } from '../../utils/formly-configs';
import { TypedField } from '../form-preparing';

/**
 * Get configs from typed field and wrapp configs into group and wrappers
 *
 * @param field
 * @param state
 */
export function getWrappedConfigsFromTypedField(
  field: TypedField,
  state: ISPFormState,
): ISPFieldConfig[] {
  // get fields configs from controls
  const configs = getConfigsFromTypedField(field, state);

  // check for inline or phone group
  const configsWithGroups = wrappConfigsIntoGroup(configs, field.field, state);

  forEachConfig(configsWithGroups, config => {
    appendConfigWrapperOptions(config, field.field, state);
  });

  return configsWithGroups;
}

<ispui-dropdown
  #dropdown
  [direction]="direction"
  [alignment]="alignment"
  [closeOnOuterClick]="false"
  [attr.popup-class]="'isp-session-tip__popup'"
  [attr.parent-selector]="attachSelector"
  [z-index]="zIndex"
  [offset]="15"
  (closeClick)="sendSeenRequest()"
>
  <div slot="anchor" class="isp-session-tip__anchor"></div>
  <ispui-dropdown-content>
    {{ message }}
  </ispui-dropdown-content>
</ispui-dropdown>

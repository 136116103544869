import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const BASE_IMG_PATH = '/manimg/common/img/';

/**
 * Component provide show custom icon and from sprite
 */
@Component({
  selector: 'isp-icon-provider',
  templateUrl: './icon-provider.component.html',
  styleUrls: ['./scss/icon-provider.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconProviderComponent {
  /* Icon name */
  @Input() name: string;

  /** Custom styles for icon */
  @Input() customStyle: {};

  /** has in sprite */
  @Input() inSprite: boolean;

  /** whether icon is animated */
  @Input() isAnimated: boolean;

  /** get url for custom img */
  private getUrl(): string {
    return `${BASE_IMG_PATH}${this.name}.png`;
  }

  getBgStyle(): string {
    return `url(${this.getUrl()}) top left / cover no-repeat `;
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IFormCollapseEvent } from 'components/form-collapse';

import { DynamicFormService } from '../../dynamic-form.service';
import { ISPFieldWrapperBase } from '../../model';

/**
 * Collapsible page wrapper
 */
@Component({
  selector: 'isp-formly-page',
  templateUrl: './page.component.html',
  styleUrls: ['./scss/page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageWrapperComponent extends ISPFieldWrapperBase {
  constructor(private readonly dynamicFormService: DynamicFormService) {
    super();
  }

  /**
   * Emits the collapse event on collapsible click
   *
   * @param event - collapse event
   */
  emitPageCollapseEvent(event: IFormCollapseEvent): void {
    this.dynamicFormService.emitPageCollapseEvent(event);
  }
}

import {
  IDocument,
  IField,
  IMListMessageType,
  IMListRoot,
  ITicket,
} from 'app/services/api5-service/api.interface';
import { IChatBubble } from 'components/chat-bubble/chat-bubble.interface';

import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { ChatTO } from './model/chat-to.interface';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';

/**
 * Returns compatible messages from backend for chat bubble component
 *
 * @param mlist - message list metadata instance
 * @param doc - document instance
 */
export function getChatMessages(
  mlist: IMListRoot | undefined,
  doc: IDocument,
): IChatBubble[] {
  if (!mlist?.message) {
    return [];
  }

  const techTypes: IMListMessageType[] = ['inner', 'system', 'ticketnote'];

  return mlist.message
    .map(message => {
      const isMessageRated = Boolean(message.rates?.rate);

      const labels = {
        setRate: DocHelper.getMessage('msg_rate_clientsetrate', doc),
        alreadyRated: DocHelper.getMessage('msg_rate_rated', doc),
      };

      // message with type 'info' is displayed in 'isp-formly-chat-summary'
      if (message.$type === 'info') {
        return null;
      }

      const baseBubble: IChatBubble = {
        id: message.$id,
        type: message.$type,
        labels,
        chatTitle:
          message.$type === 'system'
            ? message.user?.position?.$
            : message.user?.realname?.$,
        chatTitleCaption: message.date_post?.$,
        body: message.body.$,
        align:
          message.$type === 'outcoming' ||
          message.$type === 'system' ||
          message.$type === 'inner' ||
          message.$type === 'ticketnote'
            ? 'right'
            : 'left',
        avatar: {
          isDefault:
            message.avatar?.$default === 'yes' ||
            techTypes.includes(message.$type),
          type: message.avatar?.$name,
          caption: message.user?.realname?.$,
          path: message.avatar?.$,
        },
        files: message.file?.filter(Boolean).map(file => ({
          title: file.name.$,
          size: file.size.$,
          action: file.$action,
          params: { [file.param.$name]: file.param.$ },
        })),
        rates: {
          canShowRates: Boolean(message.rates),
          isRated: isMessageRated,
          rates: isMessageRated
            ? [
                {
                  img: message.rates?.rate?.$img,
                },
              ]
            : message.rates?.setrate.map(rate => ({
                img: rate.$img,
                func: rate.$func,
              })),
        },
      };

      return baseBubble;
    })
    .filter(Boolean);
}

/**
 * Get config for chat field
 *
 * @param control - control metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getChatConfig(
  control: ITicket,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Chat> {
  const templateOptions: ChatTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    messages: getChatMessages(
      state.doc.mlist?.find(mlist => mlist.$name === control.$name),
      state.doc,
    ),
    fontSize: control.$fontsize,
    fontFamily: control.$fontfamily,
    label: getLabel(field, state.doc),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Chat,
    templateOptions,
  };
}

/**
 * Add messages to chat config, sorting messages and removing dulicates
 *
 * @param config - chat component config
 * @param mlist - mlist with new messages
 * @param doc - doc instance
 */
export function addMessagesToChatConfig(
  config: ISPFieldConfig<ISPFieldType.Chat>,
  mlist: IMListRoot,
  doc: IDocument,
) {
  const oldMessages = config.templateOptions.messages;
  const newMessages = getChatMessages(mlist, doc);

  // it's to remove duplicate messages, Backend can send them to send messages,
  // perhaps then it's worth investigating why
  const oldMessagesIds = oldMessages.map(message => message.id);
  const uniqueNewMessages = newMessages.filter(
    message => !oldMessagesIds.includes(message.id),
  );
  if (!uniqueNewMessages.length) {
    return;
  }

  const resumeIndex = oldMessages.findIndex(
    message => message.type === 'ticketnote',
  );
  if (resumeIndex >= 0) {
    const messages = [...oldMessages];
    // insert new messages before resume
    messages.splice(resumeIndex, 0, ...uniqueNewMessages);
    config.templateOptions.messages = messages;
  } else {
    // just insert new messages in the end of the list, with ref overriding
    config.templateOptions.messages = [...oldMessages, ...uniqueNewMessages];
  }
}

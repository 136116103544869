import { Directive } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldWrapper } from '@ngx-formly/core';

import { ISPFieldConfig } from './field-config.interface';
import { ISPFieldType } from './field-type.enum';
import { ISPFormOptions } from './form-options.interface';
import { ISPFormState } from './form-state.interface';
import { ISPTemplateOptions } from './template-options.interface';

/**
 * Field wrapper class for Dragon theme
 */
@UntilDestroy()
@Directive()
export class ISPFieldWrapperBase<
  T extends ISPFieldType = ISPFieldType,
> extends FieldWrapper {
  field: ISPFieldConfig<T>;

  readonly to: ISPTemplateOptions<T>;

  readonly options: ISPFormOptions;

  readonly formState: ISPFormState;
}

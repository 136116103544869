import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { TextWithLinkComponent } from './text-with-link.component';

import { ISPFieldType } from '../../model';
import { LinkFieldModule } from '../link';

@NgModule({
  declarations: [TextWithLinkComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    LinkFieldModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.TextWithLink,
          component: TextWithLinkComponent,
        },
      ],
    }),
    SvgIconModule,
  ],
})
export class TextWithLinkModule {}

import { IInput, IField } from 'app/services/api5-service/api.interface';

import { getDesc } from 'common/dynamic-form/utils/get-desc';
import { getLabel } from 'common/dynamic-form/utils/get-label';

import { CheckboxTO } from './model/checkbox-to.interface';

import {
  ISPFormState,
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
} from '../../model';

/**
 * Get config for checkbox field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getCheckboxConfig(
  control: IInput,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Checkbox> {
  const label = getLabel(field, state.doc);
  const desc = getDesc(field, state.doc);
  const displayLabel = label?.length && desc?.length;

  const templateOptions: CheckboxTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    caption: desc ? desc : label,
    isMixed: state.mixedService.isControlMixed(control),
    label: displayLabel ? label : '',
    labelSize: '1.17em',
    labelWeight: 'bold',
    labelOffset: '1em',
    hintPlace: displayLabel ? 'label' : 'field',
    hintDisplayMode: 'center',
    // hide desc in field-wrapper. Checkbox display it by it's own rules
    hideDesc: true,
  };

  const config: ISPFieldConfig<ISPFieldType.Checkbox> = {
    key: control.$name,
    type: ISPFieldType.Checkbox,
    wrappers: [ISPFieldWrapper.FieldBase, ISPFieldWrapper.ValidationError],
    templateOptions,
    expressionProperties: {
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
    },
    modelOptions: {
      updateOn: 'change',
    },
  };

  return config;
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Color modificator class
 */
@Pipe({
  name: 'colorMod',
})
export class ColorModPipe implements PipeTransform {
  transform(
    value: number | string,
    elementClass: string,
    modPropClass = 'color',
  ): string {
    return `${elementClass}_${modPropClass}_${value}`;
  }
}

import { ISPFieldConfig } from '../../model';

/**
 * Perform action on each field in fields config
 *
 * @param fieldConfigs - fields config
 * @param action - action callback
 */
export function forEachConfig(
  fieldConfigs: ISPFieldConfig[],
  action: (field: ISPFieldConfig) => void,
): void {
  const forEach = (fields: ISPFieldConfig[]) => {
    fields.forEach(field => {
      action(field);

      if (field.fieldGroup) {
        forEach(field.fieldGroup);
      }
    });
  };

  return forEach(fieldConfigs);
}

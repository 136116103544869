import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

import { IDesktopPageInfo } from 'app/app.interface';
import { AppService } from 'app/app.service';
import {
  IDocument,
  IMenuNode,
  IMenuSubnode,
} from 'app/services/api5-service/api.interface';
import { LocalStorageKey } from 'app/services/local-storage/local-storage.interface';
import { LocalStorageService } from 'app/services/local-storage/local-storage.service';
import { move } from 'ramda';
import { BehaviorSubject, Observable, of, zip } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { convertStringToParamsWithDecode } from 'utils/convert-string-to-params';
import { DocHelper } from 'utils/dochelper';
import { getObjectSortedString } from 'utils/object.hash';

import { Tab } from './tab.class';
import { SerializedTab, TabType } from './tab.interface';

import { getRedirectTypeOrDoRedirect } from '../../../utils/get-redirect-type-or-do-redirect';
import {
  PreloadedActionOptions,
  PreloadedActionService,
} from '../preloaded-action.service';

declare const pageInfo: IDesktopPageInfo;

/**
 * Tab service
 * Controlled tabs in app
 */
@Injectable({
  providedIn: 'root',
})
export class TabService {
  /** tab map for fast access */
  private readonly tabMap: Map<number, Tab> = new Map();

  /** tab group list */
  private groupList: Tab[][] = [];

  /** group list subject */
  private readonly groupListSubject = new BehaviorSubject<Tab[][]>(
    this.groupList,
  );

  /** group list observable */
  readonly groupList$ = this.groupListSubject.asObservable();

  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly preloadedActionService: PreloadedActionService,
    private readonly appService: AppService,
  ) {
    this.init();
  }

  /**
   * Init method
   */
  private init(): void {
    this.restoreTabList();
    this.nextGroupList();
  }

  /**
   * Save sametab status for open prev tab after close current
   *
   * @param tab - tab instance
   */
  private saveSameTabState(tab: Tab): void {
    tab.isSame = true;
    tab.parentState = {
      func: tab.func,
      state: { ...tab.state },
      q: { ...tab.q },
    };
  }

  /**
   * Get index of active group
   */
  private getActiveGroupIndex(): number {
    return this.groupList.findIndex(groupItem =>
      Boolean(groupItem.find(tabItem => tabItem.isActive)),
    );
  }

  /**
   * Get startform and startpage
   */
  private getStartTabList(): Tab[] {
    const tabGroup = [];
    const startpage = pageInfo?.startpage
      ? convertStringToParamsWithDecode(`func=${pageInfo.startpage}`)
      : null;
    const startform = pageInfo?.startform
      ? convertStringToParamsWithDecode(`func=${pageInfo.startform}`)
      : null;
    if (!startpage && !startform) {
      const dashboardTab = new Tab({
        title: this.appService.getDesktopMessage('dashboard'),
        type: 'dashboard',
        func: 'dashboard',
        isActive: true,
        isChild: false,
      });
      dashboardTab.groupName = 'dashboard';
      tabGroup.push(dashboardTab);
    }

    if (startpage) {
      tabGroup.push(
        this.createTabInstanceFromParams(
          startpage,
          startpage.func === 'dashboard' ? 'dashboard' : 'list',
          !Boolean(startform),
          false,
        ),
      );
    }
    if (startform) {
      tabGroup.push(
        this.createTabInstanceFromParams(
          startform,
          'form',
          true,
          pageInfo?.startpage !== 'dashboard',
        ),
      );
    }

    return tabGroup;
  }

  /**
   * Create tab instance from params
   *
   * @param params
   * @param type
   * @param isActive
   * @param isChild
   */
  private createTabInstanceFromParams(
    params: Params,
    type: TabType,
    isActive: boolean,
    isChild: boolean,
  ): Tab {
    const tabInstance = new Tab({
      title: '',
      isActive,
      isChild,
      type,
      func: params?.func,
    });
    tabInstance.q = params;
    tabInstance.groupName = this.getMenuGroupName(tabInstance.func);

    this.tabMap.set(tabInstance.id, tabInstance);
    this.checkDocLoaded(tabInstance);
    if (tabInstance.isActive) {
      this.navigateToTab(tabInstance);
    }
    return tabInstance;
  }

  /**
   * TabList init logic
   * restore tab form localstorage, startpage, pinned
   */
  private restoreTabList(): void {
    zip(
      this.appService.desktop$.pipe(
        take(1),
        map(d => {
          const mainMenu = d?.mainmenu?.node ?? [];
          const msg = DocHelper.getMessageSet(d);
          return mainMenu.reduce<
            (IMenuSubnode & { title: string; groupName: string })[]
          >((acc, node) => {
            return acc.concat(
              node.node
                .filter(n => n.$pin)
                .map(n => {
                  return {
                    ...n,
                    title: msg[`menu_${n.$action}`],
                    groupName: this.getMenuGroupName(n.$name),
                  };
                }),
            );
          }, []);
        }),
      ),
      of(
        this.localStorageService.get<SerializedTab[][]>(
          LocalStorageKey.Tablist,
        ),
      ),
      of(this.getStartTabList()),
      of(
        Tab.createFromUrl(
          this.router.url,
          this.router.parseUrl(this.router.url)?.queryParams,
        ),
      ),
    ).subscribe(
      ([pinMenuItemList, savedTabList, startTabGroup, tabFromUrl]) => {
        // saved tabs
        const savedTabGroups: Tab[][] = [];
        const startTabFuncs = startTabGroup.map(item => item.func);
        let savedTabActive = false;
        savedTabList?.forEach(savedGroup => {
          const group = [];
          savedGroup.forEach(tab => {
            if (startTabFuncs.includes(tab.func)) {
              return;
            }
            const tabInstance = Tab.deserialize(tab);
            this.tabMap.set(tabInstance.id, tabInstance);
            if (tab.isActive) {
              this.loadAndActivateTab(tabInstance);
            }
            group.push(tabInstance);
            if (tab.isActive) {
              savedTabActive = true;
            }
          });
          if (group.length) {
            savedTabGroups.push(group);
          }
        });

        // starting tabs
        startTabGroup.forEach(tab => {
          this.tabMap.set(tab.id, tab);
          if (savedTabActive) {
            tab.isActive = false;
          }
        });

        // pinned tabs
        const pinnedGroups = [];
        pinMenuItemList.forEach(n => {
          if (
            savedTabGroups.flat().some(tab => tab.func === n.$action) ||
            startTabGroup.some(tab => tab.func === n.$action)
          ) {
            return;
          }
          const tabInstance = new Tab({
            title: n.title,
            isActive: false,
            isChild: false,
            type: n.$type as TabType,
            func: n.$action,
          });
          tabInstance.savedPinStatus = true;
          tabInstance.groupName = n.groupName;
          this.tabMap.set(tabInstance.id, tabInstance);
          pinnedGroups.push([tabInstance]);
        });
        this.groupList = [startTabGroup, ...pinnedGroups, ...savedTabGroups];

        // tab for current url
        if (
          tabFromUrl &&
          !startTabGroup.some(tab => tab.func === tabFromUrl.func)
        ) {
          let hasInMap = false;
          this.tabMap.forEach(t => {
            if (t.pHash === tabFromUrl.pHash) {
              hasInMap = true;
            }
          });
          if (!hasInMap) {
            this.tabMap.set(tabFromUrl.id, tabFromUrl);
            this.groupList.push([tabFromUrl]);
            this.setActive(tabFromUrl);
          }
        }
        this.groupList.flat().forEach(tab => {
          if (tab.func === 'dashboard' && tab.isActive) {
            this.loadAndActivateTab(tab);
          }
        });
      },
    );
  }

  /**
   * Check load and navagate tto tab
   *
   * @param tab - tab instance
   */
  private loadAndActivateTab(tab: Tab): void {
    this.checkDocLoaded(tab);
    this.navigateToTab(tab);
  }

  /**
   * Detect and activate tab after close
   *
   * @param gIndex - group index of deleted tab
   * @param index - index of deleted tab
   */
  private detectTabForActivate(gIndex: number, index: number): void {
    let indexToActive;
    let groupIndexToActive = gIndex;
    let tabToActive;
    if (index === undefined || gIndex === undefined) {
      tabToActive = this.groupList[0][0];
    } else {
      // is Parent
      if (index === 0) {
        [...this.groupList].reverse().some((group, groupIndex) => {
          const groupIndexOriginal = this.groupList.length - groupIndex - 1;
          if (
            groupIndexOriginal < gIndex &&
            group !== undefined &&
            group.length
          ) {
            groupIndexToActive = groupIndexOriginal;
            return true;
          }
        });
        indexToActive = this.groupList[groupIndexToActive].length - 1;
      } else {
        indexToActive = index - 1;
      }
      tabToActive = this.groupList[groupIndexToActive][indexToActive];
    }
    this.setActive(tabToActive);
  }

  /**
   * Remove group or tab list
   *
   * @param gIndex - group's index for delete
   * @param index - tab index for delete, if not null delete tab with children in group
   */
  private removeTabList(gIndex: number, index: number = null): void {
    let removedTabList;
    if (index === null) {
      removedTabList = this.groupList.splice(gIndex, 1);
    } else {
      removedTabList = this.groupList[gIndex].splice(index);
    }
    this.removeTabFromMap(removedTabList);
  }

  /**
   * Remove tabList form tabMap
   *
   * @param tabList - tab list for remove
   */
  private removeTabFromMap(tabList: Tab[] | Tab[][]): void {
    const tabListToRemove = Array.isArray(tabList[0]) ? tabList[0] : tabList;
    tabListToRemove.forEach(tab => {
      this.tabMap.delete(Number(tab.id));
    });
  }

  /**
   * Get postion tab in tab array
   *
   * @param id - tab's id
   */
  private getTabPosition(id: number): { index: number; gIndex: number } {
    const gIndex = this.groupList.findIndex(groupItem =>
      Boolean(groupItem.find(tabItem => tabItem.id === id)),
    );

    const index = this.groupList[gIndex]
      ? this.groupList[gIndex].findIndex(tabItem => tabItem.id === id)
      : undefined;
    return { index, gIndex };
  }

  /**
   * Navigate to active tab
   *
   * @param tab - tab instance
   */
  private navigateToTab(tab: Tab): void {
    this.router.navigate([`${tab.route}`], { queryParams: tab.q });
  }

  /**
   * Push next group list and save to localStorage
   */
  private nextGroupList(): void {
    this.groupListSubject.next(this.sortGroupListByPin(this.groupList));
    this.syncTabListToLocalstorage();
  }

  /**
   * Sort group tab pin tab
   *
   * @param groupList
   */
  private sortGroupListByPin(groupList: Tab[][]): Tab[][] {
    return [
      groupList[0],
      ...groupList.slice(1).sort((a, b) => {
        if (a[0].isPin && !b[0].isPin) {
          return -1;
        }
        if (!a[0].isPin && b[0].isPin) {
          return 1;
        }
        if (a[0].isPin === b[0].isPin) {
          return 0;
        }
      }),
    ];
  }

  /**
   * Save tab list to localStorage
   */
  private syncTabListToLocalstorage(): void {
    const tabListToSave = this.groupList
      .slice(1)
      .map(group => group.map(tab => tab.serialize()));
    this.localStorageService.set(LocalStorageKey.Tablist, tabListToSave);
  }

  /**
   * Check and load doc to tab
   *
   * @param tab - tab instance
   */
  private checkDocLoaded(tab: Tab): void {
    if (!tab.doc) {
      this.preloadedActionService.getAction(tab.params).subscribe(doc => {
        if (getRedirectTypeOrDoRedirect(doc)) {
          this.close(tab);
        } else {
          const tabItem = this.getById(tab.id);
          tabItem?.update(doc);
          this.nextGroupList();
        }
      });
    }
  }

  /**
   * Set unactive all tabs
   */
  private unSetActiveAllTabs(): void {
    this.groupList.map(group => group.map(tab => (tab.isActive = false)));
  }

  /**
   * Get group name of item, for set icon of pin tab
   *
   * @param name - tab's node name
   */
  private getMenuGroupName(name: string): string {
    if (name === 'dashboard') {
      return 'dashboard';
    }
    const modernMenuNodeList = this.appService.getModernMainMenu();
    const menuNodeList = this.appService.getMainMenu();
    return (
      this.getMenuGroupNameByList(name, modernMenuNodeList) ||
      this.getMenuGroupNameByList(name, menuNodeList)
    );
  }

  /**
   * Returns a group name by list of menu nodes
   *
   * @param name - tab's node name
   * @param list - list of menu nodes
   */
  private getMenuGroupNameByList(name: string, list: IMenuNode[]): string {
    if (list.length === 0) {
      return '';
    }

    const node = list.find(n => n.node.some(subnode => subnode.$name === name));

    const groupName = (node?.$type === 'noname' ? name : node?.$name) || '';
    return groupName.replace(/\./g, '-');
  }

  /**
   * Create main tab of group
   *
   * @param doc - doc instance
   * @param blank - create new tab
   */
  create(doc: IDocument, blank = false): Tab {
    const tab = Tab.createFromDoc(doc);
    tab.groupName = this.getMenuGroupName(tab.func);

    const group = [tab];
    const activeGroupIndex = this.groupList.findIndex(groupItem =>
      Boolean(groupItem.find(tabItem => tabItem.isActive)),
    );
    this.unSetActiveAllTabs();
    if (
      blank ||
      activeGroupIndex <= 0 ||
      this.groupList[activeGroupIndex][0].isPin
    ) {
      this.groupList.push(group);
    } else {
      this.removeTabList(activeGroupIndex);
      this.groupList.push(group);
    }
    this.tabMap.set(tab.id, tab);
    this.navigateToTab(tab);
    this.nextGroupList();
    return tab;
  }

  /**
   * Create child tab
   *
   * @param doc - doc instance
   * @param pTabid
   */
  createChild(doc: IDocument, pTabid: number): Tab {
    const tab = Tab.createFromDoc(doc);
    tab.groupName = this.getMenuGroupName(tab.func);
    tab.isChild = true;

    const { index, gIndex } = this.getTabPosition(pTabid);

    // remove old children
    if (this.groupList[gIndex].length > 1) {
      this.removeTabList(gIndex, index + 1);
    }

    this.unSetActiveAllTabs();

    this.groupList[gIndex].push(tab);
    this.tabMap.set(tab.id, tab);
    this.navigateToTab(tab);
    this.nextGroupList();
    return tab;
  }

  /**
   * Create tab from router param
   *
   * @param p - router params
   * @param q - query params
   */
  getTabFromRoute(p: Params, q: Params): Observable<Tab> {
    const pHash = getObjectSortedString({ func: p.func, ...q });
    let tabfromRoute;
    // check for active
    this.tabMap.forEach(tab => {
      if (tab.isActive && pHash === tab.pHash) {
        tabfromRoute = tab;
      }
    });
    if (tabfromRoute) {
      return of(tabfromRoute);
    } else {
      // check for pin
      this.tabMap.forEach(tab => {
        if (tab.isPin && tab.func === p.func) {
          tabfromRoute = tab;
        }
      });
      if (tabfromRoute) {
        return of(tabfromRoute);
      }
    }
    return new Observable<Tab>(subscriber => {
      const params = { func: p.func, ...q };
      this.preloadedActionService.getAction(params).subscribe(d => {
        const tab = this.create(d, true);
        subscriber.next(tab);
        subscriber.complete();
      });
    });
  }

  /**
   * Get tab by id and check loaded
   *
   * @param id
   */
  getTabForRenderById(id: number): Tab {
    const tab = this.tabMap.get(Number(id));
    return tab;
  }

  /**
   * Get tab by id
   *
   * @param id - tab's id
   */
  getById(id: number): Tab {
    return this.tabMap.get(Number(id));
  }

  /**
   * Close tab
   *
   * @param tab - tab instance
   * @param isUpdateParent
   */
  close(tab: Tab, isUpdateParent: boolean = tab.wasUpdated): void {
    const { index, gIndex } = this.getTabPosition(tab.id);
    const activegIndex = this.getActiveGroupIndex();
    const activeTabIndex = this.groupList[activegIndex]?.findIndex(
      tabItem => tabItem.isActive,
    );

    if (!tab.isChild) {
      this.removeTabList(gIndex);
    } else {
      this.removeTabList(gIndex, index);
      if (isUpdateParent) {
        const parentTab: Tab = this.groupList?.[gIndex]?.[index - 1];
        if (parentTab) {
          this.update(parentTab);
        }
      }
    }
    if (tab.isActive || (activegIndex === gIndex && index < activeTabIndex)) {
      this.detectTabForActivate(gIndex, index);
    } else {
      this.nextGroupList();
    }
  }

  /**
   * Update doc, if has doc just set to tab, else get form server
   *
   * @param tab - tab instance
   * @param doc - doc instance
   * @param navigate - navigate to the tab
   */
  update(tab: Tab, doc?: IDocument, navigate = true): void {
    if (doc) {
      const tabItem = this.getById(tab.id);
      // prevent update tab after delete
      if (tabItem) {
        tabItem.update(doc);
        this.nextGroupList();
        if (navigate) {
          this.navigateToTab(tabItem);
        }
      }
    } else {
      this.preloadedActionService.getAction(tab.params).subscribe(docData => {
        const tabItem = this.getById(tab.id);
        if (tabItem) {
          tabItem.update(docData);
          this.nextGroupList();
        }
      });
    }
  }

  /**
   * Open child tab in parent tab
   *
   * @param tab - tab instance
   * @param doc - doc instance
   */
  openSame(tab: Tab, doc: IDocument): void {
    const tabItem = this.getById(tab.id);
    this.saveSameTabState(tabItem);
    tabItem?.update(doc, true);
    this.nextGroupList();
    this.navigateToTab(tabItem);
  }

  /**
   * Open parent tab in child tab when close tab by cancel button
   *
   * @param tab - tab instance
   */
  resetSameTab(tab: Tab): void {
    if (!tab?.isSame) {
      return;
    }
    const tabItem = this.getById(tab.id);
    tabItem.func = tab.parentState.func;
    tabItem.q = { ...tab.parentState.q };
    tabItem.state = { ...tab.parentState.state };
    tabItem.parentState = { func: null, q: null, state: null };
    tabItem.doc = null;
    tabItem.isSame = false;
    this.updateFromServer(tabItem).subscribe(doc => {
      this.update(tabItem, doc);
      this.navigateToTab(tabItem);
    });
  }

  /**
   * Refetch tab doc data from server, and update tab state
   *
   * @param tab - tab instance
   * @param options - preloaded request options
   */
  updateFromServer(
    tab: Tab,
    options?: PreloadedActionOptions,
  ): Observable<IDocument> {
    return this.preloadedActionService.getAction(tab.params, options).pipe(
      tap(docData => {
        const tabItem = this.getById(tab.id);
        if (tabItem) {
          tabItem.doc = docData;
          this.nextGroupList();
          tab.wasUpdated = true;
        }
      }),
    );
  }

  /**
   * Set tab active
   *
   * @param tab - tab instance
   */
  setActive(tab: Tab): void {
    this.unSetActiveAllTabs();
    this.groupList.forEach(group => {
      const existedTab = group.find(item => item.id === tab.id);
      if (existedTab) {
        existedTab.isActive = true;
        this.checkDocLoaded(existedTab);
      }
    });
    this.navigateToTab(tab);
    this.nextGroupList();
  }

  /**
   * Return first tab in group by func
   *
   * @param func
   */
  findMainTabByFunc(func: string): Tab {
    let tab = null;
    this.groupList.some(group => {
      if (group[0].func === func) {
        tab = group[0];
        return true;
      }
      return false;
    });
    return tab;
  }

  /**
   * Rearranges the tabs according to drag'n'drop event
   *
   * @param event - drag'n'drop event
   * @param event.currentIndex
   * @param event.previousIndex
   */
  rearrangeTabs({ currentIndex, previousIndex }: CdkDragDrop<Tab[][]>): void {
    const newIndex = currentIndex || 1;
    if (newIndex === previousIndex) {
      return;
    }
    const newGroupList = this.sortGroupListByPin(
      move(previousIndex, newIndex, this.groupList),
    );
    this.groupList = newGroupList;
    this.groupListSubject.next(newGroupList);
    this.syncTabListToLocalstorage();
  }
}

<div class="container" [ngClass]="{ container_inline: to.inlineSelect }">
  <ispui-radio
    *ngFor="let option of to.options"
    class="option"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [attr.value]="option.$key"
    [attr.name]="field.id"
    (ngModelChange)="onValueChange()"
  >
    <span class="ispui-text">
      {{ option.$ }}
    </span>
  </ispui-radio>
</div>

import {
  IField,
  IControl,
  ISelect,
  IInput,
} from 'app/services/api5-service/api.interface';

import { getDesc } from 'common/dynamic-form/utils/get-desc';
import { DocHelper } from 'utils/dochelper';

import { InputBaseWrapperTO } from './model/input-base-to.interface';

import { ISPFieldConfig, ISPFormState } from '../../model';
import { getHint } from '../../utils/get-hint';
import { getShadowHint } from '../../utils/get-shadow-hint';
import { isFullWidth } from '../../utils/is-full-width';

/**
 * Append template options, specific for input-base field wrapper
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function appendInputBaseOptions(
  config: ISPFieldConfig,
  control: IControl,
  field: IField,
  state: ISPFormState,
): void {
  const desc = config.templateOptions?.desc || getDesc(field, state.doc);

  let plainhints: Record<string, string> | null = null;
  let controlWithPlainHint: IInput | ISelect;

  const controlCheckboxWithPlainhint = field.input?.find(
    input => input.$type === 'checkbox' && input.$plainhint === 'yes',
  );
  if (controlCheckboxWithPlainhint) {
    plainhints = DocHelper.getPlainhintsForCheckbox(state.doc, field.input[0]);
    controlWithPlainHint = controlCheckboxWithPlainhint;
  } else if (field.select && field.select[0].$plainhint === 'yes') {
    plainhints = DocHelper.getPlainhintsForSelect(state.doc, field.select[0]);
    controlWithPlainHint = field.select[0];
  }

  const templateOptions: InputBaseWrapperTO = {
    hint: getHint(field.$name, state.doc, state.showHints),
    hintDisplayMode: 'input-like',
    shadowHint: getShadowHint(field.$name, state.doc),
    isShadow: state.conditionService.isShadow(field.$name),
    isHidden: state.hiddenService.isHidden(control.$name, field.$name),
    isHiddenByMode: state.hiddenService.isHiddenOnlyByMode(
      control.$name,
      field.$name,
    ),
    isFullWidth: isFullWidth(field),
    isFocused: false,
    controlWithPlainHint: controlWithPlainHint?.$name,
    plainhints,
    desc,
  };

  config.templateOptions = {
    ...templateOptions,
    ...config.templateOptions,
  };

  config.expressionProperties = {
    'templateOptions.isShadow': (_, formState: ISPFormState) =>
      formState.conditionService.isShadow(field.$name),
    'templateOptions.isHidden': (_, formState: ISPFormState) =>
      formState.hiddenService.isHidden(control.$name, field.$name),
    'templateOptions.isHiddenByMode': (_, formState: ISPFormState) =>
      formState.hiddenService.isHiddenOnlyByMode(control.$name, field.$name),
    ...config.expressionProperties,
  };
}

<div
  class="group"
  [ngClass]="{
    group_column: !to.inlineGroup,
    group_inline: to.inlineGroup
  }"
  [style.gridTemplateRows]="
    'var(--form-group-template-rows, ' + (to.groupTemplateRows || 'unset') + ')'
  "
  [style.gridTemplateColumns]="
    'var(--form-group-template-columns, ' +
    (to.groupTemplateColumns || 'unset') +
    ')'
  "
>
  <formly-field
    *ngFor="let field of field.fieldGroup"
    [field]="field"
    [style]="field.templateOptions?.style"
    [ngClass]="{
      'group-item': true
    }"
  ></formly-field>
</div>

import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';

import { ISPFieldConfig, ISPFieldType } from '../model';

/**
 * Match values of two fields
 *
 * @param control - abstract control
 * @param groupField
 */
export function passMatchValidator(
  control: FormGroup,
  groupField: ISPFieldConfig<ISPFieldType.Group>,
): ValidationErrors | null {
  const passwordField: ISPFieldConfig = groupField.fieldGroup.find(
    (field: ISPFieldConfig) => {
      if (field.type !== ISPFieldType.Password) {
        return false;
      }
      return field.templateOptions.originalControl?.$checkpasswd;
    },
  );
  if (!passwordField) {
    return null;
  }
  const confirmFieldKey: string = (passwordField.templateOptions as any)
    .originalControl?.$checkpasswd;
  if (!confirmFieldKey) {
    return null;
  }
  const confirmControl: FormControl = control.controls[
    confirmFieldKey
  ] as FormControl;
  const password: string = control.value[passwordField.key as string];
  const confirm: string = control.value[confirmFieldKey];

  // avoid displaying the message error when values are empty
  if (!password) {
    return null;
  }

  if (confirm === password) {
    if (confirmControl.errors) {
      delete confirmControl.errors.passMatch;
      if (!Object.keys(confirmControl.errors).length) {
        confirmControl.setErrors(null);
      }
      const confirmField = (confirmControl as any)._fields[0];
      confirmField.options._markForCheck(confirmField);
    }
    return null;
  }

  if (confirmControl) {
    confirmControl.setErrors({
      passMatch: true,
    });
    confirmControl.markAsTouched();
    // applying _markForCheck will probably need to be changed when moving to Formly v.6
    const confirmField = (confirmControl as any)._fields[0];
    confirmField.options._markForCheck(confirmField);
  }

  return { passMatch: true };
}

/**
 * Checks duplicates in the control's value
 *
 * @param control - abstract control
 */
export function duplicateValidator(
  control: AbstractControl,
): ValidationErrors | null {
  let list = [];
  try {
    list = control.value.split(/\s+/);
  } catch (err: unknown) {
    return;
  }

  const uniqueValues = [];
  for (const item of list) {
    if (!uniqueValues.includes(item)) {
      uniqueValues.push(item);
    } else {
      return {
        duplicate: true,
      };
    }
  }
  return null;
}

/**
 * Get file max size validator function
 *
 * @param maxsize - max size of file
 */
export function getMaxSizeValidator(maxsize: number): {
  expression: (control: FormControl) => any;
} {
  return {
    expression: (control: FormControl) => {
      const value = control.value;
      if (value instanceof FileList) {
        const totalSize = Array.from(value).reduce(
          (size, file) => size + file.size,
          0,
        );

        return totalSize < maxsize;
      }
      return true;
    },
  };
}

import { Injectable } from '@angular/core';

import { NarrowBannerComponent } from './narrow-banner.component';
import { INarrowBanner } from './narrow-banner.interface';

@Injectable({
  providedIn: 'root',
})
export class NarrowBannerService {
  private anchor: NarrowBannerComponent;

  /** Used to open banners after anchor is set */
  private readonly cachedBanners: Omit<INarrowBanner, 'id' | 'state'>[] = [];

  /** Map of banners (id => banner). */
  readonly banners: Map<string, INarrowBanner> = new Map();

  /**
   * Sets base anchor component for narrow banner.
   *
   * @param component - component ref
   */
  setAnchorComponent(component: NarrowBannerComponent): void {
    this.anchor = component;

    if (this.cachedBanners.length > 0) {
      this.cachedBanners.forEach(banner => this.openBanner(banner));
      this.cachedBanners.length = 0;
    }
  }

  /**
   * Open banner on top of other.
   * Returns banner's id.
   *
   * @param banner - banner data
   */
  openBanner(banner: INarrowBanner): string {
    if (!this.anchor) {
      this.cachedBanners.push(banner);
      return;
    }

    const id = banner.id || Date.now().toString();

    if (!this.banners.has(banner.id)) {
      banner.state = 'open';
    }

    this.banners.set(banner.id, {
      id,
      isClosable: true,
      ...banner,
      button: {
        closeOnClick: true,
        ...banner.button,
      },
    });

    this.anchor.markForCheck();

    return id;
  }

  /**
   * Closes banner by id.
   *
   * @param id - banner id
   * @param event - close click event
   */
  closeBanner(id: string, event?: Event): void {
    const banner = this.banners.get(id);
    banner.state = 'close';

    if (banner.onClose) {
      banner.onClose(event);
    }
  }
}

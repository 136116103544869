import { IHttpParam } from 'app/services/http-base-service/http-base.interface';

/**
 * Return string with sorted by key object
 * uses for compare query params
 *
 * @param obj - IHttpparam object
 */
export function getObjectSortedString(obj: IHttpParam): string {
  const sortedObject: IHttpParam = {};
  Object.keys(obj)
    .sort()
    .forEach(key => {
      sortedObject[key] = obj[key];
    });

  return JSON.stringify(sortedObject);
}

/**
 * Return string with sorted array
 * uses for compare array
 *
 * @param arr - Array
 */
export function getArraySortedString(arr: any[]): string {
  arr.sort();
  return JSON.stringify(arr);
}

export enum Func {
  Logon = 'logon',
  Auth = 'auth',
  Desktop = 'desktop',
  Notify = 'notify',
  Collapse = 'collapse',
  Error = 'error',
  DashboardSave = 'dashboard.save',
  Keepalive = 'keepalive',
  Tip = 'tip',
  Dashboard = 'dashboard',
}

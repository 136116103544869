import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  OnInit,
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  Option,
  SelectBuilderParams,
  SelectEvent,
  SelectState,
} from '@ispui/select';

import { DynamicFormService } from 'common/dynamic-form/dynamic-form.service';
import { ISucceededDrawerSelectMetadata } from 'common/dynamic-form/services/drawer-parent.service';
import { DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR } from 'common/dynamic-form/services/layout.service';

import { getHTMLTemplateForOption } from './select.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

const COLORS = {
  yellow: 'var(--isp-c-c-3po)',
  blue: 'var(--isp-dc-fremen)',
  red: 'var(--isp-dc-bright-emotions)',
  green: 'var(--isp-c-grasshopper)',
  cyan: 'var(--isp-c-odysseus)',
};

/**
 * Select field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-select-field',
  templateUrl: './select.component.html',
  styleUrls: ['./scss/select.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Select>
  implements OnInit, DoCheck
{
  private readonly selectBuilderParamsSubject: BehaviorSubject<SelectBuilderParams | null> =
    new BehaviorSubject(null);

  /** dropdown open state with a list of select options */
  private isDropdownOpen = false;

  /** flag of whether the selected option has an image */
  private isImageOptionSelected: boolean;

  /** store for succeededDrawerMetadata to track changes by comparing */
  private succeededDrawerMetadataStore: ISucceededDrawerSelectMetadata | null =
    null;

  /** whether to hide the text on the open/close dropdown button of the select */
  hideTextInSelectButton: boolean;

  /** color of selected option */
  selectedColor: string;

  /** select builder params */
  selectBuilderParams$ = this.selectBuilderParamsSubject.pipe(
    filter(params => Boolean(params)),
  );

  get optionsUI(): Option[] {
    const to = this.to.options;
    return (
      to?.map(option => {
        const htmlFunc: Option['html'] = optionUI => {
          return getHTMLTemplateForOption(
            option,
            optionUI.textHtml,
            this.formState.doc,
          );
        };

        return {
          text: option.$,
          value: option.$key,
          color: COLORS[option.$color],
          html: htmlFunc,
        };
      }) || []
    );
  }

  constructor(private readonly dynamicFormService: DynamicFormService) {
    super();
  }

  /**
   * Update text hide state on select button
   */
  private updateButtonTextState(): void {
    this.hideTextInSelectButton =
      this.to.isPrefixForInput &&
      this.isImageOptionSelected &&
      !this.isDropdownOpen;
  }

  /**
   * Start builder params stream
   */
  private startBuilderParamsStream(): void {
    const hasOptionsWithImage = this.to.options.some(
      selectOption => '$image' in selectOption,
    );
    const buttonTextFuncHTML: SelectBuilderParams['buttonTextFuncHTML'] = (
      state,
      _,
      buttonText,
    ) => {
      const selectedOption = Array.isArray(state.value)
        ? this.to.options.filter(selectOption =>
            state.value.includes(selectOption.$key),
          )
        : this.to.options.find(
            selectOption => selectOption.$key === state.value,
          );
      return getHTMLTemplateForOption(
        selectedOption,
        buttonText,
        this.formState.doc,
      );
    };

    // @WARN If you update this config don't forget to update select config in login form!!!
    this.selectBuilderParamsSubject.next({
      legend: this.to.inputLabel,
      id: this.id,
      placeholder: this.to.placeholder,
      width: this.to.anchorWidth,
      maxWidth: 500,
      notFoundText: this.to.notFoundText,
      searchCount: 10,
      toggle: true,
      toggleOnText: this.to.selectAllText,
      toggleOffText: this.to.selectAllText,
      reflectColor: true,
      ...(hasOptionsWithImage ? { buttonTextFuncHTML } : {}),
      $dropdown: {
        parentSelector:
          this.formState.dropdownParentSelector ||
          DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
        popupClass: 'isp-dynamic-form-select-dropdown-popup',
        zIndex: 1005,
      },
      $button: {
        style: {
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-button-legend__width':
            'var(--ispui-input__width, 400px)',
          '--ispui-select-button__width': 'var(--ispui-input__width, 400px)',
          '--ispui-select-button-legend__pl': '15px',
          '--ispui-select-button__pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      },
      $list: {
        style: {
          marginRight: '0px',
          paddingRight: '10px',
          // @WARN '-10px' because paddingRight above
          'min-width': `calc(${this.to.listWidth} - 10px)`,
          'max-width': `calc(${this.to.listWidth} - 10px)`,
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-list__item-height': '35px',
          '--ispui-select-list__max-height': '245px',
          '--ispui-select-list__item-br': 'var(--isp-border-radius-main)',
          '--ispui-select-list__item-checkbox-pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
        },
      },
      $search: {
        style: {
          padding: '10px 10px',
          margin: '0',
        },
      },
      $toggle: {
        style: {
          /* eslint-disable @typescript-eslint/naming-convention */
          '--ispui-select-toggle__checkbox-pl': '15px',
          /* eslint-enable @typescript-eslint/naming-convention */
          padding: '5px 0px 15px 20px',
          borderBottom: '1px solid var(--isp-dc-charlie-chaplin)',
        },
      },
    });
  }

  /**
   * Update select to have drawer-related changes, if needed
   */
  private updateDrawerModeIfNeeded(): void {
    if (
      this.to.succeededDrawerMetadata?.drawerLinkText ===
      this.succeededDrawerMetadataStore?.drawerLinkText
    ) {
      return;
    }
    this.succeededDrawerMetadataStore = this.to.succeededDrawerMetadata;
    const builderParams = { ...this.selectBuilderParamsSubject.value };
    builderParams.buttonTextFunc = (
      state: SelectState,
      placeholder: string,
    ) => {
      if (state.multiple) {
        let currentValue = state.value;
        if (typeof currentValue === 'string') {
          currentValue = [currentValue];
        }
        return (
          state.options
            .filter(o => currentValue.includes(o.value))
            .map(o => o.text)
            .join(', ') || placeholder
        );
      } else {
        const matchedOption = state.options.find(o => o.value === state.value);
        if (
          this.succeededDrawerMetadataStore?.drawerOptionValue ===
            matchedOption.value &&
          this.succeededDrawerMetadataStore?.drawerOptionText !==
            matchedOption.text
        ) {
          return this.succeededDrawerMetadataStore?.drawerOptionText;
        }
        return matchedOption.text;
      }
    };

    builderParams.$customSegments = {
      $list: {
        after: {
          tag: 'div',
          params: {
            style: {
              borderTop: '1px solid #D9D9D9',
              padding: '20px 20px 10px',
              marginTop: '10px',
            },
          },
          children: [
            {
              tag: 'span',
              params: {
                style: {
                  color: '#075BBE',
                  cursor: 'pointer',
                  fontSize: '14px',
                },
                innerHTML: this.succeededDrawerMetadataStore?.drawerLinkText,
                onClick: () => {
                  document.documentElement.click(); // for closing dropdown
                  this.dynamicFormService.handleDrawerSelectEditClick(this.key);
                },
              },
            },
          ],
        },
      },
    };

    this.selectBuilderParamsSubject.next(builderParams);
  }

  ngOnInit(): void {
    this.startBuilderParamsStream();
    this.updateDrawerModeIfNeeded();
  }

  ngDoCheck(): void {
    this.updateDrawerModeIfNeeded();
  }

  /**
   * model change event handler
   *
   * @param newValue - select's new value
   */
  onModelChange(newValue: any): void {
    if (newValue === undefined && this.field.defaultValue !== undefined) {
      this.formControl.setValue(this.field.defaultValue, {
        emitEvent: false,
      });
    }

    const selectedOption = this.to.options.find(op => op.$key === newValue);

    if (selectedOption) {
      this.isImageOptionSelected = '$image' in selectedOption;
    }
    this.updateButtonTextState();

    this.selectedColor = selectedOption?.$color;
  }

  /**
   * Get mixed placeholder to insert in content
   */
  getMixedPlaceholder(): string {
    return `"${this.to.mixedPlaceholder}"`;
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }

  /**
   * Check if dropdown is open
   *
   * @param event - select event
   */
  checkDropdownOpen(event: CustomEvent<SelectEvent>): void {
    const eventsWithOpenDropdown = ['dropdown-open', 'search'];
    this.isDropdownOpen = eventsWithOpenDropdown.includes(event.detail.name);
    this.updateButtonTextState();
  }
}

import { ISPFieldConfig } from '../../model';

/**
 * Check condition for every field in fields config
 *
 * @param fieldConfigs - fields config
 * @param condition - condition callback. If return 'skip-node-and-childs', then propogation deep into fieldGroups will stop, and continue to siblings
 */
export function everyConfig(
  fieldConfigs: ISPFieldConfig[],
  condition: (field: ISPFieldConfig) => boolean | 'skip-node-and-childs',
): boolean {
  const every = (fields: ISPFieldConfig[]) => {
    return fields.every(field => {
      const check = condition(field);

      if (check === false) {
        return false;
      }

      if (check === 'skip-node-and-childs') {
        return true;
      }

      if (field.fieldGroup) {
        return every(field.fieldGroup);
      }

      return true;
    });
  };

  return every(fieldConfigs);
}

<div class="tags">
  <ispui-checkbox
    *ngFor="let tag of to.tags"
    [id]="tag.key"
    [ngModel]="isTagSelected(tag)"
    (ngModelChange)="toggleTagSelection($event, tag)"
    class="tag-item"
  >
    {{ tag.msg }}
  </ispui-checkbox>
  <ispui-link (click)="onResetTags()" type="default-hover">
    {{ to.resetMsg }}
  </ispui-link>
</div>

import { Params } from '@angular/router';

/**
 * Check that params have files
 *
 * @param params
 */
export function hasFile(params: Params): boolean {
  return Object.keys(params).some(key => params[key] instanceof FileList);
}

import { Directive, Input, OnInit, TemplateRef } from '@angular/core';

import { TemplateConfig } from './model/template-config.interface';
import { TemplateContext } from './model/template-context.interface';

/**
 * Custom template for dynamic-form
 */
@Directive({
  selector: '[ispDynamicFormTemplate]',
})
export class DynamicFormTemplateDirective implements OnInit {
  /** field template names that template relate to */
  @Input('ispDynamicFormTemplate') config: TemplateConfig;

  constructor(public templateRef: TemplateRef<TemplateContext>) {}

  ngOnInit(): void {
    this.config.templateOptions.templateRef = this.templateRef;
  }
}

import { everyConfig } from './every-config';

import { ISPFieldConfig } from '../../model';

/**
 * Check condition for some field in fields config
 *
 * @param fieldConfigs - fields config
 * @param condition - condition callback
 */
export function someConfig(
  fieldConfigs: ISPFieldConfig[],
  condition: (field: ISPFieldConfig) => boolean,
): boolean {
  return !everyConfig(fieldConfigs, field => !condition(field));
}

import { IField } from 'app/services/api5-service/api.interface';

import { getOriginalControlFromConfig } from './get-original-control-from-config';

import { ISPFieldConfig, ISPFieldWrapper, ISPFormState } from '../../model';
import { appendFieldBaseOptions } from '../../wrappers/field-base/field-base.utils';
import { appendInputBaseOptions } from '../../wrappers/input-base/input-base.utils';
import { appendValidationErrorOptions } from '../../wrappers/validation-error/validation-error.utils';

/**
 * Append wrapper options
 *
 * @param config - field config to append
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function appendConfigWrapperOptions(
  config: ISPFieldConfig,
  field: IField,
  state: ISPFormState,
): void {
  config.wrappers?.forEach(wrapper => {
    const control = getOriginalControlFromConfig(config);

    if (!control) {
      // case of fields without controls
      return;
    }

    switch (wrapper) {
      case ISPFieldWrapper.FieldBase:
        appendFieldBaseOptions(config, control, field, state);
        break;
      case ISPFieldWrapper.InputBase:
        appendInputBaseOptions(config, control, field, state);
        break;
      case ISPFieldWrapper.ValidationError:
        appendValidationErrorOptions(config, control, state);
        break;
      default:
    }
  });
}

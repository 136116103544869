import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { GroupFieldComponent } from './group.component';
import { GroupConfig } from './model/group-config.interface';

import { ISPFieldType } from '../../model';

/**
 * Formly's group field component
 */
@NgModule({
  declarations: [GroupFieldComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Group,
          component: GroupFieldComponent,
          defaultOptions: {
            fieldGroup: [],
          } as GroupConfig,
        },
      ],
    }),
  ],
})
export class GroupFieldModule {}

import { escapeRegexp } from './escape-regexp';
import { keyLangSwitch } from './key-lang-switch';

/**
 * Get a string to build a regular expression that will be used to perform an international
 * search on string elements (for example, search for navigation menu items or search for services)
 *
 * @param originalString - original string in search input
 * @returns string for RegExp corresponding to the original string for the international search
 * (for example, string 'обработчик' will return '[jо][,б<][hр][fа][,б<][jо][nт][xч][bи][rк]')
 */
export function getMatchStringForInternationalSearch(
  originalString?: string,
): string {
  if (!originalString) {
    return '';
  }
  const charsForSearch = keyLangSwitch(originalString);
  const escapedChars = charsForSearch.map(charsPair => escapeRegexp(charsPair));

  return `[${escapedChars.join('][')}]`;
}

import {
  IDocument,
  IField,
  ILink,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface LinkControlWithValue {
  readonly control: ILink;
  readonly type: 'link';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForLink(
  field: IField,
  doc: IDocument,
): LinkControlWithValue[] {
  return (field.link || []).map(control => {
    return {
      value: DocHelper.getStringValue(control.$name, doc),
      control,
      type: 'link',
    };
  });
}

<div class="slider">
  <div class="slider__wrapper">
    <label *ngIf="label" class="slider__label" [for]="id">
      <span>{{ label }}</span>
      <span class="slider__label_required">{{ required ? '*' : '' }}</span>
    </label>
    <input
      class="slider__input"
      [type]="isUnlimited() ? 'text' : 'number'"
      [min]="min"
      [max]="max"
      [step]="step"
      [disabled]="disabled"
      [id]="id"
      [(ngModel)]="value"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
      (blur)="onBlur($event)"
    />
    <fieldset aria-hidden="true" class="slider__fieldset">
      <legend *ngIf="label" class="slider__fieldset-legend">
        <span class="slider__fieldset-legend-text">{{ label }}</span>
        <span class="slider__fieldset-legend-text">{{
          required ? '*' : ''
        }}</span>
      </legend>
    </fieldset>

    <button
      *ngIf="unlimited"
      class="slider__unlimited-button"
      [disabled]="disabled"
      (click)="setUnlimitedValue()"
    >
      <isp-icon-provider
        class="slider__unlimited-button-icon"
        [customStyle]="{ width: '15px', height: '15px' }"
        [name]="unlimitedIconName"
        [inSprite]="true"
      ></isp-icon-provider>
    </button>

    <span *ngIf="description" class="slider__description">
      {{ description }}
    </span>
  </div>

  <input
    class="slider__range"
    type="range"
    [min]="min"
    [max]="max"
    [step]="step"
    [disabled]="disabled"
    [style.--data-min]="min"
    [style.--data-max]="max"
    [style.--data-value]="getNumberValue()"
    [(ngModel)]="value"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
  />
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';
import { RecaptchaModule } from 'ng-recaptcha';

import { CaptchaComponent } from './captcha.component';

import { ISPFieldType } from '../../model';

@NgModule({
  declarations: [CaptchaComponent],
  imports: [
    CommonModule,
    RecaptchaModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Captcha,
          component: CaptchaComponent,
        },
      ],
    }),
  ],
})
export class CaptchaFieldModule {}

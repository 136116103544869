import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { AutocompleteSelectModule } from 'components/autocomplete-select/autocomplete-select.module';

import { AutocompleteSelectFieldComponent } from './autocomplete-select.component';

import { ISPFieldType } from '../../model';

/**
 * Autocomplete select field module
 */
@NgModule({
  declarations: [AutocompleteSelectFieldComponent],
  imports: [
    CommonModule,
    AutocompleteSelectModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.AutocompleteSelect,
          component: AutocompleteSelectFieldComponent,
        },
      ],
    }),
  ],
})
export class AutocompleteSelectFieldModule {}

import { Injectable } from '@angular/core';

/**
 * Service for session tip component
 */
@Injectable({ providedIn: 'root' })
export class SessionTipService {
  /** current tip name (there can be only 1 tip at the same time) */
  currentTip: string;
}

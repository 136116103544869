import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { DynamicFormService } from 'common/dynamic-form/dynamic-form.service';
import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldTypeBase,
} from 'common/dynamic-form/model';

import { TextDataTO } from '../textdata';

/**
 * A text field with a link field the end grouped together as Formly field
 */
@Component({
  selector: 'isp-formly-text-with-link-field',
  templateUrl: './text-with-link.component.html',
  styleUrls: ['./scss/text-with-link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextWithLinkComponent
  extends ISPFieldTypeBase<ISPFieldType.TextWithLink>
  implements OnInit
{
  textTO: TextDataTO;

  link: ISPFieldConfig;

  hiddenFields: ISPFieldConfig[];

  constructor(private readonly dynamicFormService: DynamicFormService) {
    super();
  }

  ngOnInit() {
    this.textTO = this.field?.text.templateOptions as TextDataTO;
    this.link = this.field?.link;
    this.hiddenFields = this.field?.hiddenFields;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUIInputModule } from '@ispui/ng-input';
import { ISPUILinkModule } from '@ispui/ng-link';

import { DateTimeComponent } from './datetime.component';

import { ISPFieldType } from '../../model';

/**
 * Date output field module.
 * Use only with Formly!
 */
@NgModule({
  declarations: [DateTimeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ISPUILinkModule,
    ISPUIInputModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.DateTime,
          component: DateTimeComponent,
        },
      ],
    }),
  ],
})
export class DateTimeModule {}

<ng-container #fieldComponent></ng-container>
<ng-container *ngIf="openedState$ | async">
  <ispui-validation-error
    [style.--isp-form-validation_slide]="positioning.slide"
    [style.--isp-form-validation_offset]="positioning.offset"
    [ngClass]="{
      'validation-warning': true,
      'validation-warning_top': positioning.side === 'top',
      'validation-warning_bottom': positioning.side === 'bottom',
      'validation-warning_left': positioning.side === 'left',
      'validation-warning_right': positioning.side === 'right',
      'validation-warning_invisible': !isPopupVisible
    }"
    [triangle-position]="trianglePosition"
    (closeVE)="handleCloseWarning()"
    #errorPopup
  >
    {{ errorMessage$ | async }}
  </ispui-validation-error>
</ng-container>

<div class="isp-saved-messages">
  <ispui-tooltip [title-text]="savedMessageMeta.hint">
    <ispui-dropdown
      #dropdown
      direction="top"
      is-close="false"
      has-pointer="false"
      border-offset="30"
      parent-selector=".form__content"
      z-index="4"
      popup-class="isp-saved-messages-dropdown__popup"
    >
      <div slot="anchor" (click)="getSavedMessages($event)">
        <ispui-link
          type="default-hover"
          [disabled]="pending"
          (mousedown)="preventSelectionReset($event)"
        >
          {{ savedMessageMeta.msg }}
        </ispui-link>
      </div>

      <ispui-dropdown-content>
        <div class="isp-saved-messages__popup">
          <ng-container *ngIf="savedMessages.length; else elseTemplate">
            <ng-container *ngFor="let item of savedMessages">
              <ispui-tooltip
                [title-text]="item.message.$"
                tooltipWidth="560px"
                class="isp-saved-messages__a isp-saved-messages__a_ellipsis"
              >
                <a
                  (click)="onSelectTemplateMessage(item.message.$)"
                  (mousedown)="preventSelectionReset($event)"
                  >{{ item.name.$ }}</a
                >
              </ispui-tooltip>
            </ng-container>
          </ng-container>
          <ng-template #elseTemplate>
            {{ emptySavedMessagesMsg }}
          </ng-template>
        </div>
      </ispui-dropdown-content>
    </ispui-dropdown>
  </ispui-tooltip>
  <ispui-preloader-round
    class="preloader"
    [loading]="pending"
    [view]="'overlay'"
    [spinnerSize]="25"
  >
  </ispui-preloader-round>
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PreloadedActionService } from 'app/services/preloaded-action.service';
import { IFormCollapseEvent, ILinkClickEvent } from 'components/form-collapse';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ISucceededDrawerSelectMetadata } from 'common/dynamic-form/services/drawer-parent.service';
import { createProgressIdForToolbutton } from 'common/progress-task-modal/progress-task.utils';
import { convertStringToParamsWithDecode } from 'utils/convert-string-to-params';
import { DocHelper } from 'utils/dochelper';

import { submitForm } from './form.utils';

import {
  IFormButtonClickEvent,
  IFormModel,
  IFormWizardClickEvent,
} from '../../common/dynamic-form/dynamic-form.interface';
import { ActionService } from '../services/action.service';
import {
  FormButtonType,
  Func,
  IToolButtonEvent,
} from '../services/api5-service/api.interface';
import { Api5Service } from '../services/api5-service/api5.service';
import { BaseFuncTypeService } from '../services/base-func-type/base-func-type.service';
import { Tab } from '../services/tab/tab.class';
import { TabService } from '../services/tab/tab.service';

/**
 * Form service
 */
@UntilDestroy()
@Injectable()
export class FormService extends BaseFuncTypeService implements OnDestroy {
  /** page/collapsible toggle events */
  private readonly pageToggle$ = new Subject<IFormCollapseEvent>();

  private readonly initModelSubject = new BehaviorSubject<IFormModel>(null);

  private readonly succeededDrawerSelectsMetadataSubject = new BehaviorSubject<
    Record<string, ISucceededDrawerSelectMetadata>
  >(null);

  readonly formOpenAnimationOverSubject = new BehaviorSubject<boolean>(false);

  readonly initModel$ = this.initModelSubject.asObservable();

  readonly succeededDrawerSelectsMetadata$ =
    this.succeededDrawerSelectsMetadataSubject.asObservable();

  constructor(
    protected tabService: TabService,
    protected actionService: ActionService,
    protected preloadedActionService: PreloadedActionService,
    private readonly apiService: Api5Service,
  ) {
    super(tabService, actionService);
  }

  /**
   * Subscribes the the `pageToggle$` subject
   * and whenever the new value is pushed:
   * - saves new collapsible state to the tab's document and updates the tab
   * - saves the state to the backend by calling `func=collapse`
   */
  private initCollapsibleStateSave(): void {
    this.pageToggle$
      .pipe(
        tap(({ isOpened, name }) => {
          const doc = this.tab.doc;
          const pageList = DocHelper.getFormPageList(doc);
          if (!pageList) {
            return;
          }
          pageList.forEach(p => {
            if (p.$name === name) {
              p.$collapsed = isOpened ? 'no' : 'yes';
            }
          });
          this.tabService.update(this.tab, doc);
        }),
        switchMap(({ isOpened, name }) =>
          this.apiService.collapse({
            action: this.tab.func,
            collapse: isOpened ? 'off' : 'on',
            page: name,
          }),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Opens new tab
   *
   * @param func - function to run
   * @param params - form data
   * @param isChild - should tab open as a child tab of current tab
   */
  private openTab(func: Func, params: Params, isChild = false): void {
    if (isChild) {
      return this.actionService.openChild({ func, ...params }, this.tab);
    } else {
      return this.actionService.open({ func, ...params });
    }
  }

  /**
   * Submits the form. Also sets the `sok` parameter with value `ok`
   *
   * @param event - button click event
   */
  private submitForm(event: IFormButtonClickEvent): void {
    event.button.preloaderSubject.next(true);
    this.actionService
      .prepareAndSubmitForm$({
        form: event.form,
        button: event.button,
        tab: this.tab,
        emitOnError: true,
      })
      .subscribe(res => {
        if (res === null) {
          // we get null if submit fails with error (server returns validation errors or has internal errors etc.)
          event.button.preloaderSubject.next(false);
        }
      });
  }

  /**
   * Submits the form in the new window. Also sets the `sok` parameter with value `ok`
   *
   * @param event - click button event
   */
  private submitAndOpenNewWindow(event: IFormButtonClickEvent): void {
    submitForm({
      doc: this.tab.doc,
      form: event.form,
      button: event.button,
      func: this.tab.func,
      elid: this.tab.elid,
      plid: this.tab.plid,
      table_params: this.tab.q.table_params,
      sok: true,
      openNewWindow: true,
    });

    if (event.button.$keepform !== 'blank') {
      this.tabService.close(this.tab, true);
    }
  }

  /**
   * Resets the whole form
   */
  private resetForm(): void {
    console.warn('сбросить форму на дефолтные значения');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.pageToggle$.complete();
  }

  preInit(): void {
    this.initCollapsibleStateSave();
  }

  /**
   * Update succeeded drawer selects metadata
   *
   * @param metadata - new drawer selects metadata
   */
  updateSucceededDrawerSelectsMetadata(
    metadata: Record<string, ISucceededDrawerSelectMetadata>,
  ): void {
    this.tab.state.succeededDrawerSelectsMetadata = metadata;
    this.succeededDrawerSelectsMetadataSubject.next(metadata);
  }

  loadTab(tab: Tab): void {
    this.initModelSubject.next(tab.state.model || {});
    this.succeededDrawerSelectsMetadataSubject.next(
      tab.state.succeededDrawerSelectsMetadata || {},
    );
  }

  /**
   * Puts the collapsible's (page) event in the subject.
   * When new event is pushed - the service sends the state save request to `func=collapse`.
   *
   * @param event - collapsible toggle event
   */
  saveCollapseState(event: IFormCollapseEvent): void {
    this.pageToggle$.next(event);
  }

  /**
   * Handles the form button click depending on button's type
   *
   * @param event - form button click event
   */
  handleButtonClick(event: IFormButtonClickEvent): void {
    const isFormTargetBlank = this.doc?.metadata?.form?.$target === '_blank';
    const button = event.button;
    if (
      button.$type === FormButtonType.Ok &&
      (button.$act === FormButtonType.Blank || isFormTargetBlank)
    ) {
      this.submitAndOpenNewWindow(event);
      return;
    }
    switch (button.$type) {
      case FormButtonType.Ok:
      case FormButtonType.Next:
      case FormButtonType.Back:
      case FormButtonType.Submit:
      case undefined:
        return this.submitForm(event);
      case FormButtonType.Cancel:
        this.closeByCancel();
        return button?.$func ? this.openTab(button?.$func, event.form) : null;
      case FormButtonType.Blank:
        return this.submitAndOpenNewWindow(event);
      case FormButtonType.Func:
        return this.openTab(button?.$func, event.form, true);
      case FormButtonType.Reset:
        return this.resetForm();
      default:
    }
  }

  /**
   * Form wizard clicks handler
   *
   * @param event - event for wizard clicks, contains wizard step and form value
   */
  handleWizardClick(event: IFormWizardClickEvent): void {
    this.actionService
      .prepareAndSubmitForm$({
        form: event.form,
        tab: this.tab,
        additionalParams: {
          func: event.button.func,
          sok: false,
        },
      })
      .subscribe();
  }

  /**
   * Closes the current tab
   */
  closeByCancel(): void {
    if (this.tab.isSame) {
      this.tabService.resetSameTab(this.tab);
    } else {
      const refreshParentTab =
        this.tab.doc.metadata?.form?.$cancelrefresh === 'yes';
      this.tabService.close(this.tab, refreshParentTab);
    }
  }

  handleResponseAfterProgress(): void {
    this.actionService.handleResponseAfterProgress(this.tab);
  }

  /**
   * Opens new tab by link click from form's field. Doesn't handle `href`-links
   *
   * @param event - form link click event
   * @param event.isNewTab
   * @param event.func
   */
  openNewTabByLinkClick({ isNewTab, func }: ILinkClickEvent): void {
    const params = { ...convertStringToParamsWithDecode(`func=${func}`) };

    if (isNewTab) {
      // `null` is passed in the second argument because we don't know in advance what type the function has
      this.actionService.handleAction(params, null, true);
    } else {
      this.actionService.openChild(params, this.tab);
    }
  }

  /**
   * Set model state to tab state
   *
   * @param model
   */
  setFormModelToTab(model: IFormModel): void {
    this.tab.state.model = model;
  }

  /**
   * Reload tab data
   */
  reloadTab(): void {
    this.initModelSubject.next({});
    this.tab.state.model = null;
    this.actionService.updateTab(this.tab?.func, this.tab?.q, this.tab);
  }

  /**
   * Handles the toolbar button click
   *
   * @param event - clicked toolbar button as an emitted event
   */
  handleToolbarClick(event: IToolButtonEvent): void {
    const params = {
      elidList: [this.tab.elid],
      toolbtn: event.btn,
      tab: this.tab,
      buttonElement: event.target,
      progressId: event?.btn?.$progressbar
        ? createProgressIdForToolbutton(event.btn.$progressbar)
        : '',
      doc: this.doc,
    };

    this.actionService.handleToolBtn(params).subscribe();
  }
}

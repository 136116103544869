import { AbstractControl } from '@angular/forms';

import { FormlyConfig } from '@ngx-formly/core';
import { ValidationMessageOption } from '@ngx-formly/core/lib/services/formly.config';
import { IControl, IInput } from 'app/services/api5-service/api.interface';
import { Observable, combineLatest, of, isObservable } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { isInvisibleRecaptchaError } from 'common/dynamic-form/types';
import { findConfig } from 'common/dynamic-form/utils/formly-configs';
import { getOriginalControlFromConfig } from 'common/dynamic-form/utils/formly-configs-generation';
import { DocHelper } from 'utils/dochelper';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';

/** Name of the validator indicating that field has error from server (has not passed server validation) */
export const SERVER_ERROR_VALIDATOR_NAME = 'errorFromServer';

/**
 * Default server error validation function
 *
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 * @returns - true for valid value, false for invalid
 */
function deafultServerErrorValidationFunction(
  control: IControl,
  state: ISPFormState,
): (c: AbstractControl) => boolean {
  const initialFieldValue = DocHelper.getValue(control.$name, state.doc) || '';
  return (c: AbstractControl) => c.value !== initialFieldValue;
}

/**
 * Get message string from backend error
 *
 * @param error - backend error from form state
 */
function getMessageFromBackendError(
  error?: ISPFormState['errorFromServer'],
): string {
  if (error) {
    return Array.isArray(error.msg) ? error.msg[0].$ : error.msg.$;
  }
  return '';
}

/**
 * Get general backend error
 *
 * @param configs - field configs
 * @param state - dynamic form state
 * @returns return empty string if there is no backend error, or there is some field, that have related field name
 */
export function getGeneralBackendError(
  configs: ISPFieldConfig[],
  state: ISPFormState,
): string {
  if (!state.errorFromServer) {
    return '';
  }

  if (isInvisibleRecaptchaError(state.doc)) {
    return getMessageFromBackendError(state.errorFromServer);
  }

  const backendErrorConfig = findConfig(configs, config => {
    const control = getOriginalControlFromConfig(config);
    if (!control) {
      return false;
    }
    return control.$name === state.errorFromServer.$object;
  });

  if (backendErrorConfig) {
    return '';
  }

  return getMessageFromBackendError(state.errorFromServer);
}

/**
 * Append backend validator
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendBackendErrorValidator<
  T extends ISPFieldType,
  C extends ISPFieldConfig<T>,
>(config: C, control: IControl, state: ISPFormState): void {
  const isHaveServerError = control.$name === state.errorFromServer?.$object;
  if (!isHaveServerError) {
    return;
  }

  config.validators = {
    [SERVER_ERROR_VALIDATOR_NAME]: {
      expression:
        config.templateOptions.customServerErrorValidationFunction ||
        deafultServerErrorValidationFunction(control, state),
      message: getMessageFromBackendError(state.errorFromServer),
    },
  };
}

/**
 * Append async validator
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendAsyncValidator<
  T extends ISPFieldType,
  C extends ISPFieldConfig<T>,
>(config: C, control: IControl, state: ISPFormState): void {
  if (!control.$check) {
    return;
  }
  config.modelOptions = {
    updateOn: 'blur',
    ...config.modelOptions,
  };
  config.asyncValidators = {
    [`check_${control.$check}_${control.$name}`]: {
      expression: (c: AbstractControl) => {
        if (c.dirty && c.value !== '') {
          if ((control as IInput).$zoom) {
            const validationList: Observable<boolean>[] = c.value
              .split(' ')
              .map(value => {
                return state.validationLoader.getFieldValidator(
                  control,
                  c,
                  value,
                  state.validationLoader,
                  state.doc,
                );
              });
            return combineLatest(validationList)
              .pipe(map(res => (res.includes(false) ? false : true)))
              .toPromise();
          } else {
            return state.validationLoader
              .getFieldValidator(
                control,
                c,
                c.value,
                state.validationLoader,
                state.doc,
              )
              .toPromise();
          }
        }
        return of(true).toPromise();
      },
    },
  };
}

/**
 * Append validation error options, specific for validation-error field wrapper
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param state - dynamic form state
 */
export function appendValidationErrorOptions<
  T extends ISPFieldType,
  C extends ISPFieldConfig<T>,
>(config: C, control: IControl, state: ISPFormState): void {
  appendBackendErrorValidator(config, control, state);
  appendAsyncValidator(config, control, state);
}

/**
 * Get validation error message
 *
 * @WARN formly depended code. Copy from 'formly-validation-message' component
 * https://github.com/ngx-formly/ngx-formly/blob/main/src/core/src/lib/templates/formly.validation-message.ts
 * @param field - field config
 * @param formlyConfig - formly config service
 */
export function getErrorMessage(
  field: ISPFieldConfig,
  formlyConfig: FormlyConfig,
): Observable<string> | string | undefined {
  const formControl = field.formControl;
  if (!formControl.errors) {
    return undefined;
  }

  const errors = Object.entries(formControl.errors);
  // sort errors in a such way, that BE error will be on the first place to display
  errors.sort((a, b) =>
    a[0] === SERVER_ERROR_VALIDATOR_NAME
      ? -1
      : b[0] === SERVER_ERROR_VALIDATOR_NAME
      ? 1
      : 0,
  );

  for (const [error, errorResult] of errors) {
    let message: ValidationMessageOption['message'] =
      formlyConfig.getValidatorMessage(error);

    if (errorResult !== null && typeof errorResult === 'object') {
      if (errorResult.errorPath) {
        message = undefined;
      }

      if (errorResult.message) {
        message = errorResult.message;
      }
    }

    if (field.validation?.messages?.[error]) {
      message = field.validation.messages[error];
    }

    if (field.validators?.[error]?.message) {
      message = field.validators[error].message;
    }

    if (field.asyncValidators?.[error]?.message) {
      message = field.asyncValidators[error].message;
    }

    if (typeof message === 'function') {
      return message(errorResult, field);
    }

    // if it is a server error, we don't show validation error text, but we still need to know the error status (for making borders red etc.)
    if (error === SERVER_ERROR_VALIDATOR_NAME) {
      message = '';
    }

    return message;
  }
  return undefined;
}

/**
 * Get validation error message stream
 *
 * @param field - field config
 * @param formlyConfig - formly config service
 */
export function getErrorMessage$(
  field: ISPFieldConfig,
  formlyConfig: FormlyConfig,
): Observable<string | undefined> {
  return field.formControl.statusChanges.pipe(
    // start with null to instantly trigger get error logic
    startWith(null),
    switchMap(() => {
      const msg = getErrorMessage(field, formlyConfig);
      return isObservable(msg) ? msg : of(msg);
    }),
    shareReplay({
      refCount: true,
      bufferSize: 1,
    }),
  );
}

import {
  IInput,
  IField,
  PasswordStrength,
} from 'app/services/api5-service/api.interface';

import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { PasswordTO } from './model/password-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';

/**
 * Get config for password field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getPasswordConfig(
  control: IInput,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Password> {
  const templateOptions: PasswordTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    passwordGenLength: state.context.passwordGenLength,
    passwordGenChars: state.context.passwordGenChars,
    generateLabel: DocHelper.getMessage('msg_genpwd', state.doc),
    inputLabel: getLabel(field, state.doc),
    displayPasswordStrength: Boolean(control.$checkpasswd),
  };

  const config: ISPFieldConfig<ISPFieldType.Password> = {
    key: control.$name,
    type: ISPFieldType.Password,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
  };

  // don't validate on each keypress when it's a password field with a confirm password sibling
  if (control.$checkpasswd) {
    config.modelOptions = { updateOn: 'blur' };
  }

  return config;
}

/**
 * Get password strength level form string
 *
 * @WARN orion logick!
 * @param password
 */
export function getPasswordStrength(password: string): PasswordStrength {
  const len = password.length;
  const str = password.split('');
  let uniq = 0;
  let special = 0;
  let repeatTypes = 0;
  let varCase = 0;
  const symbArr = {};
  const strType = [];

  for (let i = 0; i < len; i++) {
    // count uniq symbols
    if (!symbArr[str[i]]) {
      uniq++;
    }
    symbArr[str[i]] = true;
    // count special symbols
    if (/[!@#$%^&*?_~{}"'()|\\+[\]]\s/.exec(str[i])) {
      special++;
      strType.push('sn');
      // count lowcase
    } else if (/[a-z]/.exec(str[i])) {
      strType.push('ll');
      // count uppercase
    } else if (/[A-Z]/.exec(str[i])) {
      strType.push('lu');
      // count number
    } else if (/\d/.exec(str[i])) {
      strType.push('dn');
      // any cyrillic symbol = 2 special
    } else {
      special++;
      special++;
      strType.push('sn');
    }
    if (i !== 0) {
      // check for repeat types symbol
      if (strType[i].substr(0, 1) === strType[i - 1].substr(0, 1)) {
        repeatTypes++;
      }
      // check for diffirent case
      if (strType[i].substr(1, 1) !== strType[i - 1].substr(1, 1)) {
        varCase++;
      }
    }
  }

  const ind =
    (len + Math.sqrt(0.5 * special + varCase) - Math.sqrt(repeatTypes)) *
    (Math.log(uniq) / Math.log(len));
  if (ind < 3) {
    // too weak
    return '0';
  } else if (ind >= 3 && ind < 6) {
    // weak
    return '1';
  } else if (ind >= 6 && ind < 9) {
    // good
    return '2';
  } else if (ind >= 9) {
    // hard
    return '3';
  } else {
    return '0';
  }
}

import { Params } from '@angular/router';

/**
 * Convert param string to param object
 * func=auth&out=devel => { func: 'auth', out: 'devel'}
 *
 * @param paramString - string to convert
 */
export function convertStringToParamsWithDecode(paramString: string): Params {
  const paramArray = paramString.split('&');
  const params: Params = {};
  paramArray.forEach(param => {
    const keyValueArray = param.split('=');
    params[decodeURIComponent(keyValueArray[0])] = decodeURIComponent(
      keyValueArray[1],
    );
  });
  return params;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { LayoutFieldComponent } from './layout.component';
import { LayoutConfig } from './model/layout-config.interface';

import { ISPFieldType } from '../../model';

/**
 * Formly's layout field component
 */
@NgModule({
  declarations: [LayoutFieldComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Layout,
          component: LayoutFieldComponent,
          defaultOptions: {
            fieldGroup: [],
          } as LayoutConfig,
        },
      ],
    }),
  ],
})
export class LayoutFieldModule {}

import {
  IDocument,
  ISelect,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface RadioControlWithValue {
  readonly control: ISelect;
  readonly type: 'radio';
  value: string[] | string;
}

/**
 * Check that input control meta is radio input meta
 *
 * @param control - control metadata
 */
export function isControlRadio(control: ISelect): boolean {
  return control.$type === SelectType.Radio;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForRadio(
  control: ISelect,
  doc: IDocument,
): RadioControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'radio',
  };
}

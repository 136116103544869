import { Injectable } from '@angular/core';

import { IControl } from 'app/services/api5-service/api.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConditionService } from './condition.service';
import { DrawerChildService } from './drawer-child.service';
import { DrawerParentService } from './drawer-parent.service';
import { ModeService } from './mode.service';

/**
 * Service that handle field visibility/hidding
 */
@Injectable()
export class HiddenService {
  /** set of names of controls, that should be hidden permanently */
  private readonly permanentHiddenNamesSubject: BehaviorSubject<Set<string>> =
    new BehaviorSubject(new Set());

  /** stream of names, that should be permanently be hidden */
  readonly permanentHiddenNames$ =
    this.permanentHiddenNamesSubject.asObservable();

  constructor(
    private readonly conditionService: ConditionService,
    private readonly modeService: ModeService,
    private readonly drawerParentService: DrawerParentService,
    private readonly drawerChildService: DrawerChildService,
  ) {}

  private isHiddenByMeta(names: string[]): boolean {
    return (
      this.conditionService.isHidden(names) ||
      names.some(n => this.permanentHiddenNamesSubject.value.has(n))
    );
  }

  private isHiddenByMeta$(names: string[]): Observable<boolean> {
    return combineLatest([
      this.permanentHiddenNames$.pipe(map(set => names.some(n => set.has(n)))),
      this.conditionService.isHidden$(names),
    ]).pipe(map(([permanent, hidden]) => permanent || hidden));
  }

  private isHiddenByMode(names: string[]): boolean {
    return this.modeService.isHidden(names);
  }

  private isHiddenByMode$(names: string[]): Observable<boolean> {
    return this.modeService.isHidden$(names);
  }

  /**
   * Add control to internal state
   *
   * @param control - control metadata
   */
  handleControl(control: IControl): void {
    if (control.$type === 'hidden') {
      const set = this.permanentHiddenNamesSubject.value;
      set.add(control.$name);
      this.permanentHiddenNamesSubject.next(new Set(set));
    }
  }

  // @TODO @v.taidonov add drawer services checks to other methods
  /**
   * Check that page/field/control by name should be hidden. By condition, metadata, or by form mode
   *
   * @param names - page/field/control names
   */
  isHidden(...names: string[]): boolean {
    return (
      this.isHiddenByMeta(names) ||
      this.isHiddenByMode(names) ||
      (this.drawerChildService.isActive()
        ? this.drawerChildService.isHidden(names)
        : this.drawerParentService.isHidden(names))
    );
  }

  /**
   * Stream of page/field/control by name hidding state. By condition, metadata, or by form mode
   *
   * @param names - page/field/control names
   */
  isHidden$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => byMeta || byMode));
  }

  /**
   * Check that page/field/control by name should be hidden ONLY because of form mode
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMode(...names: string[]): boolean {
    return !this.isHiddenByMeta(names) && this.isHiddenByMode(names);
  }

  /**
   * Stream of page/field/control by name hidding state ONLY because of form mode
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMode$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => !byMeta && byMode));
  }

  /**
   * Check that page/field/control by name should be hidden ONLY because of some condition or metadata
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMeta(...names: string[]): boolean {
    return this.isHiddenByMeta(names) && !this.isHiddenByMode(names);
  }

  /**
   * Stream of page/field/control by name hidding state ONLY because of some condition or metadata
   *
   * @param names - page/field/control names
   */
  isHiddenOnlyByMeta$(...names: string[]): Observable<boolean> {
    return combineLatest([
      this.isHiddenByMeta$(names),
      this.isHiddenByMode$(names),
    ]).pipe(map(([byMeta, byMode]) => byMeta && !byMode));
  }

  /**
   * Clear service state
   */
  clear(): void {
    this.permanentHiddenNamesSubject.next(new Set());
  }
}

<ngispui-file
  [label]="to.caption"
  [accept]="to?.originalControl?.$accept || ''"
  [ngModel]="files"
  [disabled]="to.disabled"
  [formlyAttributes]="field"
  [multiple]="to?.originalControl?.$multiple"
  (ngModelChange)="onFilesChange($event)"
  (customFocus)="toggleFocus(true)"
  (customBlur)="toggleFocus(false)"
>
</ngispui-file>

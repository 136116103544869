import { IInput } from 'app/services/api5-service/api.interface';

export interface FileControlWithValue {
  readonly control: IInput;
  readonly type: 'file';
  value: string;
}

/**
 * Check that input control meta is file meta
 *
 * @param control - control metadata
 */
export function isFileControl(control: IInput): boolean {
  return control.$type === 'file';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 */
export function getTypeControlForFile(control: IInput): FileControlWithValue {
  return {
    value: '',
    control,
    type: 'file',
  };
}

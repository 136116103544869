<div
  class="formly-date-picker__group"
  [ngClass]="{
    'formly-date-picker__group_invalid': showError,
    'formly-date-picker__group_highlight': showHighlight,
    'formly-date-picker__group_disabled': to.disabled
  }"
>
  <nz-date-picker
    ispDateFormatter
    [formControl]="formControl"
    [formlyAttributes]="field"
    [tabindex]="to.tabindex || 0"
    [nzDisabled]="to.disabled"
    [nzSuffixIcon]="icon"
    [nzShowToday]="true"
    [nzAllowClear]="false"
    [nzPlaceHolder]="' '"
    [nzMode]="to.datePickMode"
    nzFormat="dd.MM.yyyy"
    class="formly-date-picker__date-picker"
    (nzOnOpenChange)="onDatePickerStateChange($event)"
    (focusin)="onFocusChange(true)"
    (focusout)="onFocusChange(false)"
  ></nz-date-picker>

  <ng-template #icon>
    <isp-svg-icon
      class="formly-date-picker__icon"
      name="m-scheduler"
      [customStyle]="{ width: '15px', height: '15px' }"
    >
    </isp-svg-icon>
  </ng-template>

  <span
    *ngIf="to.inputLabel"
    class="formly-date-picker__label"
    [ngClass]="{
      'formly-date-picker__label_active': isLabelActive
    }"
  >
    {{ to.inputLabel
    }}<span *ngIf="to.required" class="formly-date-picker__required-mark"
      >*</span
    >
  </span>

  <fieldset class="ispui-fieldset">
    <legend
      class="ispui-fieldset__legend"
      [ngClass]="{ 'ispui-fieldset__legend_active': isLabelActive }"
    >
      {{ to.inputLabel }}<span *ngIf="to.required">*</span>
    </legend>
  </fieldset>

  <div *ngIf="formControl.value" class="formly-date-picker__clear-wrapper">
    <ispui-close
      class="formly-date-picker__clear"
      theme="light"
      (click)="clearField($event)"
    >
    </ispui-close>
  </div>
</div>

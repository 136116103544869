import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ISPUIButtonModule } from '@ispui/ng-button';
import { ISPUILinkModule } from '@ispui/ng-link';
import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { FormButtonComponent } from './form-button.component';

/**
 * Form button component module
 *
 * Usage:
 * ```html
 * <isp-form-button *ngFor="let button of buttonList$ | async" [button]="button"></isp-form-button>
 * ```
 */
@NgModule({
  declarations: [FormButtonComponent],
  exports: [FormButtonComponent],
  imports: [
    ISPUITooltipModule,
    CommonModule,
    ISPUIButtonModule,
    ISPUIPreloaderRoundModule,
    SvgIconModule,
    ISPUILinkModule,
  ],
})
export class FormButtonModule {}

import { ISlider, IField } from 'app/services/api5-service/api.interface';

import { SliderTO } from './model/slider-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { getDesc } from '../../utils/get-desc';
import { getLabel } from '../../utils/get-label';

/**
 * Get config for slider field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getSliderConfig(
  control: ISlider,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Slider> {
  const templateOptions: SliderTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    caption: getLabel(field, state.doc),
    desc: getDesc(field, state.doc),
    // hide desc for InputBase wrapper
    hideDesc: true,

    doNotShowValidationError: true,
  };

  const config: ISPFieldConfig<ISPFieldType.Slider> = {
    key: control.$name,
    type: ISPFieldType.Slider,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
  };

  return config;
}

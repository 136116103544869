<div class="container">
  <ispui-input
    class="field"
    (customFocus)="onFocus()"
    [ngModel]="displayValue"
    (ngModelChange)="onSearchInputChange($event)"
    [required]="required"
    [label]="label"
    [disabled]="disabled"
  >
    <isp-svg-icon
      class="icon"
      [customStyle]="isPopupOpened ? null : { width: '12px', height: '12px' }"
      [name]="isPopupOpened ? 'search' : 'arrow-down'"
    ></isp-svg-icon>
  </ispui-input>
  <div *ngIf="isPopupOpened" class="popup">
    <div class="popup-container scroll ispui-scrollbar_gray" #container>
      <ng-container *ngIf="options?.length; else emptyList">
        <ng-container
          *ngFor="let option of options; let i = index; trackBy: trackByFn"
          [ngTemplateOutlet]="defaultOptionTemplate"
          [ngTemplateOutletContext]="{
            value: option.v,
            key: option.k,
            select: selectByKey,
            search: displayValue,
            focused: focusedOptionIndex === i
          }"
        >
        </ng-container>
      </ng-container>
      <ng-template #emptyList>
        <div *ngIf="options?.length === 0" class="empty-list">
          {{ emptyListMsg }}
        </div>
      </ng-template>
    </div>
    <ispui-preloader-round
      *ngIf="pending"
      class="preloader"
      [loading]="true"
      [view]="options?.length ? 'overlay' : 'local'"
      [spinnerSize]="25"
    >
    </ispui-preloader-round>
  </div>

  <!-- This template can be easily replaced by custom option -->
  <ng-template
    #defaultOptionTemplate
    let-value="value"
    let-key="key"
    let-select="select"
    let-search="search"
    let-focused="focused"
  >
    <div
      class="option"
      [ngClass]="{
        option_focused: focused
      }"
      (click)="select(key)"
    >
      <ispui-tooltip ellipsis>
        <span [innerHtml]="value | highlight: search"></span>
      </ispui-tooltip>
    </div>
  </ng-template>
</div>

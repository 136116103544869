import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { ISPUICloseModule } from '@ispui/ng-close';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { DateFormatterDirective } from './date-parser-formatter.directive';
import { DatePickerFieldComponent } from './date-picker.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's input field component
 */
@NgModule({
  declarations: [DateFormatterDirective, DatePickerFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.DatePicker,
          component: DatePickerFieldComponent,
        },
      ],
    }),
    FormsModule,
    NzDatePickerModule,
    SvgIconModule,
    ISPUICloseModule,
  ],
})
export class DatePickerFieldModule {}

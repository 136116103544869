import { IControl, IField } from 'app/services/api5-service/api.interface';

import { ISPFieldConfig, ISPFormState } from '../../model';
import { FIELD_HIDDEN_CLASS } from '../class-fields';
import { getLayoutPlace } from '../get-layout-place';

/**
 * Append the common field options. Contains common members and wrapper fields
 *
 * @param config - config to append
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function appendConfigCommonOptions(
  config: ISPFieldConfig,
  control: IControl,
  field: IField,
  state: ISPFormState,
): void {
  config.templateOptions = {
    required: control.$required === 'yes' && !('$mixed' in control),
    layoutPlace: getLayoutPlace(config),
    disabled: state.disabledService.isFieldDisabled(control.$name),
    ...config.templateOptions,
  };
  config.expressionProperties = {
    className: (_, formState: ISPFormState) =>
      formState.hiddenService.isHidden(control.$name, field.$name)
        ? FIELD_HIDDEN_CLASS
        : '',
    'templateOptions.disabled': (_, formState: ISPFormState) =>
      formState.disabledService.isFieldDisabled(control.$name),
    ...config.expressionProperties,
  };
}

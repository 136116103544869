import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FormSliderComponent } from './slider.component';

import { IconProviderModule } from '../../../common/icon-provider/icon-provider.module';

@NgModule({
  imports: [CommonModule, IconProviderModule, FormsModule],
  declarations: [FormSliderComponent],
  exports: [FormSliderComponent],
})
export class FormSliderModule {}

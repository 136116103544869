import { ISPFieldConfig } from '../../model';

/**
 * Find field in fields configs by provided seek function
 *
 * @param fieldConfigs - fields configs in which we should replace field
 * @param seekFunction - seek function
 */
export function findConfig(
  fieldConfigs: ISPFieldConfig[],
  seekFunction: (field: ISPFieldConfig) => boolean | 'skip-node-and-childs',
): ISPFieldConfig | undefined {
  const seek = (field: ISPFieldConfig) => {
    const isConditionApplied = seekFunction(field);

    if (isConditionApplied === 'skip-node-and-childs') {
      return undefined;
    }

    if (isConditionApplied === true) {
      return field;
    }

    if (field.fieldGroup) {
      for (const f of field.fieldGroup) {
        const seekResult = seek(f);
        if (seekResult) {
          return seekResult;
        }
      }
    }

    return undefined;
  };

  for (const field of fieldConfigs) {
    const seekResult = seek(field);
    if (seekResult) {
      return seekResult;
    }
  }

  return undefined;
}

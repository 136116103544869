import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';

import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Date picker field component
 */
@Component({
  selector: 'isp-formly-date-picker-field',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./scss/formly-date-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.DatePicker>
  implements AfterViewInit
{
  /** date picker component */
  @ViewChild(NzDatePickerComponent)
  private readonly datePicker: NzDatePickerComponent;

  /** is datepicker field has focus */
  private focused = false;

  /** is datepicker opened */
  private opened = false;

  /** show field highlighting */
  get showHighlight(): boolean {
    return this.focused || this.opened;
  }

  /** is label in active state - arised above */
  get isLabelActive(): boolean {
    return Boolean(this.focused || this.opened || this.formControl.value);
  }

  /**
   * Set mask for datepicker input element
   * if needed and pick mode is `date`
   */
  private setMask(): void {
    const nativeInput = this.datePicker.pickerInput.nativeElement;

    if (this.to.mask === '9999-99-99' && this.to.datePickMode === 'date') {
      Inputmask({
        mask: '99.99.9999',
        showMaskOnHover: false,
      }).mask(nativeInput);
    }
  }

  ngAfterViewInit(): void {
    this.setMask();
  }

  /**
   * Handling datepicker popup toggling
   *
   * @param state - popup state
   */
  onDatePickerStateChange(state: boolean): void {
    if (this.opened && !state) {
      this.formControl.markAsTouched();
    }

    this.opened = state;
  }

  /**
   * On date picker focus change
   *
   * @param focus - focus state
   */
  onFocusChange(focus: boolean): void {
    this.focused = focus;
  }

  /**
   * Clear date picker with 'native' method
   *
   * @param event - mouse event
   */
  clearField(event: MouseEvent): void {
    this.datePicker.onClickClear(event);
  }
}

import { IDocument, ITextarea } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface TextEditorControlWithValue {
  readonly control: ITextarea;
  readonly type: 'texteditor';
  value: string;
}

/**
 * Check that input control meta is texteditor meta
 *
 * @param control - control metadata
 */
export function isTextEditorControl(control: ITextarea): boolean {
  return control.$texteditor === 'yes';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForTextEditor(
  control: ITextarea,
  doc: IDocument,
): TextEditorControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'texteditor',
  };
}

import { NgModule } from '@angular/core';

import { ResizableDirective } from './resizable.directive';

/**
 * Module for providing ispResizableBlock directive
 */
@NgModule({
  declarations: [ResizableDirective],
  exports: [ResizableDirective],
})
export class ResizableModule {}

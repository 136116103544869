import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  IControl,
  IIfStatement,
} from '../../../app/services/api5-service/api.interface';

/**
 * Service provides if/else conditions handling for form, control pages/fields/controls disabling and hiding by condition
 */
@Injectable()
export class ConditionService {
  /** map of the controls with if/else conditions and their values */
  private readonly controlsAndValues: Map<IControl, any> = new Map();

  /** set of names of pages/fields/controls, that should be hidden by condition */
  private readonly hiddenByConditionNamesSubject: BehaviorSubject<Set<string>> =
    new BehaviorSubject(new Set());

  /** set of disabled by '$shadow' condition rule pages/fields/control names. Can be changed after any form value changing */
  private readonly shadowNamesSubject: BehaviorSubject<Set<string>> =
    new BehaviorSubject(new Set());

  /** stream of all page/field/control names that hidden by condition */
  readonly hiddenNames$ = this.hiddenByConditionNamesSubject.pipe(
    map(set => Array.from(set)),
  );

  /** stream of page/field/control names that shadow by condition */
  readonly shadowNames$ = this.shadowNamesSubject.pipe(
    map(set => Array.from(set)),
  );

  /** get all page/field/control names that hidden by condition */
  get hiddenNames(): string[] {
    return Array.from(this.hiddenByConditionNamesSubject.value);
  }

  /** get all page/field/control names that shadow by condition */
  get shadowNames(): string[] {
    return Array.from(this.shadowNamesSubject.value);
  }

  /**
   * Update(make new) hidden and disabled set
   *
   * @param controlAndValues - some controls metadata and their current value
   */
  private recalculateConditions(controlAndValues: Map<IControl, any>): void {
    const hiddenSet = new Set<string>();
    const shadowSet = new Set<string>();

    for (const [control, value] of controlAndValues.entries()) {
      if (hiddenSet.has(control.$name)) {
        // in order to prevent mutual fields hiding
        // when field A hide field B by condition and field B hide field A by condition
        // @WARN potential error place, depending on control names order there can be race error
        continue;
      }

      let isAllConditionsAreFalse = true;

      for (const statement of control.if || []) {
        if (this.isActiveConditionIf(statement, value)) {
          isAllConditionsAreFalse = false;

          if (statement.$hide) {
            if (statement.$shadow) {
              shadowSet.add(statement.$hide);
            } else {
              hiddenSet.add(statement.$hide);
            }
          }
        }
      }

      for (const elseStatement of control.else || []) {
        if (isAllConditionsAreFalse) {
          if (elseStatement.$shadow) {
            shadowSet.add(elseStatement.$hide);
          } else {
            hiddenSet.add(elseStatement.$hide);
          }
        }
      }
    }

    this.hiddenByConditionNamesSubject.next(hiddenSet);
    this.shadowNamesSubject.next(shadowSet);
  }

  /**
   * Check that condition is applied
   *
   * @param statement - control if condition
   * @param value - control current value
   */
  private isActiveConditionIf(statement: IIfStatement, value: any): boolean {
    if (statement.$empty === 'no' && value !== '') {
      return true;
    } else if (statement.$empty === 'yes' && value === '') {
      return true;
    } else if (statement.$value === value) {
      return true;
    }
    return false;
  }

  /**
   * Add control hide conditions and disabled state to internal state
   *
   * @param control - control metadata
   * @param value - control current value
   */
  handleControl(control: IControl, value: any): void {
    if (control.if) {
      this.controlsAndValues.set(control, value);

      this.recalculateConditions(this.controlsAndValues);
    }
  }

  /**
   * Update value in condition list and update hidden Set
   *
   * @param control - control metadata
   * @param value - control current value
   */
  updateControlValue(control: IControl, value: any): void {
    const item = Array.from(this.controlsAndValues.keys()).find(
      c => c.$name === control.$name,
    );

    if (!item) {
      return;
    }

    this.controlsAndValues.set(item, value);
    this.recalculateConditions(this.controlsAndValues);
  }

  /**
   * Check if page/field/control is disabled because of "shadow" state
   *
   * @param name - page/field/control name
   */
  isShadow(name: string[] | string): boolean {
    const names = Array.isArray(name) ? name : [name];
    return names.some(n => this.shadowNamesSubject.value.has(n));
  }

  /**
   * Stream of page/field/control shadow state by name
   *
   * @param name - page/field/control name
   */
  isShadow$(name: string[] | string): Observable<boolean> {
    const names = Array.isArray(name) ? name : [name];
    return this.shadowNamesSubject.pipe(
      map(set => names.some(n => set.has(n))),
    );
  }

  /**
   * Check if page/field/control is hidden because of some condition
   *
   * @param name - page/field/control name
   */
  isHidden(name: string[] | string): boolean {
    const names = Array.isArray(name) ? name : [name];
    return names.some(n => this.hiddenByConditionNamesSubject.value.has(n));
  }

  /**
   * Stream of page/field/control hiding state by name
   *
   * @param name - page/field/control name
   */
  isHidden$(name: string[] | string): Observable<boolean> {
    const names = Array.isArray(name) ? name : [name];
    return this.hiddenByConditionNamesSubject.pipe(
      map(set => names.some(n => set.has(n))),
    );
  }

  /**
   * Clear service state
   */
  clear(): void {
    this.controlsAndValues.clear();
    this.hiddenByConditionNamesSubject.next(new Set());
    this.shadowNamesSubject.next(new Set());
  }
}

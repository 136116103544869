import { FormService } from 'app/form/form.service';
import { IInput, IField } from 'app/services/api5-service/api.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
  HTMLIspuiInputElement,
  HTMLIspuiPasswordElement,
  HTMLTextAreaElement,
} from '@ispui/input/dist/';

import { getDesc } from 'common/dynamic-form/utils/get-desc';
import { getLabel } from 'common/dynamic-form/utils/get-label';
import { awaitStencilElement } from 'utils/await-stencil-element';
import { DocHelper } from 'utils/dochelper';

import { InputTO } from './model/input-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { getInputPlaceholder } from '../../utils/get-input-placeholder';

/**
 * Get config for input-text field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getInputTextConfig(
  control: IInput,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.InputText> {
  const prefix =
    control.$prefix === 'yes' ? state.doc[`${control.$name}_prefix`]?.$ : null;
  const validation = [];
  const canDuplicate = control.$duplicate === 'yes';
  if (control.$zoom && !canDuplicate) {
    validation.push('duplicate');
  }

  const maxLength = control.$maxlength ? Number(control.$maxlength) : undefined;
  const desc = getDesc(field, state.doc);

  const templateOptions: InputTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    setValuesMinLength: Number(control.$svminlength),
    inputLabel: getLabel(field, state.doc),
    prefix,
    inputType: 'text',
    placeholder: getInputPlaceholder(field, state.doc),
    unlimit: control.$unlimit,
    maxLength,
    zoom: Number(control.$zoom),
    zoomLabel: DocHelper.getMessage('msg_zoom', state.doc),
    isMixed: state.mixedService.isControlMixed(control),
    mixedPlaceholder: DocHelper.getMessage('placeholder_mixed_msg', state.doc),
    width: control.$width,
    mask: control.$mask,
    invisibleMask: true,
    inputElementSubject: new BehaviorSubject(null),
    // @WARN input field never can be full width
    isFullWidth: false,
    desc,
    // hide desc in field-wrapper, cause desc with length < 5 will be displayed in input suffix
    hideDesc: desc?.length < 5,
  };

  const config: ISPFieldConfig<ISPFieldType.InputText> = {
    key: control.$name,
    type: ISPFieldType.InputText,
    wrappers: [ISPFieldWrapper.InputBase, ISPFieldWrapper.ValidationError],
    templateOptions,
    expressionProperties: {
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
    },
    validators: {
      validation,
    },
  };

  // setValues should not be requested on every letter input, because this field may also be updated with an old value
  if (control.$setvalues) {
    config.modelOptions = {
      updateOn: 'blur',
    };
  }

  return config;
}

/**
 * ISPUI input elements (input, password, textarea) have special logic for autofocusing
 *
 * @param ispuiElement - ispui focusable element
 * @param config - field config
 * @param formState - form state
 * @param formService - form service
 */
export function setAutofocus(
  ispuiElement:
    | HTMLIspuiInputElement
    | HTMLIspuiPasswordElement
    | HTMLTextAreaElement,
  config: ISPFieldConfig,
  formState: ISPFormState,
  formService?: FormService,
): Observable<unknown> {
  // @HACK somehow autofocus breaks form opening animation. You have to wait for open animation over from @vmdci/animations!
  let awaitAnimationOver = of(null);
  if (formService) {
    awaitAnimationOver = formService.formOpenAnimationOverSubject.pipe(
      filter(over => over),
      take(1),
    );
  }

  return awaitAnimationOver.pipe(
    map(async () => {
      // we need wait for stencil element to be loaded, initiallized and even first rendered, in order to 'focusNative' to work properly
      const element = await awaitStencilElement(ispuiElement);

      await formState.layoutService.scrollToField(config, true);
      element.focusNative({
        preventScroll: true,
      });
    }),
  );
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Component provide textdata and htmldata fields
 */
@Component({
  selector: 'isp-textdada',
  templateUrl: './textdata.component.html',
  styleUrls: ['./scss/textdata.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDataFieldComponent extends ISPFieldTypeBase<ISPFieldType.TextData> {}

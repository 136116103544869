import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { FullHeightModule } from 'common/fullheight/fullheight.module';
import { TrustPipeModule } from 'common/trust/trust.module';

import { FrameFieldComponent } from './frame.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's frame field component
 */
@NgModule({
  declarations: [FrameFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TrustPipeModule,
    FullHeightModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Frame,
          component: FrameFieldComponent,
        },
      ],
    }),
  ],
})
export class FrameFieldModule {}

import { ITextarea, IField } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { CodeTO } from './model/code-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';

/**
 * Get config for code field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getCodeConfig(
  control: ITextarea,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Code> {
  const templateOptions: CodeTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    language: control.$code,
    msg: {
      fontSize: DocHelper.getMessage('msg_ide_font', state.doc),
      language: DocHelper.getMessage('msg_ide_mode', state.doc),
      theme: DocHelper.getMessage('msg_ide_theme', state.doc),
      lineNumbers: DocHelper.getMessage('msg_ide_gutter', state.doc),
      wordWrap: DocHelper.getMessage('msg_ide_softwrap', state.doc),
      fullscreen: DocHelper.getMessage('msg_ide_fullscreen', state.doc),
      exitFullscreen: DocHelper.getMessage('msg_ide_exitfullscreen', state.doc),
    },

    doNotShowValidationError: true,
  };

  const config: ISPFieldConfig<ISPFieldType.Code> = {
    key: control.$name,
    type: ISPFieldType.Code,
    templateOptions,
    wrappers: [ISPFieldWrapper.ValidationError],
  };

  return config;
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { NgISPUIFileComponent } from '@ngispui/file';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * `@type=file` input field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-file-field',
  templateUrl: './file.component.html',
  styleUrls: ['./scss/file.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.InputFile>
  implements OnInit
{
  @ViewChild(NgISPUIFileComponent) fileElement: NgISPUIFileComponent;

  files: FileList | null = null;

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }

  onFilesChange(files?: FileList): void {
    this.files = files;
    this.formControl.markAsTouched();
    this.formControl.setValue(this.files || '');
  }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(
        filter(
          value =>
            value !== this.files && (!value || value instanceof FileList),
        ),
        untilDestroyed(this),
      )
      .subscribe(value => {
        if (!value && this.files) {
          this.fileElement.removeFile();
        }

        this.files = value || null;
        this.cdr.markForCheck();
      });
  }
}

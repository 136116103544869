import { IFormModel, IPreparedFormModel } from '../dynamic-form.interface';

/**
 * Moves all values from all form's pages to one single level. Flat object
 *
 * @param form - form model
 */
export function prepareFormModel(form: IFormModel): IPreparedFormModel {
  return Object.keys(form).reduce<IPreparedFormModel>((newModel, key) => {
    if (!form[key] || typeof form[key] !== 'object') {
      newModel[key] = form[key] as string;
      return newModel;
    }

    if (form[key] instanceof FileList) {
      newModel[key] = form[key] as FileList;
    } else if (Array.isArray(form[key])) {
      newModel[key] = (form[key] as string[]).join(',');
    } else {
      return {
        ...newModel,
        ...prepareFormModel(form[key] as any),
      };
    }

    return newModel;
  }, {});
}

import { IField, IList } from 'app/services/api5-service/api.interface';

export interface ListControl {
  readonly control: IList;
  readonly type: 'list';
}

export interface ListFilterControl {
  readonly control: IList;
  readonly type: 'listfilter';
}

/**
 * Map control metadata to typed object
 *
 * @param field - field metadata
 */
export function getTypeControlsForList(
  field: IField,
): (ListControl | ListFilterControl)[] {
  const filter: ListFilterControl | undefined =
    field.listfilter && field.list?.[0]
      ? {
          control: field.list[0],
          type: 'listfilter',
        }
      : undefined;

  const list: ListControl | undefined = field.list?.[0]
    ? {
        control: field.list[0],
        type: 'list',
      }
    : undefined;

  // @TODO i.ablov parse list controls here!
  return [list, filter].filter(Boolean);
}

import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { IDrawerMeta } from './drawer-manager.interface';

/**
 * Drawer manager service
 */
@Injectable({
  providedIn: 'root',
})
export class DrawerManagerService {
  readonly drawerMeta$ = new BehaviorSubject<IDrawerMeta>(null);

  readonly isOpen$ = new Subject<boolean>();

  /**
   * Open drawer with a specified type
   *
   * @param meta - options and data for drawer
   */
  openDrawer(meta: IDrawerMeta): void {
    this.drawerMeta$.next({
      overlay: true,
      ...meta,
    });
    this.isOpen$.next(true);
  }

  /**
   * Close drawer
   *
   * @param identityPatch - partial drawer meta to specify a certain drawer by checking it's props
   */
  close(identityPatch?: Partial<IDrawerMeta>): void {
    if (identityPatch) {
      const meta = this.drawerMeta$.value;
      if (
        Object.keys(identityPatch).every(k => identityPatch[k] === meta?.[k])
      ) {
        this.isOpen$.next(false);
      }
      return;
    }
    this.isOpen$.next(false);
  }

  /**
   * Destroy all the data related to any drawer
   */
  destroy(): void {
    this.isOpen$.next(false);
    this.drawerMeta$.next(null);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';
import { FormButtonModule } from 'components/form-button';

import { ISPMetricDirectiveModule } from '@ispdevkit/metric';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { IconProviderModule } from 'common/icon-provider/icon-provider.module';

import { ListCellFieldComponent } from './list-cell.component';

@NgModule({
  declarations: [ListCellFieldComponent],
  exports: [ListCellFieldComponent],
  imports: [
    CommonModule,
    IconProviderModule,
    FormlyModule,
    FormButtonModule,
    ISPMetricDirectiveModule,
    ISPUITooltipModule,
  ],
})
export class ListCellFieldModule {}

<ng-container [ngSwitch]="cell.$type">
  <ng-container *ngSwitchCase="'img'">
    <div class="cell-image">
      <ng-container *ngIf="cell.isSvgSprite || cell.img; else valueAsImg">
        <isp-icon-provider [name]="cell.img || cell.value" [inSprite]="true">
        </isp-icon-provider>
      </ng-container>

      <ng-template #valueAsImg>
        <img
          *ngIf="cell.value"
          [src]="cell.value"
          [alt]="cell.title"
          [attr.data-name]="cell.$name"
          [style]="{
            width: cell.$width || 'auto',
            height: cell.$height || 'auto'
          }"
        />
      </ng-template>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'button'">
    <div class="cell-buttons">
      <isp-form-button
        *ngFor="let button of cell.buttons"
        [button]="button"
        [ispMetric]="{
          event: 'user_click_on_action_button_with_module',
          data: { name: button?.$name, row: row?.keyValue }
        }"
        (btnClick)="onButtonClick(button)"
      ></isp-form-button>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'control'">
    <formly-field [field]="getField(configs, cell)"></formly-field>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="cell.hint; else textValueBlock">
      <div class="cell-value">
        <div class="cell-value_min-height-alignment">
          <ispui-tooltip class="cell__tooltip" title-text="">
            <span [innerHTML]="cell.value"></span>
            <div slot="content" [innerHTML]="cell.hint"></div>
          </ispui-tooltip>
        </div>
      </div>
    </ng-container>
    <ng-template #textValueBlock>
      <div class="cell-value">
        <div
          class="cell-value_min-height-alignment"
          [innerHTML]="cell.value"
        ></div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>

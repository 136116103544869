<ispui-checkbox
  returnType="string"
  [id]="id"
  [name]="key"
  [wrap]="to.wrap"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [required]="to.required"
  [ngClass]="{ checkbox__component_is_mixed: to.isMixed }"
  class="checkbox__component"
>
  <span [innerHTML]="caption"></span>
</ispui-checkbox>

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  ViewChild,
  AfterViewInit,
  Optional,
} from '@angular/core';

import { untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from 'app/form/form.service';

import { DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR } from 'common/dynamic-form/services/layout.service';

import { getNewMessageAndPositionCaret } from './textarea.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';
import { setAutofocus } from '../input/input.utils';

/**
 * `@type=textarea` textarea field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-textarea-field',
  templateUrl: './textarea.component.html',
  styleUrls: ['./scss/textarea.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.TextArea>
  implements AfterViewInit
{
  readonly containerSelector = DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;

  /** set width 100% to fullwidth field */
  @HostBinding('style.width')
  get width(): string {
    return this.to.isFullWidth ? 'inherit' : 'auto';
  }

  /** height for textarea field */
  @HostBinding('style.height')
  get height(): string {
    return this.to.height;
  }

  @ViewChild('textarea') textAreaElement: ElementRef<{
    nativeTextarea: HTMLTextAreaElement;
  }>;

  constructor(@Optional() private readonly formService?: FormService) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.to.autofocus) {
      setAutofocus(
        this.textAreaElement.nativeElement,
        this.field,
        this.formState,
        this.formService,
      )
        .pipe(untilDestroyed(this))
        .subscribe();
    }
  }

  /**
   * Get placeholder value
   */
  getPlaceholder(): string {
    if (this.to.isMixed) {
      return this.to.mixedPlaceholder;
    }
    return this.to.placeholder;
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }

  preventSelectionReset(event: MouseEvent): void {
    event.preventDefault();
  }

  insertQuote(): void {
    const selectedText = window.getSelection().toString();
    if (!selectedText) {
      return;
    }

    const selectionStart =
      this.textAreaElement.nativeElement.nativeTextarea.selectionStart;

    const newValue = `${this.formControl.value.slice(
      0,
      selectionStart,
    )}> ${selectedText}${this.formControl.value.slice(selectionStart)}`;

    this.formControl.setValue(newValue);
  }

  /**
   * Apply selected response template in textarea
   *
   * @param templateMessage - message template text
   */
  applyTemplate(templateMessage: string): void {
    const nativeTextArea = this.textAreaElement.nativeElement.nativeTextarea;

    // returning textarea focus as it could have been lost when selecting the saved message template
    nativeTextArea.focus();

    const { newMessage, newCaretPosition } = getNewMessageAndPositionCaret(
      nativeTextArea,
      templateMessage,
      true,
    );

    this.formControl.setValue(newMessage);
    nativeTextArea.setSelectionRange(newCaretPosition, newCaretPosition);
  }
}

import { IField, IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

/**
 * Get label for field
 *
 * @param field - field metadata
 * @param doc - doc instance
 */
export function getLabel(field: IField, doc: IDocument): string {
  if (field.$noname) {
    return '';
  }

  return DocHelper.getMessage(field.$name, doc);
}

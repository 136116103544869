import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import {
  IDocument,
  IRecord,
  TRecordValue,
} from 'app/services/api5-service/api.interface';
import { HttpBaseService } from 'app/services/http-base-service/http-base.service';

import { Components } from '@ispui/dropdown';

import { ISPFormState } from 'common/dynamic-form/model';
import { DocHelper } from 'utils/dochelper';

import { getSourceString } from './saved-messages.utils';

import { ISavedMessageMeta } from '../model/textarea-saved-message.interface';

/**
 * Component for loading and displaying saved response templates for support in BILLmanager
 */
@Component({
  selector: 'isp-saved-messages',
  templateUrl: './saved-messages.component.html',
  styleUrls: ['./scss/saved-messages.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedMessagesComponent {
  @Input() formState: ISPFormState;

  @Input() textAreaElement: {
    nativeTextarea: HTMLTextAreaElement;
  };

  @Input() savedMessageMeta: ISavedMessageMeta;

  @Output() readonly selectTemplate: EventEmitter<string> = new EventEmitter();

  /** Waiting for saved response templates to load */
  pending = false;

  savedMessages: IRecord<TRecordValue>[] = [];

  get emptySavedMessagesMsg(): string {
    return this.formState.context.emptyListMsg;
  }

  @ViewChild('dropdown') dropdown: ElementRef<
    Components.IspuiDropdown & HTMLElement & { isVisible: boolean }
  >;

  constructor(
    private readonly httpBaseService: HttpBaseService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  getSavedMessages(event: MouseEvent): void {
    // we prohibit further ascent of event so that the dropdown event for opening/closing is not triggered,
    // since here we need to control these events ourselves
    event.stopPropagation();

    if (this.pending) {
      return;
    }

    if (this.dropdown.nativeElement.isVisible) {
      this.dropdown.nativeElement.closeDropdown();
    }
    const doc = this.formState.doc;

    this.textAreaElement.nativeTextarea.focus();
    const sourceString = getSourceString(this.textAreaElement.nativeTextarea);

    const params = {
      source_func: doc.$func,
      source_string: sourceString,
      plid: DocHelper.plid(doc),
      elid: DocHelper.elid(doc),
    };

    this.pending = true;
    this.httpBaseService
      .get<IDocument>({ func: this.savedMessageMeta.func, ...params })
      .subscribe(responce => {
        this.savedMessages = (responce.elem as IRecord<TRecordValue>[]) || [];

        this.pending = false;
        this.dropdown.nativeElement.showDropdown();
        this.cdr.markForCheck();

        // wait for the angular to fill the content of the dropdown popup and after that we recalculate the position of the dropdown
        setTimeout(() => {
          this.dropdown.nativeElement.setupDropdown();
        });
      });
  }

  preventSelectionReset(event: MouseEvent): void {
    event.preventDefault();
  }

  onSelectTemplateMessage(templateMessage: string): void {
    this.dropdown.nativeElement.closeDropdown();

    this.selectTemplate.emit(templateMessage);
  }
}

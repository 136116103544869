import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IDesktopPageInfo } from 'app/app.interface';
import { Observable, OperatorFunction, pipe, Subject, throwError } from 'rxjs';
import { catchError, filter, map, share, tap } from 'rxjs/operators';

import { CustomHttpParamEncoder } from './custom-http-param-encode.class';
import { IHttpParam } from './http-base.interface';

import { environment } from '../../../environments/environment';
import { IDocumentBase, IFuncResponse } from '../api5-service/api.interface';

/* manager host form xsl */
declare const pageInfo: IDesktopPageInfo;

/* http Service Unavailable */
const HTTP_CODE_SERVICE_UNAVAILABLE = 503;

/**
 * Http service for ISPmanager/BILLmanager 5
 * with 503 error handler
 */
@Injectable({
  providedIn: 'root',
})
export class HttpBaseService {
  /* the host of the manager */
  private readonly mgrHost: string;

  /* default output format */
  private readonly outputFormatParam = { out: 'xjson' };

  /* header for 503 error output in json */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly forJsonOutputHeaders = { 'ISP-Client': 'Web-interface' };

  /** doc property $features subject */
  private readonly docFeaturesSubject = new Subject<string>();

  /** doc property $notify subject */
  private readonly docNotifySubject = new Subject<string>();

  /** doc property $features stream */
  readonly docFeatures$ = this.docFeaturesSubject.asObservable();

  /** doc property $notify stream */
  readonly docNotify$ = this.docNotifySubject.asObservable();

  constructor(private readonly httpClient: HttpClient) {
    if (environment.production) {
      this.mgrHost = pageInfo.baseUrl;
    } else {
      this.mgrHost = environment.mgrHost;
    }
  }

  /**
   * Convert params to FormData for upload files
   *
   * @param params
   */
  private convertToFormData(params: IHttpParam): FormData {
    const formData = new FormData();
    Object.keys(params).forEach(key => {
      const value =
        (params[key] as any[] | FileList | string | { name: string }) || '';

      // check on filelist
      if (typeof value[0] === 'object') {
        if ((value as FileList).length > 1) {
          for (const file of value as FileList) {
            formData.append(`${key}[]`, file);
          }
        } else {
          formData.append(`${key}`, value[0]);
        }
      } else if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        typeof (value as any).name === 'string'
      ) {
        // что тут происходит!?
        formData.append(key, value as any, (value as any).name);
      } else {
        formData.append(key, value as string);
      }
    });
    return formData;
  }

  /**
   * Handle error for event base http request
   *
   * @param error - error object
   */
  private handleErrorEvent(
    error: HttpErrorResponse,
  ): Observable<HttpEvent<any>> {
    if (error.status === HTTP_CODE_SERVICE_UNAVAILABLE) {
      const url = error.error;
      return this.get503Event(url);
    }
    // errorEmitter handle may be here
    return throwError(error);
  }

  /**
   * Request for get response of 503 error(long request)
   *
   * @param stringParam - request params
   */
  private get503Event(stringParam: string): Observable<HttpEvent<any>> {
    const url = `/${stringParam}`;
    return this.httpClient
      .get(url, {
        headers: this.forJsonOutputHeaders,
        observe: 'events',
      })
      .pipe(
        catchError(error => this.handleErrorEvent(error)),
        share(),
      );
  }

  /**
   * Error handler
   *
   * @param error - error object
   */
  private handleError<R extends IDocumentBase = IDocumentBase>(
    error: HttpErrorResponse,
  ): Observable<IFuncResponse<R>> {
    if (error.status === HTTP_CODE_SERVICE_UNAVAILABLE) {
      const url = error.error;
      // handle 503 error
      return this.get503<R>(url);
    }
    // errorEmitter handle may be here
    return throwError(error);
  }

  /**
   * Request for get response of 503 error(long request)
   *
   * @param stringParam - request params
   */
  private get503<R extends IDocumentBase = IDocumentBase>(
    stringParam: string,
  ): Observable<IFuncResponse<R>> {
    const url = `/${stringParam}`;

    return this.httpClient
      .get<IFuncResponse<R>>(url, { headers: this.forJsonOutputHeaders })
      .pipe(
        catchError(error => this.handleError<R>(error)),
        share(),
      );
  }

  /**
   * Returns `doc` key value from request's response
   */
  private getDocument<K extends IDocumentBase>(): OperatorFunction<
    IFuncResponse<K>,
    K
  > {
    return pipe(map(meta => (meta.doc ? meta.doc : (meta as unknown as K))));
  }

  /**
   * Update doc $notify identifier
   */
  private updateDocNotify<K extends IDocumentBase>(): OperatorFunction<K, K> {
    return pipe(tap(doc => this.docNotifySubject.next(doc.$notify)));
  }

  /**
   * Update doc features and check the need for reloading page
   */
  private updateDocFeatures<K extends IDocumentBase>(): OperatorFunction<K, K> {
    return pipe(tap(doc => this.docFeaturesSubject.next(doc.$features)));
  }

  /**
   * Convert to HttpParams with encode
   *
   * @param params - request params
   */
  private convertParamsToHttpParams(params: IHttpParam): HttpParams {
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(params).forEach(key => {
      httpParams = httpParams.set(key, params[key]);
    });
    return httpParams;
  }

  /**
   * Get request
   *
   * @param params - request params
   * @param headers - object with headers
   */
  get<
    R extends IDocumentBase = IDocumentBase,
    D extends IHttpParam = IHttpParam,
  >(params: D, headers?: HttpHeaders): Observable<R> {
    return this.httpClient
      .get<IFuncResponse<R>>(this.mgrHost, {
        params: this.convertParamsToHttpParams({
          ...this.outputFormatParam,
          ...params,
        }),
        headers: { ...this.forJsonOutputHeaders, ...headers },
      })
      .pipe(
        catchError(err => this.handleError<R>(err)),
        this.getDocument(),
        this.updateDocFeatures(),
        this.updateDocNotify(),
        share(),
      );
  }

  /**
   * Post request
   *
   * @param params - request params
   * @param action - api handler path
   * @param headers - object with headers
   */
  post<
    R extends IDocumentBase = IDocumentBase,
    D extends IHttpParam = IHttpParam,
  >(params: D, action?: string, headers?: HttpHeaders): Observable<R> {
    return this.httpClient
      .post(
        action || this.mgrHost,
        this.convertParamsToHttpParams({
          ...this.outputFormatParam,
          ...params,
        }),
        { headers: { ...this.forJsonOutputHeaders, ...headers } },
      )
      .pipe(
        catchError(err => this.handleError<R>(err)),
        this.getDocument(),
        this.updateDocFeatures(),
        this.updateDocNotify(),
        share(),
      );
  }

  /**
   * Post request with observe. Usually used for uploading media files to server
   *
   * @param params - request params
   * @param action - api handler path
   */
  uploadFile(params: IHttpParam, action?: string): Observable<HttpEvent<any>> {
    return this.httpClient
      .post(
        action || this.mgrHost,
        this.convertToFormData({ ...params, ...this.outputFormatParam }),
        {
          reportProgress: true,
          observe: 'events',
          headers: { ...this.forJsonOutputHeaders, 'ngsw-bypass': '' },
        },
      )
      .pipe(
        filter(event =>
          [HttpEventType.UploadProgress, HttpEventType.Response].includes(
            event.type,
          ),
        ),
        catchError(err => this.handleErrorEvent(err)),
        share(),
      );
  }
}

import { IDocument, IField } from 'app/services/api5-service/api.interface';

import {
  getTypeControlsForCaptcha,
  getTypeControlsForDateTime,
  getTypeControlsForFrame,
  getTypeControlsForHtmlData,
  getTypeControlsForInput,
  getTypeControlsForLink,
  getTypeControlsForList,
  getTypeControlsForSelect,
  getTypeControlsForSlider,
  getTypeControlsForTextarea,
  getTypeControlsForTextData,
  getTypeControlsForTicket,
  getTypeControlsForTree,
  TypedControls,
} from './controls';

export interface TypedField {
  readonly controls: TypedControls[];
  /** original metadata */
  readonly field: IField;
}

/**
 * Map field control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypedField(field: IField, doc: IDocument): TypedField {
  const captchaControl = getTypeControlsForCaptcha(field);
  const inputControls = getTypeControlsForInput(field, doc);
  const selectControls = getTypeControlsForSelect(field, doc);
  const datetimeControls = getTypeControlsForDateTime(field);
  const textareaControls = getTypeControlsForTextarea(field, doc);
  const linkControls = getTypeControlsForLink(field, doc);
  const listControl = getTypeControlsForList(field);
  const htmldataControl = getTypeControlsForHtmlData(field, doc);
  const textdataControl = getTypeControlsForTextData(field, doc);
  const frameControl = getTypeControlsForFrame(field, doc);
  const sliderControl = getTypeControlsForSlider(field, doc);
  const treeControl = getTypeControlsForTree(field, doc);
  const ticketControl = getTypeControlsForTicket(field, doc);

  return {
    field,
    controls: [
      ...captchaControl,
      ...inputControls,
      ...selectControls,
      ...datetimeControls,
      ...textareaControls,
      ...linkControls,
      ...listControl,
      ...htmldataControl,
      ...textdataControl,
      ...frameControl,
      ...sliderControl,
      ...treeControl,
      ...ticketControl,
    ],
  };
}

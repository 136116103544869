import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  EventKeys,
  EventMessage,
  EventPayload,
  EventResponse,
} from './messagebus.interface';

/**
 * Message bus service for global events
 * For use add custom type event to messagebus.interface.ts
 */
@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  private readonly messageSubject = new Subject<EventMessage>();

  /** all events stream */
  private readonly message$ = this.messageSubject.asObservable();

  /**
   * Emit event
   *
   * @param type - event name
   * @param payload - payload
   */
  emit<K extends EventKeys = EventKeys>(
    type: K,
    payload?: EventPayload<K>,
  ): Promise<EventResponse<K>> {
    return new Promise((response: any) => {
      this.messageSubject.next({ type, payload, response });
    });
  }

  on$<K extends EventKeys = EventKeys>(type: K): Observable<EventMessage<K>> {
    return this.message$.pipe(filter(msg => msg.type === type)) as Observable<
      EventMessage<K>
    >;
  }
}

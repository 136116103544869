import { IField } from 'app/services/api5-service/api.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import {
  getInlineFieldGroupConfig,
  getPhoneGroupFields,
  getPhoneGroupConfig,
  getTextWithLink,
  getTextWithLinkConfig,
} from '../../types';

/**
 * Wrap field controls configs into group config, if needed
 *
 * @param configs - field configs
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function wrappConfigsIntoGroup(
  configs: ISPFieldConfig[],
  field: IField,
  state: ISPFormState,
): ISPFieldConfig[] {
  const visibleFields = configs.filter(c => c.type !== ISPFieldType.Hidden);

  const isListAndFilter =
    visibleFields.length === 2 &&
    visibleFields.some(f => f.type === ISPFieldType.ListFilter) &&
    visibleFields.some(f => f.type === ISPFieldType.List);

  const ticketAndSummary =
    visibleFields.some(config => config.type === ISPFieldType.Chat) &&
    visibleFields.some(config => config.type === ISPFieldType.ChatSummary);

  if (visibleFields.length <= 1 || isListAndFilter || ticketAndSummary) {
    return [...configs];
  } else if (visibleFields.length > 1) {
    configs.forEach(c => {
      // @HACK remove all label related wrappers from fields, so only group wrapper will take control
      c.wrappers = c.wrappers?.filter(wrapper =>
        [ISPFieldWrapper.ValidationError].includes(wrapper),
      );

      // @HACK delete inputLabel for input-like fields
      delete (c as any).templateOptions.inputLabel;
    });

    const textWithLink = getTextWithLink(configs);
    if (textWithLink) {
      return [getTextWithLinkConfig(textWithLink, field, state)];
    }

    const prefixedFields = getPhoneGroupFields(configs);
    if (prefixedFields) {
      return [getPhoneGroupConfig(prefixedFields, field, state)];
    }

    // if there is many subfields inside field, then use group config
    return [getInlineFieldGroupConfig(configs, field, state)];
  }
}

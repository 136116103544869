import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Params } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from 'app/form/form.service';
import {
  ISetValueDocument,
  IListOptionList,
  IDocument,
  TSetValueType,
  IControl,
  ISlider,
  ISelectOptionList,
  IMListRoot,
} from 'app/services/api5-service/api.interface';
import { getButtonList } from 'components/form-button';
import { equals } from 'ramda';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { filter, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';
import { hasFile } from 'utils/has-file';

import { ButtonsService } from './buttons.service';
import { DisabledService } from './disabled.service';
import { HiddenService } from './hidden.service';
import { SelectService } from './select.service';

import { Api5Service } from '../../../app/services/api5-service/api5.service';
import {
  IFormModel,
  IPreparedFormModel,
  ISetValueChanges,
} from '../dynamic-form.interface';
import { DynamicFormService } from '../dynamic-form.service';
import { isFormSubmittable } from '../dynamic-form.utils';
import { ISPFieldConfig, ISPFieldType } from '../model';
import { addMessagesToChatConfig, getTextdataContent } from '../types';
import { getTypedForm } from '../utils/form-preparing';
import {
  filterConfigs,
  findConfig,
  forEachConfig,
  replaceConfig,
} from '../utils/formly-configs';
import { prepareFormModel } from '../utils/get-form-model';

/**
 * Setting values service
 */
@UntilDestroy()
@Injectable()
export class SetValuesService {
  /** df service. @TODO i.ablov remove this relation */
  private dynamicFormService: DynamicFormService;

  /** blocked set values. Should block all form on period of set values. Usually by turning preloader on */
  private readonly blockedSetValues = new BehaviorSubject<boolean>(false);

  /** skip all set values handling, if this subject value is true */
  private readonly finalSetValues = new BehaviorSubject<boolean>(false);

  private readonly reinitIntervalSetValues$: Subject<void> = new Subject();

  /**
   * Set values request event subject. Here you can catch setValues request for specific field
   */
  private readonly setValuesRequest: Subject<{
    field: string;
    result: Observable<ISetValueChanges>;
  }> = new Subject();

  readonly setValuesRequest$ = this.setValuesRequest.asObservable();

  readonly blockedSetValues$ = this.blockedSetValues.asObservable();

  constructor(
    private readonly apiService: Api5Service,
    private readonly hiddenService: HiddenService,
    private readonly disabledService: DisabledService,
    private readonly selectService: SelectService,
    private readonly buttonsService: ButtonsService,
    @Optional() private readonly formService?: FormService,
  ) {}

  private getNewSlists(
    fieldName: string,
    setValuesParams: Params,
    slists?: ISelectOptionList[],
  ): ISelectOptionList[] | undefined {
    // @HACK in case of setValues send empty slist we need to update components options
    // but, in order to do this, selectService need name of component
    const config = findConfig(
      this.dynamicFormService.fieldList,
      c => c.key === fieldName,
    );
    const isAutoselectField = ISPFieldType.AutocompleteSelect === config?.type;
    const isAutoselectSearch = setValuesParams?.sv_autocomplete;
    const isNoOptionsForAutoselectField = !slists?.some(
      slist => slist.$name === fieldName,
    );
    if (
      isAutoselectField &&
      isAutoselectSearch &&
      isNoOptionsForAutoselectField
    ) {
      return [
        {
          $name: fieldName,
          val: [],
        },
        ...(slists || []),
      ];
    } else {
      return slists;
    }
  }

  private overrideMlists(
    prev?: IMListRoot[],
    next?: IMListRoot[],
  ): IMListRoot[] {
    if (!prev) {
      return next;
    }

    if (!next) {
      return prev;
    }

    const overrides = [...prev];
    next.forEach(mlist => {
      const oldMlistIndex = overrides.findIndex(m => m.$name === mlist.$name);
      if (oldMlistIndex !== -1) {
        const oldMessagesIds = (overrides[oldMlistIndex].message || []).map(
          m => m.$id,
        );
        const uniqueMessages = (mlist.message || []).filter(
          m => !oldMessagesIds.includes(m.$id),
        );
        overrides[oldMlistIndex].message = [
          ...(overrides[oldMlistIndex].message || []),
          ...uniqueMessages,
        ];
      } else {
        overrides.push(mlist);
      }
    });
    return overrides;
  }

  private overrideSlists(
    prev?: ISelectOptionList[],
    next?: ISelectOptionList[],
  ): ISelectOptionList[] {
    if (!prev) {
      return next;
    }

    if (!next) {
      return prev;
    }

    const overrided = [...prev];
    next.forEach(slist => {
      const alredyExistedIndex = overrided.findIndex(
        s => s.$name === slist.$name,
      );
      if (alredyExistedIndex !== -1) {
        overrided[alredyExistedIndex] = slist;
      } else {
        overrided.push(slist);
      }
    });
    return overrided;
  }

  /**
   * Get lists with changed controls keys. Only new controls will be returned
   *
   * @param currentModel - current model form
   * @param newLists - new list values
   */
  private getNewListsControls(
    currentModel: IFormModel,
    newLists?: IListOptionList[],
  ): Record<string, string[]> | undefined {
    return newLists?.reduce<Record<string, string[]> | undefined>(
      (changedLists, listData) => {
        if (!listData.elem) {
          return changedLists;
        }

        const controls = listData.elem.reduce<string[]>((newControls, row) => {
          // controls can be in any row cell, so check all row values
          Object.values(row).forEach(cell => {
            // get control name from cell
            let controlName: string;

            if (typeof cell !== 'object') {
              return;
            }

            if ('select' in cell) {
              controlName = cell.select.$name;
            }

            if ('input' in cell) {
              controlName = cell.input.$name;
            }

            // do nothin if there no control
            if (!controlName) {
              return;
            }

            // if there is no such control in model - then it is new control
            if (!(controlName in currentModel)) {
              newControls.push(controlName);
            }
          });
          return newControls;
        }, []);

        if (controls.length) {
          if (!changedLists) {
            return {
              [listData.$name]: controls,
            };
          } else {
            changedLists[listData.$name] = controls;
          }
        }

        return changedLists;
      },
      undefined,
    );
  }

  /**
   * Check for set/unset readonly rules
   *
   * @param key
   * @param doc - doc instance
   */
  private isReadOnlySet(key: string, doc: ISetValueDocument): boolean | null {
    switch (doc?.[key].$readonly) {
      case 'yes':
        return true;
      case 'no':
        return false;
      default:
        return null;
    }
  }

  /**
   * Apply setValues changes to form doc metadata
   *
   * @HACK this is required step for listReconfiguration. We mustn't change original doc, but in order to reconfigure formly configs
   * we need doc metadata. So we update doc meta, and then use it to reconfigure formly configs
   * @param changes - prepared setValues data
   */
  private applyDocUpdate(changes: ISetValueChanges): void {
    if (changes.docToUpdate) {
      Object.entries(changes.docToUpdate).forEach(([key, value]) => {
        this.dynamicFormService.options.formState.doc[key] = value;
      });
    }
    if (changes.slist) {
      this.dynamicFormService.options.formState.doc.slist = this.overrideSlists(
        this.dynamicFormService.doc.slist,
        changes.slist,
      );
    }
    if (changes.mlist) {
      this.dynamicFormService.options.formState.doc.mlist = this.overrideMlists(
        this.dynamicFormService.doc.mlist,
        changes.mlist,
      );
    }
    if (changes.textdata) {
      Object.entries(changes.textdata).forEach(([key, value]) => {
        this.dynamicFormService.options.formState.doc[key] = {
          ...this.dynamicFormService.options.formState.doc[key],
          $: value,
        };
      });
    }
    if (changes.list) {
      if (this.dynamicFormService.options.formState.doc.list) {
        this.dynamicFormService.options.formState.doc.list.push(
          ...changes.list,
        );
      } else {
        this.dynamicFormService.options.formState.doc.list = changes.list;
      }
    }
    if (changes.buttons) {
      this.dynamicFormService.options.formState.doc.metadata.form.buttons = {
        button: changes.buttons,
      };
    }
  }

  /**
   * Apply setValues changes to all list fields in form. Add new elements to lists
   *
   * @param changes - prepared setValues data
   */
  private applyListElements(changes: ISetValueChanges): void {
    if (changes.list) {
      this.dynamicFormService.options.formState.listService.addElements(
        changes.list,
      );
    }
  }

  /**
   * List reconfiguratio after setValues
   *
   * @HACK sometimes BE send NEW fields in form metadata, and we need to add new fields to form, that requires whole form reconfiguration
   * for now this was noticed only in billmgr for domain order
   * @param changes - prepared setValues data
   */
  private applyListReconfiguration(changes: ISetValueChanges): void {
    if (!changes.listsToReconfigure) {
      return;
    }

    const typedForm = getTypedForm(changes.setValuesDoc);
    // const configs: ISPFieldConfig[] = getConfigsFromTypedForm(typedForm, state);
    const newConfigs = this.dynamicFormService.generateConfigs(
      typedForm,
      this.dynamicFormService.options.formState,
    );

    // get new lists configs, to use them in current form configuration
    const newListsConfigs = Object.keys(changes.listsToReconfigure)
      .map(key => {
        return findConfig(
          newConfigs,
          f => f.type === ISPFieldType.List && f.templateOptions.name === key,
        ) as ISPFieldConfig<ISPFieldType.List>;
      })
      .filter(Boolean);

    const newAndOldListConfigs = new Map(
      newListsConfigs.map(newListConfig => {
        // replace field in current form configuration
        const oldListConfig = replaceConfig(
          this.dynamicFormService.fieldList,
          newListConfig,
          f => {
            if (
              f.type === ISPFieldType.List &&
              f.templateOptions.name === newListConfig.templateOptions.name
            ) {
              return true;
            }
            return false;
          },
        ) as ISPFieldConfig<ISPFieldType.List>;

        // store old configs. It will be needed later for old controls removing
        return [newListConfig, oldListConfig];
      }),
    );

    // this will force Formly to reconfigurate form
    this.dynamicFormService.fieldList = [...this.dynamicFormService.fieldList];

    // set timeout needed in order to check old and new lists for hidden state
    // hidden state will be setted after formGroup.patchValue, but this is async process, so wee need to wait
    setTimeout(() => {
      Array.from(newAndOldListConfigs.entries()).forEach(
        ([newListConfig, oldListConfig]) => {
          const isOldListWasHidden = this.hiddenService.isHidden(
            oldListConfig.templateOptions.name,
          );
          const isNewListWasHidden = this.hiddenService.isHidden(
            newListConfig.templateOptions.name,
          );
          const isListFieldsAreDifferent = oldListConfig.fieldGroup.some(
            olf => !newListConfig.fieldGroup.some(nlf => olf.key === nlf.key),
          );

          const isNeedToRemoveOldControls =
            !isOldListWasHidden &&
            !isNewListWasHidden &&
            isListFieldsAreDifferent;

          if (isNeedToRemoveOldControls) {
            oldListConfig.fieldGroup.forEach(f => {
              const key = f.key;

              // because of reconfiguration we need to remove fields valueChanges subscriptions
              this.dynamicFormService.reconfiguringFields$.next(key);
              // remove value from form model
              this.dynamicFormService.formGroup.removeControl(key);
              // remove value from doc model, so it won't accumulate there
              delete this.dynamicFormService.doc[key];
            });
          }
        },
      );
    });
  }

  /**
   * Apply setValues changes to form model
   *
   * @param changes - prepared setValues data
   */
  private applyModelChange(changes: ISetValueChanges): void {
    const isFormChanged = Boolean(Object.keys(changes.model).length);
    if (isFormChanged) {
      this.dynamicFormService.formGroup.patchValue(changes.model);
    }
  }

  /**
   * Apply setValues changes to slider
   *
   * @param changes - prepared setValues data
   */
  private applySliderChange(changes: ISetValueChanges): void {
    if (!changes.sliderData) {
      return;
    }

    forEachConfig(this.dynamicFormService.fieldList, config => {
      if (config.type === ISPFieldType.Slider) {
        const name = config.templateOptions.originalControl.$name;

        const sliderConfig = changes.sliderData[name];
        if (sliderConfig) {
          config.templateOptions.max = Number(sliderConfig.$max);
          config.templateOptions.min = Number(sliderConfig.$min);
          config.templateOptions.step = Number(sliderConfig.$step);
          this.dynamicFormService.markForCheck(config);
        }
      }
    });
  }

  /**
   * Apply setValues changes, that disable/enable different fields in form
   *
   * @param changes - prepared setValues data
   */
  private applyFieldsDisabledChange(changes: ISetValueChanges): void {
    for (const name of changes.controlsToDisable) {
      this.disabledService.disableControl(name);
    }
    for (const name of changes.controlsToEnable) {
      this.disabledService.enableControl(name);
    }
  }

  /**
   * Apply setValues changes to all select-like fields in form, that use slist
   *
   * @param changes - prepared setValues data
   */
  private applySlistChange(changes: ISetValueChanges): void {
    this.selectService.overrideOptions(changes.slist);
  }

  /**
   * Apply setValues changes to all chats in form
   *
   * @param changes - prepared setValues data
   */
  private applyMlistChange(changes: ISetValueChanges): void {
    changes.mlist?.forEach(mlist => {
      const config = findConfig(
        this.dynamicFormService.fieldList,
        c => c.type === ISPFieldType.Chat,
      ) as ISPFieldConfig<ISPFieldType.Chat>;
      if (!config) {
        return;
      }

      addMessagesToChatConfig(config, mlist, this.dynamicFormService.doc);
      this.dynamicFormService.markForCheck(config);
    });
  }

  /**
   * Apply setValues changes to buttons in form
   *
   * @param changes - prepared setValues data
   */
  private applyButtonsChange(changes: ISetValueChanges): void {
    if (changes.buttons?.length) {
      this.buttonsService.setFooterButtons(
        getButtonList(
          this.dynamicFormService.doc,
          changes.buttons,
          this.dynamicFormService.showHints,
          !isFormSubmittable(this.dynamicFormService.fieldList),
        ),
      );
    }
  }

  /**
   * Apply setValues changes to all textdata fields in form
   *
   * @param changes - prepared setValues data
   */
  private applyTextDataChange(changes: ISetValueChanges): void {
    if (!changes.textdata) {
      return;
    }

    forEachConfig(this.dynamicFormService.fieldList, config => {
      if (config.type === ISPFieldType.TextData) {
        const name = config.templateOptions.originalControl.$name;
        if (changes.textdata[name]) {
          config.templateOptions.content = changes.textdata[name];
          this.dynamicFormService.markForCheck(config);
        }
      }
    });
  }

  /**
   * Picks up changed values from setValues document root and returns the flat model with changed values
   *
   * @param fieldName
   * @param setValuesParams
   * @param setValuesDoc - setValues document
   * @param originalModel - original form model before setValues
   */
  private getSetValuesChanges(
    fieldName: string,
    setValuesParams: Params,
    setValuesDoc: ISetValueDocument,
    originalModel: IFormModel,
  ): ISetValueChanges {
    const changes: ISetValueChanges = {
      model: {},
      setValuesDoc,
      controlsToDisable: [],
      controlsToEnable: [],
      mlist: setValuesDoc.mlist,
      slist: this.getNewSlists(fieldName, setValuesParams, setValuesDoc.slist),
      list: setValuesDoc.list,
      buttons: setValuesDoc.buttons?.button,
    };

    changes.listsToReconfigure = this.getNewListsControls(
      originalModel,
      setValuesDoc.list,
    );

    // array of all field keys, that expected to be in setValues doc
    const docModelKeys = [
      ...Object.keys(originalModel),
      ...Object.values(changes.listsToReconfigure || {}).flat(),
    ];

    changes.docToUpdate = docModelKeys.reduce<Partial<IDocument>>(
      (valuesFromSetValuesDoc, key) => {
        // get new values from set-value doc. Used for doc merging. Required for lists reconfiguration
        // in reconfiguration process fields are created from scratch, and get their defaultValue from 'this.doc'
        // this is why we need to get those defaultValues from set-value doc
        const valueFromDoc = setValuesDoc[key];
        if (valueFromDoc) {
          if (valuesFromSetValuesDoc) {
            valuesFromSetValuesDoc[key] = valueFromDoc;
          } else {
            return {
              [key]: valueFromDoc,
            };
          }
        }

        return valuesFromSetValuesDoc;
      },
      undefined,
    );

    const sliderConfigs = filterConfigs(
      this.dynamicFormService.fieldList,
      config => config.type === ISPFieldType.Slider,
    ) as ISPFieldConfig<ISPFieldType.Slider>[];
    changes.sliderData = sliderConfigs.reduce<Record<string, ISlider>>(
      (sliderChanges, sliderConfig) => {
        const name = sliderConfig.templateOptions.originalControl.$name;
        const newConfigSlider = setValuesDoc[name];
        if (!newConfigSlider) return sliderChanges;

        if (
          sliderConfig.templateOptions.max !== Number(newConfigSlider.$max) ||
          sliderConfig.templateOptions.min !== Number(newConfigSlider.$min) ||
          sliderConfig.templateOptions.step !== Number(newConfigSlider.$step)
        ) {
          if (!sliderChanges) {
            return {
              [name]: newConfigSlider,
            };
          } else {
            sliderChanges[name] = newConfigSlider;
          }
        }

        return sliderChanges;
      },
      undefined,
    );

    const textdataConfigs = filterConfigs(
      this.dynamicFormService.fieldList,
      config => config.type === ISPFieldType.TextData,
    ) as ISPFieldConfig<ISPFieldType.TextData>[];
    changes.textdata = textdataConfigs.reduce<Record<string, string>>(
      (textdataChange, config) => {
        const control = config.templateOptions.originalControl;
        const field = config.templateOptions.originalField;
        const name = control.$name;

        if (!setValuesDoc[name]) {
          // do not change textdata, if BE don't send any data about it
          return textdataChange;
        }

        const content = getTextdataContent(control, field, setValuesDoc);

        if (!textdataChange) {
          return {
            [name]: content,
          };
        } else {
          textdataChange[name] = content;
        }

        return textdataChange;
      },
      undefined,
    );

    changes.model = docModelKeys.reduce((model, key) => {
      const originalValue = originalModel[key] ?? '';
      const originalType = typeof originalValue;

      const newValue = DocHelper.getValue(key, setValuesDoc);
      if (newValue === null) {
        return model;
      }

      if (originalType === 'string' || originalType === 'number') {
        // case string -> array
        if (Array.isArray(newValue)) {
          if (!equals([originalValue], newValue)) {
            model[key] = newValue;
          }
        } else {
          // case string -> string
          model[key] = newValue;
        }

        const isReadOnlySet = this.isReadOnlySet(key, setValuesDoc);
        if (isReadOnlySet !== null) {
          if (isReadOnlySet) {
            changes.controlsToDisable.push(key);
          } else {
            changes.controlsToEnable.push(key);
          }
        }
      } else if (originalType === 'object') {
        if (Array.isArray(originalValue)) {
          // case array -> string/array
          const newValueArray = Array.isArray(newValue) ? newValue : [newValue];
          if (!equals(originalValue.sort(), newValueArray.sort())) {
            model[key] = newValueArray;
          }
        } else if (originalValue instanceof FileList) {
          model[key] = newValue;
        } else {
          // form with tabs
          const newChildModel = {};
          for (const childKey of Object.keys(originalValue)) {
            const childModelValue = originalValue[childKey];
            let newChildValue = DocHelper.getValue(childKey, setValuesDoc);

            if (newChildValue === null) {
              continue;
            }

            // case array -> string/array
            if (Array.isArray(childModelValue)) {
              if (!Array.isArray(newChildValue)) {
                newChildValue = [newChildValue];
              }
              if (
                !equals(
                  childModelValue.sort((a, b) =>
                    a > b ? 1 : a === b ? 0 : -1,
                  ),
                  newChildValue.sort((a, b) => (a > b ? 1 : a === b ? 0 : -1)),
                )
              ) {
                newChildModel[childKey] = newChildValue;
              }
            } else {
              // case string -> array
              if (Array.isArray(newChildValue)) {
                if (!equals([childModelValue], newChildValue)) {
                  newChildModel[childKey] = newChildValue;
                }
                // case string -> string
              } else {
                newChildModel[childKey] = newChildValue;
              }
            }
            const isReadOnlySet = this.isReadOnlySet(childKey, setValuesDoc);
            if (isReadOnlySet !== null) {
              if (isReadOnlySet) {
                changes.controlsToDisable.push(key);
              } else {
                changes.controlsToEnable.push(key);
              }
            }
          }
          model[key] = newChildModel;
        }
      }

      return model;
    }, {});

    return changes;
  }

  /**
   * Common logic for applying setValues response data to form
   *
   * @param changes - prepared setValues data
   */
  private applySetValuesChanges(changes: ISetValueChanges): void {
    // @WARN applys order is very important!
    this.applyDocUpdate(changes);

    this.applyListElements(changes);
    this.applyListReconfiguration(changes);

    this.applyModelChange(changes);
    this.applyFieldsDisabledChange(changes);
    this.applyButtonsChange(changes);

    this.applySlistChange(changes);
    this.applyMlistChange(changes);
    this.applyTextDataChange(changes);

    this.applySliderChange(changes);
  }

  /**
   * Gets new values to set in the form by `sv_field`
   *
   * @param fieldName - name of the field, that was changed
   * @param additionalParams - additional params for setValues request
   * @param sendFiles
   */
  private getSetValues(
    fieldName: string,
    additionalParams?: Params,
    sendFiles?: boolean,
  ): Observable<ISetValueChanges> {
    const modelWithDisabledFields =
      this.dynamicFormService.formGroup.getRawValue();
    const setValuesParams = this.prepareSetValuesParams(
      modelWithDisabledFields,
      additionalParams,
      sendFiles,
    );
    const setValuesRequest = this.requestSetValues(
      fieldName,
      setValuesParams,
    ).pipe(
      map(response =>
        this.getSetValuesChanges(
          fieldName,
          setValuesParams,
          response,
          modelWithDisabledFields,
        ),
      ),
      tap(changes => this.applySetValuesChanges(changes)),
      share(),
    );

    this.setValuesRequest.next({
      field: fieldName,
      result: setValuesRequest,
    });

    return setValuesRequest;
  }

  private prepareSetValuesParams(
    model: IFormModel,
    additionalParams?: Params,
    sendFiles?: boolean,
  ): IPreparedFormModel {
    const setValuesModel = prepareFormModel({
      ...model,
      ...additionalParams,
    });
    if (!sendFiles) {
      Object.entries(setValuesModel).forEach(([key, value]) => {
        if (value instanceof FileList) {
          delete setValuesModel[key];
        }
      });
    }
    return setValuesModel;
  }

  init(dynaicFormService: DynamicFormService): void {
    this.reinitIntervalSetValues$.next();
    this.dynamicFormService = dynaicFormService;
  }

  /**
   * Send 'sv_field' request
   *
   * @param fieldName - name of the field, that was changed
   * @param model - request params with form model
   */
  requestSetValues(
    fieldName: string,
    model: Params,
  ): Observable<ISetValueDocument> {
    const params = {
      field: fieldName,
      model,
      elid: DocHelper.elid(this.dynamicFormService.doc),
      plid: DocHelper.plid(this.dynamicFormService.doc),
      func: this.dynamicFormService.doc.$func,
    };
    if (hasFile(model)) {
      const tab = this.formService?.tab;
      return this.apiService.setValuesWithFiles(params).pipe(
        filter(res => {
          if (res.type === HttpEventType.UploadProgress) {
            if (tab) {
              const uploadProgress = Math.round((res.loaded / res.total) * 100);
              tab.setUploadFileProgressModal(
                uploadProgress,
                this.dynamicFormService.context.fileUploadMsg,
              );
            }

            return false;
          } else {
            if (tab) {
              tab.setUploadFileProgressModal(null);
            }

            return true;
          }
        }),
        map(res => (res as HttpResponse<{ doc: ISetValueDocument }>).body.doc),
      );
    } else {
      return this.apiService.setValues(params);
    }
  }

  checkAndStartIntervalSetValues(control: IControl): void {
    let time = Number(control.$setvalues);
    if (isNaN(time)) {
      return;
    }

    const second = 1000;
    time = time * second;
    interval(time)
      .pipe(
        switchMap(() => this.getSetValues(control.$name)),
        takeUntil(this.reinitIntervalSetValues$),
        untilDestroyed(this),
      )
      .subscribe();
  }

  handleSetValues(
    setValuesType: TSetValueType,
    fieldName: string,
    additionalParams?: Params,
    sendFiles?: boolean,
  ): Observable<ISetValueChanges | null> {
    if (!isNaN(Number(setValuesType)) || this.finalSetValues.value) {
      return of(null);
    }

    if (setValuesType === 'blocking') {
      this.blockedSetValues.next(true);
    }

    if (setValuesType === 'final') {
      this.finalSetValues.next(true);
    }

    return this.getSetValues(fieldName, additionalParams, sendFiles).pipe(
      tap(() => {
        this.blockedSetValues.next(false);
        this.finalSetValues.next(false);
      }),
    );
  }
}

<div class="code__settings-panel">
  <div class="code__settings-controls">
    <div class="code__setting code__setting_type_font-size">
      <span class="code__setting-label">{{ to.msg.fontSize }}</span>
      <ispui-select
        [name]="getInputName('fontsize')"
        [id]="getInputName('fontsize')"
        [(ngModel)]="fontSize"
        [options]="fontSizeOptions"
        [builder-params]="fontSizeSelectBuilderParams"
      >
      </ispui-select>
    </div>
    <div class="code__setting code__setting_type_language">
      <span class="code__setting-label">{{ to.msg.language }}</span>
      <ispui-select
        [name]="getInputName('language')"
        [id]="getInputName('language')"
        class="code__setting-control"
        [(ngModel)]="mode"
        [options]="modeOptionList"
        [builder-params]="modeSelectBuilderParams"
      >
      </ispui-select>
    </div>
    <div class="code__setting code__setting_type_theme">
      <span class="code__setting-label">{{ to.msg.theme }}</span>
      <ispui-select
        [name]="getInputName('theme')"
        [id]="getInputName('theme')"
        class="code__setting-control"
        [(ngModel)]="theme"
        [options]="themeOptionList"
        [builder-params]="themeSelectBuilderParams"
      >
      </ispui-select>
    </div>
    <div class="code__setting">
      <ispui-checkbox
        [name]="getInputName('linenumbers')"
        [id]="getInputName('linenumbers')"
        [(ngModel)]="canShowLineNumbers"
        returnType="boolean"
      >
        {{ to.msg.lineNumbers }}
      </ispui-checkbox>
    </div>
    <div class="code__setting code__setting_type_word-wrap">
      <span class="code__setting-label">{{ to.msg.wordWrap }}</span>
      <ispui-select
        [name]="getInputName('wordwrap')"
        [id]="getInputName('wordwrap')"
        class="code__setting-control"
        [(ngModel)]="wrap"
        [options]="wrapOptionList"
        [builder-params]="wrapSelectBuilderParams"
      >
      </ispui-select>
    </div>
  </div>
</div>
<div class="code__expand" [class.code__expand_is_fullscreen]="isFullScreen">
  <ispui-tooltip [title-text]="fullscreenButtonTitle">
    <span
      role="button"
      (click)="toggleFullscreen()"
      class="code__expand-button"
      [tabIndex]="0"
      [attr.aria-label]="fullscreenButtonTitle"
    >
      <isp-svg-icon
        [name]="fullscreenButtonIcon"
        class="code__expand-button-icon"
      ></isp-svg-icon>
    </span>
  </ispui-tooltip>
</div>
<div
  [ispResizableBlock]="!isFullScreen"
  (resizeEnd)="resetEditor()"
  class="code__editor-wrap"
  [class.code__editor-wrap_is_fullscreen]="isFullScreen"
>
  <ace-editor
    class="code__editor"
    [options]="aceOptions"
    [formControl]="formControl"
    [formlyAttributes]="field"
    (keyup.enter)="preventEnterClickBubbling($event)"
  ></ace-editor>
</div>

import { Injectable } from '@angular/core';

import {
  IListElement,
  IListOptionList,
} from 'app/services/api5-service/api.interface';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import { IListMap } from '../dynamic-form.interface';

/**
 * Service for list field
 */
@Injectable()
export class ListFieldService {
  private readonly listMapSubject: BehaviorSubject<IListMap> =
    new BehaviorSubject({});

  private readonly filterSubject: BehaviorSubject<
    Record<
      string,
      {
        targetProperty: string;
        tags: string[];
      }
    >
  > = new BehaviorSubject({});

  setFilter(name: string, propertyName: string, tags: string[]): void {
    this.filterSubject.next({
      ...this.filterSubject.value,
      [name]: {
        targetProperty: propertyName,
        tags,
      },
    });
  }

  resetFilter(): void {
    this.filterSubject.next({});
  }

  getListElems$(name: string): Observable<IListElement[]> {
    return combineLatest([this.listMapSubject, this.filterSubject]).pipe(
      map(([listMap, filter]) => {
        const elems = listMap[name];

        const filterForList = filter[name];
        const isFilterFormListEmpty = !filterForList?.tags.length;

        if (isFilterFormListEmpty) {
          return elems;
        }

        return elems.filter(elem => {
          if (!(filterForList.targetProperty in elem)) {
            // if elem have no target property then... let's hope it will have this property
            return false;
          }

          const elemTargetCell = elem[filterForList.targetProperty];
          if (!('tag' in elemTargetCell)) {
            // elem have no tags, then it will be filtered;
            return false;
          }

          const elemTags = Array.isArray(elemTargetCell.tag)
            ? elemTargetCell.tag
            : [elemTargetCell.tag];
          const filterTags = filterForList.tags;

          return filterTags.every(tag => elemTags.some(t => t.$ === tag));
        });
      }),
    );
  }

  getListElems(name: string): IListElement[] | undefined {
    return this.listMapSubject.value[name];
  }

  addElements(list?: IListOptionList[]): void {
    if (!list) {
      return;
    }

    this.listMapSubject.next({
      ...this.listMapSubject.value,
      ...DocHelper.reduceFormList(list),
    });
  }

  clear(): void {
    this.listMapSubject.next({});
    this.resetFilter();
  }
}

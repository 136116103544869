import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { SliderFieldComponent } from './slider.component';

import { FormSliderModule } from '../../../../components/form-components/slider/slider.module';
import { ISPFieldType } from '../../model';

/**
 * Formly's slider field component
 */
@NgModule({
  declarations: [SliderFieldComponent],
  imports: [
    CommonModule,
    FormSliderModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Slider,
          component: SliderFieldComponent,
        },
      ],
    }),
  ],
})
export class SliderFieldModule {}

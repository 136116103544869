import { IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

/**
 * Get hint msg
 *
 * @param code - hint msg code
 * @param doc - doc instance
 * @param showHints - is button hint should be displayed
 * @param isDisabled - is hint for disabled field
 */
export function getHint(
  code: string,
  doc: IDocument,
  showHints: boolean,
  isDisabled = false,
): string | undefined {
  const suffix = isDisabled ? '_disabled' : '';
  return showHints
    ? DocHelper.getMessage(`hint_${code}${suffix}`, doc)
    : undefined;
}

import { IDocument, ITextarea } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface TextAreaControlWithValue {
  readonly control: ITextarea;
  readonly type: 'textarea';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForTextarea(
  control: ITextarea,
  doc: IDocument,
): TextAreaControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'textarea',
  };
}

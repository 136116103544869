import { IField, IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

/**
 * Get local message for placeholder
 *
 * @param field - field metadata
 * @param doc - doc instance
 */
export function getInputPlaceholder(field: IField, doc: IDocument): string {
  return DocHelper.getMessage(`placeholder_${field.$name}`, doc);
}

<ispui-select
  (focus)="toggleFocus(true)"
  (blur)="toggleFocus(false)"
  (internalAction)="checkDropdownOpen($event)"
  [builder-params]="selectBuilderParams$ | async"
  [options]="optionsUI"
  [formControl]="formControl"
  [multiple]="to.multiple"
  [formlyAttributes]="field"
  [required]="to.required"
  [id]="id"
  (ngModelChange)="onModelChange($event)"
  [class]="selectedColor | colorMod: 'select'"
  [ngClass]="{
    select_is_mixed: to.isMixed,
    'select-button_color_transparent': hideTextInSelectButton
  }"
  [style.--isp-select-placeholder]="getMixedPlaceholder()"
></ispui-select>

import { Directive, OnDestroy } from '@angular/core';
import { Params } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import { ActionService } from '../action.service';
import {
  IDocument,
  IMessageSet,
  IToolbar,
} from '../api5-service/api.interface';
import { Tab } from '../tab/tab.class';
import { ITab } from '../tab/tab.interface';
import { TabService } from '../tab/tab.service';

/**
 * Base function type service class.
 *
 * Contains tab$ and doc$ streams.
 *
 * Usage:
 * 1. Create the type service that extends this class
 * 2. Call new class's `init()` method in the func type's component
 */
@Directive()
export class BaseFuncTypeService implements OnDestroy {
  /** tab subject */
  private readonly tabSubject = new BehaviorSubject<Tab>(null);

  /** current tab stream */
  readonly tab$ = this.tabSubject.pipe(filter(tab => tab !== null));

  /** current page's doc stream */
  readonly doc$ = this.tab$.pipe(
    switchMap(tab => tab.doc$),
    filter(doc => doc !== null),
  );

  /** current tab */
  get tab(): Tab {
    return this.tabSubject.value;
  }

  get doc(): IDocument {
    return this.tab.doc;
  }

  /** current tab's title */
  readonly title$ = this.tab$.pipe(map(tab => tab.title));

  constructor(
    protected tabService: TabService,
    protected actionService: ActionService,
  ) {}

  /**
   * Lifecycle tab hook, for detect loaded tab data
   * DO NOT DELETE!
   *
   * @param _
   */
  protected loadTab(_: Tab): void {}

  /**
   * Runs the code before the service initialization
   */
  protected preInit(): void {}

  ngOnDestroy(): void {
    this.tabSubject.complete();
  }

  /**
   * Service initialization. Gets the `tab` and its `doc` from `tabService`
   * and navigates to the page by current route
   *
   * @param queryParams - query parameters
   * @param routeParams - route parameters
   */
  init(queryParams: Params, routeParams: Params): Observable<IDocument> {
    this.preInit();
    const tab = this.tabService.getTabForRenderById(routeParams.tabId);
    const tab$ =
      tab?.func === routeParams.func
        ? of(tab)
        : this.tabService.getTabFromRoute(routeParams, queryParams);
    return tab$.pipe(
      tap(t => {
        this.tabSubject.next(t);
      }),
      tap(t => this.loadTab(t)),
      switchMap((t: Tab) => t.doc$),
    );
  }

  /**
   * Returns the message list
   *
   * @param tab - tab instance
   */
  getMessageSet(tab: ITab): IMessageSet {
    return DocHelper.getMessageSet(tab.doc);
  }

  /**
   * Get toolbar data
   *
   * @param tab
   */
  getToolbar(tab: ITab): IToolbar {
    return DocHelper.getToolbar(tab);
  }

  /**
   * Get message by code
   *
   * @param code - the code
   */
  getMessage(code: string): string {
    return DocHelper.getMessage(code, this.tab.doc);
  }

  /**
   * Reload tab data
   */
  reloadTab(): void {
    this.actionService.updateTab(this.tab?.func, this.tab?.q, this.tab);
  }
}

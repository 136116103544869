import { IFormModel } from '../dynamic-form.interface';

/**
 * Remove property list from deep object
 *
 * @param model - some object
 * @param keyList - keys to remove
 */
export function removePropertyList(
  model: IFormModel,
  keyList: string[],
): IFormModel {
  const newModel = {};
  if (keyList.length === 0) {
    return model;
  }
  // check on group in form
  if (typeof model === 'object') {
    Object.keys(model).forEach(key => {
      const value = model[key];

      // check on file, multiselect value
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        !(value?.[0] instanceof Blob)
      ) {
        newModel[key] = removePropertyList(value as any, keyList);
      } else {
        if (!keyList.includes(key)) {
          newModel[key] = model[key];
        }
      }
    });
  }
  return newModel;
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Get color class by color attribute
 */
@Pipe({ name: 'iconSimular' })
export class IconSimularPipe implements PipeTransform {
  /** simular name */
  private readonly simularMap = {
    't-sendmsg': 't-sender',
    'm-www': 'm-webserver',
    'p-webserver': 'm-webserver',
    'p-www': 'm-webserver',
    'm-stat': 'm-marketing',
    'm-account': 'lv-16',
    'm-customer': 'lv-16',
    'p-user': 'lv-16',
    'p-bill-webdomainlimit': 'p-bill-domainlimit',
    'p-shell': 'lv-4',
    'p-resell': 'lv-24',
    'p-popular': 'mbar-popular',
    'p-admin': 'lv-29',
    'p-root': 'lv-30',
    'p-ftp': 'lv-3',
    'p-bpftp': 'lv-3',
    'm-productnode': 'm-maintain',
    'p-email': 'lv-9',
    'p-file-2': 'p-bptgz',
    'p-file-3': 'p-bptbz2',
    'p-file-4': 'p-bpzip',
    'p-file-1': 'p-bptar',
    'p-file-6': 'p-bprar',
    'p-lock': 'p-file-300',
    'p-file-0': 'p-bplocal',
    'm-clustersettings': 'm-clustersettings',
    't-go': 'p-go',
    'm-equipment': 't-servers',
    't-invoice': 't-contract',
    'm-mainmenuaccount': 'lv-16',
    'm-mainmenuservice': 'm-service',
    'm-mainmenutool': 'm-tool',
    'm-directory': 'm-gdpr',
    'm-backup2-list': 'm-backup2-superlist',
    'm-monitoring_and_journal': 't-mon',
    'm-helpboard': 'm-mgrhelp',
    'p-kvm': 'p-ipmi',
    'p-lt2': 'p-additional',
    // for sites when has some problem in list
    'm-webdomain-notloaded': 'm-webdomain',
    'm-drweb': 'm-revisium_antivirus_func',
    'm-antivirus': 'm-revisium_antivirus_func',
  };

  /**
   * Transform icon name to it's similar name
   *
   * @param name - icon name
   */
  transform(name: string): string {
    const newName = this.simularMap[name];
    if (!newName) {
      return name;
    }
    return newName;
  }
}

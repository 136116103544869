/**
 * Form button's type
 */
export enum FormButtonType {
  /** submits the form, sets `sok` parameter to `ok` */
  Ok = 'ok',
  /** closes the form */
  Cancel = 'cancel',
  /** submits the form, sets `sback` parameter to `ok` and `sok` - to `ok` */
  Back = 'back',
  /** submits the form, sets `sok` parameter to `ok` */
  Next = 'next',
  /** submits the form *in a new tab*, sets `sok` parameter to `ok` */
  Blank = 'blank',
  /** submits the form as `setvalues` and set the name to the button with `sv_field` parameter */
  SetValues = 'setvalues',
  /** opens the child list or form, function's name is specified in `$func` attribute */
  Func = 'func',
  /** resets the form to the default values */
  Reset = 'reset',
  /** undocumented button type */
  Submit = 'submit',
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Optional,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormService } from 'app/form/form.service';
import { PasswordStrength } from 'app/services/api5-service/api.interface';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { HTMLIspuiPasswordElement } from '@ispui/input/dist/';

import { DocHelper } from 'utils/dochelper';

import { getPasswordStrength } from './password.utils';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';
import { setAutofocus } from '../input/input.utils';

/**
 * `@type=password` password field component
 *
 * Use only with Formly
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-password-field',
  templateUrl: './password.component.html',
  styleUrls: ['./scss/password.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Password>
  implements AfterViewInit
{
  /** password field link */
  @ViewChild('passwordField')
  passwordField: ElementRef<HTMLIspuiPasswordElement>;

  /** password strength msg */
  passwordStrengthMsg?: string;

  /** password strength */
  passwordStrength?: PasswordStrength;

  /**
   * Get state for generate button
   */
  get isShowGenerate(): boolean {
    return Boolean(
      this.to.originalControl.$checkpasswd ||
        this.to.originalControl.$genpasswd === 'yes',
    );
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional() private readonly formService?: FormService,
  ) {
    super();
  }

  private updatePasswordStrength(value: string): void {
    if (value) {
      this.passwordStrength = getPasswordStrength(value);
      this.passwordStrengthMsg = DocHelper.getPasswordStrengthMessage(
        this.passwordStrength,
        this.formState.doc,
      );
    } else {
      delete this.passwordStrengthMsg;
      delete this.passwordStrength;
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    if (this.to.autofocus) {
      setAutofocus(
        this.passwordField.nativeElement,
        this.field,
        this.formState,
        this.formService,
      )
        .pipe(untilDestroyed(this))
        .subscribe();
    }

    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.updatePasswordStrength(value);
      });

    // small time debounde in order to input element update it's value property after keydown event (Firefox case!)
    fromEvent(this.passwordField.nativeElement, 'keydown')
      .pipe(debounceTime(10), untilDestroyed(this))
      .subscribe((event: InputEvent) => {
        this.updatePasswordStrength((event.target as HTMLInputElement).value);
      });
  }

  /**
   * Handler for generate password button click
   */
  generatePassword(): void {
    const password = this.passwordField.nativeElement;
    password.animatePasswordGeneration();
  }

  /**
   * Generate password handler
   *
   * @param event - custom event, contains generated password
   */
  handlePasswordGenerate(event: CustomEvent): void {
    this.formControl.setValue(event.detail.value);
    const confirmName = this.field.templateOptions.originalControl.$checkpasswd;
    if (confirmName && this.form.controls[confirmName]) {
      this.form.patchValue({ [confirmName]: event.detail.value });
    }
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }
}

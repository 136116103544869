import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';

import { LayoutService } from 'common/dynamic-form/services/layout.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Form scroller formly component
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-form-scroller',
  templateUrl: './form-scroller.component.html',
  styleUrls: ['./scss/form-scroller.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormScrollerFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.FormScroller>
  implements OnInit
{
  private readonly SCROLL_BUTTONS_OFFSET = 150;

  /** whether chat can show scroll to bottom button */
  canShowScrollToBottomButton = false;

  constructor(
    private readonly layoutService: LayoutService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutService.formContainer$
      .pipe(
        filter(container => Boolean(container)),
        switchMap(container =>
          // startWith to trigger initial calculation
          fromEvent(container, 'scroll').pipe(startWith(null)),
        ),
        untilDestroyed(this),
      )
      .subscribe(() => {
        const el = this.layoutService.formContainer;
        this.canShowScrollToBottomButton =
          el.scrollHeight - (el.clientHeight + el.scrollTop) >
          this.SCROLL_BUTTONS_OFFSET;
        this.cdr.markForCheck();
      });
  }

  handleBottomButtonClick(): void {
    this.layoutService.scrollTo(this.to.bottomScrollToSelector);
  }
}

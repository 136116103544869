import { LayoutConfig } from './model/layout-config.interface';
import { LayoutTO } from './model/layout-to.interface';

import { ISPFieldConfig, ISPFieldType } from '../../model';
import { findConfig } from '../../utils/formly-configs';
import { getGroupConfig } from '../group/group.utils';

/**
 * All fields in layout automatically wraps into group for right indenting. But it could be manually turned off if 'layoutDisplayInGroup' equals 'false'
 *
 * @param configs - configs to wrapp into group
 */
function wrappLayoutFieldsIntoGroup(
  configs: ISPFieldConfig[],
): ISPFieldConfig[] {
  const configsToWrapp: ISPFieldConfig[] = [];
  const configsToStayUnwrapped: ISPFieldConfig[] = [];

  configs.forEach(config => {
    if (config.templateOptions.layoutDisplayInGroup === false) {
      configsToStayUnwrapped.push(config);
    } else {
      configsToWrapp.push(config);
    }
  });

  return [
    configsToWrapp.length ? getGroupConfig(configsToWrapp) : null,
    ...configsToStayUnwrapped,
  ].filter(Boolean);
}

/**
 * Get config for layout field
 *
 * @param configs - form fields
 */
export function getLayoutConfig(configs: ISPFieldConfig[]): LayoutConfig {
  const fields = configs.reduce(
    (places, config) => {
      let place: LayoutTO['layoutPlace'] = config.templateOptions?.layoutPlace;
      if (!place && config.fieldGroup) {
        const fieldWithPlace = findConfig(config.fieldGroup, c =>
          Boolean(c.templateOptions.layoutPlace),
        );
        place = fieldWithPlace?.templateOptions?.layoutPlace;
      }

      switch (place) {
        case 'header':
          places.header.push(config);
          break;
        case 'footer':
          places.footer.push(config);
          break;
        case 'summary':
          places.summary.push(config);
          break;
        case 'body':
        default:
          places.body.push(config);
          break;
      }
      return places;
    },
    {
      header: [],
      body: [],
      footer: [],
      summary: [],
    } as Record<LayoutTO['layoutPlace'], ISPFieldConfig[]>,
  );

  const templateOptions: LayoutTO = {
    headerFields: wrappLayoutFieldsIntoGroup(fields.header),
    bodyFields: wrappLayoutFieldsIntoGroup(fields.body),
    footerFields: wrappLayoutFieldsIntoGroup(fields.footer),
    summaryFields: wrappLayoutFieldsIntoGroup(fields.summary),
  };

  const fieldGroup = [
    ...templateOptions.headerFields,
    ...templateOptions.bodyFields,
    ...templateOptions.footerFields,
    ...templateOptions.summaryFields,
  ];

  return {
    type: ISPFieldType.Layout,
    templateOptions,
    expressionProperties: {
      'templateOptions.isHeaderVisible': (_, __, fieldConfig) =>
        fieldConfig.templateOptions.headerFields.some(
          field => !field.templateOptions.isHidden,
        ),
      'templateOptions.isFooterVisible': (_, __, fieldConfig) =>
        fieldConfig.templateOptions.footerFields.some(
          field => !field.templateOptions.isHidden,
        ),
      'templateOptions.isSummaryVisible': (_, __, fieldConfig) =>
        fieldConfig.templateOptions.summaryFields.some(
          field => !field.templateOptions.isHidden,
        ),
    },
    fieldGroup,
  };
}

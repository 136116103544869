import { IDocument, IField } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

/**
 * Get desc msg for field
 *
 * @param field - field metadata
 * @param doc - doc instance
 */
export function getDesc(field: IField, doc: IDocument): string | null {
  if (field.desc) {
    const descList = field.desc.map(item =>
      DocHelper.getMessage(item.$name, doc),
    );
    return descList.join(' ');
  }
  return null;
}

import { TypedSummary } from 'common/dynamic-form/utils/form-preparing/get-typed-summary';

import { SiteSummaryTO } from './model/site-summary-to.interface';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';
/**
 * Get config for site summary field
 *
 * @param summary - summary pages metadata
 * @param state
 */
export function getSiteSummaryConfig(
  summary: TypedSummary,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.SiteSummary> {
  const templateOptions: SiteSummaryTO = {
    summary,
    layoutPlace: 'summary',
    isHidden: state.modeService.mode === 'base',
  };

  return {
    type: ISPFieldType.SiteSummary,
    templateOptions,
    expressionProperties: {
      'templateOptions.isHidden': (_, formState) =>
        formState.modeService.mode === 'base',
    },
  };
}

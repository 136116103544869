import {
  IDocument,
  IField,
  IHtmlData,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface HtmlDataControlWithValue {
  readonly control: IHtmlData;
  readonly type: 'htmldata';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForHtmlData(
  field: IField,
  doc: IDocument,
): HtmlDataControlWithValue[] {
  return (field.htmldata ? [field.htmldata[0]] : []).map(control => {
    return {
      value: DocHelper.getStringValue(control.$name, doc),
      control,
      type: 'htmldata',
    };
  });
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FullHeightDirective } from './fullheight.directive';

/**
 * Full height directive, for set height to end of viewport
 */
@NgModule({
  imports: [CommonModule],
  declarations: [FullHeightDirective],
  providers: [],
  exports: [FullHeightDirective],
})
export class FullHeightModule {}

import {
  ITextarea,
  IField,
  IMacrosOption,
} from 'app/services/api5-service/api.interface';
import { Editor } from 'tinymce';

import {
  ISPFormState,
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
} from 'common/dynamic-form/model';
import { isFullWidth } from 'common/dynamic-form/utils/is-full-width';
import { DocHelper } from 'utils/dochelper';

import { ITextEditorMacrosOption } from './model/texteditor-macros-option.interface';
import { ITinyMCEMacrosPluginInitOptions } from './model/texteditor-tinymce-init-options.interface';
import { TextEditorTO } from './model/texteditor-to.interface';

const DEFAULT_ROWS_COUNT = 10;
const BASE_DRAGON_THEME_PATH = '/manimg/dragon/';

/**
 * Get preparing option list with macro data for ispmacros plugin
 *
 * @param macrosList - macro data list
 */
export function prepareMacrosOptions(
  macrosList?: IMacrosOption[],
): ITextEditorMacrosOption[] {
  if (!macrosList) {
    return [];
  }

  return macrosList.map(macros => ({ value: macros.$key, text: macros.$ }));
}

/**
 * Get language code to display tinyMCE editor
 *
 * @WARN e.polyakov added for now the display of only Russian and English
 * (by default for all other localizations), the rest will be added as needed
 * @param userLang - user language in app
 */
function getLangForTinyMCE(userLang: string): string {
  return userLang === 'ru' ? userLang : 'en';
}

/**
 * Get options for tinyMCE editor initialization
 *
 * @param macrosOptions - macros data options
 * @param formState - state of dynamic form
 * @param textAreaRows - number of displayed rows for textarea
 * @param placeholder
 */
export function getInitOptionsForTinyMCE(
  macrosOptions: ITextEditorMacrosOption[],
  formState: ISPFormState,
  textAreaRows: number,
  placeholder: string,
): ITinyMCEMacrosPluginInitOptions {
  const baseUrlTinyMCE = `${BASE_DRAGON_THEME_PATH}tinymce`;
  // defined language tinyMCE settings
  const tinyMCELang = getLangForTinyMCE(formState.doc.$lang);
  const langUrl = `${BASE_DRAGON_THEME_PATH}assets/langs/tinymce/${tinyMCELang}.js`;
  const tinyMCEOptions: ITinyMCEMacrosPluginInitOptions = {
    base_url: baseUrlTinyMCE,
    suffix: '.min',
    resize: false,
    relative_urls: false,
    convert_urls: false,
    branding: false,
    placeholder,
    toolbar_mode: 'wrap',
    language: tinyMCELang,
    language_url: langUrl,
    menu: {
      // 'preview' excluded as in orion
      file: {
        title: 'File',
        items:
          'newdocument restoredraft | export print | deleteallconversations',
      },
    },
  };

  // defined plugins, toolbar, menu and plugin variables tinyMCE settings
  tinyMCEOptions.plugins =
    'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table';
  tinyMCEOptions.toolbar =
    'insertfile undo redo pastetext | fontfamily fontsize styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image';

  const ispMacrosFilled = macrosOptions.length > 0;
  if (ispMacrosFilled) {
    tinyMCEOptions.plugins += ' ispmacros';
    tinyMCEOptions.toolbar += ' | ispmacros';

    const ispMacrosPluginUrl = `${BASE_DRAGON_THEME_PATH}assets/scripts/tinymce/plugins/ispmacros/plugin.min.js`;

    tinyMCEOptions.external_plugins = { ispmacros: ispMacrosPluginUrl };
    tinyMCEOptions.ispmacros_options = macrosOptions;
    // add the macros plugin to the Insert menu
    tinyMCEOptions.menu.insert = {
      title: 'Insert',
      items:
        'image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime  | ispmacros',
    };
  }

  tinyMCEOptions.setup = (editor: Editor) => {
    editor.on('ScriptsLoaded', event => {
      const targetElm = event.target.targetElm;
      const textArea = targetElm.tagName.toLowerCase() === 'textarea';
      // Because a text editor cannot specify the height of the number of rows to display,
      // specify the number of lines for textarea that the editor is based on.
      // But before that, it needs to specify the font size and line height.
      if (textArea) {
        targetElm.style['font-size'] = '14px';
        targetElm.style['line-height'] = '18px';
        targetElm.rows = textAreaRows;
      }
    });
  };

  return tinyMCEOptions;
}

/**
 * Get config for textarea field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getTextEditorConfig(
  control: ITextarea,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.TextEditor> {
  const mixedPlaceholder = DocHelper.getMessage(
    'placeholder_mixed_msg',
    state.doc,
  );
  const rows = parseInt(control.$rows, 10) || DEFAULT_ROWS_COUNT;
  const macrosOptions = prepareMacrosOptions(state.doc.olist?.val);

  const placeholder = state.mixedService.isControlMixed(control)
    ? mixedPlaceholder
    : '';

  const templateOptions: TextEditorTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    setValuesMinLength: Number(control.$svminlength),
    isFullWidth: isFullWidth(field),
    tinymceOptions: getInitOptionsForTinyMCE(
      macrosOptions,
      state,
      rows,
      placeholder,
    ),
  };

  const config: ISPFieldConfig<ISPFieldType.TextEditor> = {
    key: control.$name,
    type: ISPFieldType.TextEditor,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
    },
  };

  return config;
}

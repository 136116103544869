<div class="layout" [style.--isp-layout-header-height]="headerHeight$ | async">
  <div class="form ispui-scrollbar_gray" [id]="formContainerId" #form>
    <div
      *ngIf="to.isHeaderVisible"
      [id]="headerId"
      class="form__header"
      #header
    >
      <formly-field
        *ngFor="let field of to.headerFields"
        [field]="field"
      ></formly-field>
    </div>
    <div class="form__content">
      <formly-field
        *ngFor="let field of to.bodyFields"
        [field]="field"
      ></formly-field>
    </div>
    <div *ngIf="to.isFooterVisible" class="form__footer" #footer>
      <formly-field
        *ngFor="let field of to.footerFields"
        [field]="field"
      ></formly-field>
    </div>
  </div>
  <div *ngIf="to.isSummaryVisible" class="summary ispui-scrollbar_gray">
    <formly-field
      *ngFor="let field of to.summaryFields"
      [field]="field"
    ></formly-field>
  </div>
</div>

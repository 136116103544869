import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SideBarService } from 'app/layout/side-bar/side-bar.service';
import { Api5Service } from 'app/services/api5-service/api5.service';
import { timer } from 'rxjs';

import { Components, DropdownAlign, DropdownDirection } from '@ispui/dropdown';

import { SessionTipService } from './session-tip.service';

import { IDocument } from '../../app/services/api5-service/api.interface';

/**
 * Tooltip with a hint from server
 */
@UntilDestroy()
@Component({
  selector: 'isp-session-tip',
  templateUrl: './session-tip.component.html',
  styleUrls: ['./scss/session-tip.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionTipComponent
  implements OnDestroy, AfterContentInit, OnChanges
{
  private readonly DELAY = 1000;

  @Input() name: string;

  @Input() attachSelector: string;

  @Input() doc: IDocument;

  @Input() direction: DropdownDirection = 'bottom';

  @Input() alignment: DropdownAlign = 'auto';

  @Input() zIndex = 120;

  @ViewChild('dropdown') dropdown: ElementRef<Components.IspuiDropdown>;

  constructor(
    private readonly api5Service: Api5Service,
    private readonly sessionTipService: SessionTipService,
    private readonly sidebarService: SideBarService,
    private readonly elementRef: ElementRef,
  ) {}

  get message(): string {
    if (this.doc?.tips?.tip?.$ === this.name) {
      return this.doc?.messages?.msg[`tip_${this.name}`];
    } else {
      return null;
    }
  }

  sendSeenRequest(): void {
    this.api5Service
      .tip(this.name)
      .subscribe(() => (this.sessionTipService.currentTip = null));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doc) {
      if (this.doc?.tips?.tip?.$ !== this.name) {
        this.dropdown?.nativeElement.closeDropdown();
      }
    }
  }

  ngAfterContentInit(): void {
    timer(this.DELAY)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.message && !this.sessionTipService.currentTip) {
          this.dropdown.nativeElement.showDropdown();
          this.sessionTipService.currentTip = this.name;
        }
      });
    this.sidebarService.menuExpanded$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const dropdown: any = this.dropdown?.nativeElement;
        if (!dropdown) {
          return;
        }
        const isVisible = dropdown.isVisible;
        dropdown.closeDropdown();
        if (isVisible) {
          setTimeout(() => {
            if ((this.elementRef.nativeElement as HTMLElement).parentElement) {
              this.dropdown.nativeElement.showDropdown();
            }
          }, 500);
        }
      });
  }

  ngOnDestroy(): void {
    if (this.sessionTipService.currentTip === this.name) {
      this.sessionTipService.currentTip = null;
      this.dropdown.nativeElement.showDropdown();
    }
  }
}

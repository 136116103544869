import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';
import { ChatModule } from 'components/chat/chat.module';

import { ChatFieldComponent } from './chat.component';

import { ISPFieldType } from '../../model';

/**
 * Ticket's chat formly field component module
 */
@NgModule({
  declarations: [ChatFieldComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [{ name: ISPFieldType.Chat, component: ChatFieldComponent }],
    }),
    ChatModule,
  ],
})
export class ChatFieldModule {}

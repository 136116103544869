export type PositioningSide = 'bottom' | 'left' | 'right' | 'top';

export interface PositioningOptions {
  popup: DOMRect | Element | string;
  anchor: DOMRect | Element | string;
  container?: DOMRect | Element | string;
  containerOffsets?: Record<PositioningSide, number>;
  popupMargin?: number;
  popupTriangleWidth?: number;
  popupTriangleLength?: number;
  sidePriority?: PositioningSide[];
}

export interface PositioningResult {
  side: PositioningSide;
  offset: string;
  slide: string;
}

/**
 * Get DOMRect with offsets
 *
 * @param rect - DOMRect
 * @param offsets - offsets
 */
function getRectWithOffsets(
  rect: DOMRect,
  offsets: Record<PositioningSide, number>,
): DOMRect {
  return {
    ...rect,
    x: rect.x + offsets.left,
    y: rect.y + offsets.top,
    left: rect.left + offsets.left,
    right: rect.right - offsets.right,
    top: rect.top + offsets.top,
    bottom: rect.bottom - offsets.bottom,
    width: rect.width - (offsets.left + offsets.right),
    height: rect.height - (offsets.bottom + offsets.top),
  };
}

/**
 * Get element DOMRect, but with scroll sizes adjasted
 *
 * @param element - DOM element
 */
function getRectWithScrollSizes(element: Element): DOMRect {
  const rect = element.getBoundingClientRect();
  if (element.scrollHeight > rect.height || element.scrollWidth > rect.width) {
    return {
      ...rect,
      height: element.scrollHeight,
      width: element.scrollWidth,
      bottom: rect.top + element.scrollHeight,
      right: rect.left + element.scrollWidth,
    };
  }
  return rect;
}

/**
 * Auxiliary tool, for resolving position element option to DOMRect
 *
 * @param element - some element option
 * @param adjastScrollSizes
 */
function resolveElement(
  element: DOMRect | Element | string,
  adjastScrollSizes?: boolean,
): DOMRect {
  if (typeof element === 'string') {
    if (adjastScrollSizes) {
      return getRectWithScrollSizes(document.querySelector(element));
    } else {
      return document.querySelector(element).getBoundingClientRect();
    }
  }

  if (element instanceof Element) {
    return adjastScrollSizes
      ? getRectWithScrollSizes(element)
      : element.getBoundingClientRect();
  }

  return element;
}

/**
 * Calculate position for popup relative to anchor
 *
 * @param options - positioning options
 */
export function calculatePosition(
  options: PositioningOptions,
): PositioningResult {
  const {
    containerOffsets = {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    popupMargin = 0,
    popupTriangleWidth = 15,
    popupTriangleLength = 10,
    sidePriority = ['right', 'top', 'bottom'],
  } = options;
  const anchor = resolveElement(options.anchor);
  const popup = resolveElement(options.popup);
  const container = getRectWithOffsets(
    resolveElement(options.container || document.body, true),
    containerOffsets,
  );

  // distance from anchor block borders to container borders
  const dAnchorContainer = {
    left: anchor.left - container.left,
    right: container.right - anchor.right,
    top: anchor.top - container.top,
    bottom: container.bottom - anchor.bottom,
  };

  // distance between popup borders and container borders, if popup will be positioning on provided side relative to anchor
  const dPopupContainer = {
    left:
      dAnchorContainer.left - (popup.width + popupMargin + popupTriangleLength),
    right:
      dAnchorContainer.right -
      (popup.width + popupMargin + popupTriangleLength),
    top:
      dAnchorContainer.top - (popup.height + popupMargin + popupTriangleLength),
    bottom:
      dAnchorContainer.bottom -
      (popup.height + popupMargin + popupTriangleLength),
  };

  // distance between popup borders and container borders, if popup will be positioning on the orthogonal side relative to anchor
  // orthogonal left or right mean if popup will be positioned on top or bottom relative to anchor
  // orthogonal top or down mean if popup will be positioned on left or right relative to anchor
  const dPopupContainerOrtho = {
    left:
      dAnchorContainer.left +
      anchor.width -
      popup.width +
      (popup.width - anchor.width) / 2,
    right:
      dAnchorContainer.right +
      anchor.width -
      popup.width +
      (popup.width - anchor.width) / 2,
    top:
      dAnchorContainer.top +
      anchor.height -
      popup.height +
      (popup.height - anchor.height) / 2,
    bottom:
      dAnchorContainer.bottom +
      anchor.height -
      popup.height +
      (popup.height - anchor.height) / 2,
  };

  // required slide for popup
  // slide is a shift in orthogonal direction, in order to not cross container borders
  // slide measured in numbers and can be negative
  // vertical negative - popup should be slide to up direction
  // vertical positive - popup should be slide to down direction
  // horizontal negative - popup should be slide to left direction
  // horizontal positive - popup should be slide to right direction
  const needToSlide = {
    vertical: (() => {
      if (container.height < popup.height) {
        return null;
      }

      if (dPopupContainerOrtho.top < 0) {
        return -dPopupContainerOrtho.top;
      }

      if (dPopupContainerOrtho.bottom < 0) {
        return dPopupContainerOrtho.bottom;
      }

      return 0;
    })(),
    horizontal: (() => {
      if (container.width < popup.width) {
        return null;
      }

      if (dPopupContainerOrtho.left < 0) {
        return -dPopupContainerOrtho.left;
      }

      if (dPopupContainerOrtho.right < 0) {
        return dPopupContainerOrtho.right;
      }

      return 0;
    })(),
  };

  // max slide value in vertical and horizontal directions
  const maxSlide = {
    vertical: (anchor.height - popupTriangleWidth) / 2,
    horizontal: (anchor.width - popupTriangleWidth) / 2,
  };

  // is popup can be freely slided in horizontal or verical directions
  const canBeSlided = {
    vertical: (() => {
      if (needToSlide.vertical === null) {
        return false;
      }

      if (Math.abs(needToSlide.vertical) > maxSlide.vertical) {
        return false;
      }

      return true;
    })(),
    horizontal: (() => {
      if (needToSlide.horizontal === null) {
        return false;
      }

      if (Math.abs(needToSlide.horizontal) > maxSlide.horizontal) {
        return false;
      }

      return true;
    })(),
  };

  const idealPosition = sidePriority.find(position => {
    switch (position) {
      case 'left':
      case 'right':
        if (dPopupContainer[position] > 0 && canBeSlided.vertical) {
          return true;
        }
        break;
      case 'top':
      case 'bottom':
        if (dPopupContainer[position] > 0 && canBeSlided.horizontal) {
          return true;
        }
        break;
    }
    return false;
  });

  if (idealPosition) {
    return {
      side: idealPosition,
      offset: `${popupTriangleLength + popupMargin}px`,
      slide: (() => {
        switch (idealPosition) {
          case 'left':
          case 'right':
            return `${needToSlide.vertical}px`;
          case 'top':
          case 'bottom':
            return `${needToSlide.horizontal}px`;
        }
      })(),
    };
  } else {
    // this mean that there is NO WAY to position popup with it current sizes
    // without diagonal apearing and with fixed triangle position - case of 'isp-formly-validation-error'!
    // so I use just place with most space with maximus slide
    const mostFittestPosition = sidePriority.sort((a, b) =>
      dPopupContainer[a] < dPopupContainer[b] ? 1 : -1,
    )[0];
    return {
      side: mostFittestPosition,
      offset: `${popupTriangleLength + popupMargin}px`,
      slide: (() => {
        switch (mostFittestPosition) {
          case 'left':
          case 'right':
            return `${Math.min(
              Math.max(needToSlide.vertical, -maxSlide.vertical),
              maxSlide.vertical,
            )}px`;
          case 'top':
          case 'bottom':
            return `${Math.min(
              Math.max(needToSlide.horizontal, -maxSlide.horizontal),
              maxSlide.horizontal,
            )}px`;
        }
      })(),
    };
  }
}

import { Injectable } from '@angular/core';

import { ISPUIProgressBarManager } from '@ispui/progress-bar';

/**
 * Progress bar service
 */
@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  /** class for control progress bar */
  private readonly progressBarManager = new ISPUIProgressBarManager();

  /** progress bar instance */
  private progressBar: HTMLIspuiProgressBarElement;

  /**
   * Show progress bar
   *
   * @param progressTimeout - time before progress bar get max value
   */
  showProgressBar(progressTimeout = 1000): void {
    if (this.progressBar) {
      return;
    }

    const anchorElement: HTMLElement = document.getElementById(
      'topbar-progressbar-container',
    );
    if (!anchorElement) {
      return;
    }

    this.progressBar = this.progressBarManager.showProgressBar({
      mountTarget: anchorElement,
      value: 1,
      theme: 'rainbow',
      fakeProgressTime: progressTimeout,
      mode: 'determinate',
    });
  }

  /**
   * Hide progress bar
   */
  hideProgressBar(): void {
    // when we had Angular 11 here was no setTimeout, because the finish() async method had already been firing with some delay.
    // now with Angular 12 the finish() async method runs as fast as sync one, so to prevent issues (double visual progressing) we emulate the delay with setTimeout.
    // TODO: find more elegant solution if needed
    setTimeout(() => {
      this.progressBar?.finish().then(() => {
        this.progressBar = null;
      });
    });
  }
}

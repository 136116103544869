import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Radio field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-radio-field',
  templateUrl: './radio.component.html',
  styleUrls: ['./scss/radio.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioFieldComponent extends ISPFieldTypeBase<ISPFieldType.Radio> {
  constructor(private readonly host: ElementRef<HTMLElement>) {
    super();
  }

  onValueChange(): void {
    // there is a mechanis of field tooltip force opening
    // it triggers on focus. But radio don't have any focus
    // so use value change to order tooltip opening up above
    // wrapper will decide to open it or not
    this.host.nativeElement.dispatchEvent(
      new CustomEvent<boolean>('open-tooltip', {
        detail: true,
        bubbles: true,
      }),
    );
  }
}

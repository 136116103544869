import {
  IDocument,
  IField,
  ISlider,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface SliderControlWithValue {
  readonly control: ISlider;
  readonly type: 'slider';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForSlider(
  field: IField,
  doc: IDocument,
): SliderControlWithValue[] {
  return (field.slider ? [field.slider[0]] : []).map(control => {
    return {
      value: DocHelper.getStringValue(control.$name, doc),
      control,
      type: 'slider',
    };
  });
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ISPUIDropdownModule } from '@ispui/ng-dropdown';

import { SessionTipComponent } from './session-tip.component';

/**
 * Module for session-tip component
 */
@NgModule({
  imports: [ISPUIDropdownModule, CommonModule],
  exports: [SessionTipComponent],
  declarations: [SessionTipComponent],
})
export class SessionTipModule {}

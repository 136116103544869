import { IControl, IField } from 'app/services/api5-service/api.interface';

import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { DateTimeTO } from './model/datetime-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';

/**
 * Get config for date-time field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getDateTimeConfig(
  control: IControl,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.DateTime> {
  const templateOptions: DateTimeTO = {
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    inputLabel: getLabel(field, state.doc),
    time: DocHelper.getStringValue(control.$name, state.doc),
  };

  return {
    key: control.$name,
    type: ISPFieldType.DateTime,
    wrappers: [ISPFieldWrapper.InputBase],
    templateOptions,
  };
}

import { Injectable } from '@angular/core';

import { IDocument } from 'app/services/api5-service/api.interface';
import { getDrawerFormButtons, IFormButtonUi } from 'components/form-button';

import { DocHelper } from 'utils/dochelper';

/**
 * Drawer child service, operates only when dynamic-form receives param isDrawerFor
 */
@Injectable()
export class DrawerChildService {
  /** needed to define wether form currently in child drawer mode or not */
  private _isActive = false;

  /** all fields to show in a drawer */
  private relatedFieldNames: string[] = [];

  /** a special set of form buttons form should have in a drawer mode */
  formButtons: IFormButtonUi[] = [];

  init(doc: IDocument, drawerSelectName?: string): void {
    if (!drawerSelectName) {
      return;
    }
    const drawerMetadata = DocHelper.getDrawersMetadata(doc)[drawerSelectName];
    if (!drawerMetadata) {
      return;
    }
    this._isActive = true;
    this.formButtons = getDrawerFormButtons(doc, drawerSelectName);
    this.relatedFieldNames = [
      ...drawerMetadata.drawerFieldNames.flat(),
      ...drawerMetadata.drawerPageNames.flat(),
    ];
  }

  isActive(): boolean {
    return this._isActive;
  }

  isHidden(name: string[] | string): boolean {
    const names = Array.isArray(name) ? name : [name];
    if (this.relatedFieldNames.length === 0) {
      return false;
    } else {
      return !names.some(n => this.relatedFieldNames.includes(n));
    }
  }

  clear(): void {
    this._isActive = false;
    this.relatedFieldNames = [];
    this.formButtons = [];
  }
}

import { IDocument, IInput } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface HiddenControlWithValue {
  readonly control: IInput;
  readonly type: 'hidden';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForHidden(
  control: IInput,
  doc: IDocument,
): HiddenControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'hidden',
  };
}

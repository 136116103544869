import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { NarrowBannerComponent } from './narrow-banner.component';

@NgModule({
  imports: [CommonModule, SvgIconModule],
  declarations: [NarrowBannerComponent],
  exports: [NarrowBannerComponent],
})
export class NarrowBannerModule {}

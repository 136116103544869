import 'ace-builds/src-min-noconflict/ace.js';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { AceEditorModule } from '@ispdevkit/code-editor';
import { ISPUICheckboxModule } from '@ispui/ng-checkbox';
import { ISPUISelectModule } from '@ispui/ng-select';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { ResizableModule } from 'common/resizable/resizable.module';
import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { CodeFieldComponent } from './code.component';

import { ISPFieldType } from '../../model';

/**
 * Code editor formly field component module
 */
@NgModule({
  declarations: [CodeFieldComponent],
  imports: [
    ResizableModule,
    CommonModule,
    ReactiveFormsModule,
    AceEditorModule,
    FormlyModule.forChild({
      types: [{ name: ISPFieldType.Code, component: CodeFieldComponent }],
    }),
    ISPUICheckboxModule,
    FormsModule,
    SvgIconModule,
    ISPUITooltipModule,
    ISPUISelectModule,
  ],
})
export class CodeFieldModule {}

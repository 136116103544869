import { ICaptcha, IField } from 'app/services/api5-service/api.interface';

export interface CaptchaControl {
  readonly control: ICaptcha;
  readonly type: 'captcha';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 */
export function getTypeControlsForCaptcha(field: IField): CaptchaControl[] {
  return (field.captcha ? [field.captcha] : []).map(control => {
    return {
      control,
      type: 'captcha',
    };
  });
}

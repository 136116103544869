import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive provide setting height of node to end of viewport
 */
@Directive({
  selector: '[ispFullheight]',
})
export class FullHeightDirective implements OnInit {
  /**
   * Flag applied directive
   */
  @Input() ispFullheight = true;

  /**
   * Offset from bottom
   */
  @Input() offset = 50;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  /**
   * Calculate and set height
   */
  private setHeight(): void {
    const top = this.elementRef.nativeElement.getBoundingClientRect().top;
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    const height = vh - this.offset - top;
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'height',
      `${height}px`,
    );
  }

  ngOnInit(): void {
    if (this.ispFullheight) {
      setTimeout(() => this.setHeight());
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUICheckboxModule } from '@ispui/ng-checkbox';
import { ISPUILinkModule } from '@ispui/ng-link';

import { ListFilterComponent } from './list-filter.component';

import { ISPFieldType } from '../../model';

/**
 * List filter module
 */
@NgModule({
  declarations: [ListFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ISPUICheckboxModule,
    ISPUILinkModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.ListFilter,
          component: ListFilterComponent,
        },
      ],
    }),
  ],
  exports: [ListFilterComponent],
})
export class ListFilterModule {}

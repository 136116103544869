import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { ISPFieldType } from 'common/dynamic-form/model/field-type.enum';

import { TextEditorFieldComponent } from './texteditor.component';

@NgModule({
  declarations: [TextEditorFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    EditorModule,
    ISPUIPreloaderRoundModule,
    FormlyModule.forChild({
      types: [
        { name: ISPFieldType.TextEditor, component: TextEditorFieldComponent },
      ],
    }),
    ISPUITooltipModule,
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class TextEditorFieldModule {}

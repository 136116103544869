import { Injectable } from '@angular/core';

import { TFormMode } from 'app/form/form.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service handle form mode, and control fields, that should be displayed in that mode
 */
@Injectable()
export class ModeService {
  /** fields names for base mode */
  private readonly baseModeNames: Set<string> = new Set();

  /** subject of form mode */
  private readonly modeSubject: BehaviorSubject<TFormMode> =
    new BehaviorSubject('extended');

  /** stream of form mode */
  readonly mode$ = this.modeSubject.asObservable();

  /** get form mode */
  get mode(): TFormMode {
    return this.modeSubject.value;
  }

  constructor(private readonly bus: MessageBusService) {}

  private isNamesHidden(names: string[], mode: TFormMode): boolean {
    if (mode === 'base') {
      return !names.some(n => this.baseModeNames.has(n));
    } else {
      return false;
    }
  }

  setNamesForBaseMode(names: Set<string>): void {
    this.baseModeNames.clear();
    Array.from(names).forEach(name => this.baseModeNames.add(name));
  }

  getNamesForBaseMode(): string[] {
    return Array.from(this.baseModeNames);
  }

  /**
   * Check if page/field/control by name should be hidden
   *
   * @param name - page/field/control name
   */
  isHidden(name: string[] | string): boolean {
    const names = Array.isArray(name) ? name : [name];
    return this.isNamesHidden(names, this.mode);
  }

  /**
   * Stream of page/field/control hidding state by name
   *
   * @param name - page/field/control name
   */
  isHidden$(name: string[] | string): Observable<boolean> {
    const names = Array.isArray(name) ? name : [name];
    return this.mode$.pipe(map(mode => this.isNamesHidden(names, mode)));
  }

  /**
   * Toggle form state
   *
   * @param mode - new form mode state
   */
  toggleMode(
    mode: TFormMode = this.mode === 'base' ? 'extended' : 'base',
  ): boolean {
    if (mode === this.mode) {
      return false;
    }

    this.modeSubject.next(mode);
    this.bus.emit('dynamic-form-mode', mode);

    return true;
  }

  /**
   * Clear service state
   */
  clear(): void {
    this.baseModeNames.clear();
    this.modeSubject.next('extended');
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ISPUIInputModule } from '@ispui/ng-input';
import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { AutocompleteSelectComponent } from './autocomplete-select.component';
import { HighlighPipe } from './highlight.pipe';

/**
 * Autocomplete select module
 */
@NgModule({
  declarations: [AutocompleteSelectComponent, HighlighPipe],
  exports: [AutocompleteSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    ISPUIInputModule,
    ISPUITooltipModule,
    ISPUIPreloaderRoundModule,
    SvgIconModule,
  ],
})
export class AutocompleteSelectModule {}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Site summary field component
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-chat-summary',
  templateUrl: './chat-summary.component.html',
  styleUrls: ['./scss/chat-summary.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatSummaryFieldComponent extends ISPFieldTypeBase<ISPFieldType.ChatSummary> {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { FormScrollerFieldComponent } from './form-scroller.component';

import { ISPFieldType } from '../../model';

/**
 * Form scroller component module
 */
@NgModule({
  declarations: [FormScrollerFieldComponent],
  imports: [
    CommonModule,
    SvgIconModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.FormScroller,
          component: FormScrollerFieldComponent,
        },
      ],
    }),
  ],
})
export class FormScrollerFieldModule {}

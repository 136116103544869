import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormlyModule } from '@ngx-formly/core';

import { TemplateFieldComponent } from './template.component';

import { ISPFieldType } from '../../model';

@NgModule({
  declarations: [TemplateFieldComponent],
  imports: [
    CommonModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Template,
          component: TemplateFieldComponent,
        },
      ],
    }),
  ],
})
export class TemplateFieldModule {}

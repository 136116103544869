import { Injectable } from '@angular/core';

import { DrawerManagerService } from 'app/layout/drawer-manager/drawer-manager.service';
import { IDrawerSuccessData } from 'app/layout/drawer-manager/types/form-segment/interface';
import { IDocument } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

import { IFormModel } from '../dynamic-form.interface';

/**
 * Additional data for select field when it has a corresponding drawer and that drawer was successfully submitted
 */
export interface ISucceededDrawerSelectMetadata {
  drawerOptionValue: string;
  drawerOptionText: string;
  drawerLinkText: string;
}

/**
 * An info about drawer inside form - select field to trigger drawer, fields to show in drawer, which value to open drawer on etc.
 */
export interface IDrawerMetadata {
  selectName: string;
  selectDefaultValue: any;
  selectDrawerValue: string;
  drawerFieldNames: string[];
  drawerPageNames: string[];
  succeededSelectMetadata?: ISucceededDrawerSelectMetadata;
}

export type TDrawersMetadata = Record<
  IDrawerMetadata['selectName'],
  IDrawerMetadata
>;

/**
 * Drawer parent service, operates always except the case when dynamic-form receives param isDrawerFor
 */
@Injectable()
export class DrawerParentService {
  /** all field names (from the form) to show in drawers */
  private drawerFieldNames: string[] = [];

  /** info about all form slelects which have drawers to show */
  drawersMetadata: TDrawersMetadata = {};

  constructor(private readonly drawerManagerService: DrawerManagerService) {}

  init(
    doc: IDocument,
    succeededDrawerSelects: Record<string, ISucceededDrawerSelectMetadata>,
  ) {
    const drawersMeta = DocHelper.getDrawersMetadata(doc);
    if (Object.keys(drawersMeta).length === 0) {
      return;
    }
    this.drawersMetadata = drawersMeta;
    this.drawerFieldNames = Object.values(this.drawersMetadata)
      .map(meta => meta.drawerFieldNames)
      .flat();
    Object.keys(succeededDrawerSelects).forEach(
      selectName =>
        (this.drawersMetadata[selectName].succeededSelectMetadata =
          succeededDrawerSelects[selectName]),
    );
  }

  /**
   * set new info about select to change it's UI in from
   *
   * @param data
   */
  updateSucceededDrawerSelectMetadata(data: IDrawerSuccessData): void {
    const drawerMeta = this.drawersMetadata[data.selectName];
    if (!drawerMeta) {
      return;
    }
    // text for new item, i. g. if a new DB was created the message will be like "__db_name__ (New DB)"
    const newItemMessageTemplate = DocHelper.getMessage(
      `${data.selectName}_drawer_template`,
      data.doc,
    );
    const newItemMessage = newItemMessageTemplate.replace(/__.+?__/g, match => {
      const fieldName = match.slice(2, -2);
      return data.model[fieldName] as string;
    });
    drawerMeta.succeededSelectMetadata = {
      drawerOptionValue: drawerMeta.selectDrawerValue,
      drawerOptionText: newItemMessage,
      drawerLinkText: `${DocHelper.getMessage(
        'drawer_edit',
        data.doc,
      )} ${newItemMessage}`,
    };
  }

  isHidden(name: string[] | string): boolean {
    const names = Array.isArray(name) ? name : [name];
    return names.some(n => this.drawerFieldNames.includes(n));
  }

  getSuccessDrawerFormModel(selectName: string, model: IFormModel): IFormModel {
    return (
      this.drawersMetadata[selectName]?.drawerFieldNames.reduce(
        (newModel, fName) => ({ ...newModel, [fName]: model[fName] }),
        {},
      ) || {}
    );
  }

  getSucceededDrawerSelectsMetadata(): Record<
    string,
    ISucceededDrawerSelectMetadata
  > {
    return Object.values(this.drawersMetadata)
      .filter(metadata => metadata.succeededSelectMetadata)
      .reduce<Record<string, ISucceededDrawerSelectMetadata>>(
        (collection, data) => ({
          ...collection,
          [data.selectName]: data.succeededSelectMetadata,
        }),
        {},
      );
  }

  clear(): void {
    // close all drawers (in case if any was open)
    Object.keys(this.drawersMetadata).forEach(selectName => {
      this.drawerManagerService.close({
        type: 'form-segment',
        selectName,
      });
    });
    this.drawersMetadata = {};
    this.drawerFieldNames = [];
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUICheckboxModule } from '@ispui/ng-checkbox';
import { ISPUISelectModule } from '@ispui/ng-select';

import { ColorModModule } from 'common/color-mod/color-mod.module';

import { SelectFieldComponent } from './select.component';

import { ISPFieldType } from '../../model';

/**
 * Select field module
 */
@NgModule({
  declarations: [SelectFieldComponent],
  imports: [
    ColorModModule,
    CommonModule,
    ReactiveFormsModule,
    ISPUICheckboxModule,
    ISPUISelectModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Select,
          component: SelectFieldComponent,
        },
      ],
    }),
  ],
})
export class SelectFieldModule {}

import { setTypedFieldToServices } from './set-typed-field-to-services';

import { ISPFormState } from '../../model';
import { TypedForm } from '../form-preparing';

/**
 * Set services state form typed doc
 *
 * @param typedForm
 * @param state
 */
export function setTypedFormToServices(
  typedForm: TypedForm | undefined,
  state: ISPFormState,
): void {
  if (!typedForm) {
    return;
  }
  if ('pages' in typedForm) {
    typedForm.pages.forEach(page => {
      page.fields.forEach(field => {
        setTypedFieldToServices(field, state);
      });
    });
  } else if ('fields' in typedForm) {
    typedForm.fields.forEach(field => {
      setTypedFieldToServices(field, state);
    });
  }
}

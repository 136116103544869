import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * slider field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-slider-field',
  templateUrl: './slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderFieldComponent extends ISPFieldTypeBase<ISPFieldType.Slider> {
  /** min attribute */
  get min(): string {
    return this.to.originalField.slider[0]?.$min;
  }

  /** max attribute */
  get max(): string {
    return this.to.originalField.slider[0]?.$max;
  }

  /** step attribute */
  get step(): string {
    return this.to.originalField.slider[0]?.$step;
  }

  toggleFocus(isFocused = false): void {
    this.to.isFocused = isFocused;
  }
}

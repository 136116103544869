import { NestedTreeControl } from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';

import { ITlistVal } from 'app/services/api5-service/api.interface';
import { of } from 'rxjs';

import { transformTListVal } from './tree.utils';

import { ITlistValUi } from '../../dynamic-form.interface';
import { ISPFieldTypeBase, ISPFieldType } from '../../model';
import { SetValuesService } from '../../services/set-values.service';

const BRANCH_HEIGHT = 20;

const BRANCH_MARGIN = 7;

const COMMON_MARGIN = 20;
/**
 * `tree` field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-tree-field',
  templateUrl: './tree.component.html',
  styleUrls: ['./scss/tree.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeFieldComponent
  extends ISPFieldTypeBase<ISPFieldType.Tree>
  implements OnInit, AfterViewInit
{
  /** flag of full width state of field */
  @HostBinding('style.width')
  get width(): string {
    return this.to.isFullWidth ? '100%' : '';
  }

  @ViewChild('treeElement') private readonly treeRef: ElementRef;

  /** tree control, needed for filetree to know how to expand node */
  readonly treeControl = new NestedTreeControl<any>(node => {
    return of(node.tlist ? node.tlist.val : null);
  });

  /** the height of the field by rows attribute */
  get height(): string {
    return `${
      this.to.rows * (BRANCH_HEIGHT + BRANCH_MARGIN) + COMMON_MARGIN
    }px`;
  }

  constructor(
    private readonly setValuesService: SetValuesService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  private initTree(): void {
    if (this.to.dataSource.length === 0) {
      return;
    }

    this.treeControl.expand(this.to.dataSource[0]);
    this.treeControl
      .getDescendants(this.to.dataSource[0])
      .filter(node => node.tlist?.val?.length)
      .forEach(node => this.treeControl.expand(node));
  }

  /**
   * Scroll tree component to selected node
   */
  private scrollToSelectedElement(): void {
    const treeElement = this.treeRef?.nativeElement;
    const selectedBranch = treeElement?.querySelector(
      '.tree__label_is_selected',
    );
    setTimeout(() => {
      selectedBranch?.scrollIntoView();
    }, 250);
  }

  ngOnInit(): void {
    this.initTree();
  }

  ngAfterViewInit(): void {
    this.scrollToSelectedElement();
  }

  /**
   * Select handler
   *
   * @param node - list node
   */
  select(node: ITlistVal): void {
    this.formControl.setValue(node.$key);
  }

  /**
   * Toogle (collapse/expand) handler
   *
   * @param node - list node
   */
  toggle(node: ITlistValUi): void {
    const isExpand = this.treeControl.isExpanded(node);
    if (isExpand) {
      this.treeControl.toggle(node);
    } else if (node.tlist?.val.length) {
      this.treeControl.toggle(node);
    } else if (node.$collapsed || node.tlist) {
      node.isLoading = true;
      this.setValuesService
        .requestSetValues(this.field.key, {
          sv_tree: 'yes',
          [this.field.key]: node.$key,
        })
        .subscribe(response => {
          if (response.tlist?.[0]?.val) {
            node.tlist.val.push(...transformTListVal(response.tlist[0].val));
            node.$collapsed = null;
            this.treeControl.expand(node);
          }

          node.isLoading = false;
          this.changeDetectorRef.markForCheck();
        });
    }
  }

  isExpandable(node: ITlistValUi): boolean {
    return Boolean(node.$collapsed || node.tlist?.val);
  }

  /**
   * Get icon for tree node
   *
   * @param node - current tree node
   */
  getTreeIcon(node: ITlistValUi): string {
    if (!node.tlist) {
      return 'p-file-10';
    }
    return this.treeControl.isExpanded(node) ? 'p-bplocal-opened' : node.$img;
  }

  trackByNode(subIndex: number, node: ITlistValUi): string {
    return `${node.$key}_${subIndex}`;
  }
}

import { IDocument, IInput } from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface DatepickerControlWithValue {
  readonly control: IInput;
  readonly type: 'datepicker';
  value: string;
}

/**
 * Check that input control meta is datepicker meta
 *
 * @param control - control metadata
 */
export function isDatePickerControl(control: IInput): boolean {
  return control.$date === 'yes' || control.$date === 'month';
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForDatePicker(
  control: IInput,
  doc: IDocument,
): DatepickerControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'datepicker',
  };
}

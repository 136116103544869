import { IDocument } from 'app/services/api5-service/api.interface';
import { IForm } from 'app/services/api5-service/api.interface/form.interface';

import { getTypedField, TypedField } from './get-typed-field';
import { getTypedPage, TypedPage } from './get-typed-page';
import { getTypedSummary, TypedSummary } from './get-typed-summary';

export type TypedForm = {
  readonly form: IForm;
  readonly summary?: TypedSummary;
} & (
  | {
      readonly fields: TypedField[];
    }
  | {
      readonly pages: TypedPage[];
    }
);

/**
 * Parse doc and prepare all data for rendering
 *
 * @param doc - doc
 */
export function getTypedForm(doc: IDocument): TypedForm | undefined {
  const formMetadata = doc.metadata?.form;

  if (formMetadata?.page) {
    return {
      summary: getTypedSummary(doc),
      pages: formMetadata.page
        .filter(page => page.field)
        .map(page => getTypedPage(page, doc)),
      form: formMetadata,
    };
  } else if (formMetadata?.field) {
    return {
      summary: getTypedSummary(doc),
      fields: formMetadata.field.map(field => getTypedField(field, doc)),
      form: formMetadata,
    };
  }

  return;
}

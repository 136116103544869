import { Injectable } from '@angular/core';

import {
  IControl,
  IInput,
  ISelect,
  ISlider,
  ITextarea,
} from 'app/services/api5-service/api.interface';

/**
 * Service for controls mixed field states
 */
@Injectable()
export class MixedService {
  private readonly mixedControlNames = new Set<string>();

  /**
   * Add mixed control to set
   *
   * @param control
   */
  addControl(control: IControl): void {
    if ((control as IInput | ISelect | ISlider | ITextarea).$mixed) {
      this.mixedControlNames.add(control.$name);
    }
  }

  removeControl(control: IControl): void {
    if ((control as IInput | ISelect | ISlider | ITextarea).$mixed) {
      this.mixedControlNames.delete(control.$name);
    }
  }

  isControlMixed(control: IControl): boolean {
    return this.mixedControlNames.has(control.$name);
  }

  getMixedControlNames(): string[] {
    return Array.from(this.mixedControlNames);
  }

  clear(): void {
    this.mixedControlNames.clear();
  }
}

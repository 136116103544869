import { ISPFieldConfig } from '../../model';

/**
 * Replace some field config in fields structure on provided config
 *
 * @param fieldConfigs - fields configs in which we should replace field
 * @param field - field to replace
 * @param seekFunction - seek function
 * @returns field, that was replaced or undefined if no replacement was done
 */
export function replaceConfig(
  fieldConfigs: ISPFieldConfig[],
  field: ISPFieldConfig,
  seekFunction: (field: ISPFieldConfig) => boolean,
): ISPFieldConfig | undefined {
  const replace = (fields: ISPFieldConfig[]) => {
    const index = fields.findIndex(f => seekFunction(f));
    if (index >= 0) {
      return fields.splice(index, 1, field)[0];
    }

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].fieldGroup) {
        const replacedField = replace(fields[i].fieldGroup);
        if (replacedField) {
          return replacedField;
        }
      }
    }

    return undefined;
  };

  return replace(fieldConfigs);
}

import { IDocument, IField } from 'app/services/api5-service/api.interface';

import {
  CodeControlWithValue,
  getTypeControlForCode,
  isCodeControl,
} from './get-typed-control-for-code';
import {
  getTypeControlForTextarea,
  TextAreaControlWithValue,
} from './get-typed-control-for-textarea';
import {
  getTypeControlForTextEditor,
  isTextEditorControl,
  TextEditorControlWithValue,
} from './get-typed-control-for-texteditor';

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForTextarea(
  field: IField,
  doc: IDocument,
): (
  | CodeControlWithValue
  | TextAreaControlWithValue
  | TextEditorControlWithValue
)[] {
  return (field.textarea || []).map(control => {
    switch (true) {
      case isCodeControl(control):
        return getTypeControlForCode(control, doc);
      case isTextEditorControl(control):
        return getTypeControlForTextEditor(control, doc);
      default:
        return getTypeControlForTextarea(control, doc);
    }
  });
}

import { Inject, Injectable } from '@angular/core';

import {
  BehaviorSubject,
  combineLatest,
  MonoTypeOperatorFunction,
  Observable,
  of,
  pipe,
} from 'rxjs';
import { filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { DocHelper } from 'utils/dochelper';
import { getMatchStringForInternationalSearch } from 'utils/get-match-string-for-international-search';
import { searchForElementsInArray } from 'utils/search-for-elements-in-array';

import { IFooterData, MainMenuFilter, MenuLabel } from './side-bar.interface';

import { AppService } from '../../app.service';
import {
  IDesktopMeta,
  IMenuNode,
  IMessageSet,
} from '../../services/api5-service/api.interface';
import { LocalStorageKey } from '../../services/local-storage/local-storage.interface';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

/**
 * SideBar component service
 */
@Injectable()
export class SideBarService {
  /** the search query */
  private readonly searchQuerySubject = new BehaviorSubject('');

  /** collapsed/expanded menu state */
  private readonly menuExpandedSubject = new BehaviorSubject<boolean>(true);

  /** current applied filter */
  private readonly filter$ = new BehaviorSubject<MainMenuFilter>(
    MainMenuFilter.All,
  );

  /** all the menu messages to search by */
  private readonly menuMessages$ = this.appService.desktop$.pipe(
    map(doc => DocHelper.getMessageSet(doc)),
    map(messages =>
      Object.entries(messages).reduce((menuMessages, [key, value]) => {
        if (key.startsWith('menu_') || key.startsWith('modernmenu_')) {
          menuMessages[key] = value;
        }
        return menuMessages;
      }, {}),
    ),
  );

  /** Moder menu */
  private readonly newMenuLabel: MenuLabel = {
    hintKey: 'mbarmodern',
    type: 'new',
    icon: 'mbar-all',
  };

  /** Favorite menu */
  private readonly favoriteMenuLabel: MenuLabel = {
    hintKey: 'mbarfavorite',
    type: 'favorite',
    icon: 'star_dark',
  };

  /** Popular menu */
  private readonly popularMenuLabel: MenuLabel = {
    hintKey: 'mbarpopular',
    type: 'popular',
    icon: 'mbar-popular',
  };

  /** Full menu for backward compatibility */
  private readonly fullMenuLabel: MenuLabel = {
    hintKey: 'mbarall',
    type: 'full',
    icon: 'mbar-all',
  };

  /** Five menu */
  private readonly fiveMenuLabel: MenuLabel = {
    hintKey: 'mbarall',
    type: 'five',
    icon: 'mbar-five',
  };

  /** doc stream */
  readonly doc$ = this.appService.desktop$;

  readonly searchQuery$ = this.searchQuerySubject.asObservable();

  readonly menuExpanded$ = this.menuExpandedSubject.asObservable();

  readonly allowOldInterface$ = this.appService.desktop$.pipe(
    map(doc => doc.disable_old_theme === undefined),
  );

  /** footer content. Contains the copyright and app version */
  readonly footer$: Observable<IFooterData> = this.appService.desktop$.pipe(
    filter(doc => !!doc?.copyright),
    map((doc: IDesktopMeta) => ({
      copyright: doc.copyright.$,
      product: doc?.product?.$,
      copyrightUrl: doc.copyright.$href,
      oldThemeMessage: DocHelper.getMessage('msg_old_interface', doc),
      tariffFunc: doc.pricelist_change?.$func,
      tariffMessage: DocHelper.getMessage(doc.pricelist_change?.$name, doc),
    })),
  );

  /** filtererd menu nodes */
  readonly filteredMenuList$ = this.appService.desktop$.pipe(
    filter(doc => !!doc?.mainmenu),
    tap(doc => {
      // check for exist modernmenu, for bussines for example
      if (
        !doc?.mainmenu?.modernmenu?.node?.length &&
        this.filter$.value === MainMenuFilter.New
      ) {
        this.filterMenuBy(MainMenuFilter.All);
      }
    }),
    map(doc =>
      doc.mainmenu.node.map(item => ({
        ...item,
        $type: item.$name === 'dashboard' ? 'noname' : item.$type,
      })),
    ),
    this.applyFilters(),
  );

  readonly modernMenuList$ = this.appService.desktop$.pipe(
    // for case without modernmenu
    startWith({ mainmenu: { modernmenu: { node: [] } } }),
    filter(doc => Boolean(doc?.mainmenu?.modernmenu?.node)),
    map(doc => doc.mainmenu.modernmenu.node),
  );

  /** mainmenu type item list */
  readonly menuLabelList$ = combineLatest([
    this.modernMenuList$,
    this.appService.desktop$,
  ]).pipe(
    map(([menu, doc]) => {
      if (!menu?.length) {
        return [
          this.fullMenuLabel,
          this.favoriteMenuLabel,
          this.popularMenuLabel,
        ];
      }
      const items = [
        this.newMenuLabel,
        this.favoriteMenuLabel,
        this.popularMenuLabel,
      ];
      if (!doc.disable_old_menu?.$name) {
        items.push(this.fiveMenuLabel);
      }
      return items;
    }),
  );

  constructor(
    private readonly appService: AppService,
    private readonly localStorage: LocalStorageService,
    @Inject(WINDOW) private readonly window: WindowWrapper,
  ) {
    this.initFilter();
    this.initMenuExpandedState();
  }

  /**
   * Returns search results by a query
   *
   * @param array - menu node to search in
   * @param message - messages set
   */
  private search(array: IMenuNode[], message: IMessageSet): IMenuNode[] {
    let messagePrefix: string;

    if (this.canShowOldMenu()) {
      messagePrefix =
        this.filter$.value === MainMenuFilter.New ? 'modernmenu_' : 'menu_';
    } else {
      messagePrefix = 'modernmenu_';
    }

    const stringForRegExp = getMatchStringForInternationalSearch(
      this.searchQuerySubject.value,
    );

    return array.reduce((acc, category) => {
      const filteredNodeList = searchForElementsInArray(
        new RegExp(stringForRegExp, 'gi'),
        category.node,
        node => message[`${messagePrefix}${node.$name}`],
      );
      if (!filteredNodeList.length) {
        return acc;
      }
      const newCategory = {
        ...category,
        node: [...filteredNodeList],
      };
      return [...acc, newCategory];
    }, []);
  }

  /**
   * Filters the input menu list by chosen category - either all menu items, by favorite or popular ones
   */
  private applyFilters(): MonoTypeOperatorFunction<IMenuNode[]> {
    return pipe(
      switchMap(categoryList =>
        combineLatest([
          of(categoryList),
          this.filter$,
          this.searchQuerySubject,
          this.menuMessages$,
          this.modernMenuList$,
        ]),
      ),
      map(
        ([categoryList, filterBy, searchQuery, messageSet, modernMenuList]) => {
          const filterValue =
            filterBy === this.filter$.value ? filterBy : this.filter$.value;
          let activeMenuList: IMenuNode[];

          if (this.canShowOldMenu()) {
            activeMenuList =
              filterValue === MainMenuFilter.New
                ? modernMenuList
                : categoryList;
          } else {
            activeMenuList = modernMenuList;
          }

          // here insert logic about change menu
          if (searchQuery) {
            return this.search(activeMenuList, messageSet);
          }
          switch (filterValue) {
            case MainMenuFilter.All:
            case MainMenuFilter.New:
              return activeMenuList;
            default:
              return this.getFilteredMenu(activeMenuList);
          }
        },
      ),
    );
  }

  /**
   * Returns the main menu filtered by favorites or popular
   *
   * @param menu - given main menu list
   */
  private getFilteredMenu(menu: IMenuNode[]): IMenuNode[] {
    return menu.reduce((categoryList, category) => {
      const subnodeList = category.node.filter(node => {
        switch (this.filter$.value) {
          case MainMenuFilter.Favorite:
            return node.$favorite === 'yes';
          case MainMenuFilter.Popular:
            return node.$popular === 'yes';
          default:
            return true;
        }
      });
      if (!subnodeList.length) {
        return categoryList;
      }
      return [
        ...categoryList,
        {
          ...category,
          node: subnodeList,
        },
      ];
    }, []);
  }

  /**
   * Initializes the filter
   */
  private initFilter(): void {
    this.filter$.next(this.getCurrentMenuFilter());
  }

  private initMenuExpandedState(): void {
    const menuState = this.localStorage.get<{ menuExpanded?: boolean }>(
      LocalStorageKey.MainMenuState,
    );
    this.menuExpandedSubject.next(
      menuState?.menuExpanded !== undefined ? menuState.menuExpanded : true,
    );
  }

  /**
   * Toggles category menu visibility
   *
   * @param name - category name
   * @param canShow - whether the category menu can be shown
   */
  private toggleCategoryVisibility(name: string, canShow: boolean): void {
    this.localStorage.patch(LocalStorageKey.MainMenuState, { [name]: canShow });
  }

  /**
   * Whether the service has the search query
   */
  private hasSearchQuery(): boolean {
    return !!this.searchQuerySubject.value;
  }

  canShowNew(): boolean {
    return this.getCurrentMenuFilter() === MainMenuFilter.New;
  }

  canShowOldMenu(): boolean {
    const desktopDoc = this.appService.desktop;
    const disableOldMenu = Boolean(desktopDoc.disable_old_menu?.$name);
    const hasModernMenu = Boolean(desktopDoc.mainmenu?.modernmenu);

    return !(disableOldMenu && hasModernMenu);
  }

  /**
   * Returns current applied filter
   */
  getCurrentMenuFilter(): MainMenuFilter {
    const currentMenu =
      (
        this.localStorage.get(LocalStorageKey.MainMenuFilter) as
          | {
              filterBy: MainMenuFilter;
            }
          | undefined
      )?.filterBy ?? MainMenuFilter.New;
    if (!this.canShowOldMenu() && currentMenu === MainMenuFilter.All) {
      this.localStorage.patch(LocalStorageKey.MainMenuFilter, {
        filterBy: MainMenuFilter.New,
      });
      return MainMenuFilter.New;
    }
    return currentMenu;
  }

  /**
   * Gets the localized caption by given code
   *
   * @param code - message code that goes after `msg_` prefix
   * @param prefix - message prefix
   * TODO: Make a Pipe for messages
   */
  message(code: string, prefix = 'msg'): Observable<string> {
    return this.appService.desktop$.pipe(
      map(doc => DocHelper.getMessage(`${prefix}_${code}`, doc)),
    );
  }

  /**
   * Gets initial category state
   *
   * @param name - category name
   */
  isCategoryOpened(name: string): boolean {
    if (this.hasSearchQuery()) {
      return true;
    }
    const state = this.localStorage.get(LocalStorageKey.MainMenuState);
    return state?.[name] ?? false;
  }

  /**
   * Saves opened category state
   *
   * @param name - category name
   */
  onOpenCategory(name: string): void {
    if (!this.hasSearchQuery()) {
      this.toggleCategoryVisibility(name, true);
    }
  }

  /**
   * Closes opened category state
   *
   * @param name - category name
   */
  onCloseCategory(name: string): void {
    if (!this.hasSearchQuery()) {
      this.toggleCategoryVisibility(name, false);
    }
  }

  /**
   * Applies the filter by "All", "Favorites" or "Popular"
   *
   * @param criteria - filter criteria
   */
  filterMenuBy(criteria: MainMenuFilter): void {
    this.filter$.next(criteria);
    this.localStorage.set(LocalStorageKey.MainMenuFilter, {
      filterBy: criteria,
    });
  }

  /**
   * Updates current search query
   *
   * @param query - query string
   */
  updateSearchQuery(query: string): void {
    this.searchQuerySubject.next(query);
  }

  /**
   * Expand/collapse menu
   *
   * @param expanded - should the menu become expanded (true) or collapsed (false)
   */
  toggleMenu(expanded: boolean) {
    this.menuExpandedSubject.next(expanded);
    this.localStorage.patch(LocalStorageKey.MainMenuState, {
      menuExpanded: expanded,
    });
  }

  /**
   * Enable old interface
   */
  navigateToOldTheme(): void {
    const themeStr = '?theme=orion';
    this.window.open(
      `${this.appService.host}${this.appService.binary}${themeStr}`,
      '_self',
    );
  }
}

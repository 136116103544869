import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { TextDataConfig } from 'common/dynamic-form/types';

@Component({
  selector: 'isp-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./scss/social-links.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialLinksComponent implements OnInit {
  @Input() config: TextDataConfig;

  ngOnInit(): void {
    // @HACK replace common image from BE to images from out bundle
    this.config.templateOptions.content =
      this.config.templateOptions.content.replace(
        'common/img/facebook.png',
        'dragon/default/facebook.svg',
      );
    this.config.templateOptions.content =
      this.config.templateOptions.content.replace(
        'common/img/google.png',
        'dragon/default/google.svg',
      );
    this.config.templateOptions.content =
      this.config.templateOptions.content.replace(
        'common/img/vkontakte.png',
        'dragon/default/vkontakte.svg',
      );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Highligting with font-weight pipe
 */
@Pipe({
  name: 'highlight',
})
export class HighlighPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: string, highlightText: string): SafeHtml {
    // escape highlighting word. Example '#6519 BILLmanager 5 - 2 (1.1.3.5)' into '#6519 BILLmanager 5 \\- 2 \\(1\\.1\\.3\\.5\\)'
    const escapedHighlighting = highlightText.replace(
      /[-/\\^$*+?.()|[\]{}]/g,
      '\\$&',
    );
    // split highlight by words for replacement in target value Example '#6519 BILLmanager 5' into '(#6519)|(BILLmanager)|(5)'
    const highligtingWords = `(${escapedHighlighting.replace(/\s+/g, ')|(')})`;
    return this.sanitizer.bypassSecurityTrustHtml(
      value.replace(
        new RegExp(highligtingWords, 'ig'),
        '<span style="font-weight: 500">$&</span>',
      ),
    );
  }
}

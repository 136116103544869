import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChatBubbleComponent } from './chat-bubble.component';

import { SvgIconModule } from '../../common/svg-icon-module/svg-icon.module';

@NgModule({
  declarations: [ChatBubbleComponent],
  imports: [CommonModule, SvgIconModule],
  exports: [ChatBubbleComponent],
})
export class ChatBubbleModule {}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AutocompleteSelectOption } from './model/autocomplete-select-option.interface';

/**
 * Option key type
 * Used as visual distinction from simple string type
 */
type K = string;

/**
 * Option value type
 * Used as visual distinction from simple string type
 */
type V = string;

/**
 * Autocomplete select
 */
@Component({
  selector: 'isp-autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteSelectComponent),
      multi: true,
    },
  ],
  styleUrls: ['./scss/autocomplete-select.component.scss'],
})
export class AutocompleteSelectComponent
  implements OnChanges, ControlValueAccessor
{
  /** Last selected option. Used for reseting select state to previous value, if searching fails */
  private lastSelected?: AutocompleteSelectOption;

  /** Value that displayed in select input */
  displayValue = '';

  /* Is popup opened state flag */
  isPopupOpened = false;

  /* Disabled state flag */
  disabled = false;

  /* Index of focused option */
  focusedOptionIndex: number | null = null;

  /** Select options. Null mean unknown options */
  @Input() options: AutocompleteSelectOption[] | null = null;

  /** required flag */
  @Input() required: boolean;

  /** Pending state flag for preloader */
  @Input() pending: boolean;

  // @TODO i.ablov make invalid state. Possible won't be needed
  // /** Invalid value flag */
  // @Input() invalid: boolean;

  /** Input label */
  @Input() label?: string;

  /** empty list msg. Shown only if options is realy empty */
  @Input() emptyListMsg?: string;

  /** popup container element */
  @ViewChild('container') container?: ElementRef<HTMLElement>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly host: ElementRef,
  ) {}

  /**
   * Keyboard listener for controling focus state
   *
   * @param event - keyboard event
   */
  @HostListener('keyup', ['$event']) onKeyboardEvent(
    event: KeyboardEvent,
  ): void {
    if (this.pending || this.disabled || !this.options) {
      return;
    }

    if (!this.isPopupOpened) {
      if (event.key === 'ArrowDown') {
        // simulate popup opening
        this.onFocus();
      }
      return;
    }

    switch (event.key) {
      case 'Enter':
        if (this.focusedOptionIndex === null) {
          this.focusedOptionIndex = 0;
        }
        this.cdr.markForCheck();
        event.preventDefault();
        event.stopPropagation();
        break;
      case 'ArrowUp':
        if (this.focusedOptionIndex === null) {
          this.focusedOptionIndex = 0;
        } else {
          this.focusedOptionIndex = Math.max(this.focusedOptionIndex - 1, 0);
        }
        this.cdr.markForCheck();
        event.preventDefault();

        this.container?.nativeElement.children[
          this.focusedOptionIndex
        ]?.scrollIntoView({
          block: 'center',
        });
        break;
      case 'ArrowDown':
        if (this.focusedOptionIndex === null) {
          this.focusedOptionIndex = 0;
        } else {
          this.focusedOptionIndex = Math.min(
            this.focusedOptionIndex + 1,
            this.options.length - 1,
          );
        }
        this.cdr.markForCheck();
        event.preventDefault();

        this.container?.nativeElement.children[
          this.focusedOptionIndex
        ]?.scrollIntoView({
          block: 'center',
        });
        break;
    }

    const focusedOption = this.options[this.focusedOptionIndex];
    if (focusedOption) {
      if (event.key === 'Enter') {
        this.selectByKey(focusedOption.k);
      } else {
        this.displayValue = focusedOption.v;
      }
    }
  }

  /**
   * Mouse event handler for catching missclick events
   *
   * @param event
   * @WARN input focusout doesn't fit here! Cause in a moment of popup clicking you lost input focus
   */
  @HostListener('document:mouseup', ['$event']) onMissclick(
    event: MouseEvent,
  ): void {
    // handle only missclicks
    if (this.host.nativeElement.contains(event.target) || !this.isPopupOpened) {
      return;
    }

    this.togglePopup(false);

    // if search was empty or cleared - then select nothin
    if (this.displayValue === '') {
      this.writeValue('', 'fromSearch');
      return;
    }

    // try to find options, that user tryed to write
    // @TODO good place for component user expirience improving! You can add regexp for matching, translit casting and etc.
    const selectedOption = this.getOptionByKey(this.displayValue);
    this.selectOption(selectedOption);
  }

  /**
   * Get option from options by key
   *
   * @param key
   */
  private getOptionByKey(key: string): AutocompleteSelectOption | undefined {
    return this.options?.find(o => o.k === key);
  }

  /**
   * Select provided option
   *
   * @param option - option to select
   */
  private selectOption(option?: AutocompleteSelectOption): void {
    if (option) {
      this.writeValue(option.k, 'fromOption');
    } else if (this.lastSelected) {
      this.writeValue(this.lastSelected.k, 'fromOption');
    } else {
      this.writeValue('', 'fromSearch');
    }
  }

  /**
   * Toggle popup state
   *
   * @param state
   */
  private togglePopup(state: boolean): void {
    this.isPopupOpened = state;
    this.focusedOptionIndex = null;

    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.pending?.currentValue === false &&
      changes.pending?.previousValue === true
    ) {
      this.focusedOptionIndex = null;
    }
  }

  /**
   * Select value from by key. Use lambda, because this function used as callback in template
   *
   * @param value - option key
   */
  selectByKey = (value: K) => {
    const selectedOption = this.getOptionByKey(value);
    this.selectOption(selectedOption);

    this.togglePopup(false);
  };

  /**
   * Input focus handling
   */
  onFocus(): void {
    if (!this.isPopupOpened) {
      // trigger search starting
      this.writeValue(this.displayValue, 'fromSearch');
    }

    this.togglePopup(true);
  }

  /**
   * Search input value change handler
   *
   * @param search - search string
   */
  onSearchInputChange(search: V): void {
    this.writeValue(search, 'fromSearch');

    this.togglePopup(true);
  }

  writeValue(
    value: K | V | null,
    source: 'fromForm' | 'fromOption' | 'fromSearch' = 'fromForm',
  ): void {
    this.focusedOptionIndex = null;

    switch (source) {
      case 'fromSearch':
        this.displayValue = value;
        break;
      case 'fromForm':
      case 'fromOption': {
        const selectedOption = this.getOptionByKey(value);
        if (selectedOption) {
          this.lastSelected = selectedOption;
          this.displayValue = selectedOption.v;
        } else {
          this.displayValue = '';
        }
        break;
      }
    }

    this.onTouched();
    this.onChange(value);

    this.cdr.markForCheck();
  }

  onChange = (_v: K | null): void => undefined;

  onTouched = (): void => undefined;

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(state: boolean): void {
    this.disabled = state;

    this.cdr.markForCheck();
  }

  trackByFn(option: AutocompleteSelectOption): any {
    return option.k;
  }
}

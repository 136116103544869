<div class="scroll-buttons-wrapper">
  <div class="scroll-buttons">
    <!-- @TODO i.ablov UX decide to remove this button. Nobody remembers why -->
    <!-- <button
      *ngIf="canShowScrollToTopButton$ | async"
      class="scroll-button"
      (click)="scrollToTop()"
    >
      <isp-svg-icon
        class="scroll-button__icon scroll-button__icon_top"
        name="t-back"
        [customStyle]="{ width: '15px', height: '15px' }"
      >
      </isp-svg-icon>
    </button> -->
    <button
      *ngIf="canShowScrollToBottomButton"
      class="scroll-button"
      (click)="handleBottomButtonClick()"
    >
      <isp-svg-icon
        class="scroll-button__icon scroll-button__icon_bottom"
        name="t-back"
        [customStyle]="{ width: '15px', height: '15px' }"
      >
      </isp-svg-icon>
    </button>
  </div>
</div>

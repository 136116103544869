import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RecaptchaComponent } from 'ng-recaptcha';

import { CaptchaService } from 'common/dynamic-form/services/captcha.service';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Captcha field component
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./scss/captcha.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaComponent
  extends ISPFieldTypeBase<ISPFieldType.Captcha>
  implements AfterViewInit, OnInit, OnDestroy
{
  isInvisibleRecaptcha: boolean;

  @ViewChild(RecaptchaComponent) captcha: RecaptchaComponent;

  constructor(private readonly captchaService: CaptchaService) {
    super();
  }

  ngOnInit(): void {
    this.isInvisibleRecaptcha = this.to.recaptchaType === 'invisible';

    if (this.isInvisibleRecaptcha) {
      this.captchaService.markFormToWaitCaptcha(true);
    }
  }

  ngAfterViewInit(): void {
    if (this.isInvisibleRecaptcha) {
      this.captchaService.captchaTrigger$
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.captcha.execute();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.isInvisibleRecaptcha) {
      this.captchaService.markFormToWaitCaptcha(false);
    }
  }

  onResolve(response: string): void {
    this.formControl.setValue(response);
    if (this.isInvisibleRecaptcha) {
      this.captchaService.setCaptchaResolve(response);
    }
  }

  onRecaptchaError(): void {
    if (this.isInvisibleRecaptcha) {
      this.captchaService.setCaptchaReject();
    }
  }
}

import {
  IDocument,
  ISelect,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface AutocompleteSelectControlWithValue {
  readonly control: ISelect;
  readonly type: 'autocomplete';
  value: string[] | string;
}

/**
 * Check that input control meta is autocomplete input meta
 *
 * @param control - control metadata
 */
export function isControlAutocomplete(control: ISelect): boolean {
  return control.$type === SelectType.Autocomplete;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param control - control metadata
 * @param doc - doc
 */
export function getTypeControlForAutocomplete(
  control: ISelect,
  doc: IDocument,
): AutocompleteSelectControlWithValue {
  return {
    value: DocHelper.getStringValue(control.$name, doc),
    control,
    type: 'autocomplete',
  };
}

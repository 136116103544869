import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUICheckboxModule } from '@ispui/ng-checkbox';

import { CheckboxComponent } from './checkbox.component';

import { ISPFieldType } from '../../model';

/**
 * Checkbox field module.
 * Use only with Formly!
 */
@NgModule({
  declarations: [CheckboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ISPUICheckboxModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.Checkbox,
          component: CheckboxComponent,
        },
      ],
    }),
  ],
})
export class CheckboxFieldModule {}

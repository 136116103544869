import { NgModule } from '@angular/core';

import { ColorModPipe } from './color-mod.pipe';

/**
 * Color modificator class
 */
@NgModule({
  declarations: [ColorModPipe],
  exports: [ColorModPipe],
  providers: [ColorModPipe],
})
export class ColorModModule {}

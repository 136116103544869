<div
  class="formly-input__control-group"
  [class.formly-input__zoom]="to.zoom"
  [style.--isp-input-field-width]="to.width"
>
  <ispui-input
    [id]="id"
    [name]="key"
    [type]="to.inputType"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [label]="to.inputLabel"
    [required]="to.required"
    [prefix]="to.prefix"
    [maxlength]="to.maxLength"
    [placeholder]="getPlaceholder()"
    forceplaceholder
    [invalid]="showError"
    (customFocus)="toggleFocus(true)"
    (customBlur)="toggleFocus()"
    autocomplete="new-password"
    #inputElement
    *ngIf="(isZoomed$ | async) === false; else zoomed"
  >
    <isp-svg-icon
      (click)="setUnlimitValue()"
      *ngIf="to.unlimit !== undefined"
      class="formly-input__unlimited"
      name="ff-unlimit-a"
    ></isp-svg-icon>
    <span *ngIf="to.desc && to.desc.length < 5" class="formly-input__desc">{{
      to.desc
    }}</span>
  </ispui-input>
  <ispui-tooltip
    [title-text]="to?.zoomLabel"
    *ngIf="to.zoom"
    class="formly-input__zoom-button-wrap"
    placement="topLeft"
    viewport-selector="#layout-main-page"
  >
    <span
      role="button"
      class="formly-input__zoom-button"
      [tabIndex]="to.tabindex"
      (click)="toggleZoom()"
    >
      <isp-svg-icon [name]="zoomButtonIcon"></isp-svg-icon>
    </span>
  </ispui-tooltip>
</div>

<ng-template #zoomed>
  <ispui-textarea
    [id]="id"
    [name]="key"
    [label]="to.inputLabel"
    [rows]="to.zoom"
    [placeholder]="getPlaceholder()"
    [disabled]="to.disabled"
    forceplaceholder
    [tabindex]="to.tabindex || 0"
    [required]="to.required"
    [(ngModel)]="controlValue"
    [invalid]="showError"
    (customFocus)="toggleFocus(true)"
    (customBlur)="handleTextareaBlur()"
  ></ispui-textarea>
</ng-template>

import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { IUiProp } from 'app/list/table/table.interface';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Func,
  IAuthParams,
  IAuthResponse,
  ICollapseParams,
  IDesktopMeta,
  IDocProgess,
  IDocument,
  ILogonMeta,
  INotifyDocument,
  ISetValueDocument,
  TBlockDisplay,
  TBlockPosition,
} from './api.interface';

import { IHttpParam } from '../http-base-service/http-base.interface';
import { HttpBaseService } from '../http-base-service/http-base.service';

export interface CheckFieldValidationParams {
  /** check func name */
  func: string;
  /** field form func name */
  funcname: string;
  /** field name */
  name: string;
  /** field value */
  value: string;
  args?: string;
  tconvert?: string;
}

/**
 * SetValues function parameters
 */
export interface SetValuesParams {
  /** form func */
  func: string;
  /** field, that was changed */
  field: string;
  /** plane form model */
  model: Params;
  /** elid of form document */
  elid: string;
  /** plid of form document */
  plid: string;
}

/**
 * ISP5 API service
 */
@Injectable({
  providedIn: 'root',
})
export class Api5Service {
  constructor(private readonly httpBaseService: HttpBaseService) {}

  /**
   * Get func=desktop
   */
  desktop(): Observable<IDesktopMeta> {
    return this.httpBaseService.get({ func: Func.Desktop });
  }

  /**
   * Get func=login
   */
  logon(): Observable<ILogonMeta> {
    return this.httpBaseService.get({ func: Func.Logon });
  }

  /**
   * Authentication
   *
   * @param data - auth parameters (login and password)
   */
  auth(data: IAuthParams): Observable<IAuthResponse> {
    return this.httpBaseService.get({ func: Func.Auth, ...data });
  }

  /**
   * func=notify
   */
  notify(): Observable<INotifyDocument> {
    return this.httpBaseService.get({ func: Func.Notify });
  }

  /**
   * Get dashboard block doc
   *
   * @param func - dashboard func
   * @param dashboard - dashboard block name
   * @param params - additional params
   */
  getDashboard<D extends IDocument>(
    func: string,
    dashboard: string,
    params?: Params,
  ): Observable<D> {
    return this.httpBaseService.get<D>({
      func,
      dashboard,
      ...params,
    });
  }

  /**
   * Mark subnotification as readed
   *
   * @param subNotifyId - subnotification id
   */
  markSubNotificationAsRead(subNotifyId: string): Observable<any> {
    return this.httpBaseService.get({
      func: 'notificationbar.delete',
      elid: subNotifyId,
    });
  }

  /**
   * func=colwidth
   *
   * @param params
   */
  colwidth(params: Params): Observable<IDocument> {
    return this.httpBaseService.get({ func: 'colwidth', ...params });
  }

  /**
   * Saves the state of form's collapsible (page)
   *
   * @param params - save parameters (state (on/off), page name, action name)
   */
  collapse(params: ICollapseParams): Observable<any> {
    return this.httpBaseService.get({ func: Func.Collapse, ...params });
  }

  setValues(params: SetValuesParams): Observable<ISetValueDocument> {
    return this.httpBaseService.post({
      func: params.func,
      ...params.model,
      elid: params.elid,
      plid: params.plid,
      sv_field: params.field,
    });
  }

  setValuesWithFiles(
    params: SetValuesParams,
  ): Observable<HttpEvent<{ doc: ISetValueDocument }>> {
    return this.httpBaseService.uploadFile({
      func: params.func,
      ...params.model,
      elid: params.elid,
      plid: params.plid,
      sv_field: params.field,
    });
  }

  /**
   * Check field validation state on BE
   *
   * @param params - field validation params
   */
  checkFieldValidation(
    params: CheckFieldValidationParams,
  ): Observable<IDocument> {
    const validatorParams: IHttpParam = {
      func: params.func,
      name: params.name,
      funcname: params.funcname,
      value: params.value,
    };
    if (params.args) {
      validatorParams.args = params.args;
    }
    if (params.tconvert) {
      validatorParams.tconvert = params.tconvert;
    }
    return this.httpBaseService.get(validatorParams);
  }

  /**
   * Request to validate drawer form, the response contains new messages for corresponding select field
   *
   * @param field - filed initiated setDrawer request
   * @param params - all other params including the form model, plid, elid, func etc.
   * @returns
   */
  setDrawer(field: string, params: Params): Observable<IDocument> {
    return this.httpBaseService.post({ ...params, sd_field: field });
  }

  /**
   * Dismiss banner
   *
   * @param params
   */
  dismiss(params: Params): Observable<IDocument> {
    return this.httpBaseService.post({ func: 'dismiss', ...params });
  }

  /**
   * Get BE task progress status
   *
   * @param elid
   */
  progress(elid: string): Observable<IDocProgess> {
    return this.httpBaseService.get({ func: 'progress.get', elid });
  }

  // Saves dashboard state
  // @param block - saved block
  // @param display - new display
  // @param position -
  dashboardStateSave(
    block: string,
    display: TBlockDisplay,
    position: TBlockPosition,
  ): Observable<IDocument> {
    return this.httpBaseService.get({
      func: Func.DashboardSave,
      block,
      display,
      position,
    });
  }

  /**
   * Get keepalive function
   */
  keepalive(): Observable<IDocument> {
    return this.httpBaseService.get({ func: Func.Keepalive });
  }

  /**
   * Announce server that certain tip was seen
   *
   * @param name - tip name
   */
  tip(name: string): Observable<IDocument> {
    return this.httpBaseService.get({ func: Func.Tip, elid: name });
  }

  globalSearch(elid: string): Observable<IDocument | null> {
    if (!elid?.trim().length) {
      return of(null);
    }

    const params = {
      func: 'globalindex',
      elid,
      out: 'json',
      sfrom: 'ajax',
    };
    return this.httpBaseService.get(params);
  }

  /**
   * Get hint for props with activehint flag. Used in table
   *
   * @param prop - prop data
   * @param tableFunc - table func
   */
  getActiveHint(prop: IUiProp, tableFunc: string): Observable<string> {
    const params: Params = {
      func: tableFunc,
      hint_field: prop.name,
      elid: prop.elid,
    };

    if (prop.value) {
      params.value = prop.value;
    }

    return this.httpBaseService
      .get(params)
      .pipe(map(response => response.hint?.$ || ''));
  }
}

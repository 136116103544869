import { IField } from 'app/services/api5-service/api.interface';

/**
 * Check if textdata should be highlighted
 *
 * @param field - field metadata
 */
export function isTextHighlighted(field: IField): boolean {
  if (field?.textdata) {
    const textdata = field.textdata[0];
    if (textdata.$warning) {
      return true;
    }
  }

  return false;
}

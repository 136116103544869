import { IDocument, OkType } from '../app/services/api5-service/api.interface';

/**
 * Checks the document for `sok=ok` redirects
 *
 * @param doc - given document
 */
export function getRedirectTypeOrDoRedirect(
  doc: IDocument,
): OkType | null | undefined {
  const ok = doc?.ok;
  let redirectType: OkType = ok?.$type;
  switch (redirectType) {
    case OkType.Top:
      location.hash = '';
      location.reload();
      redirectType = null;
      break;
    case OkType.Blank:
      window.open(ok.$, '_blank');
      // for close tab
      redirectType = null;
      break;
    case OkType.Url:
      location.href = ok.$;
      // for close tab
      break;
    default:
  }

  return redirectType;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { NgISPUIFileModule } from '@ngispui/file';

import { FileFieldComponent } from './file.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's file field component
 */
@NgModule({
  declarations: [FileFieldComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgISPUIFileModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.InputFile,
          component: FileFieldComponent,
        },
      ],
    }),
  ],
})
export class FileFieldModule {}

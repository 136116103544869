import { TWordWrap } from './code.interface';

export const fontSizeList: number[] = [10, 11, 12, 13, 14, 16, 18, 20, 24];

export const modeList: [string, string][] = [
  ['abap', 'abap'],
  ['abc', 'abc'],
  ['actionscript', 'actionscript'],
  ['ada', 'ada'],
  ['alda', 'alda'],
  ['apache_conf', 'apache conf'],
  ['apex', 'apex'],
  ['applescript', 'applescript'],
  ['aql', 'aql'],
  ['asciidoc', 'asciidoc'],
  ['asl', 'asl'],
  ['assembly_x86', 'assembly x86'],
  ['autohotkey', 'autohotkey'],
  ['batchfile', 'batchfile'],
  ['c9search', 'c9search'],
  ['c_cpp', 'c++'],
  ['cirru', 'cirru'],
  ['clojure', 'clojure'],
  ['cobol', 'cobol'],
  ['coffee', 'coffee'],
  ['coldfusion', 'coldfusion'],
  ['crystal', 'crystal'],
  ['csharp', 'csharp'],
  ['csound_document', 'csound document'],
  ['csound_orchestra', 'csound orchestra'],
  ['csound_score', 'csound score'],
  ['csp', 'csp'],
  ['css', 'css'],
  ['curly', 'curly'],
  ['d', 'd'],
  ['dart', 'dart'],
  ['diff', 'diff'],
  ['django', 'django'],
  ['dockerfile', 'dockerfile'],
  ['dot', 'dot'],
  ['drools', 'drools'],
  ['edifact', 'edifact'],
  ['eiffel', 'eiffel'],
  ['ejs', 'ejs'],
  ['elixir', 'elixir'],
  ['elm', 'elm'],
  ['erlang', 'erlang'],
  ['forth', 'forth'],
  ['fortran', 'fortran'],
  ['fsharp', 'fsharp'],
  ['fsl', 'fsl'],
  ['ftl', 'ftl'],
  ['gcode', 'gcode'],
  ['gherkin', 'gherkin'],
  ['gitignore', 'gitignore'],
  ['glsl', 'glsl'],
  ['gobstones', 'gobstones'],
  ['golang', 'golang'],
  ['graphqlschema', 'graphqlschema'],
  ['groovy', 'groovy'],
  ['haml', 'haml'],
  ['handlebars', 'handlebars'],
  ['haskell', 'haskell'],
  ['haskell_cabal', 'haskell cabal'],
  ['haxe', 'haxe'],
  ['hjson', 'hjson'],
  ['html', 'html'],
  ['html_elixir', 'html elixir'],
  ['html_ruby', 'html ruby'],
  ['ini', 'ini'],
  ['io', 'io'],
  ['jack', 'jack'],
  ['jade', 'jade'],
  ['java', 'java'],
  ['javascript', 'javascript'],
  ['json', 'json'],
  ['json5', 'json5'],
  ['jsoniq', 'jsoniq'],
  ['jsp', 'jsp'],
  ['jssm', 'jssm'],
  ['jsx', 'jsx'],
  ['julia', 'julia'],
  ['kotlin', 'kotlin'],
  ['latex', 'latex'],
  ['less', 'less'],
  ['liquid', 'liquid'],
  ['lisp', 'lisp'],
  ['livescript', 'livescript'],
  ['logiql', 'logiql'],
  ['logtalk', 'logtalk'],
  ['lsl', 'lsl'],
  ['lua', 'lua'],
  ['luapage', 'luapage'],
  ['lucene', 'lucene'],
  ['makefile', 'makefile'],
  ['markdown', 'markdown'],
  ['mask', 'mask'],
  ['matlab', 'matlab'],
  ['maze', 'maze'],
  ['mediawiki', 'mediawiki'],
  ['mel', 'mel'],
  ['mixal', 'mixal'],
  ['mushcode', 'mushcode'],
  ['mysql', 'mysql'],
  ['nginx', 'nginx'],
  ['nim', 'nim'],
  ['nix', 'nix'],
  ['nsis', 'nsis'],
  ['nunjucks', 'nunjucks'],
  ['objectivec', 'objectivec'],
  ['ocaml', 'ocaml'],
  ['pascal', 'pascal'],
  ['perl', 'perl'],
  ['perl6', 'perl6'],
  ['pgsql', 'pgsql'],
  ['php', 'php'],
  ['php_laravel_blade', 'php laravel blade'],
  ['pig', 'pig'],
  ['plain_text', 'plain text'],
  ['powershell', 'powershell'],
  ['praat', 'praat'],
  ['prisma', 'prisma'],
  ['prolog', 'prolog'],
  ['properties', 'properties'],
  ['protobuf', 'protobuf'],
  ['puppet', 'puppet'],
  ['python', 'python'],
  ['qml', 'qml'],
  ['r', 'r'],
  ['razor', 'razor'],
  ['rdoc', 'rdoc'],
  ['red', 'red'],
  ['redshift', 'redshift'],
  ['rhtml', 'rhtml'],
  ['rst', 'rst'],
  ['ruby', 'ruby'],
  ['rust', 'rust'],
  ['sass', 'sass'],
  ['scad', 'scad'],
  ['scala', 'scala'],
  ['scheme', 'scheme'],
  ['scss', 'scss'],
  ['sh', 'sh'],
  ['sjs', 'sjs'],
  ['slim', 'slim'],
  ['smarty', 'smarty'],
  ['snippets', 'snippets'],
  ['soy_template', 'soy template'],
  ['space', 'space'],
  ['sparql', 'sparql'],
  ['sql', 'sql'],
  ['sqlserver', 'sqlserver'],
  ['stylus', 'stylus'],
  ['svg', 'svg'],
  ['swift', 'swift'],
  ['tcl', 'tcl'],
  ['terraform', 'terraform'],
  ['tex', 'tex'],
  ['text', 'text'],
  ['textile', 'textile'],
  ['toml', 'toml'],
  ['tsx', 'tsx'],
  ['turtle', 'turtle'],
  ['twig', 'twig'],
  ['typescript', 'typescript'],
  ['vala', 'vala'],
  ['vbscript', 'vbscript'],
  ['velocity', 'velocity'],
  ['verilog', 'verilog'],
  ['vhdl', 'vhdl'],
  ['visualforce', 'visualforce'],
  ['wollok', 'wollok'],
  ['xml', 'xml'],
  ['xquery', 'xquery'],
  ['yaml', 'yaml'],
  ['zeek', 'zeek'],
];

export const themeList = [
  ['ambiance', 'Ambiance'],
  ['chaos', 'Chaos'],
  ['chrome', 'Chrome'],
  ['clouds', 'Clouds'],
  ['clouds_midnight', 'Clouds Midnight'],
  ['cobalt', 'Cobalt'],
  ['crimson_editor', 'Crimson Editor'],
  ['dawn', 'Dawn'],
  ['dracula', 'Dracula'],
  ['dreamweaver', 'Dreamweaver'],
  ['eclipse', 'Eclipse'],
  ['github', 'Github'],
  ['gob', 'Gob'],
  ['gruvbox', 'Gruvbox'],
  ['idle_fingers', 'Idle Fingers'],
  ['iplastic', 'Iplastic'],
  ['katzenmilch', 'Katzenmilch'],
  ['kr_theme', 'KR Theme'],
  ['kuroir', 'Kuroir'],
  ['merbivore', 'Merbivore'],
  ['merbivore_soft', 'Merbivore soft'],
  ['mono_industrial', 'Mono Industrial'],
  ['monokai', 'Monokai'],
  ['nord_dark', 'Nord Dark'],
  ['pastel_on_dark', 'Pastel on dark'],
  ['solarized_dark', 'Solarized dark'],
  ['solarized_light', 'Solarized light'],
  ['sqlserver', 'Sql server'],
  ['terminal', 'Terminal'],
  ['textmate', 'Textmate'],
  ['tomorrow', 'Tomorrow'],
  ['tomorrow_night', 'Tomorrow night'],
  ['tomorrow_night_blue', 'Tomorrow night blue'],
  ['tomorrow_night_bright', 'Tomorrow night bright'],
  ['tomorrow_night_eighties', 'Tomorrow night eighties'],
  ['twilight', 'Twilight'],
  ['vibrant_ink', 'Vibrant ink'],
  ['xcode', 'XCode'],
];

export const wrapList: [TWordWrap, string][] = [
  [false, 'Off'],
  [40, '40 Chars'],
  [80, '80 Chars'],
  [true, 'Free'],
];

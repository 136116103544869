import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { FormCollapseModule } from 'components/form-collapse';

import { PageWrapperComponent } from './page.component';

import { ISPFieldWrapper } from '../../model';

/**
 * Collapsible form wrapper
 */
@NgModule({
  declarations: [PageWrapperComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormCollapseModule,
    FormlyModule.forChild({
      wrappers: [
        { name: ISPFieldWrapper.Page, component: PageWrapperComponent },
      ],
    }),
  ],
})
export class PageWrapperModule {}

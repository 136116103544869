import { AfterViewInit, Directive, Input } from '@angular/core';

import { NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

/**
 * Directive for formatting nz datepicker form control value
 * From date object to string and back
 */
@Directive({
  selector: '[ispDateFormatter]',
})
export class DateFormatterDirective implements AfterViewInit {
  /** date picker mode */
  @Input() nzMode: 'date' | 'month';

  constructor(private readonly host: NzDatePickerComponent) {}

  private formatDateObject(date: Date): string {
    if (date === null) {
      return '';
    }

    const leadingZerosSlice = -2;

    if (this.nzMode === 'month') {
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        leadingZerosSlice,
      )}`;
    } else {
      return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(
        leadingZerosSlice,
      )}-${`0${date.getDate()}`.slice(leadingZerosSlice)}`;
    }
  }

  ngAfterViewInit(): void {
    const originalOnChange = this.host.onChangeFn;

    this.host.onChangeFn = (value: any) => {
      const formattedValue = this.formatDateObject(value);
      originalOnChange(formattedValue);
    };
  }
}

import {
  IDocument,
  IField,
  ISelect,
  ISelectOption,
  SelectType,
} from 'app/services/api5-service/api.interface';

import { IFormModel } from 'common/dynamic-form/dynamic-form.interface';
import { getLabel } from 'common/dynamic-form/utils/get-label';
import { DocHelper } from 'utils/dochelper';

import { SelectTO } from './model/select-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';

/**
 * Check select visibility by select hiding logic
 *
 * @param formState - form state
 * @param control - control (subfield) metadata
 * @param field - field config
 * @param options - original select options
 */
export function isSelectHidden(
  formState: ISPFormState,
  control: ISelect,
  field: IField,
  options: ISelectOption[],
): boolean {
  const controlName = control.$name;
  const fieldName = field.$name;
  const isDepend = Boolean(control.$depend);

  const isHiddenByCondition = formState.hiddenService.isHidden(
    controlName,
    fieldName,
  );
  const isSelectEmpty = !options.length;
  const isSelectDependAndOnlyNullIsLeft =
    isDepend && options.length === 1 && options[0].$key === 'null';
  return (
    isHiddenByCondition || isSelectEmpty || isSelectDependAndOnlyNullIsLeft
  );
}

/**
 * Get depend select options
 *
 * @param options - original select options
 * @param model - form model
 * @param fieldConfig - field config
 */
export function getDependSelectOptions(
  options: ISelectOption[],
  model: IFormModel,
  fieldConfig: ISPFieldConfig<ISPFieldType.Radio | ISPFieldType.Select>,
): ISelectOption[] {
  const dependField = fieldConfig.templateOptions.depend;
  const dependValue = model[dependField];
  if (!dependValue) {
    return [];
  }
  const newOptionList = options.filter(
    o => o.$depend === undefined || o.$depend === dependValue,
  );
  return newOptionList;
}

/**
 * Autoselect first option from all select options
 *
 * @param options - select options
 * @param model - form model
 * @param fieldConfig - field config
 * @param allowNull - allow autoslect 'null' as first option. Extform license.order and handlers table filter case
 */
export function autoselectSelectOptions(
  options: ISelectOption[],
  model: IFormModel,
  fieldConfig: ISPFieldConfig<ISPFieldType.Radio | ISPFieldType.Select>,
  allowNull?: boolean,
): void {
  const value = model[fieldConfig.key];

  let valueArray: string[];
  if (Array.isArray(value)) {
    valueArray = value;
  } else {
    valueArray = value ? (value as string).split(',') : [];
  }

  const currentValueIsNotInOptions = options.every(
    o => !valueArray.includes(o.$key),
  );
  const haveOptionsToChoose = options.length > 0;
  const isRadioOrSingleSelect =
    fieldConfig.type === ISPFieldType.Radio ||
    !fieldConfig.templateOptions.multiple;

  // set value if changed options
  const canAutoselect =
    currentValueIsNotInOptions && haveOptionsToChoose && isRadioOrSingleSelect;
  if (canAutoselect) {
    const firstOptionValue = options[0].$key;
    const firstOptionIsNotNull = firstOptionValue !== 'null';

    if (allowNull || firstOptionIsNotNull) {
      fieldConfig.formControl.setValue(firstOptionValue, {
        emitEvent: true,
      });
    }
  }
}

/**
 * Get config for select field
 *
 * @param control - control (subfield) metadata
 * @param field - field metadata
 * @param state - dynamic form state
 */
export function getSelectConfig(
  control: ISelect,
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.Select> {
  const options = state.selectService.getOptionsForSelect(control.$name) || [];
  const templateOptions: SelectTO = {
    inputLabel: getLabel(field, state.doc),
    originalControl: control,
    originalField: field,
    setValues: control.$setvalues,
    options: options,
    multiple: control.$type === SelectType.Multiple,
    depend: control.$depend,
    // why 'null' !?
    placeholder: DocHelper.getMessage('null', state.doc),
    notFoundText: DocHelper.getMessage('select_option_not_found', state.doc),
    selectAllText: DocHelper.getMessage('msg_select_all', state.doc),
    hintPlace: 'field',
    mixedPlaceholder: DocHelper.getMessage('placeholder_mixed_msg', state.doc),
    isMixed: state.mixedService.isControlMixed(control),
    listWidth: state.selectDropdownWidth,
    isPrefixForInput: false,
    anchorWidth: state.selectAnchorWidth,
    succeededDrawerMetadata:
      state.drawerParentService.drawersMetadata[control.$name]
        ?.succeededSelectMetadata,
    isHidden: isSelectHidden(state, control, field, options),
  };

  return {
    key: control.$name,
    type: ISPFieldType.Select,
    wrappers: [ISPFieldWrapper.InputBase],
    templateOptions,
    expressionProperties: {
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        isSelectHidden(
          formState,
          fieldConfig.templateOptions.originalControl,
          fieldConfig.templateOptions.originalField,
          fieldConfig.templateOptions.options,
        ),
      'templateOptions.succeededDrawerMetadata': (_, formState) =>
        formState.drawerParentService.drawersMetadata[control.$name]
          ?.succeededSelectMetadata,
      'templateOptions.isMixed': (_, formState) =>
        formState.mixedService.isControlMixed(control),
      'templateOptions.options': (model, formState, fieldConfig) => {
        const selectOptions =
          formState.selectService.getOptionsForSelect(control.$name) || [];

        const isSelectDepends = Boolean(fieldConfig.templateOptions.depend);

        let result: ISelectOption[];

        if (isSelectDepends) {
          const dependOptions = getDependSelectOptions(
            selectOptions,
            model,
            fieldConfig,
          );
          autoselectSelectOptions(dependOptions, model, fieldConfig, true);

          return dependOptions;
        } else {
          result = selectOptions;
        }

        autoselectSelectOptions(result, model, fieldConfig);

        const drawerSelectMetadata =
          formState.drawerParentService.drawersMetadata[control.$name];
        if (drawerSelectMetadata?.succeededSelectMetadata) {
          const optionToChangeText = result.find(
            o => o.$key === drawerSelectMetadata.selectDrawerValue,
          );
          if (optionToChangeText) {
            optionToChangeText.$ =
              drawerSelectMetadata.succeededSelectMetadata.drawerOptionText;
          }
        }

        return result;
      },
    },
  };
}

/**
 * Get the html template of an option for when the option has an image and when it doesn't
 *
 * @param option - select option data from doc.slist
 * @param optionText - option text, which may contain html as search result among options
 * @param doc - form doc
 */
export function getHTMLTemplateForOption(
  option: ISelectOption | ISelectOption[] | undefined,
  optionText: string,
  doc: IDocument,
): string {
  if (!option) {
    return DocHelper.getMessage('null', doc);
  }

  if (Array.isArray(option)) {
    return optionText;
  }

  if (!('$image' in option) && optionText) {
    return optionText;
  }

  const imageTemplate = `<span class="select__option-image" style="background-image: url('${option.$image}');"></span>`;
  const htmlOptionTemplate = `<span class="select__option-container">${imageTemplate}${optionText}</span>`;

  return htmlOptionTemplate;
}

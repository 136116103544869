import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IFormButtonUi } from './model/form-button.interface';

/**
 * Form button component
 *
 * Usage:
 * ```html
 * <isp-form-button *ngFor="let button of buttonList$ | async" [button]="button"></isp-form-button>
 * ```
 */
@Component({
  selector: 'isp-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./scss/form-button.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonComponent {
  /** button metadata */
  @Input() button: IFormButtonUi;

  /** button click event */
  @Output() readonly btnClick = new EventEmitter<void>();

  /** hint message */
  get hint(): Observable<string> {
    return this.button.disabledSubject.pipe(
      map(disabled => (disabled ? this.button.hintDisabled : this.button.hint)),
    );
  }

  /** size for button icons */
  readonly svgIconSize = {
    width: '20px',
    height: '20px',
  };

  /**
   * Sends the click event
   */
  handleButtonClick(): void {
    this.btnClick.emit();
  }
}

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DrawerManagerService } from 'app/layout/drawer-manager/drawer-manager.service';
import {
  IDocument,
  TSetValueType,
} from 'app/services/api5-service/api.interface';
import { MessageBusService } from 'app/services/messagebus/messagebus.service';
import { IDocService } from 'components/chat/chat.interface';
import { getButtonList, IFormButtonUi } from 'components/form-button';
import { IFormCollapseEvent, ILinkClickEvent } from 'components/form-collapse';
import { Subject, merge } from 'rxjs';
import {
  debounceTime,
  takeUntil,
  tap,
  distinctUntilChanged,
  filter,
  startWith,
  skip,
} from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import { ValidationLoader } from './config/validations.loader';
import { IFormButtonClickEvent, IFormModel } from './dynamic-form.interface';
import { isFieldValid, isFormSubmittable } from './dynamic-form.utils';
import {
  ISPFieldConfig,
  ISPFormOptions,
  ISPFieldType,
  ISPFormState,
  ISPFieldTypeWithControl,
  DynamicFormContext,
} from './model';
import { ButtonsService } from './services/buttons.service';
import { CaptchaService } from './services/captcha.service';
import { ConditionService } from './services/condition.service';
import { DisabledService } from './services/disabled.service';
import { DrawerChildService } from './services/drawer-child.service';
import {
  DrawerParentService,
  ISucceededDrawerSelectMetadata,
} from './services/drawer-parent.service';
import { HiddenService } from './services/hidden.service';
import {
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
  LayoutService,
} from './services/layout.service';
import { ListFieldService } from './services/list-field.service';
import { MixedService } from './services/mixed-service';
import { ModeService } from './services/mode.service';
import { SelectService } from './services/select.service';
import { SetValuesService } from './services/set-values.service';
import {
  TemplateConfig,
  concatTemplateConfigs,
  getLayoutConfig,
  isSelectedKeyValue,
} from './types';
import { getTypedForm, TypedForm } from './utils/form-preparing';
import { forEachConfig, findConfig } from './utils/formly-configs';
import {
  getConfigsFromTypedForm,
  setTypedFormToServices,
} from './utils/formly-configs-generation';
import { isFieldWithControl } from './utils/is-field-with-control';
import { removePropertyList } from './utils/remove-prop';
import { SERVER_ERROR_VALIDATOR_NAME } from './wrappers/validation-error/validation-error.utils';

/**
 * Default list dropdown width
 *
 * @WARN setted from js, it cannot be setted from css variables, cause dropdown opens out of dynamic-form css scope
 */
const LIST_DROPDOWN_DEFAULT_WIDTH = '400px';

/**
 * Field list service
 */
@UntilDestroy()
@Injectable()
export class DynamicFormService implements OnDestroy, IDocService {
  private destroy$: Subject<void> = new Subject();

  private readonly FORM_RENDER_TIME = 100;

  /** When updating the form on setValues any field, need to reconfigure the form fields */
  readonly reconfiguringFields$: Subject<string> = new Subject();

  /** form group */
  formGroup: FormGroup;

  /** form fields */
  fieldList: ISPFieldConfig[];

  /** document */
  get doc(): IDocument {
    return this.options.formState.doc;
  }

  /** form model */
  model = {};

  /** data about drawer selects which have been successfully resolved (i.e. new entity in drawer form was validated and submitted) */
  initialSucceededDrawerSelectsMetadata: Record<
    string,
    ISucceededDrawerSelectMetadata
  > = {};

  /** formly options */
  options: ISPFormOptions;

  /** page collapse event */
  readonly collapseEvent = new EventEmitter<IFormCollapseEvent>();

  /** button click event */
  readonly buttonClickEvent = new EventEmitter<IFormButtonClickEvent>();

  /** link click event */
  readonly linkClickEvent = new EventEmitter<ILinkClickEvent>();

  readonly changeModelEvent = new EventEmitter<IFormModel>();

  readonly succeededDrawerSelectsMetadataUpdateEvent = new EventEmitter<
    Record<string, ISucceededDrawerSelectMetadata>
  >();

  /** should the form initiate in base mode (when it comes with two modes) */
  startInBaseMode = true;

  /** name of extendable select inside form, if passed form should show appear in a drawer and display only certain fields and another set of buttons */
  isDrawerFor = '';

  /** whether hints should be displayed or not */
  showHints = true;

  /** width of dropdown list for controls, that use dropdown for displaying */
  listWidth = LIST_DROPDOWN_DEFAULT_WIDTH;

  templates: TemplateConfig[];

  /** dynamic form context information */
  context: DynamicFormContext;

  validationBoundingElement: string =
    DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;

  dropdownParentSelector: string = DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;

  constructor(
    private readonly setValuesService: SetValuesService,
    private readonly validationLoader: ValidationLoader,
    private readonly conditionService: ConditionService,
    private readonly hiddenService: HiddenService,
    private readonly modeService: ModeService,
    private readonly disabledService: DisabledService,
    private readonly buttonsService: ButtonsService,
    private readonly mixedService: MixedService,
    private readonly listFieldService: ListFieldService,
    private readonly selectService: SelectService,
    private readonly layoutService: LayoutService,
    private readonly messageBusService: MessageBusService,
    private readonly drawerParentService: DrawerParentService,
    private readonly drawerChildService: DrawerChildService,
    private readonly captchaService: CaptchaService,
    private readonly drawerManagerService: DrawerManagerService,
  ) {}

  /**
   * Reduce the existing form model to contain only empty values. Needed to properly reset the form.
   */
  private getEmptyModel(): any {
    const model = {};
    forEachConfig(this.fieldList || [], (field: ISPFieldConfig) => {
      if (!isFieldWithControl(field)) {
        return;
      }

      const originalControl = field.templateOptions.originalControl;

      const name = originalControl.$name;

      switch (true) {
        case field.type === ISPFieldType.Checkbox:
          model[name] = 'off';
          break;
        case field.type === ISPFieldType.Select &&
          !field.templateOptions.multiple:
          model[name] = 'null';
          break;
        default:
          model[name] = '';
          break;
      }
    });
    return model;
  }

  /**
   * Subscribe to model change for save model in tab and run setvalues
   */
  private subscribeToChangeModel(): void {
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      // get raw model for get disabled controls values
      this.changeModelEvent.emit(this.getUnmixedFormModel());
    });
  }

  /**
   * Get form model with disabled controls and without mixed controls
   */
  private getUnmixedFormModel(): IFormModel {
    return removePropertyList(
      this.formGroup.getRawValue(),
      this.mixedService.getMixedControlNames(),
    );
  }

  /**
   * Set autofocus flag to input-like field (which has error of any usual one)
   *
   * @param configs - form configs
   */
  private setAutofocus(configs: ISPFieldConfig[]): ISPFieldConfig[] {
    let specificFindCondition: (f: ISPFieldConfig) => boolean;
    if (this.options.formState.errorFromServer) {
      // first we try to focus a field containing error from server, even if it's currently hidden by form mode (the mode is supposed to be switched a moment later by another method)
      specificFindCondition = f => {
        const hasError =
          f.validators?.[SERVER_ERROR_VALIDATOR_NAME] !== undefined;
        const isVisibleOrHiddenOnlyByMode =
          !f.templateOptions.isHidden || f.templateOptions.isHiddenByMode;
        return isVisibleOrHiddenOnlyByMode && hasError;
      };
    } else {
      // otherwise we try to focus the first focusable visible field - it should be a base field if we are in base mode now, or the mode should be extended
      specificFindCondition = f => !f.templateOptions.isHidden;
    }

    type FocusableField =
      | ISPFieldConfig<ISPFieldType.InputText>
      | ISPFieldConfig<ISPFieldType.Password>
      | ISPFieldConfig<ISPFieldType.TextArea>;

    const isFieldFocusabel = (field: ISPFieldConfig): field is FocusableField =>
      field.type === ISPFieldType.Password ||
      field.type === ISPFieldType.InputText ||
      field.type === ISPFieldType.TextArea;

    const fieldToFocus = findConfig(configs, (field: ISPFieldConfig) => {
      if (field.type === ISPFieldType.List) {
        return 'skip-node-and-childs';
      }

      return (
        isFieldFocusabel(field) &&
        field.templateOptions?.originalControl?.$readonly !== 'yes' &&
        specificFindCondition(field)
      );
    }) as FocusableField | undefined;

    if (fieldToFocus) {
      fieldToFocus.templateOptions.autofocus = true;
    }

    return configs;
  }

  private addHooksToFields(fields: ISPFieldConfig[]): void {
    forEachConfig(fields, field => {
      if (!isFieldWithControl(field)) {
        return;
      }

      this.setValuesService.checkAndStartIntervalSetValues(
        field.templateOptions.originalControl,
      );

      field.hooks = {
        ...field.hooks,
        afterContentInit: (
          fieldConfig: ISPFieldConfig<ISPFieldTypeWithControl>,
        ) => {
          if (fieldConfig.templateOptions.originalControl?.if) {
            fieldConfig.formControl.updateValueAndValidity({
              onlySelf: false,
              emitEvent: true,
            });
          }
        },
        onInit: (fieldConfig: ISPFieldConfig<ISPFieldTypeWithControl>) => {
          // subscribing to value changes will only be for fields that are added to the model formly (by key)
          if (!fieldConfig.key) {
            return;
          }

          let valueChange = fieldConfig.formControl.valueChanges.pipe(
            startWith(fieldConfig.formControl.value),
            distinctUntilChanged((prev, curr) => {
              if (
                fieldConfig.type === ISPFieldType.AutocompleteSelect &&
                curr.length === 0
              ) {
                return false;
              }

              return String(prev) === String(curr);
            }),
            tap(v => {
              this.conditionService.updateControlValue(
                fieldConfig.templateOptions.originalControl,
                v,
              );
            }),
            // if it's a drawer select, open or hide the drawer
            tap(value => {
              if (
                fieldConfig.type !== ISPFieldType.Select ||
                this.isDrawerFor
              ) {
                return;
              }
              const drawerSelect =
                this.drawerParentService.drawersMetadata[fieldConfig.key];
              if (drawerSelect?.succeededSelectMetadata) {
                return;
              }
              if (drawerSelect) {
                this.toggleDrawer(
                  drawerSelect.selectName,
                  value === drawerSelect.selectDrawerValue,
                );
              }
            }),
            // skip initial value - it's required to set all hiddenService or mixedService states, and not to trigger setValues
            skip(1),
            tap(() => {
              const originalControl =
                fieldConfig.templateOptions.originalControl;
              if (originalControl && '$mixed' in originalControl) {
                if (
                  fieldConfig.formControl.value === '' &&
                  originalControl.$mixed === 'yes'
                ) {
                  this.mixedService.addControl(originalControl);
                } else {
                  this.mixedService.removeControl(originalControl);
                }
              }
            }),
          );

          if (fieldConfig.type === ISPFieldType.AutocompleteSelect) {
            // need to send a request to the server for an input-lite field
            // only after a short delay, marking the end of the user's input
            const eventDelayTime = 200;
            valueChange = valueChange.pipe(debounceTime(eventDelayTime));
          }

          // subscribe to value for setvalues controls and if/else
          valueChange
            .pipe(
              takeUntil(
                merge(
                  this.reconfiguringFields$.pipe(
                    filter(v => v === fieldConfig.key),
                  ),
                  this.destroy$,
                ),
              ),
            )
            .subscribe(() => {
              this.handleFieldChangeEvent(fieldConfig);
            });
        },
      };
    });
  }

  /**
   * Emit drawer request
   *
   * @param selectName - extendable select name
   * @param needToOpen - emit request to open drawer, otherwise to cancel and close drawer
   */
  private toggleDrawer(selectName: string, needToOpen = true): void {
    if (needToOpen) {
      this.drawerManagerService.openDrawer({
        type: 'form-segment',
        selectName,
        doc: this.doc,
        model: {
          ...this.model,
          // this is for avoiding hiddenService hiding the fields
          [selectName]:
            this.drawerParentService.drawersMetadata[selectName]
              .selectDrawerValue,
        },
      });
    } else {
      this.drawerManagerService.close({ type: 'form-segment', selectName });
    }
  }

  /**
   * Emits the `setValues` event if the changed field has it
   *
   * @param field - formly field config
   */
  private handleFieldChangeEvent(
    field: ISPFieldConfig<ISPFieldTypeWithControl>,
  ): void {
    if (field.type === ISPFieldType.AutocompleteSelect) {
      if (!isSelectedKeyValue(field, this.options.formState)) {
        // is select emit not some options key, then it must be search string value
        // that should be treated by sv_field mechanism
        this.setValuesService
          .handleSetValues('yes', field.key, {
            sv_autocomplete: 'yes',
          })
          .subscribe();
        return;
      }
    }

    if (
      field.type === ISPFieldType.InputText ||
      field.type === ISPFieldType.TextArea
    ) {
      const setValues: TSetValueType = field.templateOptions.setValues;
      if (setValues) {
        const minLength = field.templateOptions.setValuesMinLength;
        if (Boolean(minLength) && field.formControl.value.length < minLength) {
          return;
        }

        this.setValuesService.handleSetValues(setValues, field.key).subscribe();
        return;
      }
    }

    const setValues: TSetValueType = field.templateOptions.setValues;
    if (setValues) {
      this.setValuesService.handleSetValues(setValues, field.key).subscribe();
    }
  }

  /**
   * Gets new options
   *
   * @param doc
   */
  private resetOptions(doc: IDocument): ISPFormOptions {
    const baseFieldSet = DocHelper.getBaseFieldNamesSet(doc);
    this.modeService.setNamesForBaseMode(baseFieldSet);
    // switching modes makes sense only in normal drawer-parent form, a drawer-child should not bother about modes
    if (this.isDrawerFor === '') {
      this.modeService.toggleMode(
        baseFieldSet.size > 0 && this.startInBaseMode ? 'base' : 'extended',
      );
    }

    this.listFieldService.resetFilter();

    // @TODO i.ablov reset all other services!
    return {
      formState: {
        selectDropdownWidth: this.listWidth,
        selectService: this.selectService,
        listService: this.listFieldService,
        disabledService: this.disabledService,
        conditionService: this.conditionService,
        hiddenService: this.hiddenService,
        buttonsService: this.buttonsService,
        modeService: this.modeService,
        layoutService: this.layoutService,
        mixedService: this.mixedService,
        validationLoader: this.validationLoader,
        doc: doc,
        errorFromServer: doc.error,
        context: this.context,
        showHints: this.showHints,
        validationBoundingElement: this.validationBoundingElement,
        drawerParentService: this.drawerParentService,
        drawerChildService: this.drawerChildService,
        dropdownParentSelector: this.dropdownParentSelector,
      },
    };
  }

  private showFieldValidation(config: ISPFieldConfig): void {
    // validation apears on invalid and touched fields. Assumed that this method used on invalid fields
    this.formGroup.controls[config.key].markAsTouched();

    this.markForCheck(config);
  }

  private scrollToErrorField(fieldName?: string): void {
    const configToSpot: ISPFieldConfig = findConfig(this.fieldList, config => {
      if (fieldName && 'originalControl' in config.templateOptions) {
        return config.templateOptions.originalControl.$name === fieldName;
      } else {
        return !isFieldValid(config);
      }
    });

    if (!configToSpot) {
      return;
    }

    this.layoutService.scrollToField(configToSpot).then(() => {
      this.showFieldValidation(configToSpot);
    });
  }

  private clearServicesState(): void {
    this.disabledService.clear();
    this.conditionService.clear();
    this.modeService.clear();
    this.hiddenService.clear();
    this.mixedService.clear();
    this.drawerParentService.clear();
    this.drawerChildService.clear();
    this.selectService.clear();
    this.buttonsService.clear();
    this.listFieldService.clear();
  }

  /**
   * Actualize form model to have changes made in drawer, because parent form should keep drawer form's state for being able to represent it after tab switch
   *
   * @param selectName - drawer select name
   * @param model - from model from drawer
   */
  private syncModelWithDrawer(selectName: string, model: IFormModel): void {
    this.formGroup.patchValue(
      this.drawerParentService.getSuccessDrawerFormModel(selectName, model),
    );
  }

  private subscribeToChildDrawerEvents(): void {
    this.messageBusService
      .on$('dynamic-form-drawer-cancel')
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        const drawerData =
          this.drawerParentService.drawersMetadata[message.payload];
        if (!drawerData || drawerData.succeededSelectMetadata) {
          return;
        }
        this.formGroup.patchValue({
          [drawerData.selectName]: drawerData.selectDefaultValue,
        });
      });

    this.messageBusService
      .on$('dynamic-form-drawer-success')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ payload }) => {
        this.drawerParentService.updateSucceededDrawerSelectMetadata(payload);
        this.succeededDrawerSelectsMetadataUpdateEvent.emit(
          this.drawerParentService.getSucceededDrawerSelectsMetadata(),
        );
        this.syncModelWithDrawer(payload.selectName, payload.model);
      });

    this.messageBusService
      .on$('dynamic-form-drawer-model-update')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ payload }) => {
        this.syncModelWithDrawer(payload.selectName, payload.model);
      });
  }

  /**
   * Handle click on the new entity link in a drawer select
   *
   * @param selectName - select name
   */
  handleDrawerSelectEditClick(selectName: string): void {
    this.toggleDrawer(selectName);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.drawerParentService.clear();
    this.collapseEvent.complete();
  }

  /**
   * Initializes the service
   *
   * @param doc - response's document
   */
  init(doc: IDocument): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();
    this.clearServicesState();

    this.formGroup = new FormGroup({});
    this.options = this.resetOptions(doc);

    // so far, drawer forms cannot be parents for other drawers (due to the mess with reflecting all parent's drawers again because of the same doc)
    if (this.isDrawerFor === '') {
      this.options.formState.drawerParentService.init(
        this.doc,
        this.initialSucceededDrawerSelectsMetadata,
      );
    }

    this.options.formState.drawerChildService.init(this.doc, this.isDrawerFor);
    this.options.formState.selectService.setOptions(this.doc?.slist);
    this.options.formState.selectService.setFormGroup(this.formGroup);
    this.options.formState.listService.addElements(this.doc?.list);
    this.options.formState.layoutService.init(this);
    this.options.formState.buttonsService.init(this);
    this.setValuesService.init(this);

    const buttons = this.isDrawerFor
      ? this.options.formState.drawerChildService.formButtons
      : getButtonList(
          this.doc,
          this.doc?.metadata.form?.buttons?.button || [],
          this.showHints,
        );
    this.options.formState.buttonsService.setFooterButtons(buttons);

    this.options.formState.validationLoader.initValidatorMessages(
      this.options.formState,
    );

    this.subscribeToChangeModel();

    const typedForm = getTypedForm(this.options.formState.doc);

    // @WARN initiallize services must be earlier than configs generation!
    setTypedFormToServices(typedForm, this.options.formState);

    this.fieldList = this.generateConfigs(typedForm, this.options.formState);
    this.setAutofocus(this.fieldList);

    this.messageBusService
      .on$('toggle-dynamic-form-mode')
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        this.layoutService.togglePageMode(message.payload);
      });

    if (doc?.error) {
      // set timeout for formly form rendering
      setTimeout(() => {
        this.scrollToErrorField(doc.error.$object);
      }, this.FORM_RENDER_TIME);
    }

    if (this.isDrawerFor === '') {
      this.subscribeToChildDrawerEvents();
    }
  }

  markForCheck(config: ISPFieldConfig): void {
    // @HACK we need to trigger formly change detection system, to activate validation appearing
    // we can use bus to trigger cdr on some certain fields, but this required many boilarplate code
    // in formly v6 this function will be exposed outside, so it will be valid way to trigger cdr
    // @TODO i.ablov update formly to v6, remove this function
    (this.options as any)._markForCheck(config);
  }

  /**
   * Generates configs from doc state and custom templates for dynamic-form
   *
   * @param typedForm - typed doc
   * @param state - form state
   */
  generateConfigs(
    typedForm: TypedForm | undefined,
    state: ISPFormState,
  ): ISPFieldConfig[] {
    const configs = getConfigsFromTypedForm(typedForm, state);

    this.addHooksToFields(configs);

    const configsWithTemplates = concatTemplateConfigs(configs, this.templates);

    const configsWithLayout = [getLayoutConfig(configsWithTemplates)];

    return configsWithLayout;
  }

  /**
   * Reset the form
   */
  resetForm(): void {
    this.formGroup?.reset(this.getEmptyModel(), { onlySelf: true });
  }

  /**
   * Reset dynamic form state
   */
  reset(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$ = new Subject<void>();

    delete this.formGroup;
    delete this.model;
    delete this.fieldList;

    this.clearServicesState();
  }

  /**
   * Emits the page collapse event.
   *
   * @param event - collapse event
   */
  emitPageCollapseEvent(event: IFormCollapseEvent): void {
    this.collapseEvent.emit(event);
  }

  /**
   * Emits the form button click event and form's value
   *
   * @param button - clicked button
   * @param additionalParams - additional params for form submiting
   */
  async emitButtonClick(
    button: IFormButtonUi,
    additionalParams: IFormModel = {},
  ): Promise<void> {
    if (button.$type === 'setvalues') {
      button.preloaderSubject.next(true);
      this.setValuesService
        .handleSetValues(
          button.$blocking ? 'blocking' : undefined,
          button.$name,
          additionalParams,
          true,
        )
        .subscribe({
          complete: () => {
            button.preloaderSubject.next(false);
          },
        });
    } else {
      const form: IFormModel = {
        ...this.getUnmixedFormModel(),
        ...additionalParams,
      };
      const event: IFormButtonClickEvent = {
        button,
        form,
      };
      if (this.captchaService.isNeedToGetCaptchaForButtonClick(event)) {
        try {
          const resolvedValue = await this.captchaService
            .getCaptchaResolve$()
            .toPromise();
          this.captchaService.appendCaptchaResolvedValueToParams(
            event.form,
            resolvedValue,
          );
        } catch (e: unknown) {
          // event was rejected or captcha error
          return;
        }
      }
      this.buttonClickEvent.emit(event);
    }
  }

  emitLinkEvent(event: ILinkClickEvent): void {
    this.linkClickEvent.emit(event);
  }

  /**
   * Sends the keyboard event to submit
   *
   * @param event - keyboard "Enter" key press event
   */
  submitFromKeyboard(event: KeyboardEvent): void {
    const allowedTagNameList = ['input'];
    const isAllowed = event.composedPath().some((element: HTMLElement) => {
      const isElementAllowed = allowedTagNameList.includes(element.localName);
      if (isElementAllowed) {
        element.blur();
      }
      return isElementAllowed;
    });
    if (!isAllowed && !event.ctrlKey) {
      return;
    }
    if (!isFormSubmittable(this.fieldList)) {
      this.scrollToErrorField();
      return;
    }
    // @TODO i.ablov do as in core-manager/dist/skins/orion/src/App.Forms.js:532 clickButtonTrigger function
    const submitButton = this.buttonsService.getFirstSubmitButtonFromFooter();
    if (submitButton && !submitButton.disabledSubject.value) {
      this.emitButtonClick(submitButton);
    }
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo } from 'rxjs/operators';

import { PopupPlacement } from '@ispui/tooltip';

import { DynamicFormService } from 'common/dynamic-form/dynamic-form.service';
import { ISPFieldWrapperBase } from 'common/dynamic-form/model/field-wrapper-base.class';
import {
  DYNAMIC_FORM_HEADER_SELECTOR,
  DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR,
} from 'common/dynamic-form/services/layout.service';

/**
 * Base field wrapper component for Formly
 */
@UntilDestroy()
@Component({
  selector: 'isp-formly-field-base',
  templateUrl: './field-base.component.html',
  styleUrls: ['./scss/field-base.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldBaseComponent
  extends ISPFieldWrapperBase
  implements OnInit, OnDestroy
{
  /**
   * @HACK
   * ispui-tooltip doesn't watch for clicks outside it's component, so it can't determine when it should be closed
   * so we use this static distincted listener, to determine clicks outside field
   * @TODO add global click watching in ispui-tooltip component
   */
  private static readonly documentClick: Observable<HTMLElement> = fromEvent(
    document,
    'mousedown',
  ).pipe(
    map((event: MouseEvent) => event.target as HTMLElement),
    distinctUntilChanged(),
  );

  private readonly openTooltipOrder$: Subject<boolean> = new Subject();

  get hintAttachContainer(): string {
    if (this.to.layoutPlace === 'header') {
      return DYNAMIC_FORM_HEADER_SELECTOR;
    }

    if (this.dynamicFormService.isDrawerFor) {
      return `#drawer-dynamic-form ${DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR}`;
    }

    return DYNAMIC_FORM_SCROLLABLE_CONTAINER_SELECTOR;
  }

  get isForceHint(): boolean {
    return this.options.formState.context.forceHint;
  }

  /** icon sizes */
  readonly iconSize = {
    width: '15px',
    height: '14px',
  };

  @ViewChild('tooltip') tooltip: ElementRef<HTMLIspuiTooltipElement>;

  /** hint placement */
  get hintPlace(): 'field' | 'label' {
    return this.to.hintPlace === 'label' ? 'label' : 'field';
  }

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private readonly dynamicFormService: DynamicFormService,
  ) {
    super();
  }

  /**
   * Not all fields has focus to trigger tooltip opening. So we use CustomEvent to trigger tooltip opening
   *
   * @param event
   */
  @HostListener('open-tooltip', ['$event']) onOpenTooltipOrder(
    event: CustomEvent<boolean>,
  ): void {
    this.openTooltipOrder$.next(event.detail);
  }

  ngOnInit(): void {
    if (this.isForceHint) {
      merge(
        this.openTooltipOrder$,
        FieldBaseComponent.documentClick.pipe(
          filter(target => !this.host.nativeElement.contains(target)),
          mapTo(false),
        ),
      )
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe(state => {
          this.tooltip?.nativeElement.toggle(state);
        });
    }
  }

  ngOnDestroy(): void {
    this.openTooltipOrder$.complete();
  }

  /**
   * Get tooltip placement
   */
  getTooltipPlacement(): PopupPlacement[] {
    return this.dynamicFormService.options.formState.context.isFullWidth
      ? ['bottomRight', 'right']
      : ['right', 'bottomLeft', 'topLeft', 'left'];
  }

  /**
   * Get hint width shadow part
   */
  getHint(): string {
    if (this.to.isShadow) {
      return `${this.to.hint} </br></br><strong>${this.to.shadowHint}</strong>`;
    }
    return this.to.hint;
  }

  /**
   * Get plainhint message
   */
  getPlainHint(): string {
    if (!this.to.plainhints) return '';

    const fieldValue = this.form.getRawValue()[this.to.controlWithPlainHint];

    return this.to.plainhints[
      `hint_${this.to.controlWithPlainHint}__${fieldValue}`
    ];
  }
}

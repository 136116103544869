import { IControl, IField } from 'app/services/api5-service/api.interface';

export interface DateTimeControlWithValue {
  readonly control: IControl;
  readonly type: 'datetime';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 */
export function getTypeControlsForDateTime(
  field: IField,
): DateTimeControlWithValue[] {
  return (field.datetime || []).map(control => {
    return {
      value: '',
      control,
      type: 'datetime',
    };
  });
}

import { ISPFormState } from '../../model';
import { TypedField } from '../form-preparing';

/**
 * Set typed field to form state services
 *
 * @param field - typed field
 * @param state - form state
 */
export function setTypedFieldToServices(
  field: TypedField,
  state: ISPFormState,
): void {
  field.controls.forEach(control => {
    if ('value' in control) {
      state.hiddenService.handleControl(control.control);
      state.conditionService.handleControl(control.control, control.value);
      state.disabledService.handleControl(control.control);
      state.mixedService.addControl(control.control);
    }
  });
}

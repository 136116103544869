import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ListCellFieldModule } from './list-cell/list-cell.module';
import { ListFieldComponent } from './list.component';

import { ISPFieldType } from '../../model';

/**
 * List formly field module
 */
@NgModule({
  declarations: [ListFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.List,
          component: ListFieldComponent,
        },
      ],
    }),
    ListCellFieldModule,
  ],
})
export class ListFieldModule {}

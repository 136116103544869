import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

@Component({
  selector: 'isp-formly-group-field',
  templateUrl: './group.component.html',
  styleUrls: ['./scss/group.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupFieldComponent extends ISPFieldTypeBase<ISPFieldType.Group> {}

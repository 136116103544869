<div
  class="chat-bubble"
  [ngClass]="[
    'chat-bubble_type_' + data.type,
    'chat-bubble_align_' + data.align || 'left'
  ]"
>
  <ng-container *ngIf="data.avatar">
    <img
      *ngIf="
        !data.avatar.isDefault && data.avatar.path;
        else bubbleImageDefault
      "
      [src]="data.avatar.path"
      [alt]="data.avatar.caption"
      class="chat-bubble__avatar"
    />

    <ng-template #bubbleImageDefault>
      <isp-svg-icon
        class="chat-bubble__avatar"
        [name]="defaultAvatarIcon"
        [customStyle]="{ width: '35px', height: '35px' }"
      >
      </isp-svg-icon>
    </ng-template>
  </ng-container>
  <div class="chat-bubble__content">
    <span *ngIf="data.chatTitle" class="chat-bubble__title">
      <span class="chat-bubble__chat-title">{{ data.chatTitle }}</span>
      <span class="chat-bubble__chat-title-caption">{{
        data.chatTitleCaption
      }}</span>
    </span>

    <span *ngIf="data.subtitle" class="chat-bubble__subtitle">
      {{ data.subtitle }}</span
    >

    <p class="chat-bubble__body">{{ data.body }}</p>

    <div *ngIf="data.files" class="chat-bubble__files">
      <div
        *ngFor="let file of data.files; trackBy: trackByFile"
        class="chat-bubble__file"
        (click)="handleFileClick(file)"
      >
        <isp-svg-icon
          class="chat-bubble__file-icon"
          name="p-file-100"
          [customStyle]="{ width: '14px', height: '17.5px' }"
        >
        </isp-svg-icon>

        <div class="chat-bubble__file-text">
          <span class="chat-bubble__file-title">{{ file.title }}</span>
          <span class="chat-bubble__file-size">{{ file.size }}</span>
        </div>
      </div>
    </div>

    <div *ngIf="data.rates?.canShowRates" class="chat-bubble__rate">
      <span class="chat-bubble__rate-text">{{
        data.rates.isRated ? data.labels.alreadyRated : data.labels.setRate
      }}</span>
      <button
        *ngFor="let rate of data.rates.rates; trackBy: trackByRate"
        (click)="handleRateClick(rate)"
        [disabled]="data.rates.isRated"
        class="chat-bubble__rate-button"
      >
        <isp-svg-icon
          class="chat-bubble__rate-icon"
          [name]="getRateIconName(rate.img)"
          [customStyle]="{ width: '0.71em', height: '0.71em' }"
        >
        </isp-svg-icon>
      </button>
    </div>
  </div>
</div>

import { InjectionToken } from '@angular/core';

import { IDocument } from 'app/services/api5-service/api.interface';

/**
 * Doc service token
 *
 * @TODO i.ablov move to a more general place
 */
export const docInjectionToken = new InjectionToken('doc');

/**
 * Doc service interface
 *
 * @TODO i.ablov move to a more general place
 */
export interface IDocService {
  readonly doc: IDocument;
}

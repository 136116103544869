import { getWrappedConfigsFromTypedField } from './get-wrapped-configs-from-typed-field';

import { ISPFieldConfig, ISPFieldType, ISPFormState } from '../../model';
import {
  getFormScrollerConfig,
  getPageGroupConfig,
  getSiteSummaryConfig,
} from '../../types';
import { TypedForm } from '../form-preparing';
import { someConfig } from '../formly-configs/some-config';

/**
 * Get configs list for pages
 *
 * @param typedForm - typed doc
 * @param state - dynamic form state
 */
export function getConfigsFromTypedForm(
  typedForm: TypedForm | undefined,
  state: ISPFormState,
): ISPFieldConfig[] {
  if (!typedForm) {
    return [];
  }

  let configs: ISPFieldConfig[] = [];
  if ('pages' in typedForm) {
    configs = typedForm.pages.map(page => {
      const pageConfigs = page.fields
        .map(field => getWrappedConfigsFromTypedField(field, state))
        .flat();

      return getPageGroupConfig(pageConfigs, state, page.page);
    });
  } else if ('fields' in typedForm) {
    configs = typedForm.fields
      .map(field => getWrappedConfigsFromTypedField(field, state))
      .flat();
  }
  if (typedForm.summary) {
    // for now only site-summary treated from summary metadata
    configs.push(getSiteSummaryConfig(typedForm.summary, state));
  }
  const isHaveChat = someConfig(
    configs,
    c =>
      c.type === ISPFieldType.Chat &&
      Boolean(c.templateOptions.messages.length),
  );
  if (isHaveChat) {
    configs.push(
      getFormScrollerConfig('isp-chat > isp-chat-bubble:last-child'),
    );
  }

  return configs;
}

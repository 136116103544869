import {
  IDocument,
  IField,
  ITree,
} from 'app/services/api5-service/api.interface';

import { DocHelper } from 'utils/dochelper';

export interface TreeControlWithValue {
  readonly control: ITree;
  readonly type: 'tree';
  value: string;
}

/**
 * Map control metadata to typed object with value from doc state
 *
 * @param field - field metadata
 * @param doc - doc
 */
export function getTypeControlsForTree(
  field: IField,
  doc: IDocument,
): TreeControlWithValue[] {
  return (field.tree ? [field.tree[0]] : []).map(control => {
    return {
      value: DocHelper.getStringValue(control.$name, doc),
      control,
      type: 'tree',
    };
  });
}

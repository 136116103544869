import { replaceConfig } from 'common/dynamic-form/utils/formly-configs';

import { TemplateConfig } from './model/template-config.interface';

import { ISPFieldConfig } from '../../model';

/**
 * Separate templates configs to templates that should be added and templates that should replace some other configs
 *
 * @param templates
 */
export function separateTemplatesByInsertingType(
  templates: TemplateConfig[],
): [TemplateConfig[], TemplateConfig[]] {
  return templates.reduce<[TemplateConfig[], TemplateConfig[]]>(
    ([toAdd, toReplace], config) => {
      if (config.templateOptions.replace) {
        toReplace.push(config);
      } else {
        toAdd.push(config);
      }
      return [toAdd, toReplace];
    },
    [[], []],
  );
}

/**
 * Replace some configs with templates
 *
 * @param configs
 * @param templates
 */
export function replaceConfigByTemplates(
  configs: ISPFieldConfig[],
  templates: TemplateConfig[],
): void {
  templates.forEach(template => {
    if (template.templateOptions.replace) {
      template.templateOptions.replacedConfig = replaceConfig(
        configs,
        template,
        template.templateOptions.replace,
      );
    }
  });
}

/**
 * Add templates to other configs, according to provided template order
 *
 * @param configs
 * @param templates
 */
export function addTemplateToConfigs(
  configs: ISPFieldConfig[],
  templates: TemplateConfig[],
): ISPFieldConfig[] {
  const negativeOrderedTempaltes = templates
    .filter(config => config.templateOptions.order < 0)
    .sort((a, b) => {
      const ao = a.templateOptions.order || 0;
      const bo = b.templateOptions.order || 0;
      return bo - ao;
    });
  const positiveOrderedTempaltes = templates
    .filter(
      config =>
        !config.templateOptions.order || config.templateOptions.order > 0,
    )
    .sort((a, b) => {
      const ao = a.templateOptions.order || 0;
      const bo = b.templateOptions.order || 0;
      return ao - bo;
    });

  return [...positiveOrderedTempaltes, ...configs, ...negativeOrderedTempaltes];
}

/**
 * Concat templates array and BE configs array according to templates order
 *
 * @param configs - original configs
 * @param templates - additional template configs
 */
export function concatTemplateConfigs(
  configs: ISPFieldConfig[],
  templates: TemplateConfig[],
): ISPFieldConfig[] {
  const [templatesToAdd, templatesToReplace] =
    separateTemplatesByInsertingType(templates);

  replaceConfigByTemplates(configs, templatesToReplace);
  return addTemplateToConfigs(configs, templatesToAdd);
}

import { Inject, Injectable, Optional } from '@angular/core';

import { mergeDeepRight } from 'ramda';

import { WINDOW, WindowWrapper } from '@ngispui/window-service';

import { AppService } from '../../app.service';

/**
 * LocalStorage wrapper service.
 * Saves only JSON data.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(
    @Inject(WINDOW) private readonly window: WindowWrapper,
    // can be absent for extforms or showcase. BILLmgr case
    @Optional() private readonly appService?: AppService,
  ) {}

  private getKeyUserBind(key: string): string {
    return `${key}__${this.appService?.getUserName() || 'anonymous'}`;
  }

  /**
   * Returns the data from LocalStorage
   *
   * @param key - the keyword for data
   */
  get<D extends object>(key: string): D {
    const rawData = this.window.localStorage.getItem(this.getKeyUserBind(key));
    return JSON.parse(rawData) || undefined;
  }

  /**
   * Saves the data to LocalStorage
   *
   * @param key - the keyword for data
   * @param value - the data to store
   */
  set<D extends object>(key: string, value: D): any {
    return this.window.localStorage.setItem(
      this.getKeyUserBind(key),
      JSON.stringify(value),
    );
  }

  /**
   * Patches the existing data in LocalStorage
   *
   * @param key - the keyword for data
   * @param value - the new data to store
   */
  patch<D extends object>(key: string, value: D): any {
    const data = this.get(key);
    if (!data) {
      return this.set(key, value);
    }
    const newData = mergeDeepRight(this.get(key), value);
    return this.set(key, newData);
  }

  /**
   * Removes the data with given keyword from LocalStorage
   *
   * @param key - the keyword for data to remove
   */
  remove(key: string): any {
    return this.window.localStorage.removeItem(this.getKeyUserBind(key));
  }
}

<ng-container *ispDynamicFormTemplate="buttonsConfig; let field">
  <ng-container *ngIf="field.to.buttonListSubject | async as buttonList">
    <footer *ngIf="canShowFooter" class="footer">
      <div
        *ngIf="serverErrorToShow$ | async as serverErrorText"
        class="ispui-banner ispui-banner_with-close ispui-banner_status_danger server-error-banner"
      >
        <div
          class="ispui-banner__close banner-close"
          (click)="dismissServerError()"
        >
          <isp-svg-icon
            class="banner-close__icon"
            name="close_s"
          ></isp-svg-icon>
        </div>
        <div class="ispui-banner__icon"></div>
        <div class="ispui-banner__content">
          <div class="ispui-banner__text">
            <ispui-collapse [previewHeight]="35" #collapsible>
              <div
                class="server-error-banner__text"
                [innerHTML]="serverErrorText"
              ></div>
              <ispui-collapse-button class="collapse-button">
                <span>{{
                  (messages$ | async)[
                    collapsible.isOpen ? 'msg_collapse' : 'msg_expand'
                  ]
                }}</span>
                <isp-svg-icon
                  class="collapse-button-icon"
                  [name]="collapsible.isOpen ? 'down' : 'up'"
                  [customStyle]="{ width: '8px', height: '4px' }"
                ></isp-svg-icon>
              </ispui-collapse-button>
            </ispui-collapse>
          </div>
        </div>
      </div>
      <div class="buttons" *ngIf="buttonList.length">
        <isp-form-button
          *ngFor="let button of buttonList; trackBy: trackByButtonFn"
          [button]="button"
          (btnClick)="emitButtonClick(button)"
        ></isp-form-button>
      </div>
    </footer>
  </ng-container>
</ng-container>

<ng-container *ispDynamicFormTemplate="generalErrorConfig; let field">
  <div class="form__general-error" *ngIf="field.to.errorMsg">
    {{ field.to.errorMsg }}
  </div>
</ng-container>

<form *ngIf="formGroup" [formGroup]="formGroup" class="form" autocomplete="off">
  <ispui-tooltip-wrapper>
    <formly-form
      class="form__formly-component"
      [form]="formGroup"
      [model]="model"
      [fields]="fieldList"
      [options]="options"
      (keyup.enter)="submitFromKeyboard($event)"
    ></formly-form>

    <ispui-preloader-round
      class="form__preloader"
      [loading]="(isBlocked$ | async) && showBlockingPrealoder"
      view="fixed"
      [spinnerSize]="60"
    ></ispui-preloader-round>
  </ispui-tooltip-wrapper>
</form>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';
import { FormButtonModule } from 'components/form-button';

import { ISPUICollapseModule } from '@ispui/ng-collapse';
import { ISPUIPreloaderRoundModule } from '@ispui/ng-preloader-round';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { DynamicFormComponent } from './dynamic-form.component';
import { ISPValidator } from './model';
import {
  InputFieldModule,
  DatePickerFieldModule,
  DateTimeModule,
  SelectFieldModule,
  AutocompleteSelectFieldModule,
  RadioFieldModule,
  PhoneGroupModule,
  CheckboxFieldModule,
  HiddenFieldModule,
  TextDataFieldModule,
  FrameFieldModule,
  ListFieldModule,
  ListFilterModule,
  SliderFieldModule,
  PasswordFieldModule,
  TreeFieldModule,
  TextareaFieldModule,
  FileFieldModule,
  CodeFieldModule,
  LayoutFieldModule,
  LinkFieldModule,
  GroupFieldModule,
  TemplateFieldModule,
  ChatFieldModule,
  TextEditorFieldModule,
  DynamicFormTemplateDirective,
  ChatSummaryFieldModule,
  SiteSummaryFieldModule,
  FormScrollerFieldModule,
  CaptchaFieldModule,
  TextWithLinkModule,
} from './types';
import {
  passMatchValidator,
  duplicateValidator,
} from './validators/validators';
import { FieldBaseModule } from './wrappers/field-base/field-base.module';
import { InputBaseModule } from './wrappers/input-base/input-base.module';
import { PageWrapperModule } from './wrappers/page/page.module';
import { ValidationErrorModule } from './wrappers/validation-error/validation-error.module';

const fieldModuleList = [
  InputFieldModule,
  DatePickerFieldModule,
  DateTimeModule,
  SelectFieldModule,
  AutocompleteSelectFieldModule,
  RadioFieldModule,
  PhoneGroupModule,
  CaptchaFieldModule,
  CheckboxFieldModule,
  HiddenFieldModule,
  TextDataFieldModule,
  FrameFieldModule,
  ListFieldModule,
  ListFilterModule,
  SliderFieldModule,
  PasswordFieldModule,
  TreeFieldModule,
  TextareaFieldModule,
  FileFieldModule,
  CodeFieldModule,
  LayoutFieldModule,
  LinkFieldModule,
  GroupFieldModule,
  TemplateFieldModule,
  ChatFieldModule,
  ChatSummaryFieldModule,
  SiteSummaryFieldModule,
  FormScrollerFieldModule,
  TextEditorFieldModule,
  TextWithLinkModule,
];

const wrapperModuleList = [
  FieldBaseModule,
  InputBaseModule,
  PageWrapperModule,
  ValidationErrorModule,
];

/**
 * Fields list module
 *
 * Usage:
 * ```html
 * <isp-field-list [fieldList]="fieldList$ | async"></isp-field-list>
 * ```
 */
@NgModule({
  declarations: [DynamicFormComponent, DynamicFormTemplateDirective],
  exports: [DynamicFormComponent, DynamicFormTemplateDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormButtonModule,
    ISPUIPreloaderRoundModule,
    ISPUITooltipModule,
    ISPUICollapseModule,
    SvgIconModule,
    ...fieldModuleList,
    ...wrapperModuleList,
    FormlyModule.forRoot({
      validators: [
        { name: ISPValidator.PassMatch, validation: passMatchValidator },
        { name: ISPValidator.Duplicate, validation: duplicateValidator },
      ],
    }),
  ],
})
export class DynamicFormModule {}

import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * frame field component
 *
 * Use only with Formly
 */
@Component({
  selector: 'isp-formly-frame-field',
  templateUrl: './frame.component.html',
  styleUrls: ['./scss/frame.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrameFieldComponent extends ISPFieldTypeBase<ISPFieldType.Frame> {
  /** flag of full width state of field */
  @HostBinding('style.width')
  get width(): string {
    return this.to.isFullWidth ? '100%' : '';
  }

  /** flag of full height state of field */
  get isFullheight(): boolean {
    return Boolean(this.to.originalField?.frame[0]?.$fullheight);
  }
}

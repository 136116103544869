import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormlyConfig } from '@ngx-formly/core';
import { IControl, IDocument } from 'app/services/api5-service/api.interface';
import { Api5Service } from 'app/services/api5-service/api5.service';
import { Observable, Subject } from 'rxjs';
import { filter, map, pluck, tap } from 'rxjs/operators';

import { DocHelper } from 'utils/dochelper';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFormState,
  ISPValidator,
} from '../model';

/**
 * Loader for validation messages
 */
@Injectable()
export class ValidationLoader {
  private readonly patchAfterValidationSubject: Subject<{
    key: string;
    value: string;
  }> = new Subject();

  constructor(
    private readonly formlyConfig: FormlyConfig,
    private readonly api: Api5Service,
  ) {}

  private notifyAboutValidationPatch(controlName: string, value: string): void {
    this.patchAfterValidationSubject.next({ key: controlName, value });
  }

  getNotificationAfterValidationPatch(controlName: string): Observable<string> {
    return this.patchAfterValidationSubject.pipe(
      filter(notify => notify.key === controlName),
      pluck('value'),
    );
  }

  /**
   * Init local validators
   *
   * @param state - form state
   */
  initValidatorMessages(state: ISPFormState): void {
    this.registerValidationMessage(
      ISPValidator.Required,
      () => state.context.validationRequiredMsg,
    );
    this.registerValidationMessage(ISPValidator.PassMatch, () =>
      DocHelper.getMessage('msg_pwcheck_donotmatch', state.doc),
    );
    this.registerValidationMessage(
      ISPValidator.Duplicate,
      () => state.context.validationDuplicateMsg,
    );
    this.registerValidationMessage(
      ISPValidator.Maxsize,
      (_, fieldConfig: ISPFieldConfig<ISPFieldType.InputFile>) => {
        const errorMsg = state.context.validationFileMaxSizeMsg;
        const formDoc = fieldConfig.options.formState.doc;
        const maxsizeMsg = DocHelper.getMessage(
          `maxsize_${fieldConfig.templateOptions.originalControl.$name}`,
          formDoc,
        );
        return errorMsg.replace('__VALUE__', maxsizeMsg);
      },
    );
  }

  /**
   * Register message for invalid field
   *
   * @param validatorName - validator name
   * @param message - message for invalid validator
   */
  registerValidationMessage(
    validatorName: string,
    message: string | ((error: any, fieldConfig: ISPFieldConfig) => string),
  ): void {
    this.formlyConfig.addValidatorMessage(validatorName, message);
  }

  /**
   * Get validator for field control
   *
   * @param field - field metadata
   * @param control
   * @param value
   * @param validationLoader
   * @param doc - doc instance
   */
  getFieldValidator(
    field: IControl,
    control: AbstractControl,
    value: string,
    validationLoader: ValidationLoader,
    doc: IDocument,
  ): Observable<boolean> {
    return this.api
      .checkFieldValidation({
        func: `check.${field.$check}`,
        name: field.$name,
        funcname: doc.$func,
        value,
        args: field.$checkargs,
        tconvert: field.$convert,
      })
      .pipe(
        tap((res: IDocument) => {
          if (res.error) {
            validationLoader.registerValidationMessage(
              `check_${field.$check}_${field.$name}`,
              DocHelper.getError(res),
            );
          } else if (res?.value?.$) {
            const validator = control.asyncValidator;
            const newValue = control.value.replace(value, res.value.$);
            if (newValue !== control.value) {
              control.asyncValidator = null;
              control.setValue(newValue, { emitEvent: false });
              control.asyncValidator = validator;
              validationLoader.notifyAboutValidationPatch(
                field.$name,
                newValue,
              );
            }
          }
        }),
        map((res: IDocument) => {
          if (res.$func === 'error') {
            return false;
          }
          return true;
        }),
      );
  }
}

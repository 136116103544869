import { awaitStencilElement } from './await-stencil-element';

/**
 * Statuses of lazy loadable elements.
 * never-rendered - element never was appeared in DOM, component not even start loading
 * pending - element was rendered and probably start loading lazy loadable chunk for component logic
 * hydrated - loaded and hydrated
 */
type StencilElementStatus = 'hydrated' | 'never-rendered' | 'pending';

type StencilLazyLoadingElements = 'ispui-tooltip-wrapper' | 'ispui-tooltip';

const LAZY_LOADABLE_ELEMENTS: StencilLazyLoadingElements[] = [
  'ispui-tooltip-wrapper',
  'ispui-tooltip',
];

const storage = LAZY_LOADABLE_ELEMENTS.reduce(
  (s, tag) => {
    s[tag] = {
      status: 'never-rendered',
      listeners: [],
    };
    return s;
  },
  {} as Record<
    StencilLazyLoadingElements,
    {
      status: StencilElementStatus;
      listeners: (() => void)[];
    }
  >,
);

/**
 * Await for element hydration
 *
 * @param tag
 * @param element
 */
async function awaitElementHydration(
  tag: StencilLazyLoadingElements,
  element: HTMLElement,
): Promise<void> {
  storage[tag].status = 'pending';

  await awaitStencilElement(element, { lazy: true });

  storage[tag].status = 'hydrated';

  storage[tag].listeners.forEach(l => l());
  storage[tag].listeners.length = 0;
}

// Check DOM for elements already rendered somewhere
LAZY_LOADABLE_ELEMENTS.map(async tag => {
  const element = document.getElementsByTagName(tag)?.[0];
  if (element) {
    await awaitElementHydration(tag, element);
  }
});

// If some of lazy loadable elements is not considered as hydrated, then start listening of DOM change
if (Object.values(storage).some(({ status }) => status !== 'hydrated')) {
  const observer = new MutationObserver(mutations => {
    mutations.map(async mutation => {
      const target = mutation.target as HTMLElement;
      const tag: string = target.tagName?.toLowerCase();

      if (storage[tag]?.status === 'never-rendered') {
        await awaitElementHydration(tag as StencilLazyLoadingElements, target);

        if (
          Object.values(storage).every(({ status }) => status === 'hydrated')
        ) {
          observer.disconnect();
        }
      }
    });
  });
  observer.observe(document, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
  });
}

/**
 * Check if stencil lazy loadable element is loaded and hydrated
 *
 * @param tag - lazy loadable tag name
 */
export function isStencilElementLoaded(
  tag: StencilLazyLoadingElements,
): boolean {
  return storage[tag].status === 'hydrated';
}

/**
 * Await for stencil lazy loadable element loading. If it's already loaded, then promise will be resolved immediately
 *
 * @param tag
 */
export async function awaitStencilElementLazy(
  tag: StencilLazyLoadingElements,
): Promise<void> {
  if (isStencilElementLoaded(tag)) {
    return Promise.resolve();
  }

  return new Promise(r => storage[tag].listeners.push(r));
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';

import { ChatService } from 'components/chat/chat.service';

import {
  IChatBubble,
  IChatBubbleFile,
  IChatBubbleRate,
} from './chat-bubble.interface';

@Component({
  selector: 'isp-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./scss/chat-bubble.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBubbleComponent {
  /** Chat bubble data */
  @Input() data: IChatBubble;

  get defaultAvatarIcon(): string {
    switch (this.data.avatar.type) {
      case 'customer':
        return 'client_avatar';
      case 'provider':
        return 'support_avatar';
      default:
        return 'tech_message';
    }
  }

  constructor(
    private readonly chatService: ChatService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  private setRate(rate: IChatBubbleRate): void {
    this.data.rates.isRated = true;
    this.data.rates.rates = this.data.rates.rates.filter(
      r => r.func === rate.func,
    );
  }

  /**
   * Returns icon name for rate
   *
   * @param icon - name of icon from backend
   */
  getRateIconName(icon: string): string {
    switch (icon) {
      case 'p-like':
        return 'like';
      case 'p-dislike':
        return 'dislike';
      default:
        return '';
    }
  }

  /**
   * TrackBy function for files
   *
   * @param index - file index
   * @param file - file
   */
  trackByFile(index: number, file: IChatBubbleFile): string {
    return `${index}_${file.title}`;
  }

  /**
   * TrackBy function for rates
   *
   * @param index - rate index
   * @param rate - rate
   */
  trackByRate(index: number, rate: IChatBubbleRate): string {
    return `${index}_${rate.img}`;
  }

  /**
   * Handle rate click event when message is not yet rated
   *
   * @param rate - rate data
   */
  handleRateClick(rate: IChatBubbleRate): void {
    if (this.data.rates.isRated) {
      return;
    }

    this.chatService.rateMessage(this.data.id, rate.func).subscribe(() => {
      this.setRate(rate);
      this.cdr.markForCheck();
    });
  }

  /**
   * Handle file click event for form
   *
   * @param file - file data
   */
  handleFileClick(file: IChatBubbleFile): void {
    this.chatService.redirectToFileLoading(file.action, file.params);
  }
}

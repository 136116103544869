import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DynamicFormService } from '../../dynamic-form.service';
import { ISPFieldTypeBase, ISPFieldType } from '../../model';

/**
 * Link formly field
 */
@Component({
  selector: 'isp-formly-link',
  templateUrl: './link.component.html',
  styleUrls: ['./scss/link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkFieldComponent extends ISPFieldTypeBase<ISPFieldType.Link> {
  constructor(private readonly dynamicFormService: DynamicFormService) {
    super();
  }

  /**
   * Opens new tab in the panel
   */
  openTab(): void {
    this.dynamicFormService.emitLinkEvent({
      func: this.to.href,
      isNewTab: this.to.isNewTab,
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconComponent } from './svg-icon.component';
import { SvgSpriteComponent } from './svg-sprite.component';

/**
 * Module for define svg icons and use it
 */
@NgModule({
  imports: [CommonModule],
  declarations: [SvgIconComponent, SvgSpriteComponent],
  exports: [SvgIconComponent, SvgSpriteComponent],
})
export class SvgIconModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormlyModule } from '@ngx-formly/core';

import { ISPUIInputModule } from '@ispui/ng-input';
import { ISPUILinkModule } from '@ispui/ng-link';
import { ISPUITooltipModule } from '@ispui/ng-tooltip';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { InputFieldComponent } from './input.component';

import { ISPFieldType } from '../../model';

/**
 * Formly's input field component
 */
@NgModule({
  declarations: [InputFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: [
        {
          name: ISPFieldType.InputText,
          component: InputFieldComponent,
        },
      ],
    }),
    ISPUILinkModule,
    ISPUIInputModule,
    SvgIconModule,
    FormsModule,
    ISPUITooltipModule,
  ],
})
export class InputFieldModule {}

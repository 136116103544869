<div class="texteditor">
  <editor
    [formControl]="formControl"
    [formlyAttributes]="field"
    [init]="to.tinymceOptions"
    (onInit)="turnOffPreloaderOnInitEditor()"
    #textEditor
  ></editor>
  <ispui-preloader-round
    *ngIf="pending"
    [loading]="true"
    class="preloader"
    view="local"
  >
  </ispui-preloader-round>
</div>

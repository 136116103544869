import { IField } from 'app/services/api5-service/api.interface';

import { TextWithLinkTO } from './model/text-with-link-to.interface';

import {
  ISPFieldConfig,
  ISPFieldType,
  ISPFieldWrapper,
  ISPFormState,
} from '../../model';
import { FIELD_HIDDEN_CLASS } from '../../utils/class-fields';
import { isGroupFieldShouldBeHidden } from '../group/group.utils';
import { LinkConfig } from '../link';
import { TextDataConfig } from '../textdata';

/**
 * extract text and link from array containing them both as configs
 *
 * @param fields - group fields
 * @returns
 */
export function getTextWithLink(fields: ISPFieldConfig[]): {
  text: TextDataConfig;
  link: LinkConfig;
  hiddenFields: ISPFieldConfig[];
} | null {
  const text = fields.find(
    field => field.type === 'textdata',
  ) as TextDataConfig;
  const link = fields.find(field => field.type === 'link') as LinkConfig;
  const hiddenFields = fields.filter(field => field.type === 'hidden');
  // count as a "text with link" group only a group having one text item,
  // one link and any number of hidden fields
  const isTextWithLink =
    !fields.some(
      field => field !== text && field !== link && field.type !== 'hidden',
    ) &&
    text &&
    link;
  if (isTextWithLink) {
    return { text, link, hiddenFields };
  }
  return null;
}

/**
 * Combine text and link configs into a single group config
 *
 * @param textWithLink
 * @param textWithLink.text
 * @param textWithLink.link
 * @param textWithLink.hiddenFields
 * @param field
 * @param state
 */
export function getTextWithLinkConfig(
  textWithLink: {
    text: TextDataConfig;
    link: LinkConfig;
    hiddenFields: ISPFieldConfig[];
  },
  field: IField,
  state: ISPFormState,
): ISPFieldConfig<ISPFieldType.TextWithLink> {
  const { text, link, hiddenFields } = textWithLink;
  const fieldGroup = [text, link, ...hiddenFields];
  link.templateOptions.img = null;
  const groupShouldBeHidden = isGroupFieldShouldBeHidden(fieldGroup);
  const templateOptions: TextWithLinkTO = {
    isHidden: state.hiddenService.isHidden(field.$name) || groupShouldBeHidden,
  } as TextWithLinkTO;
  return {
    type: ISPFieldType.TextWithLink,
    wrappers: [ISPFieldWrapper.FieldBase],
    templateOptions,
    expressionProperties: {
      'templateOptions.isHidden': (_, formState, fieldConfig) =>
        formState.hiddenService.isHidden(field.$name) ||
        isGroupFieldShouldBeHidden(fieldConfig.fieldGroup),
      className: (_, __, fieldConfig) =>
        fieldConfig.templateOptions.isHidden ? FIELD_HIDDEN_CLASS : '',
    },
    text,
    link,
    hiddenFields,
    fieldGroup,
  };
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NarrowBannerModule } from 'components/narrow-banner/narrow-banner.module';

import { WINDOW_PROVIDERS } from '@ngispui/window-service';

import { SvgIconModule } from 'common/svg-icon-module/svg-icon.module';

import { AppComponent } from './app.component';
import { ExtformModule } from './extform/extform.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SvgIconModule,
    NarrowBannerModule,
    ExtformModule,
  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {
  // @TODO i.ablov add localization for datepicker!
}
